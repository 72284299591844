import Vue from 'vue';
/**
 Event Bus (事件总线) 
事件总线机制在组件或页面之间通信。一个页面发送事件，另一个页面监听事件并刷新数据。
举例
    //PageA.vue (触发事件的页面)
    import { EventBus } from './eventBus';
    EventBus.$emit('data-updated', newData);
    //PageB.vue (监听事件的页面)
    import { EventBus } from './eventBus';
    //下面的方法是在 mounted 中 refreshData 是mounteds中的事件
    EventBus.$on('data-updated', (newData) => {
    this.refreshData(newData);
});
 */
export const EventBus = new Vue();