import request from "@/utils/request";

/**
 * 登录
 * @param {
 *  userEmail
 *  userPassword
 * } data
 * @returns
 */
export const login = (data) =>
  request({
    url: "/login",
    method: "post",
    data,
  });

// 登出
export const loginOut = (data) =>
  request({
    url: "/logout",
    method: "post",
    data,
  });

  // 记录当前网站访问次数+1
export const recordVisit = (data) =>
  request({
    url: "/sys/visit/add",
    method: "post",
    data,
  });

// 注册验证码
export const registerCode = (data) =>
  request({
    url: "/register/code",
    method: "get",
    params: data,
  });

// 注册
export const register = (data) =>
  request({
    url: "/register",
    method: "post",
    data,
  });

// 修改密码,登录状态
export const updatePassword = (data) =>
  request({
    url: "/user/update/password",
    method: "post",
    data,
  });

// 修改密码,未登录状态,获取邮箱验证码
export const updatePasswordGetCode = (data) =>
  request({
    url: "/user/update/password/getCode",
    method: "get",
    params: data,
  });

// 修改密码,未登录状态
export const updatePasswordNotLogin = (data) =>
  request({
    url: "/user/update/password/notLogin",
    method: "post",
    data,
  });

// 查询用户信息
export const getUserDetail = (data) =>
  request({
    url: "/user/detail",
    method: "get",
    params: data,
  });

// 修改用户账户相关
export const userDetailUpdate = (data) =>
  request({
    url: "/user/update/detail",
    method: "post",
    data,
  });

// 实时成交订单 /riom/list
export const getRiomList = (data) =>
  request({
    url: "/riom/list",
    method: "get",
    params: data,
  });

// 查询评价列表 /comment/list
export const getCommentList = (data) =>
  request({
    url: "/comment/list",
    method: "get",
    params: data,
  });

// 快递国家列表
export const getCountryList = (data) =>
  request({
    url: "/express/delivery/country/list",
    method: "get",
    params: data,
  });

// 地址详情列表
export const addressList = (data) =>
  request({
    url: "/user/address/list",
    method: "get",
    params: data,
  });

// 根据地址id删除地址详情
export const addressDelete = (data) =>
  request({
    url: "/user/address/delete",
    method: "post",
    params: data,
  });

// 新增收货地址
export const addressAdd = (data) =>
  request({
    url: "/user/address/add",
    method: "post",
    data,
  });

// 根据地址id修改地址详情
export const addressUpdate = (data) =>
  request({
    url: "/user/address/update",
    method: "post",
    data,
  });

// 查询帮助中心列表
export const helpList = (data) =>
  request({
    url: "/help/list",
    method: "get",
    params: data,
  });

// 查询帮助中心列表
export const helpDetail = (data) =>
  request({
    url: "/help/detail",
    method: "get",
    params: data,
  });

// 查询轮播图列表
export const slideshowList = (data) =>
  request({
    url: "/slideshow/list",
    method: "get",
    params: data,
  });

// 查询订单进度通知列表
export const orderMsgList = (data) =>
  request({
    url: "/order/msg/list",
    method: "get",
    params: data,
  });

// 标记为已读
export const msgRead = (data) =>
  request({
    url: "/order/msg/read",
    method: "post",
    data,
  });

// 查询未读订单通知条数
export const orderMsgUnread = (data) =>
  request({
    url: "/order/msg/unread",
    method: "get",
    params: data,
  });

// 查询新闻博客列表
export const getNewsList = (data) =>
  request({
    url: "/news/list",
    method: "get",
    params: data,
  });

// 查询新闻博客详情
export const getNewsDetail = (data) =>
  request({
    url: "/news/detail",
    method: "get",
    params: data,
  });

// 获取图形验证码,前端需传入uuid,前端自主生成
export const getCaptcha = (data) =>
  request({
    url: "/quick/contact/captcha",
    method: "get",
    responseType: "arraybuffer",
    params: data,
  });

// 创建快速联系
export const quickAdd = (data) =>
  request({
    url: "/quick/contact/add",
    method: "post",
    data,
  });

// 下单实时计算 /shop/order/valuation
export const orderValuation = (data) =>
  request({
    url: "/shop/order/valuation",
    method: "post",
    data,
  });

// /shop/order/stencil/price
export const getStencilPrice = (data) =>
  request({
    url: "/shop/order/stencil/price",
    method: "get",
    params: data,
  });

// pcb下单页面配置属性列表
export const getPcbConfig = (data) =>
  request({
    url: "/pcb/config/detail/pcb",
    method: "get",
    params: data,
  });

// pcba下单页面配置属性列表
export const getPcbaConfig = (data) =>
  request({
    url: "/pcb/config/detail/pcba",
    method: "get",
    params: data,
  });

// 钢网下单页面配置属性列表
export const getStencilConfig = (data) =>
  request({
    url: "/pcb/config/detail/stencil",
    method: "get",
    params: data,
  });

// pcb-pcba添加购物车
export const addPcbCart = (data) =>
  request({
    url: "/shop/cart/add/pcb",
    method: "post",
    data,
  });

// stencil添加购物车
export const addStencilCart = (data) =>
  request({
    url: "/shop/cart/add/stencil",
    method: "post",
    data,
  });

// 查询pcba的bom文件版本
export const getCartBom = (data) =>
  request({
    url: "/shop/cart/bom/ver",
    method: "get",
    params: data,
  });

// 查询优惠券列表 /discount/coupon/list
export const getCoupon = (data) =>
  request({
    url: "/discount/coupon/list",
    method: "get",
    params: data,
  });

// 查询购物车商品数量
export const getCartNumber = (data) =>
  request({
    url: "/shop/cart/product/number",
    method: "get",
    params: data,
  });

// 查看购物车
export const getCartView = (data) =>
  request({
    url: "/shop/cart/view",
    method: "get",
    params: data,
  });

// 从购物车删除商品
export const deleteCart = (data) =>
  request({
    url: "/shop/cart/delete",
    method: "post",
    data,
  });

// 预览订单总价格(包含快递费用,以及可用优惠券)
export const getPreviewPrice = (data) =>
  request({
    url: "/shop/order/preview/price",
    method: "get",
    params: data,
  });

// 支付手续费预览
export const getPayPreview = (data) =>
  request({
    url: "/shop/extra/price/pay/preview",
    method: "get",
    params: data,
  });

// 支付差价前计算手续费-并支付
export const extraPricePay = (data) =>
  request({
    url: "/shop/extra/price/pay",
    method: "get",
    params: data,
  });

// 待付款订单支付预览手续费
export const getPendingView = (data) =>
  request({
    url: "/shop/order/pending/view",
    method: "get",
    params: data,
  });

// 修改待支付订单的支付方式
export const orderPaymentType = (data) =>
  request({
    url: "/shop/order/payment/type",
    method: "post",
    data,
  });

// 查询是否有待支付的补款订单
export const getOrderSupplement = (data) =>
  request({
    url: "/shop/order/supplement",
    method: "get",
    params: data,
  });

// 查询补款订单列表
export const getExtraPriceList = (data) =>
  request({
    url: "/shop/extra/price/list",
    method: "get",
    params: data,
  });

// 提交商品生成订单
export const orderPay = (data) =>
  request({
    url: "/shop/order/pay",
    method: "post",
    data,
  });

// 创建PayPal支付订单
export const paypalPay = (data) =>
  request({
    url: "/payment/paypal/pay",
    method: "get",
    params: data,
  });

// stripe信用卡支付
export const stripePay = (data) =>
  request({
    url: "/payment/stripe/pay",
    method: "get",
    params: data,
  });

// 生成人工转账支付记录
export const paymentManual = (data) =>
  request({
    url: "/payment/manual",
    method: "post",
    data,
  });

// 删除-取消未支付的订单
export const deleteOrder = (data) =>
  request({
    url: "/shop/order/delete",
    method: "post",
    params: data,
  });

// 查询不同状态订单
export const orderSearch = (data) =>
  request({
    url: "/shop/order/search",
    method: "get",
    params: data,
  });

// 查询订单中包含的商品信息,以及商品价格
export const orderProduct = (data) =>
  request({
    url: "/shop/order/product",
    method: "get",
    params: data,
  });

// 上传bom文件并解析
export const uploadBom = (data) =>
  request({
    url: "/shop/file/upload/bom",
    method: "POST",
    data,
  });

// 上传gerber文件,并获取对应文件的解析填充信息,以及在线查看gerber文件页面
export const uploadGerber = (data) =>
  request({
    url: "/shop/file/upload/gerber",
    method: "POST",
    data,
  });

// 根据uuid获取看图页面信息
export const getView = (data) =>
  request({
    url: "/shop/file/view/html",
    method: "get",
    params: data,
  });

// 上传文件
export const uploadFile = (data) =>
  request({
    url: "/shop/file/upload",
    method: "POST",
    data,
  });

// 确认变更
export const acknowledge = (data) =>
  request({
    url: "/shop/cart/acknowledge",
    method: "POST",
    params: data,
  });

// 拷贝商品
export const shopCopyProduct = (data) =>
  request({
    url: "/shop/order/copy",
    method: "post",
    data,
  });

// 打印发票
export const downloadInvoice = (data) =>
  request({
    url: "/shop/order/invoice",
    method: "get",
    responseType: "blob",
    params: data,
  });

// 补款打印发票
export const downloadSupplementInvoice = (data) =>
  request({
    url: "/shop/extra/price/invoice",
    method: "get",
    responseType: "blob",
    params: data,
  });

// pay支付成功的回调接口
export const paypalNotify = (data) =>
  request({
    url: "/payment/paypal/timely/notify",
    method: "get",
    params: data,
  });

// pay支付成功的回调接口
export const stripeNotify = (data) =>
  request({
    url: "/payment/stripe/timely/notify",
    method: "get",
    params: data,
  });

// 查询站内信
export const getLetterList = (data) =>
  request({
    url: "/letter/list",
    method: "get",
    params: data,
  });

// 创建反馈
export const letterAdd = (data) =>
  request({
    url: "/letter/add",
    method: "POST",
    params: data,
  });

// 回复反馈
export const letterReply = (data) =>
  request({
    url: "/letter/reply",
    method: "POST",
    params: data,
  });

// 公告列表
export const getNoticeList = (data) =>
  request({
    url: "/notice/list",
    method: "get",
    params: data,
  });

// 根据id查询公告详情
export const getNoticeDetail = (data) =>
  request({
    url: "/notice/detail",
    method: "get",
    params: data,
  });

// 获取头部公告
export const getNoticeTop = (data) =>
  request({
    url: "/notice/top",
    method: "get",
    params: data,
  });

// 获取元器件商品列表
export const getProductList = (data) =>
  request({
    url: "/shop/product/list",
    method: "get",
    params: data,
  });

// 获取元器件商品列表
export const bandCardList = (data) =>
  request({
    url: "/sys/bank/list",
    method: "get",
    params: data,
  });

// 查询支付记录列表
export const getPaymentRecordList = (data) =>
  request({
    url: "/shop/payment/record/list",
    method: "get",
    params: data,
  });

// 人工转账-银行卡转账接口
export const paymentRecordUpload = (data) =>
  request({
    url: "/shop/payment/record/upload",
    method: "post",
    data,
  });

//论坛相关
// 查询论坛类目列表
export const forumsCategory = (data) =>
  request({
    url: "/forums/category",
    method: "get",
    params: data,
  });

// 查询论坛文章标签列表
export const forumsTags = (data) =>
  request({
    url: "/forums/tags",
    method: "get",
    params: data,
  });

// 查询论坛文章热点标签列表
export const forumsTagsStatic = (data) =>
  request({
    url: "/forums/tags/static",
    method: "get",
    params: data,
  });

// 查询论坛文章列表
export const forumsArticleList = (data) =>
  request({
    url: "/forums/article/list",
    method: "get",
    params: data,
  });

export const forumsArticleDetail = (data) =>
  request({
    url: "/forums/article/detail",
    method: "get",
    params: data,
  });

// 新增论坛文章
export const forumsArticleAdd = (data) =>
  request({
    url: "/forums/article/add",
    method: "post",
    data,
  });

// 删除-论坛文章
export const forumsArticleDelete = (data) =>
  request({
    url: "/forums/article/delete",
    method: "post",
    params: data,
  });

//点赞文章
export const forumsArticleLike = (data) =>
  request({
    url: "/forums/article/like",
    method: "post",
    params: data,
  });

//取消点赞文章
export const forumsArticleUnlike = (data) =>
  request({
    url: "/forums/article/unlike",
    method: "post",
    params: data,
  });

//收藏文章
export const forumsArticleCollect = (data) =>
  request({
    url: "/forums/article/collect",
    method: "post",
    params: data,
  });

//取消收藏文章
export const forumsArticleCancelCollect = (data) =>
  request({
    url: "/forums/article/cancel/collect",
    method: "post",
    params: data,
  });

// 查询论坛文章评论列表
export const forumsCommentList = (data) =>
  request({
    url: "/forums/comment/list",
    method: "get",
    params: data,
  });

// 新增论坛文章评论
export const forumsCommentAdd = (data) =>
  request({
    url: "/forums/comment/add",
    method: "post",
    data,
  });

// 删除-论坛文章评论
export const forumsCommentDelete = (data) =>
  request({
    url: "/forums/comment/delete",
    method: "post",
    data,
  });

//点赞评论
export const forumsCommentLike = (data) =>
  request({
    url: "/forums/comment/like",
    method: "post",
    params: data,
  });

//取消点赞评论
export const forumsCommentUnlike = (data) =>
  request({
    url: "/forums/comment/unlike",
    method: "post",
    params: data,
  });

// 论坛文章收藏夹列表
export const forumsFavorites = (data) =>
  request({
    url: "/forums/favorites",
    method: "get",
    params: data,
  });

// 论坛文章点赞列表
export const forumsLikes = (data) =>
  request({
    url: "/forums/likes",
    method: "get",
    params: data,
  });
//
export const getForumsUserDetail = (data) =>
  request({
    url: "/forums/user/detail",
    method: "get",
    params: data,
  });

//商城商品相关
// 查询商品类目列表
export const getProductCategoryList = (data) =>
  request({
    url: "/product/category/list",
    method: "get",
    params: data,
  });
  
export const getProductCategoryOptionsList = (data) =>
  request({
    url: "/product/category/options",
    method: "get",
    params: data,
  });

//查询商品列表
export const getCommodityList = (data) =>
  request({
    url: "/product/list",
    method: "get",
    params: data,
  });

//将商品添加到购物车
export const addProductToCart = (data) =>
  request({
    url: "/shop/cart/add/product",
    method: "post",
    data,
  });

//查询商品详情
export const getProductDetail = (data) =>
  request({
    url: "/product/detail",
    method: "get",
    params: data,
  });

//收藏商品
export const productFavoriteAdd = (data) =>
  request({
    url: "/product/favorite/add",
    method: "post",
    data,
  });

//取消收藏商品
export const productFavoriteCancel = (data) =>
  request({
    url: "product/favorite/cancel",
    method: "post",
    params: data,
  });

//修改购物车商品数量
export const cartUpdateProductNumber = (data) =>
  request({
    url: "/shop/cart/update/product",
    method: "post",
    data,
  });

//将商品从购物车删除
export const deleteProductCart = (data) =>
  request({
    url: "/shop/cart/delete",
    method: "post",
    data,
  });

// 预览订单总价格(包含快递费用,以及可用优惠券)
export const getProductPreviewPrice = (data) =>
  request({
    url: "/shop/cart/preview/price",
    method: "get",
    params: data,
  });

// 查询订单商品数量
export const getOrderNumber = (data) =>
  request({
    url: "/shop/order/number",
    method: "get",
    params: data,
  });

// 查询不同状态商品订单
export const orderProductSearch = (data) =>
  request({
    url: "/shop/order/product/search",
    method: "get",
    params: data,
  });

//查询商品规格评论列表
export const productReviewList = (data) =>
  request({
    url: "/product/review/list",
    method: "get",
    params: data,
  });

//添加商品规格评论
export const productReviewAdd = (data) =>
  request({
    url: "/product/review/add",
    method: "post",
    data,
  });

//获取快递公司列表
export const expressDeliveryCompanyList = (data) =>
  request({
    url: "/express/delivery/company/list",
    method: "get",
    params: data,
  });