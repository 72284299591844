/**
 * 法语语言包
 */
export default {
  1: "Annuler",
  2: "Rechercher",
  3: "Réinitialiser",
  4: "Envoyer",
  5: "Succès.",
  6: "Contenu...",
  //每个页面的标签title
  title: {
    1: "Termes et Conditions | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "Prototype PCB | Assemblage PCBA | Une plateforme pour la fabrication de produits électroniques innovants et agiles | Oceansmile | Lanyue Electronics",
    3: "S'inscrire | Se connecter | Oceansmile | Lanyue Electronics",
    4: "Réinitialiser le mot de passe | Récupérer le mot de passe | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "Centre de support technique et services PCB | Conception électronique | Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Actualités industrielles et centre d'apprentissage | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Actualités industrielles et centre d'apprentissage | Oceansmile | Lanyue Electronics",
    9: "Travail CNC | Pièces métalliques | Impression 3D | Moulage par injection | Pièces sur mesure | Lanyue Electronics",
    10: "SMT | Service d'assemblage PCB | Devis PCB rapide en ligne | Oceansmile | Lanyue Electronics",
    11: "Capacités de production PCB | Donnez vie à vos idées | Oceansmile | Lanyue Electronics",
    12: "À propos de nous - Donnez vie à vos idées | Prototype PCB | Oceansmile | Lanyue Electronics",
    13: "Contactez-nous - Donnez vie à vos idées | Prototype PCB | Oceansmile | Lanyue Electronics",
    14: "Pourquoi nous choisir - Donnez vie à vos idées | Prototype PCB personnalisé | Oceansmile | Lanyue Electronics",
    15: "Devis PCB rapide en ligne | Service d'assemblage PCB | Oceansmile | Lanyue Electronics",
    16: "Modèle SMT | Service d'assemblage PCB | Oceansmile | Lanyue Electronics",
    17: "Détails de la facture | Oceansmile | Lanyue Electronics",
    18: "Informations bancaires | Virement bancaire | Oceansmile | Lanyue Electronics",
    19: "Félicitations ! Paiement réussi | Oceansmile | Lanyue Electronics",
    20: "Produit | Boutique de produits",
    21: "Détails du produit | Boutique de produits",
    22: "Composants | IC Easy Store | Fourniture de composants",
    23: "Visualisation Gerber en ligne | Oceansmile | Lanyue Electronics",
    24: "Panier | Oceansmile | Lanyue Electronics",
    25: "Liste des commandes | Oceansmile | Lanyue Electronics",
    26: "Liste des notifications de commande | Oceansmile | Lanyue Electronics",
    27: "Gérer l'adresse de livraison | Oceansmile | Lanyue Electronics",
    28: "Informations personnelles | Oceansmile | Lanyue Electronics",
    29: "Liste des nouvelles commandes",
    30: "Coupons",
    31: "Réclamations et commentaires",
    32: "Liste des annonces | Oceansmile | Lanyue Electronics",
    33: " | Détails de l'annonce",
    34: "Registres de paiements | Vérification",
    35: "Actualités industrielles et centre d'apprentissage | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Actualités industrielles et centre d'apprentissage | Oceansmile | Lanyue Electronics",
    37: " | Forum centre personnel | Actualités industrielles et centre d'apprentissage | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Centre d'aide",
    2: "Envoyer un message",
    3: "WhatsApp/Lignes de support",
    4: "Mon panier",
    5: "Bienvenue sur Oceansmile",
    6: "Se connecter",
    7: "Nouveau client ?",
    8: "S'inscrire gratuitement",
    9: "E-mail",
    10: "Se déconnecter",
    11: "Panier",
    12: "Mes commandes",
    13: "Notifications de progression des commandes",
    14: "Mes coupons",
    15: "Adresse de livraison",
    16: "Mon profil",
    17: "Lettre",
    18: "Notifications",
    19: "Accueil",
    20: "Assemblage PCB",
    21: "Devis rapide PCB/PCBA",
    22: "Devis rapide de modèles",
    23: "Boutique de produits",
    24: "Actualités et blog",
    25: "Travail CNC/Impression 3D",
    26: "Produit et capacité",
    27: "À propos de nous",
    28: "Contactez-nous",
    29: "Pourquoi nous choisir",
    30: "Devis PCB rapide",
  },
  //脚部页面
  footer: {
    1: {
      1: "CONTENUS RAPIDES",
      2: "Laissez votre message ci-dessous et un de nos représentants vous contactera dans les 48 heures.",
      3: "Entrez votre e-mail",
      4: "S'abonner",
    },
    2: {
      1: "Plus de 10 ans d'expérience dans la fabrication rapide de prototypes PCB et la production de PCB et PCBA de petite à moyenne échelle. Fidélisation des clients avec une qualité et un service supérieurs...",
      2: "Adresse",
      3: "Communauté Songyuansha, Rue Guanhu, District Longhua, Shenzhen, Guangdong, Chine",
      4: "Téléphone",
      5: "Email",
      6: "Certifié par",
    },
    3: {
      1: "Compétences",
      2: "Produits/Services",
      3: "Support client",
      4: "À propos de nous",
      5: "Compétences PCB",
      6: "Devis rapide PCB",
      7: "Contactez-nous",
      8: "À propos de nous",
      9: "Compétences en assemblage PCB",
      10: "Assemblage PCB",
      11: "Centre d'aide",
      12: "Pourquoi acheter chez nous ?",
      13: "Modèles SMD",
      14: "Nos équipements",
      15: "Consultation de projet personnalisée",
      16: "Guide des méthodes d'expédition",
      17: "Compétences PCB en aluminium",
      18: "Solutions industrielles",
      19: "Avis",
      20: "Politique de confidentialité et accords",
      21: "Compétences PCB flexibles",
      22: "Politique de retour",
      23: "Impression 3D/CNC",
      24: "Conditions d'utilisation",
    },
    4: "Copyright © 2024 OSpcb. Tous droits réservés. Lanyue Electronics (Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Centre membre",
    2: "Commande",
    3: "Mon compte",
    4: "Système",
    5: "Panier",
    6: "Mes commandes",
    7: "Durabilité",
    8: "Notification de commande",
    9: "Registres de paiement",
    10: "Adresse",
    11: "Mes coupons",
    12: "Mon profil",
    13: "Lettre",
    14: "Notifications",
  },
  //aboutUs页面
  aboutUs: {
    1: "Oceansmile est une plateforme complète pour la fabrication agile et innovante de produits électroniques à Shenzhen. Forts de plus de 15 ans d'expérience dans la fabrication de PCB et PCBA en Chine, nous offrons des services de prototypage complet et de fabrication agile. Nous aidons nos clients à commercialiser leurs produits à temps, avec des ressources complètes, des coûts compétitifs, des matériaux fiables et une qualité exceptionnelle. Oceansmile emploie plus de 200 personnes dans notre usine, avec une capacité de production mensuelle de plus de 3 000 échantillons ou de petites séries. Nous servons plus de 150 000 clients dans plus de 250 pays à travers le monde. Il n'y a pas de commande minimale. Nous offrons un service personnalisé 1 à 1 et des solutions complètes pour différents scénarios d'applications industrielles. En tant que l'un des fabricants les plus expérimentés en Chine, nous sommes votre meilleur partenaire commercial et ami dans tous les aspects de vos besoins de projet. Une approche centrée sur le client et l'adaptation. Nous grandissons avec vous et nous ajustons à vos besoins.",
    2: {
      1: "Agilité",
      2: "Coûts",
      3: "Assurance qualité",
      4: "Rien n'est impossible",
    },
    3: {
      1: "Gestion du processus de commande et de fabrication en ligne",
      2: "Livraison rapide",
      3: "Capacités complètes de la chaîne d'approvisionnement",
      4: "Réponse rapide",
    },
    4: {
      1: "Offres en ligne, prix transparents",
      2: "Pas de frais cachés ni de coûts supplémentaires",
      3: "Coûts bas pour la production de petites séries",
    },
    5: {
      1: "Processus de production stricts",
      2: "Équipe d'ingénieurs professionnels (plus de 5 ans d'expérience)",
      3: "Équipements de haute performance",
      4: "Base de fournisseurs robustes",
      5: "Expérience complète des produits",
    },
    6: {
      1: "Service sans limites",
      2: "Approche centrée sur le client et adaptation",
    },
    7: {
      1: "Nous offrons des services de prototypage complet et de fabrication agile. Pas seulement des PCB / PCBA !",
      2: "Cartes de circuits imprimés / Assemblage de PCB / SOLUTIONS IoT INDUSTRIELLES DE POINTE / Tests et programmes de tests personnalisés / Approvisionnement en composants / Ajustements mécaniques (métaux, silicones, plastiques, bois) / Injection plastique / Aide à la conception de PCB et à la disposition / Ajustements de batteries / Conception de produits / OEM / ODM",
    },
    8: "Oceansmile possède trois grandes usines spécialisées dans les prototypes de PCB, la production de PCB en série, l'assemblage de PCB et la production mécanique. Avec une superficie de 15 000 mètres carrés et 6 lignes de SMT, nous nous concentrons sur la validation des produits en développement des clients (prototypes), garantissant une capacité suffisante pour la production en série.",
    9: "Confiance des plus grandes marques mondiales",
    10: "Oceansmile s'engage à répondre aux besoins de nos clients (plus de 15 000) de diverses industries et pays en termes de qualité, livraison, coûts et autres exigences. En tant que l'un des fabricants les plus expérimentés de PCB/PCBA en Chine, nous avons établi des partenariats avec de nombreuses entreprises internationales de renom.",
    11: {
      1: "Clients payants",
      2: "Pays et régions",
      3: "Partenaires commerciaux",
    },
    12: "Pourquoi choisir Oceansmile ?",
  },
  //联系我们页面
  contact: {
    1: "Contactez-nous",
    2: {
      1: "Questions sur les ventes et les produits",
      2: "Oceansmile est une plateforme complète pour la fabrication agile et innovante de produits électroniques à Shenzhen. Nous offrons des services de prototypage rapide de PCB et d'assemblage de PCB. Notre équipe peut répondre à vos questions sur les capacités de PCB, les prix des PCB et des PCBA, les pièces mécaniques en silicones/plastiques… et tout autre aspect de vos besoins de projet. Passer une commande chez Oceansmile est facile grâce à nos devis en ligne pour PCB standard, PCB flexible / rigide-flexible, gabarits SMD et assemblage de PCB, avec des vérifications gratuites des fichiers Gerber, garantissant une livraison ponctuelle avec DHL, FedEx, UPS et autres.",
      3: "Veuillez laisser votre message ci-dessous et l'un de nos employés se fera un plaisir de vous répondre ",
      4: "dans les 24 heures.",
    },
    3: {
      1: "Nom",
      2: "E-mail",
      3: "Numéro de téléphone",
      4: "Votre numéro de téléphone",
      5: "Entreprise",
      6: "Nom de votre entreprise",
      7: "Captcha",
      8: "Votre captcha",
      9: "Message",
      10: "Dites-nous comment nous pouvons vous aider avec vos produits, livraisons, paiements et plus encore.",
      11: "Envoyer un message",
    },
    4: "Pensée centrée sur le client et adaptation !",
    5: "Nous vous aidons à mettre vos produits sur le marché à temps, avec des ressources complètes, des coûts compétitifs, des matériaux fiables et une excellente qualité.",
    6: "Service dans toutes les conditions",
    7: {
      1: "Conseils",
      2: "1. Pour une réponse plus rapide, nous vous recommandons de contacter notre équipe sur la page de votre compte après ",
      3: "Connectez-vous/Inscrivez-vous.",
      4: "2. Visitez le",
      5: "centre d'assistance d'Oceansmile",
      6: " pour trouver rapidement les questions fréquentes concernant les commandes, les expéditions, les paiements et plus encore.",
    },
    message: {
      1: "Le nom est requis.",
      2: "L'e-mail est requis.",
      3: "Le numéro de téléphone est requis.",
      4: "L'entreprise est requise.",
      5: "Captcha est requis.",
      6: "Le message est requis.",
    },
  },
  //why us页面
  why: {
    1: "Pourquoi choisir ",
    2: "Oceansmile est un fabricant de PCB/PCBA de haute qualité à Shenzhen, Chine, avec plus de 15 ans d'expérience. Avec des capacités professionnelles en fabrication de PCB/PCBA, nos ingénieurs PCB et SMT avec plus de 10 ans d'expérience examineront vos fichiers de conception deux fois. De plus, Oceansmile est certifié par IATF16949, ISO9001, ISO14001, UL, CQC, RoHS et REACH ; nous traitons l'ensemble du processus, y compris PCB/PCBA / Cartes de circuits imprimés / Assemblage de PCB / SOLUTIONS IoT INDUSTRIELLES DE POINTE / Tests et programmes de tests personnalisés / Approvisionnement en composants / Ajustements mécaniques (métaux, silicones, plastiques, bois) / Injection plastique / Aide à la conception PCB & disposition / Ajustements de batteries / Conception de produits / OEM / ODM et expédition finale. Nous pouvons monter des composants BGA, Micro-BGA, QFN et autres boîtiers non assemblés. Nous protégeons strictement la sécurité des documents de production et les droits de propriété intellectuelle des produits. Nous offrons des services de prototypage complet et de fabrication agile, consultez toutes les pièces dont vous avez besoin !",
    3: "Plateforme complète de soutien multi-services pour la R&D (Pas seulement PCB & PCBA)",
    4: "Oceansmile est un fabricant professionnel de services globaux avec 15 ans d'expérience dans la production de PCB et PCBA en Chine. Avec des capacités professionnelles de fabrication de PCB/PCBA, nous pouvons soutenir tous les designs avancés avec des exigences rigoureuses, y compris FR4, HDI, perçage laser, microvia, pads, cartes micro-ondes et RF, jusqu'à 32 couches. Oceansmile est un fabricant professionnel avec 15 ans d'expérience dans la production de PCB et PCBA en Chine.",
    5: "Nous offrons des services de prototypage complet et de fabrication agile. Pas seulement PCB/PCBA !",
    6: "Cartes de circuits imprimés / Assemblage de PCB / SOLUTIONS IoT INDUSTRIELLES DE POINTE / Tests et programmes de tests personnalisés / Approvisionnement en composants / Ajustements mécaniques (métaux, silicones, plastiques, bois) / Injection plastique / Aide à la conception PCB & disposition / Ajustements de batteries / Conception de produits / OEM / ODM…",
    7: "Agilité : Fabrication rapide de PCB et d'assemblage de PCB. Nous accélérons les délais de prototypage pour vous.",
    8: "Coûts : Réduction des coûts, fabrication de produits plus compétitifs et gagnant-gagnant.",
    9: "Assurance qualité : Excellent système de contrôle des ressources et des fournisseurs. Les plans de contrôle de la qualité sont constamment mis à jour et améliorés.",
    10: "Nous protégeons strictement les droits de propriété intellectuelle des produits de nos clients, garantissant que l'ensemble du processus de production soit sous surveillance de sécurité. Nous signons des accords de confidentialité (NDA) avec nos clients pour protéger les droits de propriété intellectuelle sur les produits de manière permanente.",
    11: "Rien n'est impossible : Nous grandissons avec nos clients et transformons leurs idées en réalité.",
    12: "Offre de services complète pour la fabrication agile avec des ressources fiables.",
    13: "Les matériaux PCB d'Oceansmile sont certifiés IATF 16949, ISO9001, ISO14001, UL, RoHS et REACH. En ce qui concerne les PCB assemblés, nous proposons une variété de méthodes de vérification méticuleuses, comme l'inspection optique automatisée (AOI), les tests en circuit (ICT), les tests aux rayons X, les tests fonctionnels et plusieurs étapes d'inspection visuelle, ce qui conduit à un excellent contrôle de la qualité des PCB et de l'assemblage de PCB.",
    14: "Nous sommes responsables de chaque produit selon des processus de production stricts. Nous avons une équipe d'ingénieurs professionnels (tout le monde a plus de 5 ans d'expérience). Équipement avec d'excellentes performances, tous les équipements sont de haute précision et de puissantes marques de haut niveau. Nous avons un AVL robuste, et le système de gestion des fournisseurs notera en fonction de la qualité, du délai d'exécution et du coût du fournisseur, et éliminera régulièrement les fournisseurs non qualifiés et le fournisseur nouvellement ajouté. Une expérience abondante des produits peut augmenter considérablement le taux de réussite de la recherche et du développement de produits, et elle peut fournir des solutions, des produits et des cas de marché existants aux clients pour référence.",
    15: "Respecter et protéger les droits de propriété intellectuelle",
    16: "protège strictement les droits de propriété intellectuelle des produits des clients, en s'appuyant sur des commandes et des systèmes de production stricts pour assurer la sécurité des documents de conception, et chaque équipement de production est surveillé par le système de sécurité. Nous signons l'accord NDA avec nos clients pour protéger en permanence les droits de propriété intellectuelle des produits. Faites confiance à Oceansmile, nous vous offrons le meilleur service.",
    17: "Rien d'impossible: nous grandissons avec nos clients et concrétisons nos idées",
    18: "Nous proposons des services de prototypage et de fabrication agile à guichet unique. Nous avons des options de service illimitées, en nous appuyant sur les meilleures ressources de Shenzhen, en Chine. Esprit orienté client et adaptabilité autonome. Des équipes expérimentées, des pensées dynamiques, nous continuerons d'avancer et ne nous arrêterons jamais. Choisissez Oceansmile, l'étape importante vers le succès.",
  },
  //论坛详情页面
  articleDetail: {
    1: "Actualités et Blog",
    2: "Commentaires",
    3: "Connectez-vous pour publier des commentaires.",
    4: "Publier un commentaire",
    5: "Répondre",
    7: "Publier un article",
    8: "ÉTIQUETTE POPULAIRE",
    9: "Catégories",
    message: {
      1: "La catégorie du forum ne peut pas être vide.",
      2: "Le titre ne peut pas être vide.",
      3: "L'image du titre ne peut pas être vide.",
      4: "Le contenu ne peut pas être vide.",
      5: "Créé avec succès.",
      6: "Veuillez vous connecter pour publier un article.",
      7: "Sélectionnez un maximum de 10 étiquettes d'article !",
      8: "Veuillez entrer un commentaire !",
      9: "Commentaire soumis avec succès.",
      10: "Êtes-vous sûr de vouloir supprimer le commentaire ?",
      11: "Astuces",
      12: "Supprimer",
      13: "Supprimé avec succès !",
    },
  },
  //论坛页面
  article: {
    1: "Centre du forum",
    2: "Publier un article",
    3: "Catégories",
    4: "Sélectionner une catégorie",
    5: {
      1: "Catégorie",
      2: "Sélectionner une catégorie",
      3: "Titre",
      4: "Veuillez entrer le titre de l'article !",
      5: "Image du titre",
      6: "Croquis du contenu",
      7: "Veuillez entrer une brève description de l'article !",
      8: "Étiquettes",
      9: "Rechercher/Ajouter",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Publications",
    2: "Collections",
    3: "J'aime",
    4: "Publier un article",
    5: {
      1: "Créer un nouvel article",
      2: "Catégorie",
      3: "Titre",
      4: "Veuillez entrer le titre de l'article !",
      5: "Image du titre",
      6: "Esquisse du contenu",
      7: "Veuillez entrer une brève description de l'article !",
      8: "Rechercher/Ajouter",
    },
  },
  //帮助中心列表
  help: {
    1: "Comment pouvons-nous vous aider ?",
    2: "Entrez des mots-clés associés pour rechercher",
  },
  //帮助中心详情
  helpDetail: {
    1: "Centre d'aide",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "Impression 3D",
      2: "Technologies SLA, MJF, SLM, FDM, SLS",
      3: "•Résine, Nylon, Métal et Plastique",
      4: "•Tolérance jusqu'à 0,2 mm",
      5: "•Délai de livraison dès 2 jours ouvrés",
    },
    2: {
      1: "Usinage CNC",
      2: "Fraisage (3-, 4- et 5-axes), tournage et post-traitement",
      3: "•Aluminium, Cuivre, Plastique",
      4: "•Tolérance jusqu'à 0,05 mm",
      5: "•Délai de livraison dès 3 jours ouvrés",
    },
    3: {
      1: "Fabrication de moules par injection",
      2: "Moulage sous vide",
      3: "•ABS, POM, Nylon, PC, PC/ABS, PVC, PE, PP, PS, TPU, Silicone, Matériaux personnalisés",
      4: "•Cycle de vie >=50k/100k",
      5: "•Tolérances contrôlées selon ISO 2768-1",
      6: "•Délai de livraison 15-25 jours ouvrés",
    },
    4: {
      1: "Pays expédiés",
      2: "Zone d'usine",
      3: "Matériaux et finitions",
      4: "Demande de devis",
    },
    5: "Demander un devis maintenant",
    6: "Service fermé 360°",
    7: "L'équipe d'ingénieurs et de service professionnels vous offre un service en ligne 360° en temps réel, avec des rapports réguliers de suivi de production.",
    8: "Processus de service standard",
    9: "Oceansmile dispose d'un système puissant de gestion numérique pour fournir des services de traitement rapides, complets et de haute qualité.",
    10: {
      1: "Devis rapide",
      2: "Devis et service DFM en 24 heures après réception des documents par les ingénieurs.",
      3: "Confirmation de la solution",
      4: "Sélectionnez les exigences de matériau et de finition, confirmez le plan de production.",
      5: "Confirmation du paiement",
      6: "Vérifiez votre commande et payez. Ajoutez des ventes pour obtenir des services VIP.",
      7: "Usinage / Impression 3D",
      8: "Découpe - Programmation - Traitement - Post-traitement, Inspection - Livraison",
      9: "Service après-vente",
      10: "Vérifiez les produits avec le dessin. Nous réagirons rapidement aux problèmes de qualité.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Détails du produit",
    2: "Avis",
    3: {
      1: "Évaluations",
      2: "Avis",
      3: "Images",
      4: "Cliquez pour télécharger",
      5: "Soumettre un avis",
      6: "Entrez votre avis...",
      7: "Aucun commentaire pour le moment",
    },
    4: "Inclut la batterie",
    5: "jours ouvrés",
    6: "en stock",
    7: "sélectionner",
    8: {
      1: "Entrepôt CN",
    },
    9: "AJOUTER AU PANIER",
    10: "Étiquettes",
    11: "RUPTURE DE STOCK",
    12: "Accueil",
    13: "Boutique de produits",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Veuillez entrer le contenu à noter. Ce champ est facultatif.",
      2: "Remarques",
      3: "Aucune",
      4: "Ajouté avec succès.",
      5: "Avis réussi !",
      6: "Il y a déjà trois images ! Veuillez en supprimer une avant d'en télécharger une nouvelle.",
    },
  },
  commodity: {
    1: {
      1: "mot-clé",
      4: "par page",
    },
    2: {
      1: "Plus...",
      2: "Ajouter au panier",
      3: "RUPTURE DE STOCK",
      4: "Aucun résultat trouvé",
    },
    3: {
      1: "Remarques et quantité",
      2: "Quantité",
      3: "Description",
      4: "Veuillez entrer le contenu à noter. Vous pouvez l'ignorer",
      5: "SKU",
      6: "Pas de données",
    },
    message: {
      1: "Ajouté avec succès.",
    },
  },
  //忘记密码
  forget: {
    1: "Mot de passe oublié",
    2: {
      1: "Adresse e-mail",
      2: "E-mail",
    },
    3: {
      1: "Code d'autorisation",
      2: "Code",
      3: "Obtenir",
    },
    4: {
      1: "Nouveau mot de passe",
    },
    5: {
      1: "Confirmer le mot de passe",
    },
    message: {
      1: "Veuillez entrer une adresse e-mail.",
      2: "Veuillez entrer une adresse e-mail.",
      3: "Veuillez entrer le code d'autorisation.",
      4: "Veuillez entrer le mot de passe.",
      5: "Veuillez confirmer le mot de passe.",
      6: "Le mot de passe ne correspond pas au mot de passe de confirmation.",
      7: "Mot de passe mis à jour avec succès.",
    },
  },
  //主页
  home: {
    1: {
      1: "Calculateur de fabrication de PCB",
      2: "Longueur \\ Largeur",
      3: "Longueur",
      4: "Largeur",
      5: "Quantité",
      6: "Veuillez entrer",
      7: "Couches",
      8: "Épaisseur",
      9: "Nouveau ? Non inscrit ?",
      10: "Obtenez",
      11: "Coupons ici !",
      12: "Obtenir un devis",
      13: "Calculateur d'assemblage PCBA",
    },
    2: {
      1: "Délai rapide",
      2: "Délai aussi court que 24 heures",
      3: "Assistance personnalisée",
      4: "Expérience d'achat fluide",
      5: "Meilleur rapport qualité/prix",
      6: "Prix directs du fabricant",
      7: "Actualités",
      8: "Prototypage PCB facile",
      9: "Avec plus d'une décennie dans le domaine des PCB...",
      10: "En savoir plus",
    },
    3: {
      1: "Devis instantané PCB",
      2: "1~6 couches de Polymide flexible",
      3: "Formulaire de commande 1 pièce",
      4: "Délai de livraison de 4~5 jours",
      5: "DEMANDE DE DEVIS PCB INSTANTANÉ",
      6: "Télécharger le fichier PCB",
      7: "Examen de la commande",
      8: "Paiement",
      9: "Suivi en temps réel",
      10: "Livraison",
      11: "Confirmer la réception",
      12: "Cliquez sur « Devis instantané » dans la barre de menu, sélectionnez « Devis instantané PCB/PCBA ». Ensuite, vous accédez à la page de devis et téléchargez le fichier PCB.",
      13: "Téléchargez des fichiers Gerber (zip, rar), d'autres formats peuvent entraîner des erreurs en raison de problèmes de compatibilité de version. Le système effectuera l'examen automatique en 30 secondes. L'upload a échoué ? Vous pouvez le passer et envoyer le fichier par e-mail à",
      14: "Les ingénieurs expérimentés de Oceansmile effectueront un DFM sur la conception du produit et fourniront des suggestions d'optimisation pour le produit.",
      15: "Paiement en ligne, sécurisé et traçable. Nous soutenons plusieurs méthodes de paiement, Paypal, carte de crédit, virement bancaire hors ligne, etc. Si vous avez des questions sur le paiement, veuillez contacter notre service client.",
      16: "Visualisez l'étape du progrès de la commande et consultez l'état de la production du produit à tout moment. Un personnel spécialisé sera affecté pour suivre les commandes et garantir que les produits soient terminés à temps et de manière qualitative.",
      17: "Une large gamme d'options de messagerie et des entreprises de messagerie reconnues mondialement garantissent une livraison rapide et sécurisée des produits.",
      18: "Vous recevrez votre produit. Nous maintenons des relations gagnant-gagnant avec nos clients et continuerons à vous offrir un service après-vente de qualité. Si vous rencontrez des problèmes avec le produit, nous utiliserons nos ressources pour vous assister.",
    },
    4: {
      1: "Produits Bestsellers",
      2: "Prototype PCB",
      3: "À partir de",
      4: "Temps de construction",
      5: "Assemblage PCB",
      6: "Fournisseurs de composants",
      7: "PCB Flex, Rigid-Flex",
      8: "PCB HDI",
      9: "Impression 3D",
      10: "jours",
      11: "heures",
      12: "Usinage CNC",
      13: "pièces",
    },
    5: {
      1: "Promotion du service d'assemblage",
      2: "Seulement",
      3: "AU TOTAL",
      4: "pour assemblage de 1 à 20 pièces",
      5: "DEMANDEZ UN DEVIS MAINTENANT",
      6: "Solution clé en main pour PCB & Assemblage",
      7: "Facile à fabriquer, Quantité, Une seule fois",
      8: "Assemblage SMT et traversant",
      9: "Soudure par onde pour PCBA",
      10: "Devis en ligne pour PCBA",
      11: "Solution d'achat BOM clé en main",
      12: "Taux de satisfaction supérieur à 99,6%",
      13: "Assemblage de boîtier, câblage",
      14: "Tests fonctionnels",
      15: "et autres tests",
      16: "Programmation IC",
      17: "BGA",
      18: "QFN",
      19: "Derniers 30 jours",
      20: "Clients",
      21: "Payant",
      22: "SMT",
    },
    6: {
      1: "Fabricant de PCB prototypé abordable en Chine",
      2: "Date",
      3: "Nom de l'acheteur",
      4: "Dernière fois",
      5: "Progrès",
    },
    7: {
      1: "COUCHE",
      2: "PCB 4 couches avec 2 couches de routage, une couche de masse et une couche d'alimentation",
      3: "Empilement PCB multi-couches",
      4: "PCB 6 couches avec GTL, GBL et 4 couches internes. Chaque signal qui voyage de 1 à 6 couches",
      5: "PCB 8 couches avec GTL, GBL et 6 couches internes. Chaque signal qui voyage de 1 à 8 couches",
    },
    8: "Depuis notre blog",
    9: "Ce que disent les clients",
    10: "mm",
  },
  //登录页面
  login: {
    1: "Rejoindre / Se connecter",
    2: "Créer un compte",
    3: {
      1: "Adresse e-mail",
      2: "Code",
      3: "Obtenir",
      4: "Mot de passe",
      5: "Confirmer le mot de passe",
      6: "S'inscrire",
      7: "En créant ce compte, vous acceptez nos",
      8: "Conditions générales",
    },
    4: {
      1: "SE CONNECTER",
      2: "Bon retour !",
      3: "Mot de passe oublié ?",
      4: "Se connecter",
    },
    message: {
      1: "Veuillez entrer une adresse e-mail.",
      2: "Veuillez accepter les conditions d'utilisation pour continuer.",
      3: "Veuillez entrer le code d'authentification.",
      4: "Veuillez entrer le mot de passe.",
      5: "Veuillez confirmer le mot de passe.",
      6: "Le mot de passe ne correspond pas à la confirmation.",
      7: "Inscription réussie ! Veuillez vous connecter sur le côté droit de la page.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Prix raisonnable garanti",
      2: "$3.78 pour 5 pcs de prototype PCB 10*10cm, $25 pour 10pcs d'assemblage PCB.",
      3: "Garantie des matériaux PCB",
      4: "Oceansmile n'utilise que des matériaux de haute qualité conformes à ROHS pour la production.",
      5: "Qualité garantie",
      6: "Oceansmile est entièrement conforme au système de gestion de qualité ISO 9001:2015.",
      7: "Expédition à temps garanti",
      8: "Prototype PCB accéléré, service personnalisé en ligne, expédition à temps 99% par DHL.",
    },
    2: {
      1: "Affichage des produits",
      2: "Avec plus de 10 ans d'expérience dans la prototypage rapide de PCB et la production de PCB en petit et moyen volume.",
    },
    3: {
      1: "Capacités d'assemblage PCB",
      2: "Nous sommes fiers de notre réputation de spécialiste de premier plan dans l'assemblage PCB de technologie de montage en surface, trous traversants et technologie mixte ainsi que des services de fabrication électronique et d'assemblage PCB clé en main.",
      3: "ASSEMBLAGE DE PCB PROTOTYPE",
      4: "Obtenez un devis maintenant",
      5: "Types de PCB pour l'assemblage",
      6: "Carte FR-4",
      7: "Carte à noyau métallique",
      8: "Carte flexible",
      9: "Carte rigide-flexible",
      10: "Etc.",
      11: "Types de soudure",
      12: "Soudure sans plomb",
      13: "Assemblage de composants SMD et TH",
      14: "Assemblage simple et double face",
      16: "Fournisseurs de composants",
      17: "Digi-key, Mouser, Arrow, Element 14 et",
      18: "plus…",
      19: "Marché chinois pour les composants universels",
      20: "Composant après approbation.",
      21: "Type de test",
      22: "Inspection visuelle",
      23: "Inspection AOI",
      24: "Inspection par rayons X",
      25: "Test en circuit",
      26: "Test fonctionnel",
    },
    4: {
      1: "Showroom de l'usine",
      2: "Avec plus de 10 ans d'expérience dans la prototypage rapide de PCB et la production de PCB en petit et moyen volume.",
    },
    5: {
      1: "Comment commander un service d'assemblage PCB chez OceanSmile ?",
      2: "Cliquez ici pour obtenir des détails sur la commande de PCBA, ou contactez-nous",
      3: "si vous avez des questions sur nos services d'assemblage.",
    },
    6: {
      1: "Première étape",
      2: "Téléchargez vos fichiers Gerber PCB et remplissez le formulaire",
      3: "Deuxième étape",
      4: "Téléchargez un fichier BOM formaté",
      5: "Troisième étape",
      6: "Obtenez un devis en ligne instantané",
    },
    7: "Chargement...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "Fabrication et capacités d'assemblage PCB",
      3: "En savoir plus sur les capacités d'Oceansmile et concrétisez vos idées rapidement.",
      4: "Vert",
      5: "Violet",
      6: "Rouge",
      7: "Jaune",
      8: "Bleu",
      9: "Blanc",
      10: "Noir",
    },
    2: {
      1: "PCB rigide",
      2: "PCB flexible",
      3: "Assemblage PCB",
    },
    3: {
      1: "Spécifications PCB",
      2: "Taille des trous/perçages",
      3: "Anneau minimal",
      4: "Écart minimal",
      5: "Largeur et espacement minimal des traces",
      6: "BGA",
      7: "Masque de soudure",
    },
    4: {
      1: "Caractéristiques",
      2: "Capacités",
      3: "Remarques",
      4: "Modèles",
      5: "Nombre de couches",
      6: "Couches",
      7: "Le nombre de couches de cuivre de la carte.",
      8: "Matériel",
      9: "FR-4",
      10: "Aluminium",
      11: "Noyau en cuivre",
      12: "Rogers",
      13: "Céramique",
      14: "PCB FR-4",
      15: "PCB en aluminium",
      16: "Dimensions de la carte",
      17: "Min",
      18: "Max",
      19: "La taille de la carte PCB",
      20: "Tolérance dimensionnelle",
      21: "(Régulier) pour usinage CNC, et ±0,4mm pour un fraisage en V",
      22: "Épaisseur de la carte",
      23: "L'épaisseur finie de la carte",
      24: "Tolérance d'épaisseur",
      25: "± 0,1mm pour une épaisseur de carte ≤1,6mm, ± 10% pour une épaisseur de carte ≥1,6mm",
      26: "Cuivre fini",
      27: "Couche externe",
      28: "Couche interne",
      29: "Finition de surface",
      30: "HASL (avec plomb/sans plomb), ENIG, OSP, ENEPIG",
      31: "Le processus supplémentaire pour exposer le cuivre afin d'améliorer la soudabilité.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Caractéristiques",
      2: "Capacité",
      3: "Remarques",
      5: "Taille du trou de perçage (trou arrondi)",
      6: "Perçage mécanique",
      7: "Perçage laser",
      8: "Taille du trou de perçage (trou en fente)",
      9: "Taille du trou de perçage (trou en demi-cercle)",
      10: "Le diamètre des trous en demi-cercle doit être supérieur à 0,5 mm et l'écartement entre les trous en demi-cercle doit être supérieur à 0,9 mm.",
      11: "Vias aveugles/enterrés",
      12: "Oceansmile peut supporter à la fois les vias aveugles et les vias enterrés.",
      13: "Tolérance de taille de trou de perçage",
      14: "PTH",
      15: "NPTH",
      16: "Trou en fente",
    },
    6: {
      1: "Cuivre",
      2: "VIA",
    },
    7: {
      1: "Écartement entre les trous (réseaux différents)",
      2: "L'écartement minimum entre les trous plaqués que nous pouvons accepter.",
      3: "Écartement entre vias (réseaux identiques)",
      4: "L'écartement minimum entre les vias que nous pouvons accepter.",
      5: "Écartement entre pads (pads SMD, réseaux différents)",
      6: "L'écartement minimum entre les pads SMD que nous pouvons accepter.",
      7: "Écartement entre pads (pads TH, réseaux différents)",
      8: "L'écartement minimum entre les pads TH que nous pouvons accepter.",
      9: "Via à piste",
      10: "L'écartement minimum entre via et piste que nous pouvons accepter.",
      11: "PTH à piste",
      12: "L'écartement minimum entre PTH et piste que nous pouvons accepter.",
      13: "NPTH à piste",
      14: "L'écartement minimum entre NPTH et piste que nous pouvons accepter.",
      15: "Pad à piste",
      16: "L'écartement minimum entre pad et piste que nous pouvons accepter.",
    },
    8: {
      1: "Largeur minimale de la trace",
      2: "Espacement minimal",
      3: "Largeur/espacement des traces recommandé pour la production en série",
    },
    9: {
      1: "Spécification",
      2: "Dimensions minimales des pads BGA",
      3: "Distance minimale entre les BGA",
      4: "Diamètre BGA",
      5: "Min pour HASL avec plomb",
      6: "Min pour HASL sans plomb",
      7: "Min pour autres finitions de surface",
      8: "Le diamètre minimum des pads BGA que nous pouvons accepter.",
      9: "Écartement entre les pads BGA",
      10: "L'écartement minimum entre les pads BGA que nous pouvons accepter.",
    },
    10: {
      1: "Couleur de la mask à souder",
      2: "La couleur de la carte PCB",
    },
    11: "mm",
  },

  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Information produit",
      2: "Description",
      3: "RoHs",
      4: "Disponibilité",
      5: "En stock",
      6: "Prix",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Dernière mise à jour : 16 mai 2024.",
    2: "Déclaration de fonctionnement du site web d'Oceansmile",
    3: "Ce site web est géré par Oceansmile, y compris cnospcb.com et Lanyue Electronics (Shenzhen) Co., Ltd. Sur ce site, les termes 'Oceansmile', 'OSPCB', 'Lanyue', 'nous' et 'notre' se réfèrent aux marques et filiales mentionnées ci-dessus. Les termes et conditions décrits dans ce document s'appliquent à ces entités et à tous les services fournis par ces marques.",
    4: "Politique de confidentialité",
    5: "C'est la politique de confidentialité publiée par Lanyue/Oceansmile/Lanyue Electronics (Shenzhen) Co., Ltd., qui inclut le traitement des données personnelles et comment exercer vos droits, etc. La politique de confidentialité s'applique à ce site pour tous les produits et/ou services liés fournis par Lanyue (Oceansmile). En tant que responsable du traitement de vos données personnelles, Lanyue s'engage à protéger vos données personnelles et prendra toutes les mesures raisonnables pour collecter et utiliser vos données personnelles conformément aux lois et règlements applicables.",
    6: "Données personnelles",
    7: "Conformément à la définition spécifique établie dans le règlement n° 2016/679 relatif à la protection des personnes physiques en ce qui concerne le traitement des données personnelles (le 'RGPD'), les données personnelles traitées par Lanyue sont classées en différentes catégories :",
    8: "1. Certaines données personnelles sont fournies volontairement par vous.",
    9: "2. D'autres données personnelles sont traitées automatiquement lorsque vous visitez nos sites et/ou utilisez les produits et/ou services de Lanyue.",
    10: "3. D'autres données personnelles sont collectées par des sites et services tiers.",
    11: "Conformité avec le contrôle des exportations",
    12: "Traitement des données personnelles",
    13: "Vous fournirez vos données personnelles volontairement pendant le processus d'achat ou lors d'autres processus avec votre consentement préalable, y compris, mais sans s'y limiter, votre nom personnel et vos informations de contact, les données de compte, les informations de paiement, les données de commentaires et toute autre donnée que vous fournissez. Lanyue utilisera vos données personnelles fournies dans le but d'exécuter vos commandes, de vous fournir certains services, d'enregistrer un compte et pour toute communication entre vous et Lanyue.",
    14: "Lanyue n'utilisera vos données personnelles que pour l'objectif spécifié ci-dessus ou pour toute autre exigence légale. Vous avez le droit de demander à tout moment de retirer ou de supprimer vos données personnelles si vous estimez que vos droits ne sont pas protégés.",
    15: "Lanyue peut collecter automatiquement vos données personnelles lorsque vous utilisez les produits et/ou services de Lanyue, y compris, mais sans s'y limiter :",
    16: "Informations sur l'appareil, comme après que vous ayez acheté des produits de Lanyue et que vous les ayez installés à votre adresse, les serveurs web de Lanyue peuvent enregistrer automatiquement votre adresse IP ou toute information liée à votre appareil.",
    17: "Données relatives aux produits que vous utilisez et comment vous les utilisez, ainsi que vos habitudes d'utilisation lorsque vous naviguez sur nos sites, etc. L'objectif de collecter vos données personnelles automatiquement est d'évaluer et d'améliorer votre expérience avec les produits et/ou services de Lanyue. Si vous ne souhaitez pas divulguer vos habitudes d'utilisation ou d'autres données personnelles, veuillez contacter notre email de contact désigné comme indiqué dans cette politique de confidentialité.",
    18: "De plus, le site de Lanyue utilise des services tiers, y compris des prestataires de services de paiement, des prestataires d'expédition et des annonceurs. Certains prestataires peuvent collecter vos données personnelles dans le but des objectifs précédents et ont également leur propre politique de confidentialité pour protéger le traitement de vos données personnelles.",
    19: "PRIX ET PAIEMENT",
    20: "Lanyue s'efforce de fournir des informations actualisées et précises sur les produits et les prix, mais ne garantit pas la validité ou l'exactitude de ces informations.",
    21: "La cotation des prix en ligne ne garantit pas la quantité totale de la commande ni les délais de livraison. Toutes les quantités de commandes et les délais de livraison sont soumis à une révision de la capacité de production. Le prix affiché sur le formulaire web lors de la saisie de la commande sera contraignant uniquement après confirmation et après que les fichiers documents envoyés aient été examinés. Lanyue se réserve le droit de modifier le prix ou d'annuler la commande si la documentation envoyée ne correspond pas aux données saisies sur l'écran de saisie de commande. Dans ces cas, nous vous informerons des modifications. Si votre commande est annulée et que vous avez déjà effectué le paiement, Lanyue procédera au remboursement via le mode de paiement original.",
    22: "Lanyue ne sera pas responsable des erreurs de prix, des erreurs typographiques ou d'autres erreurs dans toute offre de Lanyue et se réserve le droit d'annuler toute commande résultant de ces erreurs.",
    23: "EXPÉDITION ET LIVRAISON",
    24: "Le client est responsable de s'assurer que l'adresse de livraison fournie dans le formulaire web est correcte et complète. Tout coût supplémentaire de transport résultant de détails incorrects d'adresse sera facturé au client. Les clients internationaux sont responsables des frais de douane et des taxes d'importation sur toutes les commandes.",
    25: "Lanyue expédiera vos produits dans un délai raisonnablement commercial à l'adresse spécifiée dans votre commande. Nous nous efforçons de faire en sorte que toutes vos commandes soient expédiées à temps. Cependant, il y a des occasions où les transporteurs peuvent rencontrer des retards ou faire des erreurs lors de l'expédition. Nous nous excusons lorsque cela se produit, mais nous ne pouvons être tenus responsables des retards dus à ces transporteurs.",
    26: "RENONCIATION AUX DROITS DE PROPRIÉTÉ INTELLECTUELLE",
    27: "Les documents de conception de PCB/produits utilisés pour la fabrication ont été fournis par les clients, et la responsabilité de Lanyue était de fabriquer la carte/produit selon la conception du client. Les clients doivent garantir qu'ils détiennent les droits de propriété intellectuelle relatifs aux documents de conception fournis, qui ne doivent enfreindre aucun droit de propriété intellectuelle ni tout autre droit légal de tiers, comme les droits de brevet, de copyright, de marque déposée, etc.",
    28: "Toute violation ou problème juridique résultant des documents fournis par les clients, causant une influence ou une perte pour nous, sera de la responsabilité exclusive des clients, qui devront nous indemniser.",
    29: "Contactez-nous",
    30: "Si vous avez des questions ou des commentaires concernant ces conditions, contactez-nous à",
  },
  //看图页面
  canvas: {
    1: {
      1: "Tout ouvrir",
      2: "Tout fermer",
    },
    2: {
      1: "Taille",
      2: "Largeur de trace minimale",
      3: "Espacement de trace minimal",
      4: "Nombre de trous de perçage",
      5: "Taille minimale du trou",
      6: "Taille maximale du trou",
      7: "Zone ENIG",
      8: "Point de test",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA & Gabarits",
    2: {
      1: "TOUS",
      2: "Annulé",
      3: "En attente de paiement",
      4: "Confirmation par Oceansmile",
      5: "Payé",
      6: "En traitement",
      7: "Emballé",
      8: "Expédié",
      9: "Entrez le numéro de commande",
    },
    3: {
      1: "Commande | Information produit",
      2: "TOUS Acheter",
      3: "Matériau",
      4: "Quantité",
      5: "Prix",
      6: "Description du produit",
      7: "Nom du fichier",
      8: "Description du fichier",
    },
    4: {
      1: "Téléphone",
      2: "Entreprise",
      3: "Numéro de TVA",
      4: "Poids",
      5: "Livraison",
      6: "Envoyer à",
    },
    5: {
      1: "Temps de construction",
      2: "Heure de paiement",
      3: "Non payé",
      4: "Conseils",
      5: "Le temps de construction est calculé à partir du moment après que le paiement a été effectué",
      6: "Heure de création",
    },
    6: {
      1: "Mode de paiement",
      2: "Prix du produit",
      3: "Frais",
      4: "Frais d'expédition",
      5: "Autres réductions",
      6: "Total",
    },
    7: "Description de la commande",
    8: "Voir les détails",
    9: "Mode de fourniture",
    10: "Cadre",
    11: {
      1: "Acheter maintenant",
      2: "Facture",
    },
    12: "Produits",
    13: {
      1: "Prix unitaire",
      2: "Prix total",
      3: "Description utilisateur",
    },
    14: {
      0: "Détail",
      1: "Fichier Gerber",
      2: "Description du fichier Gerber",
      3: "Description du produit",
      4: "Type de carte",
      5: "Matériau",
      6: "Longueur",
      7: "Largeur",
      8: "Couches",
      9: "Épaisseur",
      10: "Cuivre fini",
      11: "Taille minimale du trou",
      12: "Masque à souder",
      13: "Sérigraphie",
      14: "Temps de construction",
      15: "Trou en demi",
      16: "Statut de la commande",
      17: "Poids du produit",
      18: "Conception différente dans le panneau",
      19: "FR4-TG",
      20: "Quantité",
      21: "Finition de surface",
      22: "Dégâts minimaux du masque à souder",
      23: "Largeur de trace/espacement minimaux",
      24: "Contrôle d'impédance",
      25: "Détails des frais",
      26: "Frais d'ingénierie",
      27: "Panneau",
      28: "Trou castellé",
      29: "Carte",
      30: "Test",
      31: "Cuivre",
      32: "Finition de surface",
      33: "Processus de via",
      34: "Via aveugle",
      35: "Via dans le pad",
      36: "Frais de V-cut",
      37: "Frais de couleur",
      38: "Grande taille",
      39: "Contrôle d'impédance",
      40: "Réduction",
      41: "Total",
      42: "Coût PCBA",
      43: "Coût des composants",
      44: "Coût d'assemblage",
      45: "Frais de service",
      46: "Fichier Bom",
      47: "Description Bom",
      48: "Détails des composants",
      49: "Confirmation produit",
      50: "Designateur",
      51: "MPN",
      52: "Quantité totale",
      53: "Matériaux perdus",
      54: "Prix unitaire",
      55: "Description",
      56: "Versions",
      57: "Cadre",
      58: "Taille",
      59: "Côté du gabarit",
      60: "Détails des frais",
      61: "Stencil SMD",
    },
    15: {
      1: "Commander un supplément",
      2: "Compléter la commande No",
      3: "Numéro de commande",
      4: "Différence de prix",
      5: "Description",
      6: "Payer",
    },
    16: "PCB",
    17: "PCBA",
    18: "Gabarit",
    message: {
      1: "Supprimé avec succès.",
      2: "Souhaitez-vous payer la commande supplémentaire ensemble ?",
      3: "Conseils",
      4: "Veuillez sélectionner une commande.",
      5: "Copié avec succès.",
    },
  },
  //预览价格
  preview: {
    1: "Retour",
    2: "Adresse de livraison",
    3: "Modifier",
    4: "Supprimer",
    5: "AJOUTER UNE NOUVELLE ADRESSE",
    6: {
      1: "Si c'est l'adresse par défaut",
      2: "Nous pourrions confirmer certains problèmes avec vos commandes ou fichiers par e-mail, assurez-vous que l'e-mail est valide !",
    },
    7: "Méthode d'expédition",
    8: "Non applicable",
    9: "Coupons",
    10: "Aucun",
    11: "Méthode de paiement",
    12: "Carte de crédit",
    13: "Virement bancaire manuel (TT)",
    14: {
      1: "RÉSUMÉ",
      2: "Prix de la différence",
      3: "Commission",
      4: "Poids du produit (KG)",
      5: "Sous-total",
      6: "Frais de livraison",
      7: "Prix original",
      8: "Montant de la remise",
      9: "Montant payable",
      10: "Remise sur coupon",
      11: "Remise OceanSmile",
      12: "Acheter maintenant",
    },
    message: {
      1: "Êtes-vous sûr de vouloir supprimer l'adresse ?",
      2: "Conseil",
      3: "Supprimé avec succès.",
      4: "Le prénom ne peut pas être vide.",
      5: "Le nom de famille ne peut pas être vide.",
      6: "L'entreprise ne peut pas être vide.",
      7: "La rue ne peut pas être vide.",
      8: "Le pays ne peut pas être vide.",
      9: "La province ne peut pas être vide.",
      10: "La ville ne peut pas être vide.",
      11: "Le code postal ne peut pas être vide.",
      12: "Le téléphone ne peut pas être vide.",
      13: "L'e-mail ne peut pas être vide.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Devis en ligne",
      2: "Télécharger le fichier Gerber",
      3: "Révision de la commande",
      4: "Paiement",
      5: "Fabrication",
      6: "Expédition",
      7: "Confirmer et réviser",
    },
    2: "Sélection des spécifications PCB",
    3: {
      1: "Ajouter des fichiers Gerber",
      2: "Téléchargement réussi",
      3: "Visionneuse Gerber en ligne",
      4: "Le fichier PCB a été téléchargé avec succès, mais les paramètres ne peuvent pas être analysés.",
      5: "Seuls les fichiers ZIP ou RAR sont acceptés. Taille maximale : 50 Mo.",
      6: "Si le fichier zip téléchargé n'est pas analysé correctement ou si aucune image n'est affichée, ne vous inquiétez pas, nos ingénieurs le vérifieront manuellement et vous répondront par e-mail plus tard.",
      7: "Pour des exigences spéciales non disponibles sur cette page ou si vous ne trouvez pas ce que vous cherchez, veuillez nous envoyer vos exigences et fichiers Gerber, ",
      8: "et nous reviendrons vers vous dans les 24 heures.",
    },
    4: {
      1: "Remarque pour Gerber",
      2: "Pièces individuelles",
      3: "Panneau par le client",
      4: "Panneau par OSpcb",
      5: "Rails de bord",
      6: "Non",
      7: "Taille du panneau",
      8: "Quantité de panneaux",
      9: "Autres",
      10: "Remarque pour PCB",
    },
    5: {
      1: "Assemblage de PCB",
      2: "Quantité unique",
      3: "Pochoir",
      4: "Mode d'approvisionnement des pièces",
      5: "Clé en main",
      6: "Consignation",
      7: "Combo",
      8: "Remarque pour PCBA",
      9: "Remarque pour la nomenclature (BOM)",
      10: "Ajouter un fichier BOM",
      11: "Télécharger le modèle de BOM",
      12: "Veuillez télécharger un fichier BOM précis, suivant ce modèle, afin que nous puissions le lire correctement.",
      13: "Le MPN peut être choisi parmi Digikey, Mouser, Arrow, Element ou toute autre plateforme, notre système le reconnaîtra automatiquement.",
      14: "Pour toute demande spéciale d'assemblage PCB, veuillez écrire à",
      15: "ne les écrivez pas directement dans le fichier BOM.",
      16: "Nous ne reconnaissons que les virgules pour distinguer l'emplacement.",
      17: "Le numéro de désignateur doit être ≤ Quantité.",
      18: "Veuillez ne pas remplir le numéro de pièce Digikey ou Mouser dans la ligne MPN (Numéro de pièce du fabricant).",
      19: "Veuillez ne pas inclure de composants inutiles dans votre fichier BOM.",
    },
    6: {
      1: "Veuillez sélectionner le pays",
      2: "Spécification du PCB",
      3: "Quantité",
      4: "Délai de fabrication",
      5: "Coût du PCB",
      6: "Frais d'ingénierie",
      7: "Carte",
      8: "Coût du PCBA",
      9: "Coût d'assemblage",
      10: "Coût des composants",
      11: "Sous-total",
      12: "Ajouter au panier",
      13: "jours ouvrés",
    },
    str: {
      "Normal FR-4 Board": "Carte FR-4 normale",
      "Flexible Boards": "Cartes flexibles",
      "Board Type": "Type de carte",
      "Single Quantity": "Quantité unique",
      "FR4-TG": "FR4-TG",
      "Single Size": "Taille unique",
      "Different Design in Panel": "Design différent dans le panneau",
      "Min Trace Width/Spacing": "Largeur de trace minimale/Espace",
      "Min Hole Size": "Taille minimale du trou",
      "Minimum Solder Mask Dam": "Digue de masque de soudure minimale",
      "Half Hole": "Trou partiel",
      "Surface Finish": "Finition de surface",
      "Finished Copper": "Cuivre fini",
      "Impedance Control": "Contrôle de l'impédance",
      "Panel Quantity": "Quantité de panneaux",
      "Edge Qty": "Quantité de bords",
      "Solder Mask": "Masque de soudure",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 couche",
      "2 Layer": "2 couches",
      "4 Layer": "4 couches",
      "6 Layer": "6 couches",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0,2mm",
      "0_25mm": "0,25mm",
      "0_3mm": "0,3mm",
      "0_4mm": "0,4mm",
      "HASL with lead": "HASL avec plomb",
      "HASL lead free": "HASL sans plomb",
      "Immersion gold": "Or par immersion",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1,0w/(m⋅K)",
      "2_0w/(m⋅K)": "2,0w/(m⋅K)",
      "0_08": "0,08",
      "0_13": "0,13",
      "0_22": "0,22",
      "0_6": "0,6",
      "0_8": "0,8",
      "1_0": "1,0",
      "1_2": "1,2",
      "1_6": "1,6",
      "2_0": "2,0",
      "0_6mm": "0,6mm",
      "0_8mm": "0,8mm",
      "1_0mm": "1,0mm",
      "1_2mm": "1,2mm",
      "1_6mm": "1,6mm",
      "2_0mm": "2,0mm",
      "0_10": "0,10",
      "0_12": "0,12",
      "0_15": "0,15",
      "0_18": "0,18",
      "0_10mm": "0,10mm",
      "0_12mm": "0,12mm",
      "0_15mm": "0,15mm",
      "0_18mm": "0,18mm",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0,5oz",
      mm: "mm",
      OSP: "OSP",
      None: "Aucun",
      White: "Blanc",
      Black: "Noir",
      Green: "Vert",
      Red: "Rouge",
      Yellow: "Jaune",
      Blue: "Bleu",
      Silkscreen: "Sérigraphie",
      Aluminum: "Aluminium",
      Layers: "Couches",
      Material: "Matériau",
      Thickness: "Épaisseur",
      TCE: "TCE",
      No: "Non",
      Yes: "Oui",
      "Single Pieces": "Pièces individuelles",
      "Panel By Customer": "Panneau par le client",
      "Panel By OSpcb": "Panneau par OSpcb",
      true: "Oui",
      false: "Non",
      Turnkey: "Clé en main",
      Consignment: "Consignation",
      Combo: "Combo",
      Unit: "Unité",
      Total: "Total",
      Framework: "Cadre",
      "Stencil Side": "Côté du pochoir",
      "Existing fiducials": "Fiduciaux existants",
      Top: "Haut",
      Bottom: "Bas",
      "Top+Bottom(On Single Stencil)": "Haut+Bas (Sur un seul pochoir)",
      "Top & Bottom(On Separate Stencil)":
        "Haut et Bas (Sur un pochoir séparé)",
      "SIZE (mm)": "TAILLE (mm)",
      Quantity: "Quantité",
      none: "Aucun",
      "half lasered": "Moitié laserisé",
      "lasered through": "Laserisé entièrement",
      undefined: "",
    },
    tips: {
      Material:
        "Le PCB FR-4 est le matériau le plus utilisé. Le PCB en aluminium a une dureté physique plus élevée et de meilleures performances en dissipation thermique. Le PCB flexible est beaucoup plus mince que les autres matériaux et peut être plié dans une certaine mesure.",
      "FR4-TG":
        "La résistance à haute température du PCB FR-4. Plus la valeur TG est élevée, meilleure sera la résistance à la température.",
      Layers:
        "Le nombre de couches de cuivre du circuit dans votre fichier, par défaut, la seule plaque n'a qu'un côté de masque de soudure.",
      Thickness: "L'épaisseur de la carte finie que vous souhaitez.",
      "Solder Mask":
        "La couleur du masque de soudure appliqué sur le matériau de base.",
      Silkscreen: "La couleur des textes sérigraphiés.",
      "Single Size": "La longueur et la largeur de votre carte.",
      "Single Quantity": "La quantité des cartes dont vous avez besoin.",
      "Panel Quantity": "La quantité de cartes dont vous avez besoin.",
      "Board Type":
        "Les cartes complètes sont soit une seule carte, soit agencées avec des PCBs identiques et différents.",
      "Different Design in Panel":
        "Combien de conceptions différentes dans votre fichier séparées par des v-cuts, des trous de tampon ou des fentes de fraisage.",
      "Min Trace Width/Spacing":
        "La largeur minimale de la trace et l'espacement minimal entre les traces dans votre fichier.",
      "Min Hole Size":
        "La taille minimale des trous de perçage dans votre fichier.",
      "Minimum Solder Mask Dam":
        "Le dique minimal de masque de soudure entre les pads de cuivre.",
      "Half Hole":
        "Les trous PTH ou vias qui sont coupés pour créer un trou partiel ou demi-trou pour former une ouverture dans le côté du barillet du trou. Ils sont généralement utilisés pour monter un PCB sur un autre.",
      "Surface Finish":
        "Le traitement HASL (nivelage de soudure par air chaud) applique une fine couche de soudure sur les pads. Le ENIG (Or électrolytique / Or par immersion) a une meilleure planéité et une durée de vie plus longue que HASL. Il est également sans plomb. L'or dur est utilisé pour les doigts en or ou d'autres zones à haute usure. En sélectionnant cette option, toute la carte sera plaquée en or dur.",
      "Finished Copper":
        "L'épaisseur du cuivre dans la couche extérieure (haut et bas).",
      TCE: "Coefficient thermique d'expansion, une propriété du matériau indiquant l'étendue à laquelle un matériau se dilate lorsqu'il est chauffé.",
      Framework:
        "Assurez-vous que le cadre est correctement aligné et correctement positionné.",
      "Stencil Side":
        "Vérifiez l'alignement du côté du pochoir avant de l'utiliser.",
      "Existing fiducials":
        "Vérifiez la position des fiduciaux existants pour garantir la précision de l'alignement.",
    },
    7: {
      1: "Pochoir SMD",
      2: "Zone valide",
      3: "Coût du pochoir",
      4: "Type de pochoir",
      5: "Taille",
      6: "Côté",
      7: "Poids",
      8: "Détails des charges",
      9: "Pochoir SMD",
    },
    message: {
      1: "Si les données dépassent la limite du système, veuillez envoyer directement le fichier de conception à l'adresse e-mail indiquée.",
      2: "Nous fournirons des commentaires manuels et un devis dans les 24 heures.",
      3: "Veuillez remplir la largeur de la carte.",
      4: "Veuillez remplir la longueur de la carte.",
      5: "Veuillez remplir la quantité de cartes.",
      6: "Veuillez télécharger le fichier Gerber.",
      7: "Veuillez télécharger le fichier BOM.",
    },
  },
  //人工转账页面
  pay: {
    1: "Votre demande de paiement a été acceptée.",
    2: "Veuillez effectuer le virement via votre compte bancaire. Le paiement sera terminé une fois que le virement sera effectué. Total du paiement",
    3: "Prend en charge le virement de compte professionnel et personnel",
    4: "1. Si votre compte de paiement est un compte bancaire professionnel, veuillez effectuer le paiement sur ce compte",
    5: "2. Si votre compte de paiement est un compte bancaire personnel, veuillez effectuer le paiement sur ce compte",
    6: "Banque bénéficiaire",
    7: "Adresse de la banque bénéficiaire",
    8: "Code SWIFT",
    9: "Nom complet du bénéficiaire",
    10: "Numéro de compte du bénéficiaire",
    11: "Adresse et numéro de téléphone du bénéficiaire",
    12: "Banque intermédiaire",
    13: "Code SWIFT (intermédiaire)",
    14: "Adresse de la banque intermédiaire",
    15: {
      1: "Conseils pour effectuer un virement",
      2: "1. Après le virement, veuillez envoyer le numéro de commande Ospcb et une copie du reçu de virement bancaire à service@cnospcb.com pour que nous puissions commencer la production à temps.",
      3: "2. Veuillez vous assurer d'entrer les informations correctes du compte bancaire lors de l'envoi du virement.",
      4: "3. Effectuez le virement du montant total en une seule fois. Ne divisez pas le paiement en plusieurs virements.",
      5: "4. Conservez votre reçu de virement bancaire pour référence future.",
      6: "Conseils pour la sécurité financière",
      7: "1. Notre personnel ne vous contactera que par l'adresse e-mail avec le domaine cnospcb.com, ne faites donc pas confiance à toute autre adresse e-mail.",
      8: "2. Si des informations sur le compte bancaire changent, nous l'annoncerons à l'avance sur le site Web, veuillez ne pas faire confiance ni transférer d'argent vers un autre compte bancaire que celui mentionné sur cette page.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Conseil: Il peut y avoir un retard dans le paiement. Si le paiement ne arrive pas longtemps, veuillez contacter le service client",
    2: "Redirection automatique dans",
    3: "secondes",
    4: "maintenant",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Numéro de commande",
      2: "Paiement reçu",
      3: "Type de paiement",
    },
    2: {
      1: "Numéro de commande",
      2: "Montant du paiement",
      3: "Monnaies",
      4: "Type de paiement",
      5: "ID de paiement",
      6: "E-mail de paiement",
      7: "Heure du paiement",
      8: "Paiement reçu",
      9: "Preuve de paiement",
      10: "Action",
    },
    3: {
      1: "Télécharger PDF",
      2: "En attente de téléchargement",
      3: "Télécharger",
    },
    message: {
      1: "Le reçu de virement a été téléchargé avec succès. Veuillez patienter pour la révision par notre personnel!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA et Stencil",
      2: "TOUS",
      3: "PCB",
      4: "PCBA",
      5: "Stencil",
      6: "Veuillez entrer le nom du fichier",
    },
    2: {
      1: "Produits",
      2: "Acheter tout",
      3: "Matériel",
      4: "Quantité",
      5: "Prix",
      6: "État du produit",
      7: "Description du produit",
      8: "Nom du fichier",
      9: "Description du fichier",
      10: "Heure de création",
      11: "Total",
    },
    3: "Temps de construction",
    4: "Voir les détails",
    5: "Acheter maintenant",
    6: "Heure de création",
    7: "Total",
    8: "En attente de confirmation",
    9: "Mode de fourniture",
    10: {
      1: "Mot-clé",
      2: "Nom du produit",
      3: "Comprend une batterie",
      4: "Poids total",
      5: "Prix unitaire",
      6: "Prix Ext.",
      7: "État",
      8: "Description",
      9: "Acheter",
      10: "Supprimer",
      11: "Action",
    },
    statusMap: {
      0: "Supprimer",
      1: "En attente de devis",
      2: "En attente de confirmation par le client",
      3: "Disponible à l'achat",
      4: "Commande soumise",
      undefined: "",
    },
    message: {
      1: "Veuillez sélectionner l'article que vous souhaitez payer !",
      2: "Êtes-vous sûr de vouloir supprimer ce produit ?",
      3: "Conseil",
      4: "Supprimer",
      5: "Supprimé avec succès.",
      6: "Voulez-vous payer avec la commande de réapprovisionnement ?",
      7: "Modifications des composants confirmées.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Numéro de commande de réapprovisionnement",
      2: "Numéro de produit",
      3: "État",
      4: "Type de paiement",
      5: "Type de produit",
      6: "Tout payer",
    },
    2: {
      1: "Numéro de commande",
      2: "Type de produit",
      3: "Numéro de produit",
      4: "Montant",
      5: "Type de paiement",
      6: "Commission",
      7: "Total",
      8: "Heure de création",
      9: "État de réapprovisionnement",
      10: "Action",
    },
    3: {
      1: "Voir",
      2: "Payer",
      3: "Facture",
    },
    4: {
      1: "Détails",
      2: "Numéro de réapprovisionnement",
      3: "État de réapprovisionnement",
      4: "Numéro de commande",
      5: "Type de produit",
      6: "Numéro de produit",
      7: "Type de paiement",
      8: "Frais de service",
      9: "Montant total",
      10: "Heure de paiement",
      11: "Description",
      12: "En attente de calcul",
      13: "Non disponible",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Stencil",
      4: "Frais d'expédition",
    },
    message: {
      1: "Veuillez sélectionner une commande.",
    },
  },
  //地址
  address: {
    1: {
      1: "Modifier",
      2: "Supprimer",
    },
    2: "AJOUTER UNE NOUVELLE ADRESSE",
    3: "MODIFIER L'ADRESSE",
    4: {
      1: "Prénom",
      2: "Nom",
      3: "Entreprise",
      4: "Rue",
      5: "Veuillez sélectionner un pays",
      6: "Provinces",
      7: "Ville",
      8: "Code postal",
      9: "Téléphone",
      10: "E-mail",
      11: "Numéro fiscal",
      12: "Est-ce l'adresse par défaut?",
      13: "Nous pouvons confirmer certains problèmes concernant vos commandes ou fichiers par e-mail, veuillez vous assurer que l'adresse e-mail est correcte.",
    },
  },
  //优惠券
  coupon: {
    1: "Mes coupons",
    2: "Coupon",
    useOfTypeMap: {
      1: "utilisable",
      2: "en cours d'utilisation",
      3: "utilisé",
      4: "expiré",
    },
    consumptionTypeMap: {
      0: "Illimité",
      1: "Pour PCB",
      2: "Pour PCBA",
      3: "Pour gabarit",
    },
    useTypeMap: {
      1: "Coût total",
      2: "Frais de port",
    },
  },
  //信函
  letter: {
    1: {
      1: "TOUS",
      2: "Réclamation",
      3: "Commentaires",
      4: "Proposition",
      5: "Commande",
    },
    2: "Créer",
    3: {
      1: "Numéro de série",
      2: "Titre",
      3: "Type",
      4: "Contenu",
      5: "Voir",
      6: "Heure de création",
      7: "Heure de fin",
      8: "État du message",
      9: "Livré",
      10: "Vu",
      11: "Taux de progression",
      12: "Messages",
      13: "Action",
      14: "Répondre",
      15: "Sélectionner le type",
    },
    4: "Détails du contenu",
    5: "Détails du message",
    6: "Répondre",
    7: {
      1: "Destinataires",
    },
    8: {
      1: "Contenu de la réponse",
    },
    9: {
      1: "Créer",
      2: "Communication",
      3: "Terminer",
    },
    10: {
      1: "Personnel",
      2: "Service",
    },
    message: {
      1: "Le titre ne peut pas être vide.",
      2: "Le type ne peut pas être vide.",
      3: "Le contenu ne peut pas être vide.",
      4: "Créé avec succès!",
      5: "Répondu avec succès.",
    },
  },
  //公告
  notice: {
    1: "Titre",
    2: "Équipe",
    3: "Heure de publication",
    4: "Action",
    5: "Détails",
    detail: {
      1: "Retour",
      2: "Notification système",
      3: "Notification des activités",
    },
  },
  //个人信息
  profile: {
    1: "Profil",
    2: {
      1: "Nom d'utilisateur",
      2: "Type de compte",
      3: "Avatar",
      4: "Mettre à jour l'avatar",
      5: "Nom de l'entreprise",
      6: "Numéro fiscal",
      7: "Numéro de téléphone",
      8: "Facebook",
      9: "Skype",
      10: "Whatsapp",
      11: "Mettre à jour",
    },
    3: {
      1: "Mettre à jour le mot de passe",
      2: "Mot de passe actuel",
      3: "Nouveau mot de passe",
      4: "Confirmer le mot de passe",
    },
    4: {
      1: "Sauvegarder",
      2: "Annuler",
    },
    5: {
      1: "personnel",
      2: "entreprise",
    },
    message: {
      1: "Le nouveau mot de passe et la confirmation ne correspondent pas.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Carte de crédit",
    3: "Virement bancaire manuel (TT)",
    4: "Autre",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "Succès!",
    "-1": "Erreur inconnue!",
    401: "Session expirée, veuillez vous reconnecter!",
    403: "Échec de la vérification de l'autorisation!",
    10000:
      "Échec du système! Veuillez envoyer des retours à l'administrateur à temps! Merci!",
    10001: "La taille du fichier dépasse la limite maximale!",
    10002: "Veuillez sélectionner la commande que vous souhaitez payer.",
    10003: "Exception de commande!",
    10004:
      "La taille du fichier dépasse la limite maximale! Taille maximale de fichier 50 Mo!",
    10005: "Une commande a déjà été générée pour cet article!",
    10006:
      "Veuillez télécharger le fichier Gerber du pochoir! Seuls les fichiers ZIP ou RAR sont acceptés. Taille maximale 50 Mo!",
    10007: "Le prix du moule n'a pas pu être trouvé!",
    10008:
      "Sous la sélection Haut et Bas (sur des pochoirs séparés), la quantité ne peut pas être inférieure à 2!",
    10009:
      "Le produit actuellement sélectionné n'existe pas ou a été désactivé!",
    10010: "Le prix de vente de ce produit n'a pas encore été annoncé!",
    10011: "Rupture de stock, impossible d'ajouter au panier!",
    10012: "L'élément du panier actuel n'existe pas!",
    10013: "Le prix de vente de ce produit n'a pas encore été annoncé!",
    10014:
      "Le stock actuel est inférieur à la quantité modifiée, veuillez ajuster et réessayer!",
    10015: "L'article n'existe pas ou a été supprimé!",
    10016:
      "Veuillez sélectionner le commentaire auquel vous souhaitez répondre!",
    10017: "Ce commentaire n'existe pas ou a été supprimé!",
    10018: "L'UUID n'existe pas!",
    10019: "Veuillez sélectionner le commentaire que vous souhaitez aimer!",
    10020: "Ce commentaire n'existe pas ou a été supprimé!",
    10021:
      "Veuillez sélectionner le commentaire que vous souhaitez ne plus aimer!",
    10022:
      "Veuillez sélectionner les détails de l'article du forum que vous souhaitez consulter!",
    10023: "La catégorie de forum sélectionnée n'existe pas!",
    10024: "Veuillez sélectionner l'article que vous souhaitez supprimer!",
    10025: "Veuillez sélectionner l'article que vous souhaitez aimer!",
    10026: "Veuillez sélectionner l'article que vous souhaitez ne plus aimer!",
    10027:
      "Veuillez sélectionner l'article que vous souhaitez annuler la collecte!",
    10028: "Veuillez sélectionner le produit pour voir la version Gerber!",
    10029: "Veuillez sélectionner le produit pour générer une commande!",
    10030: "Il n'y a actuellement aucune commande disponible pour le paiement!",
    10031: "Le paiement a échoué!",
    10032:
      "Veuillez télécharger le fichier Gerber PCB. Seuls les fichiers ZIP ou RAR sont acceptés. Taille maximale 50 Mo!",
    10033:
      "Veuillez télécharger le fichier BOM PCBA. Seuls les fichiers .CSV, .XLS ou .XLSX sont acceptés!",
    10034: "Le Panel By Customer x ne peut pas être nul!",
    10035: "Le Panel By Customer y ne peut pas être nul!",
    10036: "Le Panel By Customer x ne peut pas dépasser 500 mm!",
    10037: "Le Panel By Customer y ne peut pas dépasser 500 mm!",
    10038: "Les bords du rail ne peuvent pas être vides!",
    10039: "La taille du panneau ne peut pas être vide!",
    10040: "Le paramètre FR4 Tg ne peut pas être vide!",
    10041: "Le masque de soudure minimum FR4 ne peut pas être vide!",
    10042: "Le contrôle d'impédance FR4 ne peut pas être vide!",
    10043: "La quantité de trous demi-bord FR4 est incorrecte!",
    10044: "Le TCE de l'aluminium ne peut pas être vide!",
    10045: "La quantité de PCBA ne peut pas être 0!",
    10046: "Un pochoir est nécessaire pour le PCBA!",
    10047: "Le mode d'approvisionnement des pièces ne peut pas être vide!",
    10048: "L'ID d'adresse ne peut pas être nul!",
    10049: "L'ancien mot de passe est incorrect!",
    10050: "Le compte email n'existe pas!",
    10051: "Le CAPTCHA a expiré!",
    10052: "Le CAPTCHA est incorrect!",
    10053: "Le format de l'email est incorrect!",
    10054: "Cet email a déjà été enregistré!",
    10055: "Le nom d'utilisateur ou le mot de passe est incorrect!",
    10056: "Veuillez sélectionner les messages marqués comme lus!",
    10057: "Le mode de paiement ne peut pas être nul!",
    10058: "Le type d'article de paiement ne peut pas être vide!",
    10059: "L'ID de la commande ne peut pas être nul!",
    10060: "Veuillez sélectionner la commande que vous souhaitez payer!",
    10061: "Votre commande a été traitée!",
    10062: "Le prix du moule n'a pas pu être trouvé!",
    10063: "Impossible de récupérer le prix actuel du modèle!",
    10064:
      "Votre commande est en attente de confirmation ou a déjà été traitée!",
    10065: "Veuillez sélectionner le produit que vous souhaitez payer!",
    10066:
      "Les articles sélectionnés sont dans un état anormal ou ont déjà été payés!",
    10067: "Le produit PCB sélectionné a déjà une commande générée!",
    10068:
      "Votre commande est en attente de confirmation ou a déjà été traitée!",
    10069: "Le produit PCBA sélectionné a déjà une commande générée!",
    10070: "Le produit pochoir sélectionné a déjà une commande générée!",
    10071: "Veuillez sélectionner l'article que vous souhaitez afficher!",
    10072:
      "Le format de l'email est incorrect. Veuillez entrer un email correct!",
    10073: "Le code de vérification n'existe pas!",
    10074: "Le code de vérification est incorrect, veuillez réessayer!",
    10075: "Veuillez sélectionner le produit pour voir la version BOM!",
    10076: "Veuillez sélectionner la commande que vous souhaitez confirmer!",
    10077:
      "Cette opération ne peut pas être effectuée en raison d'un état anormal du produit!",
    10078:
      "Veuillez sélectionner les détails de la marque que vous souhaitez afficher!",
    10079: "Veuillez sélectionner l'élément à ne plus aimer!",
    10080:
      "Seuls les utilisateurs ayant acheté ce produit peuvent le commenter!",
    10081: "Vous avez déjà commenté ce produit!",
    10082:
      "Veuillez sélectionner les détails du produit que vous souhaitez afficher!",
    10083: "Le numéro de commande ne peut pas être nul!",
    10084: "Les commandes ne peuvent pas être nulles!",
    10085: "Échec du paiement par carte de crédit!",
    10086:
      "Fournissez les numéros de commande pour générer le bon de transfert!",
    10087: "Le produit sélectionné a déjà une commande générée!",
    10088:
      "Les éléments suivants ne peuvent pas être achetés en raison d'un manque de stock!",
    10089:
      "Impossible de générer des commandes en raison de stocks insuffisants!",
    10090: "Exception réseau, veuillez réessayer plus tard!",
    10091: "Le feedback actuel est en attente de traitement!",
    10092: "Le feedback actuel a été traité!",
    10093:
      "Veuillez sélectionner les détails de l'annonce que vous souhaitez afficher!",
    10094:
      "Veuillez sélectionner un emplacement pour interroger le graphique du carrousel!",
    10095: "La ressource demandée n'existe pas!",
  },
};
