/**
 * 西班牙语言包
 */
export default {
  1: "Cancelar",
  2: "Buscar",
  3: "Restablecer",
  4: "Enviar",
  5: "Éxito.",
  6: "Contenido...",
  //每个页面的标签title
  title: {
    1: "Términos y Condiciones | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "Prototipo de PCB | Montaje de PCBA | Una plataforma para la fabricación innovadora y ágil de productos electrónicos | Oceansmile | Lanyue Electronics",
    3: "Unirse | Iniciar sesión | Oceansmile | Lanyue Electronics",
    4: "Restablecer contraseña | Recuperar contraseña | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "Centro de soporte técnico y servicios PCB | Diseño electrónico | Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Noticias de la industria y centro de aprendizaje | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Noticias de la industria y centro de aprendizaje | Oceansmile | Lanyue Electronics",
    9: "Trabajo CNC | Piezas de metal | Impresión 3D | Moldeo por inyección | Piezas personalizadas | Lanyue Electronics",
    10: "SMT | Servicio de montaje de PCB | Cotización rápida online de PCB | Oceansmile | Lanyue Electronics",
    11: "Capacidades de producción de PCB | Da vida a tus ideas | Oceansmile | Lanyue Electronics",
    12: "Sobre nosotros - Da vida a tus ideas | Prototipo de PCB | Oceansmile | Lanyue Electronics",
    13: "Contáctenos - Da vida a tus ideas | Prototipo de PCB | Oceansmile | Lanyue Electronics",
    14: "Por qué elegirnos - Da vida a tus ideas | Prototipo de PCB personalizado | Oceansmile | Lanyue Electronics",
    15: "Cotización rápida de PCB online | Servicio de montaje de PCB | Oceansmile | Lanyue Electronics",
    16: "Plantilla SMT | Servicio de montaje de PCB | Oceansmile | Lanyue Electronics",
    17: "Detalles de la factura | Oceansmile | Lanyue Electronics",
    18: "Información bancaria | Transferencia bancaria | Oceansmile | Lanyue Electronics",
    19: "¡Felicidades! Pago exitoso | Oceansmile | Lanyue Electronics",
    20: "Producto | Tienda de productos",
    21: "Detalles del producto | Tienda de productos",
    22: "Componentes | IC Easy Store | Suministro de componentes",
    23: "Vista online de Gerber | Oceansmile | Lanyue Electronics",
    24: "Carrito de compras | Oceansmile | Lanyue Electronics",
    25: "Lista de pedidos | Oceansmile | Lanyue Electronics",
    26: "Lista de notificaciones de pedidos | Oceansmile | Lanyue Electronics",
    27: "Gestionar dirección de envío | Oceansmile | Lanyue Electronics",
    28: "Información personal | Oceansmile | Lanyue Electronics",
    29: "Lista de nuevos pedidos",
    30: "Cupones",
    31: "Quejas y comentarios",
    32: "Lista de anuncios | Oceansmile | Lanyue Electronics",
    33: " | Detalles del anuncio",
    34: "Registros de pagos | Revisión",
    35: "Noticias de la industria y centro de aprendizaje | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Noticias de la industria y centro de aprendizaje | Oceansmile | Lanyue Electronics",
    37: " | Foro centro personal | Noticias de la industria y centro de aprendizaje | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Centro de ayuda",
    2: "Enviar mensaje",
    3: "Whatsapp/Líneas de atención",
    4: "Mi carrito",
    5: "Bienvenido a Oceansmile",
    6: "Iniciar sesión",
    7: "¿Nuevo cliente?",
    8: "Unirse gratis",
    9: "Correo electrónico",
    10: "Cerrar sesión",
    11: "Carrito",
    12: "Mis pedidos",
    13: "Notificaciones de progreso de pedidos",
    14: "Mis cupones",
    15: "Dirección de envío",
    16: "Mi perfil",
    17: "Carta",
    18: "Notificaciones",
    19: "Inicio",
    20: "Montaje de PCB",
    21: "Cotización rápida de PCB/PCBA",
    22: "Cotización rápida de plantillas",
    23: "Tienda de productos",
    24: "Noticias y blog",
    25: "Trabajo CNC/Impresión 3D",
    26: "Producto y capacidad",
    27: "Sobre nosotros",
    28: "Contáctenos",
    29: "Por qué elegirnos",
    30: "Cotización de PCB rápida",
  },
  //脚部页面
  footer: {
    1: {
      1: "CONTENIDOS RÁPIDOS",
      2: "Deja tu mensaje abajo y uno de nuestros amables representantes te contactará dentro de 48 horas.",
      3: "Introduce tu correo electrónico",
      4: "Suscribirse",
    },
    2: {
      1: "Más de 10 años de experiencia en la fabricación rápida de prototipos de PCB y producción de PCB & PCBA de pequeña a mediana escala. Fidelización de clientes con calidad y servicio superiores...",
      2: "Dirección",
      3: "Comunidad Songyuansha, Calle Guanhu, Distrito Longhua, Shenzhen, Guangdong, China",
      4: "Teléfono",
      5: "Correo electrónico",
      6: "Certificado por",
    },
    3: {
      1: "Habilidades",
      2: "Productos/Servicios",
      3: "Soporte al cliente",
      4: "Sobre nosotros",
      5: "Habilidades PCB",
      6: "Cotización rápida de PCB",
      7: "Contáctenos",
      8: "Sobre nosotros",
      9: "Habilidades de montaje de PCB",
      10: "Montaje de PCB",
      11: "Centro de ayuda",
      12: "¿Por qué comprar con nosotros?",
      13: "Plantillas SMD",
      14: "Nuestra equipamiento",
      15: "Consultoría de proyectos personalizada",
      16: "Guía de métodos de envío",
      17: "Habilidades de PCB de aluminio",
      18: "Soluciones industriales",
      19: "Comentarios",
      20: "Política de privacidad y acuerdo",
      21: "Habilidades de PCB flexible",
      22: "Política de devoluciones",
      23: "Impresión 3D/CNC",
      24: "Términos de uso",
    },
    4: "Copyright © 2024 OSpcb. Todos los derechos reservados. Lanyue Electronics (Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Centro de miembros",
    2: "Pedido",
    3: "Mi cuenta",
    4: "Sistema",
    5: "Carrito",
    6: "Mis pedidos",
    7: "Sostenibilidad",
    8: "Notificación de pedidos",
    9: "Registros de pago",
    10: "Dirección",
    11: "Mis cupones",
    12: "Mi perfil",
    13: "Carta",
    14: "Notificaciones",
  },
  //aboutUs页面
  aboutUs: {
    1: "Oceansmile es una plataforma integral para la fabricación ágil e innovadora de productos electrónicos en Shenzhen. Con más de 15 años de experiencia en la fabricación de PCB y PCBA en China, ofrecemos servicios de prototipado integral y fabricación ágil. Ayudamos a nuestros clientes a llevar sus productos al mercado a tiempo, con recursos completos, costos competitivos, materiales confiables y una calidad excelente. Oceansmile emplea a más de 200 personas en nuestra fábrica, con una capacidad de producción mensual de más de 3,000 muestras o entregas de pequeñas series. Servimos a más de 150,000 clientes en más de 250 países alrededor del mundo. No hay cantidad mínima de pedido. Ofrecemos un servicio personalizado 1 a 1 y soluciones completas para diferentes escenarios de aplicaciones industriales. Como uno de los fabricantes más experimentados en China, somos su mejor socio comercial y amigos en todos los aspectos de sus necesidades de proyecto. Pensamiento orientado al cliente y adaptación. Crecemos con usted y nos ajustamos a sus necesidades.",
    2: {
      1: "Agilidad",
      2: "Costos",
      3: "Aseguramiento de Calidad",
      4: "Nada es Imposible",
    },
    3: {
      1: "Gestión del proceso de pedido y fabricación en línea",
      2: "Entrega rápida",
      3: "Capacidades completas de cadena de suministro",
      4: "Respuesta rápida",
    },
    4: {
      1: "Ofertas en línea, precios transparentes",
      2: "Sin tarifas ocultas ni costos adicionales",
      3: "Bajos costos para producción en pequeña serie",
    },
    5: {
      1: "Proceso de producción estricto",
      2: "Equipo de ingenieros profesionales (más de 5 años de experiencia)",
      3: "Equipos de alto rendimiento",
      4: "AVL robusta",
      5: "Experiencia completa en productos",
    },
    6: {
      1: "Servicio sin limitaciones",
      2: "Pensamiento orientado al cliente y adaptación.",
    },
    7: {
      1: "Ofrecemos servicios de prototipado integral y fabricación ágil. ¡No solo PCB / PCBA!",
      2: "Circuitos impresos / Montaje PCB / SOLUCIONES IoT LÍDERES EN LA INDUSTRIA / Pruebas y programas de prueba personalizados / Abastecimiento de componentes / Ajustes mecánicos (metales, silicona, plásticos, madera) / Moldeo por inyección / Ayuda en el diseño PCB y disposición / Ajustes de batería / Diseño de productos / OEM / ODM",
    },
    8: "Oceansmile tiene tres grandes fábricas profesionales especializadas en prototipos de PCB, producción masiva de PCB, ensamblaje de PCB y producción mecánica. Con una superficie de 15,000 metros cuadrados y 6 líneas de SMT, se enfoca en la validación de productos de desarrollo de clientes (prototipos) para garantizar una capacidad suficiente para la producción en serie.",
    9: "Confianza de las marcas más grandes del mundo",
    10: "Oceansmile se compromete a satisfacer las necesidades de nuestros clientes (más de 15K) de diversas industrias y países en términos de calidad, entrega, costo y otros requisitos. Como uno de los fabricantes más experimentados de PCB/PCBA en China, hemos establecido cooperaciones con muchas empresas internacionales de renombre.",
    11: {
      1: "Clientes pagos",
      2: "Países y regiones",
      3: "Empresas cooperadoras",
    },
    12: "¿Por qué Oceansmile?",
  },
  //联系我们页面
  contact: {
    1: "Contáctanos",
    2: {
      1: "Consultas de ventas y productos",
      2: "Oceansmile es una plataforma integral para la fabricación ágil e innovadora de productos electrónicos en Shenzhen. Ofrecemos servicios de producción de prototipos rápidos de PCB y ensamblaje de PCB. Nuestro equipo puede responder preguntas sobre capacidades de PCB, precios de PCB y PCBA, piezas mecánicas de silicona/plástico… y cualquier aspecto de sus necesidades de proyecto. Pedir en Oceansmile es fácil, ya que ofrecemos ofertas inmediatas en línea para PCB estándar, PCB flexible / rígido-flexible, plantillas SMD y montaje de PCB con comprobaciones gratuitas de archivos Gerber, asegurando una entrega puntual con DHL, FedEx, UPS y más.",
      3: "Por favor, deje su mensaje a continuación y uno de nuestros amables empleados se pondrá en contacto con usted dentro ",
      4: "de las 24 horas.",
    },
    3: {
      1: "Nombre",
      2: "Correo electrónico",
      3: "Número de teléfono",
      4: "Su número de teléfono",
      5: "Empresa",
      6: "El nombre de su empresa",
      7: "Captcha",
      8: "Su Captcha",
      9: "Mensaje",
      10: "Cuéntenos cómo podemos ayudarle con productos, entrega, pagos y más.",
      11: "Enviar mensaje",
    },
    4: "¡Pensamiento orientado al cliente y adaptación!",
    5: "Ayudamos a que sus productos lleguen al mercado a tiempo, con recursos completos, costos competitivos, materiales confiables y calidad excelente.",
    6: "Servicio en cualquier clima",
    7: {
      1: "Consejos",
      2: "1. Para obtener una respuesta más rápida, le recomendamos que se ponga en contacto con nuestro personal en la página de su cuenta después de ",
      3: "Iniciar sesión/Registrarse.",
      4: "2. Visite el ",
      5: "centro de ayuda de Oceansmile",
      6: " para encontrar rápidamente las preguntas más frecuentes sobre pedidos, envíos, pagos y más.",
    },
    message: {
      1: "El nombre es obligatorio.",
      2: "El correo electrónico es obligatorio.",
      3: "El número de teléfono es obligatorio.",
      4: "La empresa es obligatoria.",
      5: "Captcha es obligatorio.",
      6: "El mensaje es obligatorio.",
    },
  },
  //why us页面
  why: {
    1: "¿Por qué elegirnos ",
    2: "Oceansmile es un fabricante de PCB/PCBA de alta calidad en Shenzhen, China, con más de 15 años de experiencia. Con capacidades profesionales en fabricación de PCB/PCBA, nuestros ingenieros de PCB y SMT con más de 10 años de experiencia revisarán sus archivos de diseño dos veces. Además, Oceansmile está certificado por IATF16949, ISO9001, ISO14001, UL, CQC, RoHS y REACH; además, procesamos todo el proceso incluyendo PCB/PCBA / Circuitos impresos / Montaje PCB / SOLUCIONES IoT LÍDERES EN LA INDUSTRIA / Pruebas y programas de prueba personalizados / Abastecimiento de componentes / Ajustes mecánicos (metales, silicona, plásticos, madera) / Moldeo por inyección / Ayuda en diseño PCB & disposición / Ajustes de batería / Diseño de productos / OEM / ODM y envío final. Podemos montar componentes BGA, Micro-BGA, QFN y otros paquetes sin ensamblar. Protegemos estrictamente la seguridad de la documentación de producción y los derechos de propiedad intelectual de sus productos. ¡Ofrecemos servicios de prototipado integral y fabricación ágil, consulte todas las piezas que necesita!",
    3: "Plataforma integral de soporte multi-servicio de I+D (¡No solo PCB & PCBA)",
    4: "Oceansmile es un fabricante profesional de servicios integrales globales con 15 años de experiencia en producción de PCB y PCBA en China. Con capacidades profesionales de fabricación de PCB/PCBA, podemos apoyar todos los diseños avanzados con requisitos exigentes, incluyendo FR4, HDI, perforaciones láser, microvias, pads, placas de microondas y RF, hasta 32 capas. Oceansmile es un fabricante profesional con 15 años de experiencia en producción de PCB y PCBA en China.",
    5: "Ofrecemos servicios de prototipado integral y fabricación ágil. ¡No solo PCB/PCBA!",
    6: "Circuitos impresos / Montaje PCB / SOLUCIONES IoT LÍDERES EN LA INDUSTRIA / Pruebas y programas de prueba personalizados / Abastecimiento de componentes / Ajustes mecánicos (metales, silicona, plásticos, madera) / Moldeo por inyección / Ayuda en diseño PCB & disposición / Ajustes de batería / Diseño de productos / OEM / ODM…",
    7: "Agilidad: Velocidad de fabricación de PCB y ensamblaje con nuestro sistema de datos y sistema de producción MES, colaboración eficiente",
    8: "Gestión del proceso de pedido y fabricación en línea, entrega rápida con DHL, FedEx, UPS…",
    9: "Capacidades completas de cadena de suministro que proporcionan una cadena de suministro internacional rica que no solo ofrece una rápida capacidad de adquisición de componentes, sino que también ofrece más opciones rentables con mejor calidad. Ingenieros experimentados ofrecen respuestas rápidas las 24 horas.",
    10: "Menores costos: Reducir los costos, hacer el producto más competitivo y ganar-ganar.",
    11: "Ofrecemos ofertas en línea, precios transparentes. Ingenieros experimentados ayudan a analizar la producción (DFM) para evitar costos adicionales en el proceso de fabricación y ahorrar tiempo. Sin tarifas ocultas ni costos adicionales. Nos centramos en prototipos y producción de pequeñas y medianas series. Métodos de fabricación únicos y flexibles.",
    12: "Aseguramiento de calidad: Excelente sistema de control de recursos y proveedores, los planes de control de calidad se actualizan y mejoran constantemente.",
    13: "Los materiales de PCB de Oceansmile están certificados por IATF 16949, ISO9001, ISO14001, UL, RoHS y REACH. Para PCB montados, ofrecemos una variedad de métodos de prueba meticulosos, como Inspección Óptica Automatizada (AOI), Pruebas en Circuito (ICT), Inspección por Rayos X, Pruebas Funcionales y múltiples niveles de inspección visual.",
    14: "Nos encargamos de cada producto con procesos de producción estrictos. Tenemos un equipo profesional de ingenieros (todos con más de 5 años de experiencia). El equipo tiene un excelente rendimiento, todos los equipos son de marcas de alto nivel, precisos y poderosos.",
    15: "Protección de derechos de propiedad intelectual.",
    16: "Protegemos estrictamente los derechos de propiedad intelectual de los productos de nuestros clientes, asegurando que todo el equipo de producción esté bajo un sistema de monitoreo de seguridad. Firmamos acuerdos de NDA con nuestros clientes para proteger los derechos de propiedad intelectual de los productos de forma permanente.",
    17: "Nada es imposible: Crecemos con nuestros clientes y llevamos sus ideas a la realidad.",
    18: "Ofrecemos servicios completos de prototipado y fabricación ágil. Tenemos opciones de servicio ilimitadas, basadas en los mejores recursos en Shenzhen, China. Pensamiento orientado al cliente y adaptación. Equipos experimentados, ideas vibrantes, siempre nos desarrollaremos y nunca nos detendremos.",
  },
  //论坛详情页面
  articleDetail: {
    1: "Noticias y Blog",
    2: "Comentarios",
    3: "Inicia sesión para publicar comentarios.",
    4: "Publicar comentario",
    5: "Responder",
    7: "Publicar artículo",
    8: "ETIQUETAS POPULARES",
    9: "Categorías",
    message: {
      1: "La categoría del foro no puede estar vacía.",
      2: "El título no puede estar vacío.",
      3: "La imagen del título no puede estar vacía.",
      4: "El contenido no puede estar vacío.",
      5: "Creado exitosamente.",
      6: "Por favor, inicia sesión para publicar un artículo.",
      7: "¡Selecciona un máximo de 10 etiquetas para el artículo!",
      8: "¡Por favor ingresa un comentario!",
      9: "Comentario enviado con éxito.",
      10: "¿Estás seguro de que deseas eliminar el comentario?",
      11: "Consejos",
      12: "Eliminar",
      13: "¡Eliminado exitosamente!",
    },
  },
  //论坛页面
  article: {
    1: "Centro de Foros",
    2: "Publicar artículo",
    3: "Categorías",
    4: "Seleccionar una categoría",
    5: {
      1: "Categoría",
      2: "Seleccione una categoría",
      3: "Título",
      4: "¡Por favor ingrese el título del artículo!",
      5: "Imagen del título",
      6: "Borrador del contenido",
      7: "¡Por favor ingrese una breve descripción del artículo!",
      8: "Etiquetas",
      9: "Buscar/Agregar",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Publicaciones",
    2: "Colecciones",
    3: "Me gusta",
    4: "Publicar artículo",
    5: {
      1: "Crear un nuevo artículo",
      2: "Categoría",
      3: "Título",
      4: "¡Por favor ingresa el título del artículo!",
      5: "Imagen del título",
      6: "Boceto del contenido",
      7: "¡Por favor ingresa una breve descripción del artículo!",
      8: "Buscar/Agregar",
    },
  },
  //帮助中心列表
  help: {
    1: "¿Cómo podemos ayudarte?",
    2: "Ingresa palabras clave relacionadas para buscar",
  },
  //帮助中心详情
  helpDetail: {
    1: "Centro de ayuda",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "Impresión 3D",
      2: "Tecnologías SLA, MJF, SLM, FDM, SLS",
      3: "•Resina, Nylon, Metal y Plástico",
      4: "•Tolerancia hasta 0.2mm",
      5: "•Tiempo de entrega desde 2 días hábiles",
    },
    2: {
      1: "Mecanizado CNC",
      2: "Fresado (3-, 4- y 5-ejes), torneado y post-procesamiento",
      3: "•Aluminio, Cobre, Plástico",
      4: "•Tolerancia hasta 0.05mm",
      5: "•Tiempo de entrega desde 3 días hábiles",
    },
    3: {
      1: "Fabricación de moldes por inyección",
      2: "Moldeo al vacío",
      3: "•ABS, POM, Nylon, PC, PC/ABS, PVC, PE, PP, PS, TPU, Silicona, Materiales personalizados",
      4: "•Ciclo de vida >=50k/100k",
      5: "•Tolerancias controladas con ISO 2768-1",
      6: "•Tiempo de entrega 15-25 días hábiles",
    },
    4: {
      1: "Países a los que se envía",
      2: "Área de fábrica",
      3: "Materiales y acabados",
      4: "Solicitud de cotización",
    },
    5: "Solicitar cotización ahora",
    6: "Servicio cerrado 360°",
    7: "Equipo profesional de ingenieros y servicio te brindan un servicio en línea 360° en tiempo real, con informes regulares de retroalimentación de producción.",
    8: "Proceso de servicio estándar",
    9: "Oceansmile tiene un potente sistema de gestión digital para ofrecer servicios de procesamiento rápidos, integrales y de alta calidad.",
    10: {
      1: "Cotización rápida",
      2: "Cotización y servicio DFM en 24 horas tras recibir los documentos por parte de los ingenieros.",
      3: "Confirmación de solución",
      4: "Selecciona los requisitos de material y acabado, confirma el plan de producción.",
      5: "Confirmación de pago",
      6: "Revisa tu pedido y paga. Añade ventas para obtener servicios VIP.",
      7: "Mecanizado / Impresión 3D",
      8: "Corte - Programación - Procesamiento - Postprocesamiento, Inspección - Entrega",
      9: "Servicio postventa",
      10: "Revisa los productos con el dibujo. Reaccionamos a problemas de calidad lo antes posible.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Detalles del producto",
    2: "Reseñas",
    3: {
      1: "Valoraciones",
      2: "Reseña",
      3: "Imágenes",
      4: "Haz clic para subir",
      5: "Enviar reseña",
      6: "Escribe tu reseña...",
      7: "No hay comentarios por ahora",
    },
    4: "Incluye batería",
    5: "días hábiles",
    6: "en stock",
    7: "seleccionar",
    8: {
      1: "Almacén CN",
    },
    9: "AÑADIR AL CARRITO",
    10: "Etiquetas",
    11: "AGOTADO",
    12: "Inicio",
    13: "Tienda de productos",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Por favor ingresa el contenido a ser anotado. Este campo es opcional.",
      2: "Observaciones",
      3: "Ninguna",
      4: "Añadido con éxito.",
      5: "¡Reseña exitosa!",
      6: "¡Ya hay tres imágenes! Por favor elimina una antes de subir nuevas imágenes.",
    },
  },
  commodity: {
    1: {
      1: "palabra clave",
      4: "por página",
    },
    2: {
      1: "Más...",
      2: "Añadir al carrito",
      3: "AGOTADO",
      4: "No se encontraron resultados",
    },
    3: {
      1: "Observaciones y cantidad",
      2: "Cantidad",
      3: "Descripción",
      4: "Por favor ingresa el contenido a ser anotado. Puedes ignorarlo",
      5: "SKU",
      6: "Sin datos",
    },
    message: {
      1: "Añadido con éxito.",
    },
  },
  //忘记密码
  forget: {
    1: "Olvidé la contraseña",
    2: {
      1: "Dirección de correo electrónico",
      2: "Correo electrónico",
    },
    3: {
      1: "Código de autenticación",
      2: "Código",
      3: "Obtener",
    },
    4: {
      1: "Nueva contraseña",
    },
    5: {
      1: "Confirmar contraseña",
    },
    message: {
      1: "Por favor ingresa una dirección de correo electrónico.",
      2: "Por favor ingresa una dirección de correo electrónico.",
      3: "Por favor ingresa el código de autenticación.",
      4: "Por favor ingresa la contraseña.",
      5: "Por favor confirma la contraseña.",
      6: "La contraseña no coincide con la confirmación.",
      7: "Contraseña actualizada con éxito.",
    },
  },
  //主页
  home: {
    1: {
      1: "Calculadora de fabricación de PCB",
      2: "Longitud \\ Ancho",
      3: "Longitud",
      4: "Ancho",
      5: "Cantidad",
      6: "Por favor ingresa",
      7: "Capas",
      8: "Espesor",
      9: "¿Nuevo? ¿No registrado?",
      10: "Obtener",
      11: "¡Códigos aquí!",
      12: "Cotizar ahora",
      13: "Calculadora de ensamblaje PCBA",
    },
    2: {
      1: "Entrega rápida",
      2: "Tan rápido como en 24 horas",
      3: "Asistencia personalizada",
      4: "Experiencia de compra fluida",
      5: "Mejor valor",
      6: "Precios directos de fabricante",
      7: "Noticias",
      8: "Prototipo de PCB fácil",
      9: "Con más de una década en el campo de PCB...",
      10: "Aprende más",
    },
    3: {
      1: "Cotización instantánea de PCB",
      2: "1~6 capas de Poliamida flexible",
      3: "Formulario de pedido 1 pieza",
      4: "Tiempo de entrega de 4~5 días",
      5: "COTIZACIÓN INSTANTÁNEA PCB",
      6: "Sube el archivo PCB",
      7: "Revisión de pedido",
      8: "Pago",
      9: "Seguimiento en tiempo real",
      10: "Entrega",
      11: "Confirmar recibido",
      12: "Haz clic en “Cotización instantánea” en la barra de menú, selecciona “Cotización instantánea PCB/PCBA”. Luego entras a la página de cotización y subes el archivo PCB.",
      13: "Sube archivos Gerber (zip, rar), otros formatos pueden causar errores debido a problemas de compatibilidad de versiones. El sistema completará la revisión automática en 30 segundos. ¿Falló la subida? Puedes saltarla y enviar el archivo por correo electrónico a",
      14: "Los ingenieros experimentados de Oceansmile realizarán DFM en el diseño del producto y proporcionarán sugerencias de optimización para el producto.",
      15: "Pago en línea, seguro y rastreable. Soportamos varios métodos de pago, Paypal, tarjeta de crédito, transferencia bancaria offline, etc. Si tienes alguna duda sobre el pago, por favor contacta a nuestro servicio de atención al cliente.",
      16: "Visualiza la etapa del progreso del pedido y consulta el estado de producción del producto en cualquier momento. Se asignará personal especializado para dar seguimiento a los pedidos y asegurar que los productos se completen a tiempo y con buena calidad.",
      17: "Una amplia gama de opciones de mensajería y empresas de mensajería mundialmente reconocidas garantizan una entrega rápida y segura de los productos.",
      18: "Recibirás tu producto. Mantenemos relaciones win-win con los clientes y seguiremos brindándote servicio postventa de alta calidad. Si tienes problemas con el producto, utilizaremos nuestros recursos para asistirte.",
    },
    4: {
      1: "Productos más vendidos",
      2: "Prototipo PCB",
      3: "Desde",
      4: "Tiempo de construcción",
      5: "Montaje PCB",
      6: "Proveedores de componentes",
      7: "PCB Flex, Rígido-Flex",
      8: "PCB HDI",
      9: "Impresión 3D",
      10: "días",
      11: "horas",
      12: "Mecanizado CNC",
      13: "piezas",
    },
    5: {
      1: "Promoción de servicio de montaje",
      2: "Solo",
      3: "EN TOTAL",
      4: "para montaje de 1-20 piezas",
      5: "SOLICITAR COTIZACIÓN AHORA",
      6: "Solución integral para PCB y montaje",
      7: "Fácil de fabricar, Cantidad, Una sola vez",
      8: "Montaje SMT y de agujero pasante",
      9: "Soldadura por onda para PCBA",
      10: "Cotización online de PCBA",
      11: "Solución integral de compra de BOM",
      12: "Más del 99,6% de tasa de satisfacción",
      13: "Montaje de caja, cableado",
      14: "Pruebas funcionales",
      15: "y otras pruebas",
      16: "Programación de IC",
      17: "BGA",
      18: "QFN",
      19: "Últimos 30 días",
      20: "Clientes",
      21: "Pagando",
      22: "SMT",
    },
    6: {
      1: "Fabricante de prototipos PCB asequible en China",
      2: "Fecha",
      3: "Nombre del comprador",
      4: "Última vez",
      5: "Progreso",
    },
    7: {
      1: "Capa",
      2: "PCB de 4 capas con 2 capas de enrutamiento, una capa de tierra y una capa de alimentación",
      3: "Apilamiento de PCB de varias capas",
      4: "PCB de 6 capas tiene GTL, GBL y 4 capas internas. Cada señal que viaja de 1 a 6 capas",
      5: "PCB de 8 capas tiene GTL, GBL y 6 capas internas. Cada señal que viaja de 1 a 8 capas",
    },
    8: "Desde nuestro blog",
    9: "Lo que dicen los clientes",
    10: "mm",
  },
  //登录页面
  login: {
    1: "Iniciar sesión",
    2: "Crear cuenta",
    3: {
      1: "Correo electrónico",
      2: "Código",
      3: "Obtener",
      4: "Contraseña",
      5: "Confirmar contraseña",
      6: "Registrar",
      7: "Al crear una cuenta, acepta nuestros",
      8: "Términos y condiciones",
    },
    4: {
      1: "INICIAR SESIÓN",
      2: "¡Bienvenido de nuevo!",
      3: "¿Olvidó su contraseña?",
      4: "Iniciar sesión",
    },
    message: {
      1: "Por favor, ingrese un correo electrónico.",
      2: "Por favor, acepte los términos y condiciones para continuar.",
      3: "Por favor, ingrese el código de autenticación.",
      4: "Por favor, ingrese la contraseña.",
      5: "Por favor, confirme la contraseña.",
      6: "La contraseña no coincide con la confirmación.",
      7: "¡Registro exitoso! Inicie sesión en el lado derecho de la página.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Precio razonable garantizado",
      2: "$3.78 por 5 piezas de PCB prototipo 10*10cm, $25 por 10 piezas de montaje PCB.",
      3: "Material de PCB garantizado",
      4: "OceanSmile solo utiliza materiales de alta calidad que cumplen con la normativa ROHS para la producción.",
      5: "3. Calidad garantizada",
      6: "OceanSmile cumple completamente con el sistema de gestión de calidad ISO 9001:2015.",
      7: "4. Entrega a tiempo garantizada",
      8: "Prototipo PCB urgente, servicio personalizado en línea, entrega 99% puntual por DHL.",
    },
    2: {
      1: "Mostrar producto",
      2: "Con más de 10 años de experiencia en la creación rápida de prototipos de PCB y producción de volúmenes pequeños y medianos de PCB.",
    },
    3: {
      1: "Capacidades de montaje PCB",
      2: "Nos enorgullecemos de nuestra reputación como líder en montaje de superficie, orificio pasante y tecnología mixta en ensamblaje de PCB, así como en servicios de fabricación electrónica y montaje completo de PCB.",
      3: "MONTADO DE PCB PROTOTIPO",
      4: "Solicitar ahora",
      5: "Tipos de PCB para montaje",
      6: "Placa FR-4",
      7: "Placa de núcleo metálico",
      8: "Placa flexible",
      9: "Placa rígida-flexible",
      10: "Y más...",
      11: "Tipos de soldadura",
      12: "Soldadura sin plomo",
      13: "Montaje de componentes SMD y TH",
      14: "Montaje de una sola y doble cara",
      16: "Proveedores de componentes",
      17: "Digi-key, Mouser, Arrow, Element 14 y",
      18: "más...",
      19: "Mercado chino de componentes universales",
      20: "Con aprobación de su parte.",
      21: "Tipos de pruebas",
      22: "Inspección visual",
      23: "Inspección AOI",
      24: "Inspección por rayos X",
      25: "Prueba en circuito",
      26: "Prueba funcional",
    },
    4: {
      1: "Mostrar fábrica",
      2: "Con más de 10 años de experiencia en la creación rápida de prototipos de PCB y producción de volúmenes pequeños y medianos de PCB.",
    },
    5: {
      1: "¿Cómo realizar un pedido de servicio de montaje de PCB en OceanSmile?",
      2: "Haga clic aquí para obtener más detalles sobre cómo realizar un pedido de PCBA o contáctenos",
      3: "si tiene alguna pregunta sobre nuestros servicios de montaje.",
    },
    6: {
      1: "Primer paso",
      2: "Suba sus archivos Gerber de PCB y complete el formulario",
      3: "Segundo paso",
      4: "Suba el archivo BOM formateado",
      5: "Tercer paso",
      6: "Obtenga una cotización en línea instantánea",
    },
    7: "Cargando ...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "Habilidades de fabricación y montaje de PCB",
      3: "Conozca más sobre las habilidades de Oceansmile y haga realidad sus ideas rápidamente.",
      4: "Verde",
      5: "Púrpura",
      6: "Rojo",
      7: "Amarillo",
      8: "Azul",
      9: "Blanco",
      10: "Negro",
    },
    2: {
      1: "PCB rígido",
      2: "PCB flexible",
      3: "Montaje de PCB",
    },
    3: {
      1: "Especificaciones de PCB",
      2: "Tamaño del agujero / agujero",
      3: "Ancho mínimo de pista",
      4: "Distancia mínima",
      5: "Ancho y distancia mínima de pista",
      6: "BGA",
      7: "Máscara de soldadura",
    },
    4: {
      1: "Propiedades",
      2: "Capacidades",
      3: "Notas",
      4: "Muestras",
      5: "Número de capas",
      6: "Capas",
      7: "El número de capas de cobre de las placas.",
      8: "Material",
      9: "FR-4",
      10: "Aluminio",
      11: "Núcleo de cobre",
      12: "Rogers",
      13: "Cerámica",
      14: "PCB FR-4",
      15: "PCB de aluminio",
      16: "Tamaño de la placa",
      17: "Mín.",
      18: "Máx.",
      19: "El tamaño de la placa PCB",
      20: "Tolerancia de tamaño",
      21: "(Regular) para enrutamiento CNC y ±0,4 mm para corte en V",
      22: "Espesor de la placa",
      23: "El grosor terminado de la placa",
      24: "Tolerancia de grosor",
      25: "± 0,1 mm para placas de espesor ≤ 1,6 mm, ± 10 % para placas de espesor ≥ 1,6 mm",
      26: "Cobre terminado",
      27: "Capa externa",
      28: "Capa interna",
      29: "Acabado superficial",
      30: "HASL (con plomo / sin plomo), ENIG, OSP, ENEPIG",
      31: "El proceso adicional para ayudar a que el cobre expuesto sea soldable.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Propiedades",
      2: "Capacidades",
      3: "Notas",
      5: "Tamaño del agujero (agujero redondo)",
      6: "Perforación mecánica",
      7: "Perforación con láser",
      8: "Tamaño del agujero (agujero de ranura)",
      9: "Tamaño del agujero (agujero semiperforado)",
      10: "El diámetro de los agujeros semiperforados debe ser superior a 0,5 mm y la distancia entre los agujeros semiperforados debe ser superior a 0,9 mm",
      11: "Vías ciegas / enterradas",
      12: "OceanSmile puede soportar tanto vías ciegas como vías enterradas.",
      13: "Tolerancia del tamaño del agujero",
      14: "PTH",
      15: "NPTH",
      16: "Agujero de ranura",
    },
    6: {
      1: "Cobre",
      2: "VÍA",
    },
    7: {
      1: "Espacio libre entre orificios (redes diferentes)",
      2: "Espacio libre mínimo entre orificios enchapados que podemos aceptar",
      3: "Espacio libre entre vías (mismas redes)",
      4: "Espacio libre mínimo entre vías que podemos aceptar",
      5: "Espacio libre entre almohadillas (almohadillas SMD, redes diferentes)",
      6: "Espacio libre mínimo entre almohadillas SMD que podemos aceptar",
      7: "Espacio libre entre almohadillas (almohadillas TH, redes diferentes)",
      8: "Espacio libre mínimo entre almohadillas TH que podemos aceptar",
      9: "Vía a pista",
      10: "Espacio libre mínimo entre vía y pista que podemos aceptar",
      11: "PTH a pista",
      12: "Espacio libre mínimo entre PTH y pista que podemos aceptar",
      13: "NPTH a pista",
      14: "La distancia mínima entre NPTH y la pista que podemos aceptar.",
      15: "Pad a pista",
      16: "El espacio libre mínimo entre el pad y la pista que podemos aceptar.",
    },
    8: {
      1: "Ancho de pista mínimo",
      2: "Espaciado mínimo",
      3: "Ancho/espaciado de pista recomendado para producción en lote",
    },
    9: {
      1: "Especificación",
      2: "Dimensiones mínimas del pad BGA",
      3: "Ancho mínimo Distancia entre BGA",
      4: "Diámetro de BGA",
      5: "Mínimo para HASL con plomo",
      6: "Mínimo para HASL sin plomo",
      7: "Mínimo para otras superficies terminadas",
      8: "El diámetro mínimo de los pads BGA que podemos aceptar",
      9: "Espacio entre pads BGA",
      10: "Espacio mínimo entre los pads BGA que podemos aceptar",
    },
    10: {
      1: "Color de la máscara de soldadura",
      2: "El color de la placa PCB",
    },
    11: "mm",
  },
  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Información del producto",
      2: "Descripción",
      3: "RoHs",
      4: "Disponibilidad",
      5: "En stock",
      6: "Precios",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Última actualización: 16 de mayo de 2024.",
    2: "Declaración de operación del sitio web de Oceansmile",
    3: "Este sitio web es operado por Oceansmile, que incluye cnospcb.com y Lanyue Electronics (Shenzhen) Co., Ltd. En este sitio web, los términos 'Oceansmile', 'OSPCB', 'Lanyue', 'nosotros' y 'nuestro' se refieren a las marcas y subsidiarias mencionadas anteriormente. Los términos y condiciones descritos en este documento representan a estas entidades y se aplican a todos los servicios proporcionados por las marcas mencionadas.",
    4: "Política de privacidad",
    5: "Esta es la Política de Privacidad publicada por Lanyue/Oceansmile/Lanyue Electronics (Shenzhen) Co., Ltd., que incluye el procesamiento de datos personales y cómo ejercer sus derechos, etc. La política de privacidad se aplicará a este sitio con respecto a todos los productos y/o servicios relacionados proporcionados por Lanyue (Oceansmile). Como controlador de sus datos personales, Lanyue está de acuerdo en que la protección de sus datos personales es importante para nosotros y tomaremos todas las medidas razonables para recopilar y usar sus datos personales de acuerdo con las leyes y regulaciones aplicables.",
    6: "Datos personales",
    7: "Según la definición específica establecida en el Reglamento No. 2016/679 sobre la protección de las personas físicas en lo que respecta al procesamiento de datos personales (el 'GDPR'), los datos personales procesados por Lanyue se dividen en las siguientes categorías:",
    8: "1. Algunos datos personales los proporciona usted de forma voluntaria.",
    9: "2. Otros datos personales se procesan automáticamente cuando visita nuestros sitios y/o utiliza los productos y/o servicios de Lanyue.",
    10: "3. Otros datos personales se recogen a través de sitios y servicios de terceros.",
    11: "Cumplimiento del control de exportaciones",
    12: "El procesamiento de datos personales",
    13: "Proporcionará sus datos personales voluntariamente durante el proceso de compra o en otros procesos con su consentimiento previo, incluidos, pero no limitados a, su nombre personal y su información de contacto, datos de cuenta, información de pago, datos de comentarios y otros datos que proporcione. Lanyue utilizará sus datos personales proporcionados para el propósito de ejecutar sus pedidos, brindarle ciertos servicios, registrar una cuenta y cualquier comunicación entre usted y Lanyue.",
    14: "Lanyue usará sus datos personales solo para el propósito especificado arriba o cualquier otro requisito legal. Usted tiene el derecho de solicitar retirar o eliminar sus datos personales en cualquier momento si considera que sus derechos no están protegidos.",
    15: "Lanyue puede recopilar automáticamente sus datos personales cuando utilice productos y/o servicios de Lanyue, incluyendo, pero no limitado a:",
    16: "Información del dispositivo, como después de que compre productos de Lanyue y los implemente en su dirección para monitoreo, los servidores web de Lanyue pueden registrar automáticamente su dirección IP o cualquier información relacionada de su dispositivo.",
    17: "Datos relacionados con los productos que utiliza y cómo los usa, así como sus hábitos de uso cuando busca en nuestros sitios web, etc. El propósito de recopilar automáticamente sus datos personales es evaluar y mejorar su experiencia con los productos y/o servicios de Lanyue. Si no desea divulgar sus hábitos de uso u otros datos personales, comuníquese con nuestro correo electrónico de contacto designado como se indica en esta Política de Privacidad.",
    18: "Además, el sitio de Lanyue utiliza servicios de terceros, lo que incluye proveedores de servicios de pago, proveedores de envío y anunciantes. Algunos proveedores pueden recopilar sus datos personales con el fin de los fines anteriores y también tienen su propia política de privacidad para proteger el procesamiento de sus datos personales.",
    19: "PRECIO Y PAGO",
    20: "Lanyue se esfuerza por proporcionar información actual y precisa sobre productos y precios, pero no garantiza la validez o precisión de dicha información.",
    21: "La cotización de precios en línea no garantiza la cantidad total de la orden ni los tiempos de entrega. Todas las cantidades de pedidos y tiempos de entrega están sujetos a una revisión de la capacidad de producción. El precio mostrado en el formulario web al ingresar el pedido será vinculante solo después de la confirmación y después de que los archivos documentales enviados hayan sido revisados. Lanyue se reserva el derecho de cambiar el precio o cancelar el pedido si la documentación enviada no cumple con los datos ingresados en la pantalla de entrada del pedido. En estos casos, le notificaremos sobre los cambios. Si su pedido ha sido cancelado y ya ha realizado el pago, Lanyue emitirá un reembolso al método de pago original.",
    22: "Lanyue no se responsabiliza de los errores de precio, tipográficos u otros errores en cualquier oferta de Lanyue y se reserva el derecho de cancelar cualquier pedido derivado de dichos errores.",
    23: "ENVÍO Y ENTREGA",
    24: "El cliente es responsable de asegurarse de que la dirección de entrega proporcionada en el formulario web sea correcta y completa. Cualquier costo adicional de transporte resultante de detalles incorrectos de la dirección será cargado al cliente. Los clientes internacionales son responsables de los cargos aduaneros e impuestos de importación en todos los pedidos.",
    25: "Lanyue enviará sus productos dentro de un período comercialmente razonable a la dirección especificada en su pedido. Trabajamos arduamente para asegurarnos de que todos sus pedidos se envíen a tiempo. Sin embargo, hay ocasiones en que los transportistas pueden tener retrasos o cometer errores en el envío. Lamentamos cuando esto sucede, pero no podemos hacernos responsables de los retrasos por parte de estos transportistas.",
    26: "RENUNCIA DE DERECHOS DE PROPIEDAD INTELECTUAL",
    27: "Los documentos de diseño de PCB/productos utilizados para la fabricación fueron proporcionados por los clientes y la responsabilidad de Lanyue fue fabricar la placa/ producto de acuerdo con el diseño del cliente. Los clientes deben garantizar que poseen los derechos de propiedad intelectual relacionados con los documentos de diseño proporcionados, los cuales no deben infringir los derechos de propiedad intelectual ni ningún otro derecho legal de terceros, como derechos de patente, derechos de autor, derechos de marca registrada, etc.",
    28: "Cualquier infracción o problema legal derivado de los documentos proporcionados por los clientes, que cause influencia o pérdida para nosotros, será responsabilidad exclusiva de los clientes, quienes deberán indemnizarnos.",
    29: "Contáctenos",
    30: "Si tiene alguna pregunta o comentario sobre estos términos, contáctenos a",
  },
  //看图页面
  canvas: {
    1: {
      1: "Abrir todo",
      2: "Cerrar todo",
    },
    2: {
      1: "Tamaño",
      2: "Ancho mínimo de traza",
      3: "Espaciado mínimo de traza",
      4: "Cantidad de agujeros de perforación",
      5: "Tamaño mínimo de agujero",
      6: "Tamaño máximo de agujero",
      7: "Área ENIG",
      8: "Punto de prueba",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA & Plantillas",
    2: {
      1: "TODOS",
      2: "Cancelado",
      3: "Pendiente de pago",
      4: "Confirmación por Oceansmile",
      5: "Pagado",
      6: "Procesando",
      7: "Empacado",
      8: "Enviado",
      9: "Ingrese número de pedido",
    },
    3: {
      1: "Pedido | Información del producto",
      2: "Todo comprar",
      3: "Material",
      4: "Cantidad",
      5: "Precio",
      6: "Descripción del producto",
      7: "Nombre del archivo",
      8: "Descripción del archivo",
    },
    4: {
      1: "Teléfono",
      2: "Compañía",
      3: "Número de IVA",
      4: "Peso",
      5: "Entrega",
      6: "Enviar a",
    },
    5: {
      1: "Tiempo de construcción",
      2: "Hora de pago",
      3: "No pagado",
      4: "Consejos",
      5: "El tiempo de construcción se calcula desde el momento después de que se realice el pago",
      6: "Hora de creación",
    },
    6: {
      1: "Método de pago",
      2: "Precio del producto",
      3: "Comisiones",
      4: "Gastos de envío",
      5: "Otros descuentos",
      6: "Total",
    },
    7: "Descripción del pedido",
    8: "Ver detalles",
    9: "Modo de suministro",
    10: "Marco",
    11: {
      1: "Comprar ahora",
      2: "Factura",
    },
    12: "Productos",
    13: {
      1: "Precio unitario",
      2: "Precio extendido",
      3: "Descripción del usuario",
    },
    14: {
      0: "Detalle",
      1: "Archivo Gerber",
      2: "Descripción del Gerber",
      3: "Descripción del producto",
      4: "Tipo de placa",
      5: "Material",
      6: "Longitud",
      7: "Anchura",
      8: "Capas",
      9: "Grosor",
      10: "Cobre terminado",
      11: "Tamaño mínimo de agujero",
      12: "Máscara de soldadura",
      13: "Serigrafía",
      14: "Tiempo de construcción",
      15: "Agujero parcial",
      16: "Estado del pedido",
      17: "Peso del producto",
      18: "Diseño diferente en el panel",
      19: "FR4-TG",
      20: "Cantidad",
      21: "Acabado superficial",
      22: "Daño mínimo de máscara de soldadura",
      23: "Ancho mínimo de traza / Espaciado",
      24: "Control de impedancia",
      25: "Detalles del cargo",
      26: "Tarifa de ingeniería",
      27: "Panel",
      28: "Huecos castellanos",
      29: "Placa",
      30: "Prueba",
      31: "Cobre",
      32: "Acabado superficial",
      33: "Proceso via",
      34: "Via ciega",
      35: "Via en el pad",
      36: "Tarifa de corte V",
      37: "Tarifa de color",
      38: "Tamaño grande",
      39: "Control de impedancia",
      40: "Descuento",
      41: "Total",
      42: "Costo PCBA",
      43: "Costo de componentes",
      44: "Costo de ensamblaje",
      45: "Costo de servicio",
      46: "Archivo Bom",
      47: "Descripción Bom",
      48: "Detalles de componentes",
      49: "Confirmación de producto",
      50: "Designador",
      51: "MPN",
      52: "Cantidad total",
      53: "Material desperdiciado",
      54: "Precio unitario",
      55: "Descripción",
      56: "Versiones",
      57: "Marco",
      58: "Tamaño",
      59: "Lado de plantilla",
      60: "Detalles del cargo",
      61: "Plantilla SMD",
    },
    15: {
      1: "Pedido complementario",
      2: "Hacer pedido por diferencia de precio",
      3: "Número de pedido",
      4: "Diferencia de precio",
      5: "Descripción",
      6: "Pagar",
    },
    16: "PCB",
    17: "PCBA",
    18: "Plantilla",
    message: {
      1: "Eliminado exitosamente.",
      2: "¿Quieres pagar por la orden de pago adicional al mismo tiempo?",
      3: "Consejos",
      4: "Por favor seleccione un pedido.",
      5: "Copiado exitosamente.",
    },
  },
  //预览价格
  preview: {
    1: "Volver",
    2: "Dirección de envío",
    3: "Editar",
    4: "Eliminar",
    5: "AÑADIR NUEVA DIRECCIÓN",
    6: {
      1: "Si es la dirección predeterminada",
      2: "Podemos confirmar algunos problemas con sus pedidos o archivos por correo electrónico. ¡Asegúrese de que el correo electrónico sea válido!",
    },
    7: "Método de envío",
    8: "No aplicable",
    9: "Cupones",
    10: "Ninguno",
    11: "Método de pago",
    12: "Tarjeta de crédito",
    13: "Transferencia bancaria manual (TT)",
    14: {
      1: "RESUMEN",
      2: "Precio de la diferencia",
      3: "Comisión",
      4: "Peso del producto (KG)",
      5: "Subtotal",
      6: "Costo de envío",
      7: "Precio original",
      8: "Monto del descuento",
      9: "Monto a pagar",
      10: "Descuento de cupón",
      11: "Descuento OceanSmile",
      12: "Comprar ahora",
    },
    message: {
      1: "¿Está seguro de que desea eliminar la dirección?",
      2: "Consejo",
      3: "Eliminado con éxito.",
      4: "El primer nombre no puede estar vacío.",
      5: "El apellido no puede estar vacío.",
      6: "La empresa no puede estar vacía.",
      7: "La calle no puede estar vacía.",
      8: "El país no puede estar vacío.",
      9: "La provincia no puede estar vacía.",
      10: "La ciudad no puede estar vacía.",
      11: "El código postal no puede estar vacío.",
      12: "El teléfono no puede estar vacío.",
      13: "El correo electrónico no puede estar vacío.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Cotización en línea",
      2: "Subir archivo Gerber",
      3: "Revisión del pedido",
      4: "Pago",
      5: "Fabricación",
      6: "Envío",
      7: "Confirmar y revisar",
    },
    2: "Selección de especificación PCB",
    3: {
      1: "Añadir archivos Gerber",
      2: "Subida exitosa",
      3: "Visor online Gerber",
      4: "El archivo PCB se ha subido correctamente, pero no se pueden analizar los parámetros.",
      5: "Solo se aceptan archivos ZIP o RAR. Tamaño máximo: 50 MB.",
      6: "Si el archivo zip cargado no se analiza correctamente o no se muestra ninguna imagen, no se preocupe, nuestros ingenieros lo revisarán manualmente y se pondrán en contacto con usted por correo electrónico más tarde.",
      7: "Para requisitos especiales que no están disponibles en esta página o si no encuentra lo que está buscando, por favor envíenos sus requisitos y archivos Gerber y ",
      8: "le responderemos dentro de las 24 horas.",
    },
    4: {
      1: "Comentario para Gerber",
      2: "Piezas individuales",
      3: "Panel por el cliente",
      4: "Panel por OSpcb",
      5: "Rieles de borde",
      6: "No",
      7: "Tamaño del panel",
      8: "Cantidad de paneles",
      9: "Otros",
      10: "Comentario para PCB",
    },
    5: {
      1: "Montaje de PCB",
      2: "Cantidad individual",
      3: "Stencil",
      4: "Modo de suministro de piezas",
      5: "Turnkey",
      6: "Consignación",
      7: "Combo",
      8: "Comentario para PCBA",
      9: "Comentario para la lista de materiales (BOM)",
      10: "Agregar archivo BOM",
      11: "Descargar plantilla BOM",
      12: "Por favor, suba un archivo BOM preciso que siga esta plantilla para que podamos leerlo correctamente.",
      13: "MPN puede elegirse de Digikey, Mouser, Arrow, Element u otras plataformas, nuestro sistema lo reconocerá automáticamente.",
      14: "Para solicitudes especiales de ensamblaje PCB, por favor escriba a",
      15: "no las escriba directamente en el archivo BOM.",
      16: "Solo reconocemos comas para distinguir la ubicación.",
      17: "El número de designador debe ser ≤ Cantidad.",
      18: "Por favor, no rellene el número de parte de Digikey o Mouser en la línea de MPN (Número de parte del fabricante).",
      19: "Por favor, no incluya componentes inútiles en su archivo BOM.",
    },
    6: {
      1: "Por favor seleccione el país",
      2: "Especificación PCB",
      3: "Cantidad",
      4: "Tiempo de construcción",
      5: "Costo de PCB",
      6: "Tarifa de ingeniería",
      7: "Placa",
      8: "Costo de PCBA",
      9: "Costo de ensamblaje",
      10: "Costo de componentes",
      11: "Subtotal",
      12: "Agregar al carrito",
      13: "días hábiles",
    },
    str: {
      "Normal FR-4 Board": "Placa normal FR-4",
      "Flexible Boards": "Placas flexibles",
      "Board Type": "Tipo de placa",
      "Single Quantity": "Cantidad individual",
      "FR4-TG": "FR4-TG",
      "Single Size": "Tamaño individual",
      "Different Design in Panel": "Diseño diferente en el panel",
      "Min Trace Width/Spacing": "Ancho mínimo de traza/Espaciado",
      "Min Hole Size": "Tamaño mínimo de agujero",
      "Minimum Solder Mask Dam": "Dique mínimo de máscara de soldadura",
      "Half Hole": "Agujero parcial",
      "Surface Finish": "Acabado superficial",
      "Finished Copper": "Cobre terminado",
      "Impedance Control": "Control de impedancia",
      "Panel Quantity": "Cantidad de panel",
      "Edge Qty": "Cantidad de borde",
      "Solder Mask": "Máscara de soldadura",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 capa",
      "2 Layer": "2 capas",
      "4 Layer": "4 capas",
      "6 Layer": "6 capas",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0,2mm",
      "0_25mm": "0,25mm",
      "0_3mm": "0,3mm",
      "0_4mm": "0,4mm",
      "HASL with lead": "HASL con plomo",
      "HASL lead free": "HASL libre de plomo",
      "Immersion gold": "Oro por inmersión",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1,0w/(m⋅K)",
      "2_0w/(m⋅K)": "2,0w/(m⋅K)",
      "0_08": "0,08",
      "0_13": "0,13",
      "0_22": "0,22",
      "0_6": "0,6",
      "0_8": "0,8",
      "1_0": "1,0",
      "1_2": "1,2",
      "1_6": "1,6",
      "2_0": "2,0",
      "0_6mm": "0,6mm",
      "0_8mm": "0,8mm",
      "1_0mm": "1,0mm",
      "1_2mm": "1,2mm",
      "1_6mm": "1,6mm",
      "2_0mm": "2,0mm",
      "0_10": "0,10",
      "0_12": "0,12",
      "0_15": "0,15",
      "0_18": "0,18",
      "0_10mm": "0,10mm",
      "0_12mm": "0,12mm",
      "0_15mm": "0,15mm",
      "0_18mm": "0,18mm",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0,5oz",
      mm: "mm",
      OSP: "OSP",
      None: "Ninguno",
      White: "Blanco",
      Black: "Negro",
      Green: "Verde",
      Red: "Rojo",
      Yellow: "Amarillo",
      Blue: "Azul",
      Silkscreen: "Serigrafía",
      Aluminum: "Aluminio",
      Layers: "Capas",
      Material: "Material",
      Thickness: "Espesor",
      TCE: "TCE",
      No: "No",
      Yes: "Sí",
      "Single Pieces": "Piezas individuales",
      "Panel By Customer": "Panel por el cliente",
      "Panel By OSpcb": "Panel por OSpcb",
      true: "Sí",
      false: "No",
      Turnkey: "Turnkey",
      Consignment: "Consignación",
      Combo: "Combo",
      Unit: "Unidad",
      Total: "Total",
      Framework: "Marco",
      "Stencil Side": "Lado de stencil",
      "Existing fiducials": "Fiduciales existentes",
      Top: "Arriba",
      Bottom: "Abajo",
      "Top+Bottom(On Single Stencil)": "Arriba+Abajo (En un solo stencil)",
      "Top & Bottom(On Separate Stencil)":
        "Arriba y Abajo (En stencil separado)",
      "SIZE (mm)": "TAMAÑO (mm)",
      Quantity: "Cantidad",
      none: "Ninguno",
      "half lasered": "Medio laserizado",
      "lasered through": "Laserizado completamente",
      undefined: "",
    },
    tips: {
      Material:
        "El PCB FR-4 es el material más utilizado. El PCB de aluminio tiene una mayor dureza física y mejor rendimiento de disipación térmica. El PCB flexible es mucho más delgado que otros materiales y puede doblarse hasta cierto punto.",
      "FR4-TG":
        "Rendimiento de resistencia a altas temperaturas del PCB FR-4. Cuanto mayor sea el valor de TG, mejor será la resistencia a la temperatura.",
      Layers:
        "La cantidad de capa de cobre del circuito en su archivo, por defecto la máscara de soldadura solo está en un lado de la placa única.",
      Thickness: "El grosor de la placa terminada que necesita.",
      "Solder Mask":
        "El color de la máscara de soldadura aplicada al material base.",
      Silkscreen: "El color de los textos de serigrafía.",
      "Single Size": "La longitud y el ancho de su placa.",
      "Single Quantity": "La cantidad de las placas que necesita.",
      "Panel Quantity": "La cantidad de placas que necesita.",
      "Board Type":
        "Las placas completas son una sola o están dispuestas con las mismas y diferentes PCBs.",
      "Different Design in Panel":
        "Cuántos diseños diferentes en su archivo separados por v-cuts, agujeros de estampado o ranuras de fresado.",
      "Min Trace Width/Spacing":
        "El ancho mínimo de la pista y la distancia mínima entre trazas en su archivo.",
      "Min Hole Size":
        "El tamaño mínimo de los agujeros perforados en su archivo.",
      "Minimum Solder Mask Dam":
        "El dique mínimo de la máscara de soldadura entre las almohadillas de cobre.",
      "Half Hole":
        "Los agujeros PTH o vías que se cortan a través para crear un agujero parcial o medio agujero para formar una abertura en el costado del barril del agujero. Normalmente se utilizan para montar un PCB sobre otro.",
      "Surface Finish":
        "El procesamiento HASL (Nivelado de Soldadura por Aire Caliente) aplica una capa delgada de soldadura sobre las almohadillas. El ENIG (Oro Inmerso/ Níquel Electrolítico) tiene una mejor planicidad y vida útil que el HASL. También es libre de plomo. El oro duro se usa para los dedos de oro u otras áreas de alta fricción. Al seleccionar esta opción, toda la placa será chapada en oro duro.",
      "Finished Copper":
        "El grosor del cobre en la capa exterior (superior e inferior).",
      TCE: "Coeficiente térmico de expansión, una propiedad material que indica el grado en el que un material se expande al calentarse.",
      Framework:
        "Asegúrese de que el marco esté correctamente alineado y posicionado de manera segura.",
      "Stencil Side":
        "Verifique la alineación de la parte del stencil antes de usarlo.",
      "Existing fiducials":
        "Verifique la posición de los fiduciales existentes para garantizar la precisión en la alineación.",
    },
    7: {
      1: "SMD-Stencil",
      2: "Área válida",
      3: "Costo del stencil",
      4: "Tipo de stencil",
      5: "Tamaño",
      6: "Lado",
      7: "Peso",
      8: "Detalles de cargos",
      9: "SMD-Stencil",
    },
    message: {
      1: "Si los datos superan el límite del sistema, envíe el archivo de diseño directamente a la dirección de correo electrónico indicada.",
      2: "Le proporcionaremos retroalimentación manual y una cotización dentro de 24 horas.",
      3: "Por favor, ingrese el ancho de la placa.",
      4: "Por favor, ingrese la longitud de la placa.",
      5: "Por favor, ingrese la cantidad de placas.",
      6: "Por favor, cargue el archivo Gerber.",
      7: "Por favor, cargue el archivo BOM.",
    },
  },
  //人工转账页面
  pay: {
    1: "Su solicitud de pago ha sido aceptada.",
    2: "Por favor, realice la transferencia a través de la cuenta bancaria. El pago se completará una vez que se haya realizado la transferencia. Total de pago",
    3: "Soporte para Transferencia de cuenta comercial y personal",
    4: "1. Si su cuenta de pago es una cuenta bancaria empresarial, por favor pague a esta cuenta",
    5: "2. Si su cuenta de pago es una cuenta bancaria personal, por favor pague a esta cuenta",
    6: "Banco beneficiario",
    7: "Dirección del Banco Beneficiario",
    8: "Código SWIFT",
    9: "Nombre completo del beneficiario",
    10: "Número de cuenta del beneficiario",
    11: "Dirección y número de teléfono del beneficiario",
    12: "Banco intermediario",
    13: "Código SWIFT (intermediario)",
    14: "Dirección del Banco Intermediario",
    15: {
      1: "Consejos para la remesa",
      2: "1. Después de realizar la remesa, envíe el número de pedido de Ospcb y una copia del recibo de la transferencia bancaria a service@cnospcb.com para que podamos comenzar la producción a tiempo.",
      3: "2. Asegúrese de ingresar la información correcta de la cuenta bancaria al realizar la remesa.",
      4: "3. Transfiera el monto total en un solo pago. No divida el pago en más de una transferencia.",
      5: "4. Conserve su recibo de transferencia bancaria para referencia futura.",
      6: "Consejos para la seguridad financiera",
      7: "1. Nuestro personal solo usará la dirección de correo electrónico con el dominio cnospcb.com para contactarlo, no confíe en direcciones de correo electrónico con otros dominios.",
      8: "2. Si hay algún cambio en la información de la cuenta bancaria, lo anunciaremos en el sitio web con anticipación, no confíe ni transfiera dinero a ninguna cuenta bancaria que no esté en esta página.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Consejo: Puede haber un retraso en el pago. Si no llega durante mucho tiempo, póngase en contacto con el servicio de atención al cliente",
    2: "Redirección automática en",
    3: "segundos",
    4: "ahora",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Número de pedido",
      2: "Recibir pago",
      3: "Tipo de pago",
    },
    2: {
      1: "Número de pedido",
      2: "Monto pagado",
      3: "Monedas",
      4: "Tipo de pago",
      5: "ID de pago",
      6: "Correo electrónico de pago",
      7: "Hora de pago",
      8: "Pago recibido",
      9: "Comprobante de pago",
      10: "Acción",
    },
    3: {
      1: "Descargar PDF",
      2: "Esperando carga",
      3: "Cargar",
    },
    message: {
      1: "Comprobante de transferencia cargado con éxito. ¡Por favor, espere pacientemente la revisión del personal!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA y Stencil",
      2: "TODO",
      3: "PCB",
      4: "PCBA",
      5: "Stencil",
      6: "Por favor ingrese el nombre del archivo",
    },
    2: {
      1: "Productos",
      2: "Todo comprar",
      3: "Material",
      4: "Cantidad",
      5: "Precio",
      6: "Estado del producto",
      7: "Descripción del producto",
      8: "Nombre del archivo",
      9: "Descripción del archivo",
      10: "Hora de creación",
      11: "Total",
    },
    3: "Tiempo de construcción",
    4: "Ver detalle",
    5: "Comprar ahora",
    6: "Hora de creación",
    7: "Total",
    8: "Pendiente de confirmación",
    9: "Modo de suministro",
    10: {
      1: "Palabra clave",
      2: "Nombre del producto",
      3: "Incluye batería",
      4: "Peso total",
      5: "Precio unitario",
      6: "Precio Ext.",
      7: "Estado",
      8: "Descripción",
      9: "Comprar",
      10: "Eliminar",
      11: "Acción",
    },
    statusMap: {
      0: "Eliminar",
      1: "Esperando cotización",
      2: "Pendiente de confirmación por el cliente",
      3: "Disponible para compra",
      4: "Pedido enviado",
      undefined: "",
    },
    message: {
      1: "¡Por favor, seleccione el artículo que desea pagar!",
      2: "¿Está seguro de que desea eliminar este producto?",
      3: "Consejo",
      4: "Eliminar",
      5: "Eliminado con éxito.",
      6: "¿Desea pagar junto con la orden de reposición?",
      7: "Cambios en los componentes confirmados.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Número de pedido de reposición",
      2: "Número de producto",
      3: "Estado",
      4: "Tipo de pago",
      5: "Tipo de producto",
      6: "Todo pagar",
    },
    2: {
      1: "Número de pedido",
      2: "Tipo de producto",
      3: "Número de producto",
      4: "Monto",
      5: "Tipo de pago",
      6: "Comisión",
      7: "Total",
      8: "Hora de creación",
      9: "Estado de reposición",
      10: "Acción",
    },
    3: {
      1: "Ver",
      2: "Pagar",
      3: "Factura",
    },
    4: {
      1: "Detalles",
      2: "Número de reposición",
      3: "Estado de reposición",
      4: "Número de pedido",
      5: "Tipo de producto",
      6: "Número de producto",
      7: "Tipo de pago",
      8: "Cargo por servicio",
      9: "Monto total",
      10: "Hora de pago",
      11: "Descripción",
      12: "Esperando cálculo",
      13: "No tiene",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Stencil",
      4: "Tarifa de envío",
    },
    message: {
      1: "Por favor seleccione un pedido.",
    },
  },
  //地址
  address: {
    1: {
      1: "Editar",
      2: "Eliminar",
    },
    2: "AÑADIR NUEVA DIRECCIÓN",
    3: "EDITAR DIRECCIÓN",
    4: {
      1: "Nombre",
      2: "Apellido",
      3: "Empresa",
      4: "Calle",
      5: "Por favor seleccione un país",
      6: "Provincias",
      7: "Ciudad",
      8: "Código postal",
      9: "Teléfono",
      10: "Correo electrónico",
      11: "Número de identificación fiscal",
      12: "¿Es esta la dirección predeterminada?",
      13: "Podemos confirmar algunos problemas con sus pedidos o archivos por correo electrónico, por favor asegúrese de que el correo electrónico sea correcto.",
    },
  },
  //优惠券
  coupon: {
    1: "Mis cupones",
    2: "Cupón",
    useOfTypeMap: {
      1: "utilizable",
      2: "en uso",
      3: "usado",
      4: "caducado",
    },
    consumptionTypeMap: {
      0: "Ilimitado",
      1: "Para PCB",
      2: "Para PCBA",
      3: "Para plantilla",
    },
    useTypeMap: {
      1: "Costo total",
      2: "Costo de envío",
    },
  },
  //信函
  letter: {
    1: {
      1: "TODOS",
      2: "Queja",
      3: "Comentarios",
      4: "Propuesta",
      5: "Pedido",
    },
    2: "Crear",
    3: {
      1: "Número de serie",
      2: "Título",
      3: "Tipo",
      4: "Contenido",
      5: "Ver",
      6: "Hora de creación",
      7: "Hora de finalización",
      8: "Estado del mensaje",
      9: "Entregado",
      10: "Visto",
      11: "Tasa de progreso",
      12: "Mensajes",
      13: "Acción",
      14: "Responder",
      15: "Seleccionar tipo",
    },
    4: "Detalles del contenido",
    5: "Detalles del mensaje",
    6: "Responder",
    7: {
      1: "Destinatarios",
    },
    8: {
      1: "Contenido de la respuesta",
    },
    9: {
      1: "Crear",
      2: "Comunicación",
      3: "Terminar",
    },
    10: {
      1: "Propio",
      2: "Servicio",
    },
    message: {
      1: "El título no puede estar vacío.",
      2: "El tipo no puede estar vacío.",
      3: "El contenido no puede estar vacío.",
      4: "¡Creado con éxito!",
      5: "Respondido con éxito.",
    },
  },
  //公告
  notice: {
    1: "Título",
    2: "Equipo",
    3: "Hora de publicación",
    4: "Acción",
    5: "Detalles",
    detail: {
      1: "Atrás",
      2: "Notificación del sistema",
      3: "Notificación de actividades",
    },
  },
  //个人信息
  profile: {
    1: "Perfil",
    2: {
      1: "Nombre de usuario",
      2: "Tipo de cuenta",
      3: "Avatar",
      4: "Actualizar avatar",
      5: "Nombre de la empresa",
      6: "Número de identificación fiscal",
      7: "Número de teléfono",
      8: "Facebook",
      9: "Skype",
      10: "Whatsapp",
      11: "Actualizar",
    },
    3: {
      1: "Actualizar contraseña",
      2: "Contraseña actual",
      3: "Nueva contraseña",
      4: "Confirmar contraseña",
    },
    4: {
      1: "Guardar",
      2: "Cancelar",
    },
    5: {
      1: "personal",
      2: "corporativo",
    },
    message: {
      1: "La nueva contraseña y la confirmación no coinciden.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Tarjeta de crédito",
    3: "Transferencia bancaria manual (TT)",
    4: "Otro",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "¡Éxito!",
    "-1": "¡Error desconocido!",
    401: "¡Sesión expirada, por favor inicie sesión nuevamente!",
    403: "¡Falló la verificación de autorización!",
    10000:
      "¡Error del sistema! ¡Por favor, envíe comentarios al administrador a tiempo! ¡Gracias!",
    10001: "¡El tamaño del archivo excede el límite máximo!",
    10002: "Por favor, seleccione el pedido que desea pagar.",
    10003: "¡Excepción en el pedido!",
    10004:
      "¡El tamaño del archivo excede el límite máximo! ¡El archivo máximo es 50 MB!",
    10005: "¡Se ha generado un pedido para el artículo!",
    10006:
      "¡Por favor, cargue el archivo Gerber de la plantilla! ¡Solo se aceptan archivos ZIP o RAR. ¡Tamaño máximo de 50MB!",
    10007: "¡No se pudo encontrar el precio del molde!",
    10008:
      "En la selección de Top & Bottom (en plantilla separada), ¡la cantidad no puede ser menor a 2!",
    10009:
      "¡El producto seleccionado actualmente no existe o ha sido desactivado!",
    10010: "¡El precio de venta de este producto aún no ha sido anunciado!",
    10011: "¡Fuera de stock, no se puede agregar al carrito!",
    10012: "¡El artículo actual en el carrito no existe!",
    10013: "¡El precio de venta de este producto aún no ha sido anunciado!",
    10014:
      "¡El inventario actual de los productos es menor que la cantidad modificada, por favor ajuste y vuelva a intentar!",
    10015: "¡El artículo no existe o ha sido eliminado!",
    10016: "¡Por favor seleccione el comentario al que desea responder!",
    10017: "¡Este comentario no existe o ha sido eliminado!",
    10018: "¡El UUID no existe!",
    10019: "¡Por favor seleccione el comentario que desea darle like!",
    10020: "¡Este comentario no existe o ha sido eliminado!",
    10021: "¡Por favor seleccione el comentario que desea quitar el like!",
    10022:
      "¡Por favor seleccione los detalles del artículo del foro que desea ver!",
    10023: "¡La categoría del foro seleccionada no existe!",
    10024: "¡Por favor seleccione el artículo que desea eliminar!",
    10025: "¡Por favor seleccione el artículo que desea darle like!",
    10026: "¡Por favor seleccione el artículo que desea quitar el like!",
    10027: "¡Por favor seleccione el artículo que desea cancelar la colección!",
    10028: "¡Por favor seleccione el producto para ver la versión Gerber!",
    10029: "¡Por favor seleccione el producto para generar un pedido!",
    10030: "¡Actualmente no hay pedidos disponibles para pagar!",
    10031: "¡El pago no fue exitoso!",
    10032:
      "¡Por favor cargue el archivo Gerber de la PCB! ¡Solo se aceptan archivos ZIP o RAR. ¡Tamaño máximo 50MB!",
    10033:
      "¡Por favor cargue el archivo BOM de la PCBA! ¡Solo se aceptan archivos .CSV, .XLS o .XLSX!",
    10034: "¡El Panel By Customer x no puede ser nulo!",
    10035: "¡El Panel By Customer y no puede ser nulo!",
    10036: "¡El Panel By Customer x no puede exceder los 500 mm!",
    10037: "¡El Panel By Customer y no puede exceder los 500 mm!",
    10038: "¡Los rieles de borde no pueden ser nulos!",
    10039: "¡El tamaño del panel no puede ser nulo!",
    10040: "¡El Tg del FR4 no puede ser nulo!",
    10041: "¡El dam mínimo de máscara de soldadura del FR4 no puede ser nulo!",
    10042: "¡El control de impedancia del FR4 no puede ser nulo!",
    10043: "¡La cantidad de bordes de agujeros del FR4 está incorrecta!",
    10044: "¡El TCE del aluminio no puede ser nulo!",
    10045: "¡La cantidad de PCBA no puede ser 0!",
    10046: "¡El stencil es necesario para la PCBA!",
    10047: "¡El modo de suministro de partes no puede estar vacío!",
    10048: "¡El ID de dirección no puede ser nulo!",
    10049: "¡La contraseña antigua es incorrecta!",
    10050: "¡La cuenta de correo electrónico no existe!",
    10051: "¡El CAPTCHA ha expirado!",
    10052: "¡El CAPTCHA es incorrecto!",
    10053: "¡El formato del correo electrónico es incorrecto!",
    10054: "¡Este correo electrónico ya ha sido registrado!",
    10055: "¡El nombre de usuario o la contraseña son incorrectos!",
    10056: "¡Por favor seleccione los mensajes marcados como leídos!",
    10057: "¡El método de pago no puede ser nulo!",
    10058: "¡El tipo de artículo de pago no puede estar vacío!",
    10059: "¡El ID del pedido no puede ser nulo!",
    10060: "¡Por favor seleccione el pedido que desea pagar!",
    10061: "¡Su pedido ha sido procesado!",
    10062: "¡No se pudo encontrar el precio del molde!",
    10063: "¡No se puede obtener el precio de la plantilla actual!",
    10064: "¡Su pedido está pendiente de confirmación o ya ha sido procesado!",
    10065: "¡Por favor seleccione el producto que desea pagar!",
    10066:
      "¡Los productos seleccionados están en condiciones anormales o ya han sido pagados!",
    10067: "¡El producto PCB seleccionado ya tiene un pedido generado!",
    10068: "¡Su pedido está pendiente de confirmación o ya ha sido procesado!",
    10069: "¡El producto PCBA seleccionado ya tiene un pedido generado!",
    10070: "¡El producto stencil seleccionado ya tiene un pedido generado!",
    10071: "¡Por favor seleccione lo que desea ver!",
    10072:
      "¡El formato del correo electrónico es incorrecto. ¡Por favor ingrese un correo electrónico correcto!",
    10073: "¡El código de verificación no existe!",
    10074: "¡El código de verificación es incorrecto, intente nuevamente!",
    10075: "¡Por favor seleccione el producto para ver la versión BOM!",
    10076: "¡Por favor seleccione el pedido que desea confirmar!",
    10077:
      "¡Esta operación no se puede realizar debido al estado anormal del producto!",
    10078: "¡Por favor seleccione los detalles de la marca que desea ver!",
    10079:
      "¡Por favor seleccione el artículo que desea quitar de la lista de favoritos!",
    10080:
      "¡Solo los usuarios que compraron este producto pueden dejar una reseña!",
    10081: "¡Ya ha dejado una reseña para este producto!",
    10082: "¡Por favor seleccione los detalles del producto que desea ver!",
    10083: "¡El número de pedido no puede ser nulo!",
    10084: "¡Los pedidos no pueden ser nulos!",
    10085: "¡Fallo en el pago con tarjeta de crédito!",
    10086:
      "¡Proporcione los números de pedido para generar la factura de transferencia!",
    10087: "¡El producto seleccionado ya tiene un pedido generado!",
    10088:
      "¡Los siguientes artículos no se pueden comprar debido a falta de existencias!",
    10089: "¡No se pueden generar pedidos debido a falta de existencias!",
    10090: "¡Error de red, por favor intente nuevamente más tarde!",
    10091: "¡El comentario actual está esperando procesamiento!",
    10092: "¡El comentario actual ha sido procesado!",
    10093: "¡Por favor seleccione los detalles del anuncio que desea ver!",
    10094:
      "¡Por favor seleccione una ubicación para consultar el gráfico del carrusel!",
    10095: "¡El recurso al que se accedió no existe!",
  },
};
