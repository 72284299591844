import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api_loading: false,
        is_api_loading: true,

        username: '',
        email: '',
        userid: '',
        freshchatLoaded: false,
    },
    mutations:{
        set_name(state,v){
            state.username = v;
        },
        set_email(state,v){
            state.email = v;
        },
        set_userid(state,v){
            state.userid = v;
        },
        set_freshchatLoaded(state,v){
            state.freshchatLoaded = v;
        },// 其他 mutation
        SET_LOGGED_IN(state, status) {
            state.isLoggedIn = status; // 确保有 isLoggedIn 状态
        },
    },
    actions:{
        set_name({commit},v){
            commit("set_name",v);
        },
        set_email({commit},v){
            commit("set_email",v);
        },
        set_userid({commit},v){
            commit("set_userid",v);
        },
        set_freshchatLoaded({commit},v){
            commit("set_freshchatLoaded",v);
        },
    },
    getters: {},
    modules: {
        login
    }
})