import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/layout.vue";
import personLayout from "@/layout/personLayout.vue";
import { i18n } from '../main'; // 引入 i18n 实例

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush
      .call(this, location, onResolve, onReject)
      .catch((err) => err);
  } else {
    return originalPush.call(this, location).catch((err) => err);
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: layout,
    children: [
      {
        //条款和约
        path: "/termsAndConditions",
        name: "termsAndConditions",
        meta: {
          titleKey: 'title.1',
        },
        component: () => import("@/views/home/termsAndConditions"),
      },
      {
        // 首页
        path: "/home",
        name: "home",
        meta: {
          titleKey: 'title.2',
        },
        component: () => import("@/views/home/home"),
      },
      {
        // 注册/登录
        path: "/login",
        name: "login",
        meta: {
          titleKey: 'title.3',
        },
        component: () => import("@/views/home/login"),
      },
      {
        path: "/forget",
        name: "forget",
        meta: {
          titleKey: 'title.4',
        },
        component: () => import("@/views/home/forgetPassword"),
      },
      {
        // 帮助中心
        path: "/helpCenter",
        name: "helpCenter",
        meta: {
          titleKey: 'title.5',
        },
        component: () => import("@/views/helpCenter/helpCenter"),
      },
      {
        path: "/helpCenter/:id",
        name: "helpCenterDetail",
        meta: {
          titleKey: 'title.6',
        },
        component: () => import("@/views/helpCenter/helpCenterDetail"),
      },
      {
        path: "/news",
        name: "news",
        meta: {
          titleKey: 'title.7',
        },
        component: () => import("@/views/home/news"),
      },
      {
        path: "/news/detail",
        name: "newsDetail",
        meta: {
          titleKey: 'title.8',
        },
        component: () => import("@/views/home/newsDetail"),
      },
      {
        path: "/cnc",
        name: "cncView",
        meta: {
          titleKey: 'title.9',
        },
        component: () => import("@/views/home/cncView"),
      },
      {
        path: "/assembly",
        name: "assembly",
        meta: {
          titleKey: 'title.10',
        },
        component: () => import("@/views/home/pcbAssembly"),
      },
      {
        path: "/product",
        name: "product",
        meta: {
          titleKey: 'title.11',
        },
        component: () => import("@/views/home/product"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        meta: {
          titleKey: 'title.12',
        },
        component: () => import("@/views/About/aboutUsView"),
      },
      {
        path: "/contactUs",
        name: "contactUs",
        meta: {
          titleKey: 'title.13',
        },
        component: () => import("@/views/About/contactView"),
      },
      {
        path: "/whyUs",
        name: "whyUs",
        meta: {
          titleKey: 'title.14',
        },
        component: () => import("@/views/About/whyView"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          titleKey: 'title.15',
        },
        component: () => import("@/views/order/orderView"),
      },
      {
        path: "/stencil",
        name: "stencil",
        meta: {
          titleKey: 'title.16',
        },
        component: () => import("@/views/order/stencilOrder"),
      },
      {
        // 预支付页面
        path: "/preview",
        name: "orderPreview",
        meta: {
          titleKey: 'title.17',
        },
        component: () => import("@/views/order/orderPreview"),
      },
      {
        path: "/pay",
        name: "pay",
        meta: {
          titleKey: 'title.18',
        },
        component: () => import("@/views/order/pay"),
      },
      {
        path: "/payment",
        name: "payment",
        meta: {
          titleKey: 'title.19',
        },
        component: () => import("@/views/order/payment"),
      },
      {
        path: "/commodityList",
        name: "commodityList",
        meta: {
          titleKey: 'title.20',
        },
        component: () => import("@/views/home/commodityList"),
      },
      {
        path: "/commodityDetail",
        name: "commodityDetail",
        meta: {
          titleKey: 'title.21',
        },
        component: () => import("@/views/home/commodityDetail"),
      },
      {
        path: "/productList",
        name: "productList",
        meta: {
          titleKey: 'title.22',
        },
        component: () => import("@/views/home/productList"),
      },
      {
        path: "/gerberviewer",
        name: "gerberviewer",
        meta: {
          titleKey: 'title.23',
        },
        component: () => import("@/views/order/canvas"),
      },
      {
        path: "/person",
        redirect: "/person/carts",
        component: personLayout,
        children: [
          {
            path: "carts",
            name: "carts",
            meta: {
              titleKey: 'title.24',
            },
            component: () => import("@/views/order/shopCart"),
          },
          {
            path: "orderList",
            name: "orderList",
            meta: {
              titleKey: 'title.25',
            },
            component: () => import("@/views/order/orderList"),
          },
          {
            path: "notice",
            name: "notice",
            meta: {
              titleKey: 'title.26',
            },
            component: () => import("@/views/order/orderNotice"),
          },
          {
            path: "address",
            name: "address",
            meta: {
              titleKey: 'title.27',
            },
            component: () => import("@/views/personal/address"),
          },
          {
            path: "profile",
            name: "profile",
            meta: {
              titleKey: 'title.28',
            },
            component: () => import("@/views/personal/profile"),
          },
          {
            path: "supplement",
            name: "supplement",
            meta: {
              titleKey: 'title.29',
            },
            component: () => import("@/views/order/supplementOrder"),
          },
          {
            path: "coupons",
            name: "coupons",
            meta: {
              titleKey: 'title.30',
            },
            component: () => import("@/views/personal/coupons"),
          },
          {
            path: "letter",
            name: "letter",
            meta: {
              titleKey: 'title.31',
            },
            component: () => import("@/views/personal/letter"),
          },
          {
            path: "notices",
            name: "notices",
            meta: {
              titleKey: 'title.32',
            },
            component: () => import("@/views/personal/notice"),
          },
          {
            path: "notices/detail",
            name: "noticeDetail",
            meta: {
              titleKey: 'title.33',
            },
            component: () => import("@/views/personal/noticeDetail"),
          },
          {
            path: "paymentRecord",
            name: "paymentRecord",
            meta: {
              titleKey: 'title.34',
            },
            component: () => import("@/views/order/paymentRecord"),
          },
        ],
      },
      {
        path: "/forums",
        name: "forums",
        meta: {
          titleKey: 'title.35',
        },
        component: () => import("@/views/forums/articleList"),
      },
      {
        path: "/forums/detail",
        name: "forumsDetail",
        meta: {
          titleKey: 'title.36',
        },
        component: () => import("@/views/forums/articleDetail"),
      },
      {
        path: "/forums/center",
        name: "forumsCenter",
        meta: {
          titleKey: 'title.37',
        },
        component: () => import("@/views/forums/forumCenter"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
//跳转后自动返回页面顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
  const titleKey = to.meta.titleKey;
  if (titleKey) {
    document.title = i18n.t(titleKey);
  }
  next();
});

export default router;
