/**
 * 英文语言包
 */
export default {
  1: "Cancel",
  2: "Search",
  3: "Reset",
  4: "Submit",
  5: "Success.",
  6: "Content...",
  //每个页面的标签title
  title: {
    1: "Terms & Conditions | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "PCB Prototype | PCBA Assembly | One-stop electronic product innovative agile manufacturing platform | Oceansmile | Lanyue Electronics",
    3: "Join | Sign In | Oceansmile | Lanyue Electronics",
    4: "Reset Password | Retrieve password | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "PCB Technical & Service Support Center| Electronics Design |Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Industry News & Learning Center | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Industry News & Learning Center | Oceansmile | Lanyue Electronics",
    9: "CNC Machining | Sheet Matal | 3D Printing | Injection Molding | Custom Parts | Lanyue Electronics",
    10: "SMT | PCB Assembly Service | PCB Online Rapid Quote | Oceansmile | Lanyue Electronics",
    11: "PCB Production Capability | Bring your ideas to life | Oceansmile | Lanyue Electronics",
    12: "About Us - Bring your ideas to life | PCB Prototype | Oceansmile | Lanyue Electronics",
    13: "Contact Us - Bring your ideas to life | PCB Prototype | Oceansmile | Lanyue Electronics",
    14: "Why Us - Bring your ideas to life | Custom PCB Prototype | Oceansmile | Lanyue Electronics",
    15: "PCB Online Rapid Quote | PCB Assembly Service | Oceansmile | Lanyue Electronics",
    16: "SMT Stencil | PCB Assembly Service | Oceansmile | Lanyue Electronics",
    17: "Billing details | Oceansmile | Lanyue Electronics",
    18: "Bank Information Details | Wire transfer | Oceansmile | Lanyue Electronics",
    19: "Congratulations! Successful payment | Oceansmile | Lanyue Electronics",
    20: "Product | Product Store",
    21: "Product detail | Product Store",
    22: "Components | IC Easy Store | Sourcing Parts",
    23: "Gerber Online Viewing | Oceansmile | Lanyue Electronics",
    24: "Shopping Cart | Oceansmile | Lanyue Electronics",
    25: "Order List | Oceansmile | Lanyue Electronics",
    26: "Order Notification List | Oceansmile | Lanyue Electronics",
    27: "Ship-to address management | Oceansmile | Lanyue Electronics",
    28: "Personal Information | Oceansmile | Lanyue Electronics",
    29: "Replenishment Order List",
    30: "Coupons",
    31: "Complaints & Feedback",
    32: "Announcement List | Oceansmile | Lanyue Electronics",
    33: " | Announcement Detail",
    34: "Payment records | Checking",
    35: "Industry News & Learning Center | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Industry News & Learning Center | Oceansmile | Lanyue Electronics",
    37: " | Forum personal center | Industry News & Learning Center | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Help Center",
    2: "Sending a message",
    3: "Whatsapp/Service hotlines",
    4: "My Cart",
    5: "Welcome to Oceansmile",
    6: "Sign in",
    7: "New Customer?",
    8: "Join Free",
    9: "Email",
    10: "Sign out",
    11: "Shopping Cart",
    12: "My Orders",
    13: "Order Progress Notice",
    14: "My Coupons",
    15: "Delivery Address",
    16: "My Profile",
    17: "Letter",
    18: "Notice",
    19: "Home",
    20: "PCB Assembly",
    21: "PCB/PCBA Instant Quote",
    22: "Stencil Instant Quote",
    23: "Product Shop",
    24: "News&Blog",
    25: "CNC 3D Printing",
    26: "Product&Capacity",
    27: "About us",
    28: "Contact us",
    29: "Why us",
    30: "PCB Instant Quote",
  },
  //脚部页面
  footer: {
    1: {
      1: "QUICK CONTENT",
      2: "Please leave your message below and one of our helpful staff will contact you within 48 hours.",
      3: "enter your email",
      4: "Subscribe",
    },
    2: {
      1: "Over 10 years of experience in quick PCB prototyping and production of small- medium PCB & PCBA. Gain customer satisfaction with superior quality and service...",
      2: "Address",
      3: "Songyuansha Community, Guanhu Street, Longhua District, Shenzhen, Guangdong, China",
      4: "Number Phone",
      5: "Address Email",
      6: "Verified by",
    },
    3: {
      1: "Capabilities",
      2: "Products/Services",
      3: "Customer Support",
      4: "About Us",
      5: "PCB Capabilities",
      6: "PCB Instant Quote",
      7: "Contact Us",
      8: "About Us",
      9: "PCB Assembly Capabilities",
      10: "PCB Assembly",
      11: "Help Center",
      12: "Why Buy From Us?",
      13: "SMD Stencils",
      14: "Our Equipment",
      15: "Customised project consultancy",
      16: "Shipping Method Guide",
      17: "Aluminum PCB Capabilities",
      18: "Industries Solution",
      19: "Feedback",
      20: "Privacy Policy & Agreement",
      21: "Flexible PCB Capabilities",
      22: "Refund Policy",
      23: "3D Printing/CNC",
      24: "Terms of Service",
    },
    4: "Copyright © 2024 OSpcb. All rights reserved. Lanyue Electronics(Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Member Center",
    2: "Order",
    3: "My Account",
    4: "System",
    5: "Shopping Cart",
    6: "My Orders",
    7: "Supplement",
    8: "Order Notice",
    9: "Payment Record",
    10: "Address",
    11: "My Coupons",
    12: "My Profile",
    13: "Letter",
    14: "Notice",
  },
  //aboutUs页面
  aboutUs: {
    1: " Oceansmile is an one-stop electronic product innovative agile manufacturing platform from Shenzhen. We have 15 years of PCB and PCBA production experience in China. We offers one-stop prototyping & agile manufacturing services. Help you to release your products time-to-market with comprehensive resources, competitive cost, reliable material, and excellent quality. Oceansmile have more than 200 employees in the factory, with a monthly production capacity of more than 3,000 samples / small batch shipments. Serving 150,000+ customers from more than 250 countries worldwide. No MOQ, provides refined one-to-one service, rich solutions for a variety of industrial application scenarios. As one of the most experienced manufacturers in China, we will be your best business partners as well as good friends in the every aspect of your project needs. Customer-oriented mindset and self-adaptable. We grow with you and adapt ourselves per your requirement. ",
    2: {
      1: "Agile",
      2: "Cost",
      3: "Quality Assurance",
      4: "Nothing impossible",
    },
    3: {
      1: "Ordering and manufacturing process online management",
      2: "Fast delivery",
      3: "Comprehensive supply chain capability",
      4: "Quick response",
    },
    4: {
      1: "Online quotation, transparent price",
      2: "No hidden charges and secondary charges",
      3: "Low cost for operating of small batch",
    },
    5: {
      1: "Strict production process",
      2: "Professional engineering team (5+ years experience)",
      3: "Equipment with excellent performance",
      4: "Robust AVL",
      5: "Abundant products experience",
    },
    6: {
      1: "Unlimited service",
      2: "Customer-oriented mindset and self-adaptable.",
    },
    7: {
      1: "We offers one-stop prototyping & agile manufacturing services. Not Only PCB/PCBA!",
      2: "Printed circuit board / PCB assembly / INDUSTRY-LEADING IOT SOLUTIONS / Testing and Custom Testing Programmes / Component sourcing / Mechanical customized (Metal, silicone, plastic, wood) / Injection Mold / PCB Design-Aid & Layout / Battery customization / Product design / OEM / ODM",
    },
    8: "Oceansmile has three large professional factories, which specialized in PCB prototype, PCB Mass Production, PCB Assembly and Production of Mechanical. The area is 15,000 square metres with 6 SMT lines. Focus on validation of customer-developed products(Prototype), to ensuring sufficient capacity for volume production.",
    9: "Trusted by the World’s Largest Brands",
    10: "Oceansmile committed to meet the needs of our customers (over 15K) from different industries and countrys in terms of quality, delivery, cost-effectiveness and any other requests. As one of the most experienced PCB/PCBA manufacturers in China, we have established cooperation with lots of well-known international companies.",
    11: {
      1: "Paid Customers",
      2: "Countries and regions",
      3: "Co-operating companies",
    },
    12: "Why Oceansmile?",
  },
  //联系我们页面
  contact: {
    1: "Contact Us",
    2: {
      1: "Sales and Product Inquiries",
      2: "Oceansmile is a one-stop electronic product innovative agile manufacturing online platform from Shenzhen. We offers quick-turn prototype PCB manufacturing and PCB assembly service. Our contacts team can answer the questions about PCB capabilities, PCB & PCBA pricing inquiries, Mechanical Silicon/Plastic…and every aspect of your project needs. Ordering on Oceansmile is easy because we offer  online instant quote for standard PCBs, Flex / Rigid-Flex PCBs, SMD Stencils, and PCB assembly with free Gerber file checks and provide on-time shipping with DHL, FedEx, UPS, and more.",
      3: "Please leave your message below and one of our helpful staff will contact you ",
      4: "within 24 hours.",
    },
    3: {
      1: "Name",
      2: "Email",
      3: "Phone Number",
      4: "Your phone number",
      5: "Company",
      6: "Your company name",
      7: "Captcha",
      8: "Your captcha",
      9: "Message",
      10: "Tell us what we can help you with your products, delivery, payment, and so on.",
      11: "Send Message",
    },
    4: "Customer-oriented mindset and self-adaptable!",
    5: "Help you to release your products time-to-market with comprehensive resources, competitive cost, reliable material, and excellent quality.",
    6: "All-weather Service",
    7: {
      1: "Tips",
      2: "1.To get the fastest reply, we highly recommend contacting our staff on the Account Page after",
      3: "Sign in/Register.",
      4: "2. Visit",
      5: "Oceansmile Help Center",
      6: "site to quickly find FAQs about ordering, shipping, payment, and more.",
    },
    message: {
      1: "Name is required.",
      2: "Email is required.",
      3: "Phone number is required.",
      4: "Company is required.",
      5: "Captcha is required.",
      6: "Message is required.",
    },
  },
  //why us页面
  why: {
    1: "Why Choose",
    2: "Oceansmile is a high-quality PCB/PCBA Manufacturer in Shenzhen China for 15 years. With professional PCB manufacturing capabilities, our PCB/SMT engineers with more than 10 years of experience will double-check your engineering files. Besides, Oceansmile is certified by IATF16949, ISO9001, ISO14001, UL, CQC, RoHS and REACH; More importantly, we handle the whole process including PCB/PCBA / Printed circuit board / PCB assembly / INDUSTRY-LEADING IOT SOLUTIONS / Testing and Custom Testing Programmes / Component sourcing / Mechanical customized (Metal, silicone, plastic, wood) / Injection Mold / PCB Design-Aid & Layout / Battery customization / Product design / OEM / ODM and final shipment. We are capable of assembling BGA, Micro-BGA, QFN, and other leadless package parts. We strictly protect the security of production documentation and intellectual property rights of your products. We offers one-stop prototyping & agile manufacturing services, welcome to inquire about any parts you need!",
    3: "One-stop multi-service R&D assistance platform (Not only PCB&PCBA)",
    4: "Oceansmile is a professional Global one-stop services manufacturer with 15 years of PCB and PCBA production experience in China. With professional PCB/PCBA manufacturing capabilities, we can support all advanced designs with demanding requirements, including FR4, HDI, laser drilled micro-vias, pads, microwaves and RF boards, up to 32 layers. Ospcb is a professional manufacturer with 15 years of PCB and PCBA production experience in China.",
    5: "We offers one-stop prototyping & agile manufacturing services. Not Only PCB/PCBA!",
    6: "Printed circuit board / PCB assembly / INDUSTRY-LEADING IOT SOLUTIONS / Testing and Custom Testing Programmes / Component sourcing / Mechanical customized (Metal, silicone, plastic, wood) / Injection Mold / PCB Design-Aid & Layout / Battery customization / Product design / OEM / ODM…",
    7: "Agile: Speed of PCB and Assembly manufacturing With our Data System and MES Production System, efficient collaboration",
    8: "Ordering and manufacturing process online management, Fast delivery with DHL, FedEx, UPS…",
    9: "Comprehensive supply chain capability, the rich international supply chain has a wide range of supply chain in China not only provides fast component search procurement capabilities, relying on strong resources can provide more costs with lower costs and better quality options. Experienced engineers provide 24 -hour fast feedback.",
    10: "Lower Cost: Reduce costs, make the product stronger competitiveness, and achieve a win-win situation",
    11: "We quote online, no transparent price. Experienced engineers help you analyze production(DFM), help you avoid the extra costs in the production process, save time. No hidden charges and secondary charges. We focus on prototypes and small and medium batch production. Unique, flexible production method. The equipment in the sample production system is to run independently, improve efficiency and achieve rapid line convert. Low cost for operating of small batch.",
    12: "Quality Assurance: Excellent resources and supplier control systems, quality control plans are constantly updated and upgraded",
    13: "Oceansmile's PCB materials are certified by IATF 16949, ISO9001, ISO14001, UL, RoHS and REACH. As for assembled PCBs, we offer a variety of meticulous verification methods, like Automated Optical Inspection (AOI), In-Circuit Testing (ICT), X-Ray Testing, Functional Testing, and multiple stages of Visual Inspection, which leads to a great PCB and PCB Assembly quality control.",
    14: "We are responsible for each product according to strict production processes. We have a professional engineering team (Everyone has 5+ years experience). Equipment with excellent performance, all equipment is high-precision and powerful top-level brands. We have Robust AVL, and the supplier management system will score according to the quality, lead time and cost of the supplier, and regularly eliminate the unqualified suppliers and the newly added supplier. Abundant products experience can greatly increase the success rate of product research and development, and it can provide existing solutions, products and market cases for customers for reference.",
    15: "Respect and protect intellectual property rights",
    16: "strictly protects customers' product intellectual property rights, relying on strict orders and production systems to ensure the safety of design documents, and each production equipment is monitored by the security system. We sign the NDA agreement with our customers to permanently protect the intellectual property rights of products. Trust Oceansmile, we give you the best service.",
    17: "Nothing impossible: we grow up with our customers and bring our ideas into reality",
    18: "We offers one-stop prototyping & agile manufacturing services. We have unlimited service options, relying on the best resources in Shenzhen, China. Customer-oriented mindset and self-adaptable. Experienced teams, vibrant thoughts, we will keep moving and never stop. Choose Oceansmile, the important step closer to success.",
  },
  //论坛详情页面
  articleDetail: {
    1: "News&Blog",
    2: "Comments",
    3: "Log in to post comments.",
    4: "Post Comment",
    5: "Reply",
    7: "Publish Article",
    8: "HOT TAG",
    9: "Categories",
    message: {
      1: "Forum category cannot be empty.",
      2: "Title cannot be empty.",
      3: "Title image cannot be empty.",
      4: "Content cannot be empty.",
      5: "Created successfully.",
      6: "Please log in to post an article.",
      7: "Select a maximum of 10 article tags!",
      8: "Please enter a comment!",
      9: "Comment submitted successfully.",
      10: "Are you sure you want to delete the comment?",
      11: "Tips",
      12: "Delete",
      13: "Deleted successfully!",
    },
  },
  //论坛页面
  article: {
    1: "Forum Center",
    2: "Publish Article",
    3: "Categories",
    4: "Select a Category",
    5: {
      1: "Category",
      2: "Select a Category",
      3: "Title",
      4: "Please enter the title of the article!",
      5: "Title Img",
      6: "Content Sketch",
      7: "Please enter a brief description of the article!",
      8: "Tags",
      9: "Search/Add",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Posts",
    2: "Collects",
    3: "Likes",
    4: "Publish Article",
    5: {
      1: "Create a new article",
      2: "Category",
      3: "Title",
      4: "Please enter the title of the article!",
      5: "Title Img",
      6: "Content Sketch",
      7: "Please enter a brief description of the article!",
      8: "Search/Add",
    },
  },
  //帮助中心列表
  help: {
    1: "How can we help you with?",
    2: "Enter related keywords for search",
  },
  //帮助中心详情
  helpDetail: {
    1: "Help Center",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "3D Printing",
      2: "SLA, MJF, SLM, FDM, SLS Technologies",
      3: "•Resin, Nylon, Metal and Plastic",
      4: "•Tolerance down to 0.2mm",
      5: "•Lead time from 2 business days",
    },
    2: {
      1: "CNC Machining",
      2: "Milling (3-, 4- & full 5-axis), turning and post- processing",
      3: "•Aluminum, Copper, Plastic",
      4: "•Tolerance down to 0.05mm",
      5: "•Lead time from 3 business days",
    },
    3: {
      1: "Injection Mold Fabrication",
      2: "Vacuum Casting",
      3: "•ABS, POM, Nylon, PC, PC/ABS, PVC, PE, PP, PS, TPU, Silicon, Custom Materials",
      4: "•Lifecycle>=50k/100k",
      5: "•Tolerances controlled with ISO 2768-1",
      6: "•Lead time 15-25 Business Days",
    },
    4: {
      1: "Countries Shipped",
      2: "Factory Area",
      3: "Material and Finishings",
      4: "Quote Request",
    },
    5: "Quote Now",
    6: "360°Closed-loop Service",
    7: "Professional engineer team and service team provide you with one-to-one 360° real-time online service, and regular production feedback reports.",
    8: "Standard Service Process",
    9: "Oceansmile has a powerful IT digital production management system to provide fast, comprehensive and high-quality product processing services.",
    10: {
      1: "Rapid Quote",
      2: "Quotation and one-to-one DFM service within 24 hours of receipt of documents by engineers.",
      3: "Solution confirmation",
      4: "Select material and finishing requirements, confirm production plan.",
      5: "Payment confirmation",
      6: "Check your order and pay. Add sales to get VIP services.",
      7: "Machining / 3D Printing",
      8: "Blanking - Programming - Processing - Post processing, Inspection - Delivery",
      9: "After sales service",
      10: "Check products with the drawing. We will react to quality problems as soon as possible.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Product Details",
    2: "Reviews",
    3: {
      1: "Ratings",
      2: "Review",
      3: "Images",
      4: "Click to upload",
      5: "Submit Review",
      6: "Enter your review...",
      7: "No comments for now",
    },
    4: "Includes battery",
    5: "working days",
    6: "in stock",
    7: "select",
    8: {
      1: "CN Warehouse",
    },
    9: "ADD TO CART",
    10: "Tags",
    11: "OUT OF STOCK",
    12: "Home",
    13: "Products Shop",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Please enter the content to be noted. This field is optional.",
      2: "Remarks",
      3: "None",
      4: "Added successfully.",
      5: "Review successful!",
      6: "There are already three images! Please delete one before uploading new images.",
    },
  },
  commodity: {
    1: {
      1: "keyword",
      4: "per page",
    },
    2: {
      1: "More...",
      2: "Add To Cart",
      3: "OUT OF STOCK",
      4: "No results found",
    },
    3: {
      1: "Remarks and quantity",
      2: "Quantity",
      3: "Descr",
      4: "Please enter the content to be noted. You can ignore it",
      5: "SKU",
      6: "No Data",
    },
    message: {
      1: "Successfully added.",
    },
  },
  //忘记密码
  forget: {
    1: "Forget Password",
    2: {
      1: "Eamil Address",
      2: "Eamil",
    },
    3: {
      1: "Auth Code",
      2: "Code",
      3: "Get",
    },
    4: {
      1: "New Password",
    },
    5: {
      1: "Confirm Password",
    },
    message: {
      1: "Please enter an email address.",
      2: "Please enter an email address.",
      3: "Please enter the authentication code.",
      4: "Please enter the password.",
      5: "Please confirm the password.",
      6: "Password does not match the confirmation password.",
      7: "Password updated successfully.",
    },
  },
  //主页
  home: {
    1: {
      1: "PCB Manufacturing Calculator",
      2: "Length \\ Width",
      3: "Length",
      4: "Width",
      5: "Quantity",
      6: "Please Enter",
      7: "Layers",
      8: "Thickness",
      9: "Newcomer? Unregistered?",
      10: "Get",
      11: "Coupons Here!",
      12: "Quote Now",
      13: "PCBA Assembly Calculator",
    },
    2: {
      1: "Fast Turnaround",
      2: "As fast as 24 hours",
      3: "One-on-One Assistance",
      4: "Smooth shopping experience",
      5: "Best value",
      6: "Manufacturer Direct Pricing",
      7: "News",
      8: "PCB Prototype The Easy Way",
      9: "With more than a decade in the field of PCB ...",
      10: "Learn more",
    },
    3: {
      1: "PCB Instant Quote",
      2: "1~6 layers Polyimide flexible",
      3: "Order form 1 piece",
      4: "4~5 days lead time",
      5: "PCB INSTANT QUOTE",
      6: "Upload PCB File",
      7: "Order Review",
      8: "Payment",
      9: "Real-time Tracking",
      10: "Delivery",
      11: "Confirm Received",
      12: "Click “Instant Quote” on the menu bar, select “PCB/PCBA Instant Quote”. Then you enter the quote page and upload the PCB File.",
      13: "Upload Gerber files (zip, rar), other formats may cause errors due to version compatibility issues. 30s system to complete the automatic review. Failed to upload? You can skip and email your PCB file to",
      14: "Oceansmile’s experienced engineers will perform DFM on product design and provide optimization suggestions for the product.",
      15: "Online payment, safe and traceable. We support multiple payment methods, Paypal, credit card payment, offline bank wire transfer, etc. If you have any questions about payment, please contact our customer service.",
      16: "Visualise the order progress stage and check the product production status at any time. Arranging specialised personnel to follow up on orders to ensure that products are completed on time and with good quality.",
      17: "A wide range of courier options and globally recognised courier companies guarantee fast and safe delivery of products to you. DHL, FedEx, UPS, EUPost",
      18: "You will receive your product. We maintain win-win customer relationships and we will continue to provide you with high-quality after-sales service. If you have any product problems, we will use our resources to assistant you.",
    },
    4: {
      1: "Bestseller Products",
      2: "PCB Prototype",
      3: "From",
      4: "Build Time",
      5: "PCB Assembly",
      6: "component vendors",
      7: "Flex,Rigid-Flex PCB",
      8: "HDI PCB",
      9: "3D Printing",
      10: "days",
      11: "hours",
      12: "CNC machining",
      13: "pcs",
    },
    5: {
      1: "Assembly Service Promotion",
      2: "Only",
      3: "IN TOTAL",
      4: "for 1-20pcs assembly",
      5: "QUOTE NOW",
      6: "One-Stop Solution for PCB & Assembly",
      7: "Made Easy,Quantity,One Time",
      8: "SMT & Through-Hole Assembly",
      9: "Wave Solder for PCBA",
      10: "PCBA Online Quote",
      11: "One-stop BOM Purchase Solution",
      12: "Over 99.6% Satisfied rate",
      13: "Box Build,Cable Wire Harness",
      14: "Functional Testing",
      15: "and Other Testing",
      16: "IC Programming",
      17: "BGA",
      18: "QFN",
      19: "Last 30 days",
      20: "Customers",
      21: "Paying",
      22: "SMT",
    },
    6: {
      1: "Affordable Prototype PCB Manufacturer in China",
      2: "Date",
      3: "Buyer Name",
      4: "Last Time",
      5: "Progress",
    },
    7: {
      1: "LAYER",
      2: "4-Layer PCB with 2 Routing Layers, a Ground Plane and a Power Plane",
      3: "Multiple Layer PCB Stack-up",
      4: "6 layer PCB has GTL, GBL, and 4 inner layers. Every signal that travels from 1 to 6 layers",
      5: "8 layer PCB has GTL, GBL, and 6 inner layers. Every signal that travels from 1 to 8 layers",
    },
    8: "From Our Blog",
    9: "What Client Say",
    10: "mm",
  },
  //登录页面
  login: {
    1: "Join / Sign in",
    2: "Create Account",
    3: {
      1: "Email Address",
      2: "Code",
      3: "Get",
      4: "Password",
      5: "Confirm Password",
      6: "Sign Up",
      7: "By creating this account, you agree with our",
      8: "Terms & Conditions",
    },
    4: {
      1: "SIGN IN",
      2: "Welcome back!",
      3: "Forget Password?",
      4: "Sign In",
    },
    message: {
      1: "Please enter an email address.",
      2: "Please accept the terms of use to continue.",
      3: "Please enter the authentication code.",
      4: "Please enter the password.",
      5: "Please confirm the password.",
      6: "Password does not match the confirmation password.",
      7: "Registration successful! Please log in on the right side of the page.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Reasonable Price Guaranteed",
      2: "$3.78 for 5 pcs 10*10cm PCB prototype, $25 for 10pcs PCB assembly.",
      3: "PCB Material Guaranteed",
      4: "Oceansmile only uses high-quality raw materials with ROHS compliant for production.",
      5: "3.Quality Guaranteed",
      6: "Oceansmile is fully compliant with the ISO 9001:2015 quality management system.",
      7: "4.On-time Shipping Guaranteed",
      8: "Expedited PCB prototype, one-to-one service online, 99% on-time shipping by DHL.",
    },
    2: {
      1: "Product Display",
      2: "With over 10 years of experience in quick PCB prototyping and small-medium volume PCB production.",
    },
    3: {
      1: "PCB Assembly Capabilities",
      2: "We are proud of our reputation as a leading specialist in surface-mount, thru-hole and mixed technology PCB assembly and electronic manufacturing services as well as turn-key electronic PCB assembly.",
      3: "PROTOTYPE PCB ASSEMBLY",
      4: "Quote Now",
      5: "PCB Types for Assembly",
      6: "FR-4 Board",
      7: "Metal Core Board",
      8: "Flexible Board",
      9: "Rigid -flex board",
      10: "Etc.",
      11: "Solder Types",
      12: "Lead-Free Soldering",
      13: "SMD and TH components assembly",
      14: "Single & Double side assembly",
      16: "Component Suppliers",
      17: "Digi-key, Mouser, Arrow, Element 14 and",
      18: "more…",
      19: "Chinese market for universal",
      20: "Component after your approval.",
      21: "Test Type",
      22: "Visual Inspection",
      23: "AOI Inspection",
      24: "X-Ray inspection",
      25: "In-Circuit test",
      26: "Functional test",
    },
    4: {
      1: "Factory Show",
      2: "With over 10 years of experience in quick PCB prototyping and small-medium volume PCB production.",
    },
    5: {
      1: "How to Order PCB Assembly Service in OceanSmile?",
      2: "Click Here to get details of how to order PCBA, or contact",
      3: "if you have any questions about our Assembly services.",
    },
    6: {
      1: "First step",
      2: "Upload your PCB Gerber files and fill in the form",
      3: "Second step",
      4: "Upload a formatted BOM file",
      5: "Third step",
      6: "Get an Instant Online Quote",
    },
    7: "Loading...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "PCB Manufacturing & Assembly Capabilities",
      3: "Learn more Oceansmile's Capabilities & Bringing your ideas into reality fast.",
      4: "Green",
      5: "Purple",
      6: "Red",
      7: "Yellow",
      8: "Blue",
      9: "White",
      10: "Black",
    },
    2: {
      1: "Rigid PCB",
      2: "Flex PCB",
      3: "PCB Assembly",
    },
    3: {
      1: "PCB Specifications",
      2: "Drill/Hole Size",
      3: "Minimum Annular Ring",
      4: "Minimum clearance",
      5: "Minimum trace width and spacing",
      6: "BGA",
      7: "Solder Mask",
    },
    4: {
      1: "Features",
      2: "Capability",
      3: "Notes",
      4: "Patterns",
      5: "Layer count",
      6: "Layers",
      7: "The number of the copper layer of the boards.",
      8: "Material",
      9: "FR-4",
      10: "Aluminum",
      11: "Copper core",
      12: "Rogers",
      13: "Ceramic",
      14: "FR-4 PCB",
      15: "Aluminum PCB",
      16: "Board Dimension",
      17: "Min",
      18: "Max",
      19: "The size of the PCB board",
      20: "Dimension Tolerance",
      21: "(Regular) for CNC routing, and ±0.4mm for V-scoring",
      22: "Board Thickness",
      23: "The finished thickness of the board",
      24: "Thickness Tolerance",
      25: "± 0.1mm for board thickness ≤1.6mm, ± 10% for board thickness ≥1.6mm",
      26: "Finished Copper",
      27: "Outer Layer",
      28: "Inner Layer",
      29: "Surface Finish",
      30: "HASL (with lead/ lead-free), ENIG, OSP, ENEPIG",
      31: "The extra process to exposed copper to help solderability.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Features",
      2: "Capability",
      3: "Notes",
      5: "Drill Hole Size (Rounded Hole)",
      6: "Mechanical drill",
      7: "Laser drill",
      8: "Drill Hole Size (Slot Hole)",
      9: "Drill Hole Size (Half Hole)",
      10: "The diameter of the half holes should more than 0.5mm and the pitch between the half holes should more then 0.9mm",
      11: "Blind/Buried Vias",
      12: "Oceansmile can support both blind vias and buried vias.",
      13: "Drill Hole Size Tolerance",
      14: "PTH",
      15: "NPTH",
      16: "Slot Hole",
    },
    6: {
      1: "Copper",
      2: "VIA",
    },
    7: {
      1: "Hole to hole clearance(Different nets)",
      2: "The min clearance between plated holes we can accpet.",
      3: "Via to Via clearance(Same nets)",
      4: "The min clearance between vias we can accpet.",
      5: "Pad to Pad clearance(SMD Pads, Different nets)",
      6: "The min clearance between SMD pads we can accpet.",
      7: "Pad to Pad clearance(TH pads, Different nets)",
      8: "The min clearance between TH pads we can accpet.",
      9: "Via to Track",
      10: "The min clearance between via and track we can accpet.",
      11: "PTH to Track",
      12: "The min clearance between PTH and track we can accpet.",
      13: "NPTH to Track",
      14: "The min clearance between NPTH and track we can accpet.",
      15: "Pad to Track",
      16: "The min clearance between Pad and track we can accpet.",
    },
    8: {
      1: "Min. Trace width",
      2: "Min. Spacing",
      3: "recomend track width/spacing for batch production",
    },
    9: {
      1: "Specification",
      2: "Min. BGA Pad Dimensions",
      3: "Min. Distance Between BGA",
      4: "BGA diameter",
      5: "Min for HASL with lead",
      6: "Min for HASL lead free",
      7: "Min for other surface finished",
      8: "The min diameter of the BGA pads we can accpet.",
      9: "BGA pads to BGA pads clearance",
      10: "The min clearance between the BGA pads we can accpet.",
    },
    10: {
      1: "Soldermask Color",
      2: "The color of the PCB board",
    },
    11: "mm",
  },
  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Product information",
      2: "Description",
      3: "RoHs",
      4: "Avalabiliy",
      5: "In Stock",
      6: "Pricing",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Last Updated: May 16th, 2024.",
    2: "Oceansmile Website Operation Statement",
    3: 'his website is operated by Oceansmile, which includes cnospcb.com and Lanyue Electronics(Shenzhen) Co., Ltd. On this website, the terms "Oceansmile", “OSPCB”, “Lanyue” "we" and "our" refer to the above brands and subsidiaries. The terms and conditions described in this document represent these entities and suit all provided services by the above brands.',
    4: "Privacy Policy",
    5: "This is the Privacy Policy published by Lanyue/Oceansmile/Lanyue Electronics(Shenzhen) Co., Ltd. Which includes the processing of personal data and how to exercise your rights, etc. The privacy policy will apply to this site with regards to any related products and/or services provided by Lanyue(Oceansmile). As the data controller of your personal data, Lanyue agrees that the protection of your personal data is important for us and Lanyue will take any reasonable measures to collect and use your personal data in accordance with any applicable laws and regulations. Please read this privacy policy carefully when you are the users of Oceansmile’s products and/or services. If you have any questions concerning bout your personal information, please contact us with the designated email: service@cnospcb.com. Service Overview. The service websites provided by Oceansmile contain all information, tools, and services, that aim to support users, on the condition that you fully accept and comply with all terms, conditions, policies, and notices stated on this website.",
    6: "Personal data",
    7: "Subject to the specific definition as stated in the Regulation No. 2016/679 on the protection of natural persons with regard to the processing of Personal Data(the “GDPR”), the personal data may be processed by Lanyue mainly falls into the following categories",
    8: "1. Some personal data is provided by you voluntarily.",
    9: "2. Other personal data is processed automatically when you visit our sites and/or use Lanyue's products and/or services.",
    10: "3. Another personal data is collected through any third parties' sites and services.",
    11: "Compliance with Export Control",
    12: "The processing of Personal Data",
    13: "You will provide your personal data voluntarily to Lanyue in the purchase process or other processes with your prior consent, including but not limited to your personal name and its contact information, account data, payment information, comment data and other data you provide to us. Lanyue will use your provided personal data for the purpose of the performance of your orders, providing certain services for you, registering an account and any communications between you and Lanyue, etc.",
    14: "Lanyue will use your personal data for the specified purpose as listed above or any other legally requirements. You have the right to requesting to withdraw or delete your provided personal data at any time if you think your right are not protected.",
    15: "Lanyue may automatically collect your personal data when you use Lanyue’s products and/or services, including but not limited to",
    16: "Device information, such as after you purchase Lanyue’s products and deploy these products to your address for monitoring, Lanyue's web servers may automatically log your IP address or any related information from your device.",
    17: "Data that include the products which you use and how you use them, and your using habit when you search our websites etc. The purpose to automatically collect your personal data is to evaluate and enhance your experience about your usage of Lanyue’s products and/or services. If you don’t desire to disclose your usage habit or other personal data, please contact our designated contact email as listed in this Privacy Policy.",
    18: "In addition, Lanyue’s site use any third parties’ service, which may includes that any payment service provider, shipping providers, and advertisers, etc. Some providers may collect your personal data for the purpose as stated above, and then they also have their own privacy policy for protecting the processing of your personal data.",
    19: "PRICE AND PAYMENT",
    20: "Lanyue makes every effort to provide current and accurate information relating to the products and prices, but does not guarantee the currency or accuracy of any such information.",
    21: "Online price quotation does not guarantee total order quantity and turntimes. All order quantities and turntimes are subject to production capacity review. The price shown in the web-form on entering the order will be binding only upon confirmation and after the submitted documentation files have been checked. Lanyue reserves the right to change the price or cancel the order if the documentation submitted does not comply with the data entered in the order input screen. In these cases, we will notify you the changes. If your order has been cancelled, and you have already paid for the order, Lanyue will issue a refund to the original payment method.",
    22: "Lanyue is not responsible for pricing, typographical, or other errors in any offer by Lanyue and Lanyue reserves the right to cancel any orders arising from such errors.",
    23: "SHIPPING & DELIVERY",
    24: "The customer is responsible for ensuring that the delivery address he has given on the web-form is both correct and complete. Any additional transport costs resulting from incorrect address details will be charged to the customer. International customers are responsible for their own custom charges and import fees on all orders.",
    25: "Lanyue will ship your goods within a commercially reasonable period to the address specified in your order. We work very hard to make sure all your orders get shipped on time. There are occasions, however, when the freight carriers have delays and/or make shipment errors. We regret when this happens but we cannot be responsible for delays by these carriers.",
    26: "DISCLAIMER OF INTELLECTUAL PROPERTY RIGHTS",
    27: "The PCB/product design documents used for manufacturing were provided by customers and the responsibility of Lanyue was to manufacture the PCB board/product according to the design of customers. Customers shall guarantee its ownership and the related intellectual property rights of the design document provided, which should not infringe anyone else's intellectual property rights and any other legal right, such as patent right, copyright, trademark right, and so on.",
    28: "Any infringement or illegal problem arises by the documents provided by customers, which causes influence or loss to us, customers will bear all related responsibilities, Lanyue will keep the rights to claim compensation to customers.",
    29: "Contact Us",
    30: "If you have any questions or comments about these Terms, please contact us at",
  },
  //看图页面
  canvas: {
    1: {
      1: "Open All",
      2: "Close All",
    },
    2: {
      1: "Size",
      2: "Min Trace Width",
      3: "Trace Min Spacing",
      4: "Quantity Of Drilling Hole",
      5: "Min Hole Size",
      6: "Max Hole Size",
      7: "ENIG Area",
      8: "Test Point",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA&Stencil",
    2: {
      1: "ALL",
      2: "Cancelled",
      3: "Pending Payment",
      4: "Confirmation by Oceansmile",
      5: "Paid",
      6: "Processing",
      7: "Packed",
      8: "Shipped",
      9: "Please Enter Order No",
    },
    3: {
      1: "Order | Product information",
      2: "ALL Buy",
      3: "Material",
      4: "Quantity",
      5: "Price",
      6: "Product Descr",
      7: "File Name",
      8: "File Descr",
    },
    4: {
      1: "Phone",
      2: "Company",
      3: "Tax no",
      4: "Weight",
      5: "Delivery",
      6: "Ship to",
    },
    5: {
      1: "Build Time",
      2: "Payment Time",
      3: "unpaid",
      4: "Tips",
      5: "The build time is calculated from the time after payment is mad",
      6: "Create Time",
    },
    6: {
      1: "Payment Method",
      2: "Product Price",
      3: "Commissions",
      4: "Express Fee",
      5: "Other Discount",
      6: "Total",
    },
    7: "Order Descr",
    8: "View Detail",
    9: "Supply Mode",
    10: "Framework",
    11: {
      1: "Buy Now",
      2: "Invoice",
    },
    12: "Products",
    13: {
      1: "Unit.Price",
      2: "Ext.Price",
      3: "User Descr",
    },
    14: {
      0: "Detail",
      1: "Gerber File",
      2: "Gerber Descr",
      3: "Product Descr",
      4: "Board Type",
      5: "Material",
      6: "Length",
      7: "Width",
      8: "Layers",
      9: "Thickness",
      10: "Finished Copper",
      11: "Min Hole Size",
      12: "Solder Mask",
      13: "Silkscreen",
      14: "Build Time",
      15: "Half Hole",
      16: "Order Status",
      17: "Product Weight",
      18: "Different Design in Panel",
      19: "FR4-TG",
      20: "Quantity",
      21: "Surface Finish",
      22: "Minimum Solder Mask Dam",
      23: "Min Trace Width/Spacing",
      24: "Impedance Control",
      25: "Charge Deetails",
      26: "engineering fee",
      27: "panel",
      28: "Castellated Holes",
      29: "board",
      30: "test",
      31: "copper",
      32: "surface finish",
      33: "Via process",
      34: "Blind via",
      35: "Via in pad",
      36: "V-cut fee",
      37: "Color fee",
      38: "large size",
      39: "Impedance Control",
      40: "Discount",
      41: "Total",
      42: "PCBA Cost",
      43: "Components Cost",
      44: "Assembly Cost",
      45: "Service Cost",
      46: "Bom file",
      47: "Bom Descr",
      48: "Components Details",
      49: "Product confirm",
      50: "Designator",
      51: "MPN",
      52: "Total QTY",
      53: "Waste material",
      54: "Unit price",
      55: "Description",
      56: "Versions",
      57: "Framework",
      58: "Size",
      59: "Stencil Side",
      60: "Charge Details",
      61: "SMD-Stencil",
    },
    15: {
      1: "Supplement Order",
      2: "Make Up For Order No",
      3: "Order No",
      4: "Price Difference",
      5: "Desrc",
      6: "Pay",
    },
    16: "PCB",
    17: "PCBA",
    18: "Stencil",
    message: {
      1: "Deleted successfully.",
      2: "Do you want to pay for the supplementary payment order together?",
      3: "Tips",
      4: "Please select an order.",
      5: "Copied successfully.",
    },
  },
  //预览价格
  preview: {
    1: "Back",
    2: "Shopping Address",
    3: "Edit",
    4: "Delete",
    5: "ADD NEW ADDRESS",
    6: {
      1: "Whether it is the default address",
      2: "We may confirm some problems in your orders or files with you via email, please make sure the email is valid!",
    },
    7: "Shopping Method",
    8: "Not Applicable",
    9: "Coupons",
    10: "None",
    11: "Payment Method",
    12: "Credit Card",
    13: "Manual Bank Transfer(TT)",
    14: {
      1: "SUMMARY",
      2: "Price Of Difference",
      3: "Commission",
      4: "Product Weight(KG)",
      5: "Subtotal",
      6: "Shipping Cost",
      7: "Original Price",
      8: "Discount Amount",
      9: "Payable Amount",
      10: "Coupon Discount",
      11: "OceanSmile Discount",
      12: "Buy Now",
    },
    message: {
      1: "Are you sure you want to delete the address?",
      2: "Tips",
      3: "Deleted successfully.",
      4: "First name cannot be empty.",
      5: "Last name cannot be empty.",
      6: "Company cannot be empty.",
      7: "Street cannot be empty.",
      8: "Country cannot be empty.",
      9: "Province cannot be empty.",
      10: "City cannot be empty.",
      11: "Postcode cannot be empty.",
      12: "Phone cannot be empty.",
      13: "Email cannot be empty.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Online Quote",
      2: "Upload Gerber File",
      3: "Order Review",
      4: "Payment",
      5: "Fabrication",
      6: "Shipment",
      7: "Confirm and Review",
    },
    2: "PCB Specification Selection",
    3: {
      1: "Add Gerber Files",
      2: "Upload Successful",
      3: "Gerber Online Viewer",
      4: "The PCB file has been uploaded successfully, but the parameters cannot be parsed.",
      5: "Only ZIP or RAR files accepted. Maximum size 50MB.",
      6: "If the uploaded zip file is not parsed successfully or no image is displayed, don't worry, our engineers will review it manually and get back to you email later.",
      7: "For special requirements not available on this page, or you cannot find what you are looking for, please send your requirements and Gerber files to us",
      8: "and we will come back to you within 24 hours.",
    },
    4: {
      1: "Remark For Gerber",
      2: "Single Pieces",
      3: "Panel By Customer",
      4: "Panel By OSpcb",
      5: "Edge Rails",
      6: "No",
      7: "Panel Size",
      8: "Panel Quantity",
      9: "Other",
      10: "Remark For PCB",
    },
    5: {
      1: "PCB Assembly",
      2: "Single Quantity",
      3: "Stencil",
      4: "Parts Supply Mode",
      5: "Turnkey",
      6: "Consignment",
      7: "Combo",
      8: "Remark For PCBA",
      9: "Remark For BOM",
      10: "Add BOM File",
      11: "Download BOM Template",
      12: "Please upload an accurate BOM file, follows this template so that we can read it correctly.",
      13: "MPN can choose from Digikey, Mouser, Arrow, Element or any other platform, our system will automatically recognize",
      14: "For any special PCB assembly requests, please write to",
      15: "not writes them in the BOM file directly.",
      16: "We only recognize commas to distinguish the location.",
      17: "The designator number should be ≤ Qty.",
      18: "Please don’t fill Digikey or Mouser Part Number in MPN(Manufacturer Part Number) line.",
      19: "Please don't include useless components in your BOM file.",
    },
    6: {
      1: "Please select country",
      2: "PCB Specification",
      3: "Quantity",
      4: "Build Time",
      5: "PCB Cost",
      6: "Engineering Fee",
      7: "Board",
      8: "PCBA Cost",
      9: "Assembly Cost",
      10: "Components Cost",
      11: "Sub-total",
      12: "Add to Cart",
      13: "working days",
    },
    str: {
      "Normal FR-4 Board": "Normal FR-4 Board",
      "Flexible Boards": "Flexible Boards",
      "Board Type": "Board Type",
      "Single Quantity": "Single Quantity",
      "FR4-TG": "FR4-TG",
      "Single Size": "Single Size",
      "Different Design in Panel": "Different Design in Panel",
      "Min Trace Width/Spacing": "Min Trace Width/Spacing",
      "Min Hole Size": "Min Hole Size",
      "Minimum Solder Mask Dam": "Minimum Solder Mask Dam",
      "Half Hole": "Half Hole",
      "Surface Finish": "Surface Finish",
      "Finished Copper": "Finished Copper",
      "Impedance Control": "Impedance Control",
      "Panel Quantity": "Panel Quantity",
      "Edge Qty": "Edge Qty",
      "Solder Mask": "Solder Mask",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 Layer",
      "2 Layer": "2 Layer",
      "4 Layer": "4 Layer",
      "6 Layer": "6 Layer",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0.2mm",
      "0_25mm": "0.25mm",
      "0_3mm": "0.3mm",
      "0_4mm": "0.4mm",
      "HASL with lead": "HASL with lead",
      "HASL lead free": "HASL lead free",
      "Immersion gold": "Immersion gold",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1.0w/(m⋅K)",
      "2_0w/(m⋅K)": "2.0w/(m⋅K)",
      "0_08": "0.08",
      "0_13": "0.13",
      "0_22": "0.22",
      "0_6": "0.6",
      "0_8": "0.8",
      "1_0": "1.0",
      "1_2": "1.2",
      "1_6": "1.6",
      "2_0": "2.0",
      "0_6mm": "0.6mm",
      "0_8mm": "0.8mm",
      "1_0mm": "1.0mm",
      "1_2mm": "1.2mm",
      "1_6mm": "1.6mm",
      "2_0mm": "2.0mm",
      "0_10": "0.10",
      "0_12": "0.12",
      "0_15": "0.15",
      "0_18": "0.18",
      "0_10mm": "0.10mm",
      "0_12mm": "0.12mm",
      "0_15mm": "0.15mm",
      "0_18mm": "0.18mm",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0.5oz",
      mm: "mm",
      OSP: "OSP",
      None: "None",
      White: "White",
      Black: "Black",
      Green: "Green",
      Red: "Red",
      Yellow: "Yellow",
      Blue: "Blue",
      Silkscreen: "Silkscreen",
      Aluminum: "Aluminum",
      Layers: "Layers",
      Material: "Material",
      Thickness: "Thickness",
      TCE: "TCE",
      No: "No",
      Yes: "Yes",
      "Single Pieces": "Single Pieces",
      "Panel By Customer": "Panel By Customer",
      "Panel By OSpcb": "Panel By OSpcb",
      true: "Yes",
      false: "No",
      Turnkey: "Turnkey",
      Consignment: "Consignment",
      Combo: "Combo",
      Unit: "Unit",
      Total: "Total",
      Framework: "Framework",
      "Stencil Side": "Stencil Side",
      "Existing fiducials": "Existing fiducials",
      Top: "Top",
      Bottom: "Bottom",
      "Top+Bottom(On Single Stencil)": "Top+Bottom(On Single Stencil)",
      "Top & Bottom(On Separate Stencil)": "Top & Bottom(On Separate Stencil)",
      "SIZE (mm)": "SIZE (mm)",
      Quantity: "Quantity",
      none: "None",
      "half lasered": "Half Lasered",
      "lasered through": "Lasered Through",
      undefined: "",
    },
    tips: {
      Material:
        "FR-4 PCB is the most commonly used material.Aluminum PCB has higher physical hardness and better thermal dissipation performance.Flexible PCB is much thinner than other materials and can be bent to some extent.",
      "FR4-TG":
        "High-temperature resistance performance of FR-4 PCB. The higher TG value, the better the temperature resistance.",
      Layers:
        "The quantity of the circuit copper layer in your file, the default single panel only one side solder mask.",
      Thickness: "The thickness of the finished board you need.",
      "Solder Mask":
        "The color of the solder mask applied onto the base material",
      Silkscreen: "The color of the silkscreen texts.",
      "Single Size": "The length and width of your board.",
      "Single Quantity": "The quantity of the boards you need.",
      "Panel Quantity": "The quantity of the boards you need.",
      "Board Type":
        "The full boards are by the only single board or are arrayed with the same and different PCBs.",
      "Different Design in Panel":
        "How many different designs in your file separated by v-cuts, stamp holes or milling slots.",
      "Min Trace Width/Spacing":
        "The minimum track width and the minimum clearance between traces in your file.",
      "Min Hole Size": "The minimum drill hole size in your file.",
      "Minimum Solder Mask Dam":
        "The minimum solder mask dam between copper pads.",
      "Half Hole":
        "PTH holes or vias that are cutted through to create a partial or half hole to form an opening into the side of the hole barrel. Generally they are used for mounting a PCB to another one..",
      "Surface Finish":
        "HASL (Hot Air Solder Level) processing applies a thin layer of solder on the pads.ENIG (Electroless Nickel/Immersion Gold) has better planarity and shelf life than HASL. It is also lead-free. Hard gold is useful for gold fingers or other hard wear areas. By selecting this option, the entire board will be plated in Hard Gold.",
      "Finished Copper":
        "The thickness of the copper on the outer layer (top and bottom).",
      TCE: "Thermal coefficient of expansion, a material property that is indicative of the extent to which a material expands upon heating.",
      Framework:
        "Ensure the framework is properly aligned and securely positioned.",
      "Stencil Side":
        "Check the stencil side for correct orientation before use.",
      "Existing fiducials":
        "Verify the positioning of existing fiducials for alignment accuracy.",
    },
    7: {
      1: "SMD-Stencil",
      2: "Valid area",
      3: "Stencil Cost",
      4: "Stencil type",
      5: "Size",
      6: "Side",
      7: "Weight",
      8: "Charge Details",
      9: "SMD-Stencil",
    },
    message: {
      1: "If the data exceeds the system limit, please send the design file directly to the specified email address.",
      2: "We will provide manual feedback and a quote within 24 hours.",
      3: "Please fill in the board width.",
      4: "Please fill in the board length.",
      5: "Please fill in the board quantity.",
      6: "Please upload the Gerber file.",
      7: "Please upload the BOM file.",
    },
  },
  //人工转账页面
  pay: {
    1: "Your payment request has been accepted.",
    2: "Please make the transfer via Bank account. The payment will be finished after the transfer completed. Payment Total",
    3: "Support Business account & Personal account Transfer",
    4: "1. If your payment account is business bank account, please pay to this account",
    5: "2. If your payment account is personal bank account, please pay to this account",
    6: "Beneficiary Bank",
    7: "Beneficiary Bank Address",
    8: "SWIFT Code",
    9: "Beneficiary’s Full Name",
    10: "Beneficiary’s Account Number",
    11: "Beneficiary’s Address And Telephone Number",
    12: "Intermediary Bank",
    13: "SWIFT Code(Intermediary)",
    14: "Intermediary Bank Address",
    15: {
      1: "Tips for remitting",
      2: "1. Please send the Ospcb order number and the copy of the bank remittance slip to service@cnospcb.com after remitting. so that we can start production in time.",
      3: "2. Please ensure to enter the correct bank account information when remitting.",
      4: "3. Transfer the full amount in one payment. Do not divide the payment into more than one transfer.",
      5: "4. Keep your bank remittance slip for future reference.",
      6: "Tips for financial security",
      7: "1. Our staff will only use the email address with the domain name cnospcb.com to contact you, please don't trust the email address with any other domain name.",
      8: "2. If there have any change in the bank account information, we will announce it on the website in advance, please don't trust and transfer the money to any bank account other than the bank account shown on this page.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Tips: There may be a delay in the payment. If it hasn't arrived for a long time, please contact customer service",
    2: "Automatic redirection in",
    3: "seconds",
    4: "right now",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Order No",
      2: "Receive Payment",
      3: "Payment Type",
    },
    2: {
      1: "Order No",
      2: "Payment Money",
      3: "Currencies",
      4: "Payment Type",
      5: "Payment Id",
      6: "Payment Email",
      7: "Payment Time",
      8: "Payment Received",
      9: "EvidenceOf Payment",
      10: "Action",
    },
    3: {
      1: "Download PDF",
      2: "Waiting to upload",
      3: "Upload",
    },
    message: {
      1: "Transfer voucher uploaded successfully. Please wait patiently for staff review!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA&Stencil",
      2: "ALL",
      3: "PCB",
      4: "PCBA",
      5: "Stencil",
      6: "Please Enter file name",
    },
    2: {
      1: "Products",
      2: "ALL Buy",
      3: "Material",
      4: "Quantity",
      5: "Price",
      6: "Product Status",
      7: "Product Descr",
      8: "File Name",
      9: "File Descr",
      10: "Create Time",
      11: "Total",
    },
    3: "Build Time",
    4: "View Detail",
    5: "Buy Now",
    6: "Create Time",
    7: "Total",
    8: "Pending confirmation",
    9: "Supply Mode",
    10: {
      1: "keyword",
      2: "Product Name",
      3: "Includes battery",
      4: "Total Weight",
      5: "Unit.Price",
      6: "Ext.Price",
      7: "Status",
      8: "Descr",
      9: "Buy",
      10: "Delete",
      11: "Action",
    },
    statusMap: {
      0: "Remove",
      1: "Waiting for quotation",
      2: "Pending confirmation by Customer",
      3: "Purchasable",
      4: "Submitted to order",
      undefined: "",
    },
    message: {
      1: "Please select the item you want to pay for!",
      2: "Are you sure you want to delete this product?",
      3: "Tips",
      4: "Delete",
      5: "Deleted successfully.",
      6: "Do you want to pay together with the replenishment order?",
      7: "Component changes confirmed.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Supplement Order No",
      2: "Product Number",
      3: "Status",
      4: "Payment Type",
      5: "Product Type",
      6: "ALL Pay",
    },
    2: {
      1: "Order No",
      2: "Product Type",
      3: "Product Number",
      4: "Amount",
      5: "Payment Type",
      6: "Commission",
      7: "Total",
      8: "Create Time",
      9: "Make Up For Status",
      10: "Action",
    },
    3: {
      1: "View",
      2: "Pay",
      3: "Invoice",
    },
    4: {
      1: "Detail",
      2: "Make Up For Order No",
      3: "Make Up For Status",
      4: "Order No",
      5: "Product Type",
      6: "Product Number",
      7: "Payment Type",
      8: "Service Charge",
      9: "Total Amount",
      10: "Payment Time",
      11: "Desrc",
      12: "Wait to calculate",
      13: "not have",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Stencil",
      4: "Express fee",
    },
    message: {
      1: "Please select an order.",
    },
  },
  //地址
  address: {
    1: {
      1: "Edit",
      2: "Delete",
    },
    2: "ADD NEW ADDRESS",
    3: "EDIT ADDRESS",
    4: {
      1: "First Name",
      2: "Last Name",
      3: "Company",
      4: "Street",
      5: "Pleace select country",
      6: "Provinces",
      7: "City",
      8: "Postcode",
      9: "Phone",
      10: "Email",
      11: "Tax No",
      12: "Whether it is the default address",
      13: "We may confirm some problems in your orders or files with you via email, please make sure the email",
    },
  },
  //优惠券
  coupon: {
    1: "My Coupons",
    2: "coupon",
    useOfTypeMap: {
      1: "spendable",
      2: "in use",
      3: "used",
      4: "out of date",
    },
    consumptionTypeMap: {
      0: "Unlimited",
      1: "For PCB",
      2: "For PCBA",
      3: "For Stencil",
    },
    useTypeMap: {
      1: "Total cost",
      2: "Freight cost",
    },
  },
  //信函
  letter: {
    1: {
      1: "ALL",
      2: "Complaint",
      3: "Feedback",
      4: "Proposal",
      5: "Order",
    },
    2: "Create",
    3: {
      1: "Serial Number",
      2: "Title",
      3: "Type",
      4: "Content",
      5: "View",
      6: "Create time",
      7: "End time",
      8: "Message state",
      9: "Delivered",
      10: "Seen",
      11: "Rate of progress",
      12: "Messages",
      13: "Action",
      14: "Write back",
      15: "Select type",
    },
    4: "Content Detail",
    5: "Message Detail",
    6: "Replay",
    7: {
      1: "Recipients",
    },
    8: {
      1: "Content of replay",
    },
    9: {
      1: "Create",
      2: "Communication",
      3: "Finish",
    },
    10: {
      1: "Self",
      2: "Service",
    },
    message: {
      1: "Title cannot be empty.",
      2: "Type cannot be empty.",
      3: "Content cannot be empty.",
      4: "Created successfully!",
      5: "Replied successfully.",
    },
  },
  //公告
  notice: {
    1: "Title",
    2: "Team",
    3: "Release time",
    4: "Action",
    5: "Detail",
    detail: {
      1: "Back",
      2: "System notice",
      3: "Acticities notice",
    },
  },
  //个人信息
  profile: {
    1: "Profile",
    2: {
      1: "User Name",
      2: "Account Type",
      3: "Avatar",
      4: "Update Avatar",
      5: "Company name",
      6: "Tax No",
      7: "Phone Number",
      8: "Facebook",
      9: "Skype",
      10: "Whatsapp",
      11: "Update",
    },
    3: {
      1: "Update Password",
      2: "Current Password",
      3: "New Password",
      4: "Confirm Password",
    },
    4: {
      1: "Save",
      2: "Cancel",
    },
    5: {
      1: "personal",
      2: "corporation",
    },
    message: {
      1: "New password and confirm password do not match.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Credit Card",
    3: "Manual Bank Transfer(TT)",
    4: "Other",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "Success!",
    "-1": "Unknown error!",
    401: "Login expired, please log in again!",
    403: "Authorization verification failed!",
    10000:
      "System failure! Please send feedback to the administrator in time! Thanks!",
    10001: "The file size exceeds the upper limit!",
    10002: "Please select the order you want to pay for.",
    10003: "Order exception!",
    10004: "The file size exceeds the upper limit! File Max 50MB!",
    10005: "An order has been generated for the item!",
    10006:
      "Please upload the stencil Gerber file! Only ZIP or RAR files accepted. Maximum size 50MB!",
    10007: "The mold price could not be found!",
    10008:
      "Under the Top & Bottom (On Separate Stencil) selection, the quantity cannot be less than 2!",
    10009:
      "The currently selected product does not exist or has been deactivated!",
    10010: "The selling price of this product has not yet been announced!",
    10011: "Out of stock, cannot be added to the cart!",
    10012: "The current cart item does not exist!",
    10013: "The selling price of this product has not yet been announced!",
    10014:
      "The current inventory of goods is lower than the modified quantity, please adjust and try again!",
    10015: "The article does not exist or has been deleted!",
    10016: "Please select the comment you want to respond to!",
    10017: "This comment does not exist or has been removed!",
    10018: "The UUID does not exist!",
    10019: "Please select the comment you want to like!",
    10020: "This comment does not exist or has been removed!",
    10021: "Please select the comment you want to unlike!",
    10022: "Please select the forum article details you want to view!",
    10023: "The selected forum category does not exist!",
    10024: "Please select the article you want to delete!",
    10025: "Please select the article you want to like!",
    10026: "Please select the article you want to unlike!",
    10027: "Please select the article you want to cancel collection!",
    10028: "Please select the product to view the Gerber version!",
    10029: "Please select the product to generate an order!",
    10030: "There are currently no orders available for payment!",
    10031: "The payment is unsuccessful!",
    10032:
      "Please upload the PCB Gerber file. Only ZIP or RAR files accepted. Maximum size 50MB!",
    10033:
      "Please upload the PCBA BOM file. Only .CSV, .XLS, or .XLSX files accepted!",
    10034: "The Panel By Customer x is not null!",
    10035: "The Panel By Customer y is not null!",
    10036: "The Panel By Customer x cannot exceed 500mm!",
    10037: "The Panel By Customer y cannot exceed 500mm!",
    10038: "The edge rails cannot be null!",
    10039: "The panel size cannot be null!",
    10040: "The FR4 Tg cannot be null!",
    10041: "The FR4 minimum solder mask dam cannot be null!",
    10042: "The FR4 impedance control cannot be null!",
    10043: "The FR4 half hole edge quantity is incorrect!",
    10044: "The aluminum TCE cannot be null!",
    10045: "The PCBA quantity cannot be 0!",
    10046: "Stencil is a necessity for PCBA!",
    10047: "The parts supply mode cannot be empty!",
    10048: "The address ID cannot be null!",
    10049: "Old password is incorrect!",
    10050: "The email account does not exist!",
    10051: "The CAPTCHA has expired!",
    10052: "The CAPTCHA is incorrect!",
    10053: "The email format is incorrect!",
    10054: "This email has been registered!",
    10055: "The username or password is incorrect!",
    10056: "Please select messages marked as read!",
    10057: "The payment method cannot be null!",
    10058: "The payment item type cannot be empty!",
    10059: "The order ID cannot be null!",
    10060: "Please select the order you want to pay!",
    10061: "Your order has been processed!",
    10062: "The mold price could not be found!",
    10063: "Cannot retrieve the current template price!",
    10064: "Your order is pending confirmation or has already been processed!",
    10065: "Please select the product you want to pay for!",
    10066:
      "The selected goods are in abnormal condition or have been paid for!",
    10067: "The selected PCB product already has a generated order!",
    10068: "Your order is pending confirmation or has already been processed!",
    10069: "The selected PCBA product already has a generated order!",
    10070: "The selected stencil product already has a generated order!",
    10071: "Please select the item you want to view!",
    10072: "The email format is incorrect. Please enter a correct email!",
    10073: "The verification code does not exist!",
    10074: "The verification code is incorrect, please try again!",
    10075: "Please select the product to view the BOM version!",
    10076: "Please select the order you want to confirm!",
    10077: "This operation cannot be performed due to abnormal product status!",
    10078: "Please select the brand details you want to view!",
    10079: "Please select the item to unfavorite!",
    10080: "Only users who purchased this product can leave reviews!",
    10081: "You have already reviewed this product!",
    10082: "Please select the product details you want to view!",
    10083: "The order number cannot be null!",
    10084: "Orders cannot be null!",
    10085: "Credit card payment failed!",
    10086: "Provide the order numbers to generate the transfer bill!",
    10087: "The selected product already has a generated order!",
    10088: "The following items cannot be purchased due to lack of stock!",
    10089: "Unable to generate orders due to insufficient stock!",
    10090: "Network exception, please try again later!",
    10091: "The current feedback is awaiting processing!",
    10092: "The current feedback has been processed!",
    10093: "Please select the announcement details you want to view!",
    10094: "Please select a location to query the carousel chart!",
    10095: "The accessed resource does not exist!",
  },
};
