import Vue from "vue";
import App from "./App.vue";

import Vuex from "vuex";
import router from "./router";
import store from "./store";

import "element-ui/lib/theme-chalk/index.css";
import "@wangeditor/editor/dist/css/style.css";
import ElementUI from "element-ui";
import dispatchEventStroage from "@/utils/watchLocalStorage";
import { message } from "@/utils/message";
import VueWechatTitle from "vue-wechat-title";
import { i18nChangeLanguage } from "@wangeditor/editor";

import VueI18n from "vue-i18n";
import ElementLocale from "element-ui/lib/locale";
import { messages, defaultLocale } from "./locales/i18n-config";

// 富文本 切换语言 - 'en' 或者 'zh-CN'
i18nChangeLanguage("en");
Vue.use(Vuex);
Vue.use(ElementUI);
Vue.use(dispatchEventStroage);
Vue.use(VueWechatTitle);

Vue.config.productionTip = false;

Vue.prototype.$message = message;

// 使用 VueI18n
Vue.use(VueI18n);
// 检测用户语言
const browserLanguage = navigator.language || navigator.browserLanguage;
const savedLanguage = localStorage.getItem("app-language");
const selectedLocale =
  savedLanguage || (browserLanguage.includes("zh") ? "en" : "en");

// 创建 i18n 实例
export const i18n = new VueI18n({
  locale: selectedLocale || defaultLocale,
  fallbackLocale: "en",
  messages,
});
// 设置 Element UI 使用 VueI18n
ElementLocale.i18n((key, value) => i18n.t(key, value));
Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n, // 注入 i18n 实例
  render: (h) => h(App),
}).$mount("#app");

// 保存用户选择的语言
export function setLanguage(lang) {
  i18n.locale = lang;
  localStorage.setItem("app-language", lang);
}
