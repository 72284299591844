import localeDE from "element-ui/lib/locale/lang/de";
import localeEN from "element-ui/lib/locale/lang/en";
import localeES from "element-ui/lib/locale/lang/es";
import localeFR from "element-ui/lib/locale/lang/fr";
import localePT from "element-ui/lib/locale/lang/pt";
import localeRU from "element-ui/lib/locale/lang/ru-RU";

import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import pt from "./pt";
import ru from "./ru";

export const messages = {
  de: { ...de, ...localeDE },
  en: { ...en, ...localeEN },
  es: { ...es, ...localeES },
  fr: { ...fr, ...localeFR },
  pt: { ...pt, ...localePT },
  ru: { ...ru, ...localeRU },
};

export const defaultLocale = "en"; // 默认语言
