/**
 * 德语语言包
 */
export default {
  1: "Abbrechen",
  2: "Suche",
  3: "Zurücksetzen",
  4: "Absenden",
  5: "Erfolg.",
  6: "Inhalt...",
  //每个页面的标签title
  title: {
    1: "Allgemeine Geschäftsbedingungen | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "PCB-Prototyp | PCBA-Montage | Eine Plattform für innovative, agile Fertigung von Elektronikprodukten | Oceansmile | Lanyue Electronics",
    3: "Beitreten | Anmelden | Oceansmile | Lanyue Electronics",
    4: "Passwort zurücksetzen | Passwort wiederherstellen | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "PCB-Technische & Service-Unterstützungszentrum | Elektronikdesign | Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Branchennachrichten & Lernzentrum | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Branchennachrichten & Lernzentrum | Oceansmile | Lanyue Electronics",
    9: "CNC-Bearbeitung | Blech | 3D-Druck | Spritzguss | Maßgeschneiderte Teile | Lanyue Electronics",
    10: "SMT | PCB-Montageservice | PCB-Online-Schnellangebot | Oceansmile | Lanyue Electronics",
    11: "PCB-Produktionskapazität | Bringen Sie Ihre Ideen zum Leben | Oceansmile | Lanyue Electronics",
    12: "Über uns - Bringen Sie Ihre Ideen zum Leben | PCB-Prototyp | Oceansmile | Lanyue Electronics",
    13: "Kontaktieren Sie uns - Bringen Sie Ihre Ideen zum Leben | PCB-Prototyp | Oceansmile | Lanyue Electronics",
    14: "Warum wir - Bringen Sie Ihre Ideen zum Leben | Maßgeschneiderter PCB-Prototyp | Oceansmile | Lanyue Electronics",
    15: "PCB-Online-Schnellangebot | PCB-Montageservice | Oceansmile | Lanyue Electronics",
    16: "SMT-Schablone | PCB-Montageservice | Oceansmile | Lanyue Electronics",
    17: "Rechnungsdetails | Oceansmile | Lanyue Electronics",
    18: "Bankinformationen | Banküberweisung | Oceansmile | Lanyue Electronics",
    19: "Glückwunsch! Erfolgreiche Zahlung | Oceansmile | Lanyue Electronics",
    20: "Produkt | Produktshop",
    21: "Produktdetails | Produktshop",
    22: "Komponenten | IC Easy Store | Bauteilbeschaffung",
    23: "Gerber-Online-Ansicht | Oceansmile | Lanyue Electronics",
    24: "Warenkorb | Oceansmile | Lanyue Electronics",
    25: "Bestellliste | Oceansmile | Lanyue Electronics",
    26: "Bestellbenachrichtigungsliste | Oceansmile | Lanyue Electronics",
    27: "Versandadresse verwalten | Oceansmile | Lanyue Electronics",
    28: "Persönliche Informationen | Oceansmile | Lanyue Electronics",
    29: "Nachbestellliste",
    30: "Gutscheine",
    31: "Beschwerden & Feedback",
    32: "Ankündigungsliste | Oceansmile | Lanyue Electronics",
    33: " | Ankündigungsdetails",
    34: "Zahlungsaufzeichnungen | Überprüfung",
    35: "Branchennachrichten & Lernzentrum | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Branchennachrichten & Lernzentrum | Oceansmile | Lanyue Electronics",
    37: " | Forum persönliches Zentrum | Branchennachrichten & Lernzentrum | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Hilfezentrum",
    2: "Nachricht senden",
    3: "Whatsapp/Service-Hotlines",
    4: "Mein Warenkorb",
    5: "Willkommen bei Oceansmile",
    6: "Anmelden",
    7: "Neuer Kunde?",
    8: "Kostenlos beitreten",
    9: "E-Mail",
    10: "Abmelden",
    11: "Warenkorb",
    12: "Meine Bestellungen",
    13: "Bestellfortschrittsbenachrichtigung",
    14: "Meine Gutscheine",
    15: "Lieferadresse",
    16: "Mein Profil",
    17: "Brief",
    18: "Benachrichtigung",
    19: "Startseite",
    20: "PCB-Montage",
    21: "PCB/PCBA Sofortangebot",
    22: "Schablonen Sofortangebot",
    23: "Produkt-Shop",
    24: "Nachrichten & Blog",
    25: "CNC 3D-Druck",
    26: "Produkt & Kapazität",
    27: "Über uns",
    28: "Kontaktieren Sie uns",
    29: "Warum wir",
    30: "PCB Sofortangebot",
  },
  //脚部页面
  footer: {
    1: {
      1: "SCHNELLE INHALTE",
      2: "Bitte hinterlassen Sie Ihre Nachricht unten und einer unserer hilfsbereiten Mitarbeiter wird Sie innerhalb von 48 Stunden kontaktieren.",
      3: "Geben Sie Ihre E-Mail ein",
      4: "Abonnieren",
    },
    2: {
      1: "Über 10 Jahre Erfahrung in der schnellen PCB-Prototypenerstellung und der Produktion von kleinen bis mittleren PCB & PCBA. Kundenbindung durch überlegene Qualität und Service...",
      2: "Adresse",
      3: "Songyuansha Community, Guanhu Street, Longhua District, Shenzhen, Guangdong, China",
      4: "Telefonnummer",
      5: "Adresse E-Mail",
      6: "Zertifiziert von",
    },
    3: {
      1: "Fähigkeiten",
      2: "Produkte/Services",
      3: "Kundensupport",
      4: "Über uns",
      5: "PCB-Fähigkeiten",
      6: "PCB Sofortangebot",
      7: "Kontaktieren Sie uns",
      8: "Über uns",
      9: "PCB-Montagefähigkeiten",
      10: "PCB-Montage",
      11: "Hilfezentrum",
      12: "Warum bei uns kaufen?",
      13: "SMD-Schablonen",
      14: "Unsere Ausrüstung",
      15: "Individuelle Projektberatung",
      16: "Versandmethoden-Guide",
      17: "Aluminium-PCB-Fähigkeiten",
      18: "Industrie-Lösungen",
      19: "Feedback",
      20: "Datenschutzrichtlinie & Vereinbarung",
      21: "Flexible PCB-Fähigkeiten",
      22: "Rückgaberecht",
      23: "3D-Druck/CNC",
      24: "Nutzungsbedingungen",
    },
    4: "Copyright © 2024 OSpcb. Alle Rechte vorbehalten. Lanyue Electronics (Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Mitgliederbereich",
    2: "Bestellung",
    3: "Mein Konto",
    4: "System",
    5: "Warenkorb",
    6: "Meine Bestellungen",
    7: "Nachhaltigkeit",
    8: "Bestellbenachrichtigung",
    9: "Zahlungsaufzeichnung",
    10: "Adresse",
    11: "Meine Gutscheine",
    12: "Mein Profil",
    13: "Brief",
    14: "Benachrichtigung",
  },
  //aboutUs页面
  aboutUs: {
    1: "Oceansmile ist eine One-Stop-Plattform für die innovative agile Fertigung elektronischer Produkte aus Shenzhen. Wir haben 15 Jahre Erfahrung in der PCB- und PCBA-Produktion in China. Wir bieten One-Stop-Prototyping- und agile Fertigungsservices. Wir helfen Ihnen, Ihre Produkte rechtzeitig auf den Markt zu bringen, mit umfassenden Ressourcen, wettbewerbsfähigen Kosten, zuverlässigen Materialien und exzellenter Qualität. Oceansmile beschäftigt mehr als 200 Mitarbeiter in der Fabrik, mit einer monatlichen Produktionskapazität von mehr als 3.000 Proben / Kleinserienlieferungen. Wir bedienen über 150.000 Kunden aus mehr als 250 Ländern weltweit. Keine Mindestbestellmenge, wir bieten verfeinerte One-to-One-Services und reichhaltige Lösungen für verschiedene industrielle Anwendungsszenarien. Als einer der erfahrensten Hersteller in China sind wir sowohl Ihre besten Geschäftspartner als auch gute Freunde in allen Aspekten Ihrer Projektbedürfnisse. Kundenorientierte Denkweise und Selbstanpassung. Wir wachsen mit Ihnen und passen uns Ihren Anforderungen an.",
    2: {
      1: "Agilität",
      2: "Kosten",
      3: "Qualitätssicherung",
      4: "Nichts ist unmöglich",
    },
    3: {
      1: "Bestell- und Fertigungsprozess Online-Management",
      2: "Schnelle Lieferung",
      3: "Umfassende Lieferkettenkapazität",
      4: "Schnelle Reaktion",
    },
    4: {
      1: "Online-Angebote, transparente Preise",
      2: "Keine versteckten Gebühren und sekundären Kosten",
      3: "Geringe Kosten bei Kleinserienproduktion",
    },
    5: {
      1: "Strenger Produktionsprozess",
      2: "Professionelles Ingenieurteam (5+ Jahre Erfahrung)",
      3: "Ausrüstung mit hervorragender Leistung",
      4: "Robuste AVL",
      5: "Reiche Produkterfahrung",
    },
    6: {
      1: "Unbegrenzter Service",
      2: "Kundenorientierte Denkweise und Selbstanpassung.",
    },
    7: {
      1: "Wir bieten One-Stop-Prototyping- und agile Fertigungsservices an. Nicht nur PCB / PCBA!",
      2: "Gedruckte Schaltungen / PCB-Montage / INDUSTRY-LEADING IoT-LÖSUNGEN / Tests und benutzerdefinierte Testprogramme / Bauteilbeschaffung / Mechanische Anpassungen (Metall, Silikon, Kunststoff, Holz) / Spritzguss / PCB-Design-Hilfe & Layout / Batterieanpassung / Produktdesign / OEM / ODM",
    },
    8: "Oceansmile verfügt über drei große professionelle Fabriken, die auf PCB-Prototypen, PCB-Massenproduktion, PCB-Montage und Mechanische Produktion spezialisiert sind. Die Fläche beträgt 15.000 Quadratmeter mit 6 SMT-Linien. Fokussiert auf die Validierung von Kundenentwicklungsprodukten (Prototypen), um eine ausreichende Kapazität für die Serienproduktion zu gewährleisten.",
    9: "Vertrauen von den größten Marken der Welt",
    10: "Oceansmile verpflichtet sich, die Bedürfnisse unserer Kunden (über 15K) aus verschiedenen Branchen und Ländern in Bezug auf Qualität, Lieferung, Kostenwirksamkeit und andere Anforderungen zu erfüllen. Als einer der erfahrensten PCB/PCBA-Hersteller in China haben wir Kooperationen mit vielen bekannten internationalen Unternehmen etabliert.",
    11: {
      1: "Bezahlte Kunden",
      2: "Länder und Regionen",
      3: "Kooperationsunternehmen",
    },
    12: "Warum Oceansmile?",
  },
  //联系我们页面
  contact: {
    1: "Kontaktieren Sie uns",
    2: {
      1: "Verkaufs- und Produktanfragen",
      2: "Oceansmile ist eine One-Stop-Plattform für innovative agile Fertigung elektronischer Produkte aus Shenzhen. Wir bieten schnelle Prototypenproduktion von PCBs und PCB-Montageservice. Unser Team kann Fragen zu PCB-Fähigkeiten, PCB- & PCBA-Preisfragen, Mechanische Silikon-/Kunststoffteile... und allen Aspekten Ihrer Projektbedürfnisse beantworten. Bestellen bei Oceansmile ist einfach, da wir Online- Sofortangebote für Standard-PCBs, Flex-/Rigid-Flex-PCBs, SMD-Schablonen und PCB-Montage mit kostenlosen Gerber-Dateiprüfungen anbieten und eine pünktliche Lieferung mit DHL, FedEx, UPS und mehr gewährleisten.",
      3: "Bitte hinterlassen Sie Ihre Nachricht unten und einer unserer hilfreichen Mitarbeiter wird Sie innerhalb von ",
      4: "24 Stunden kontaktieren.",
    },
    3: {
      1: "Name",
      2: "E-Mail",
      3: "Telefonnummer",
      4: "Ihre Telefonnummer",
      5: "Firma",
      6: "Ihr Firmenname",
      7: "Captcha",
      8: "Ihr Captcha",
      9: "Nachricht",
      10: "Teilen Sie uns mit, wie wir Ihnen bei Produkten, Lieferung, Zahlungen und mehr helfen können.",
      11: "Nachricht senden",
    },
    4: "Kundenorientierte Denkweise und Selbstanpassung!",
    5: "Wir helfen Ihnen, Ihre Produkte rechtzeitig auf den Markt zu bringen, mit umfassenden Ressourcen, wettbewerbsfähigen Kosten, zuverlässigen Materialien und exzellenter Qualität.",
    6: "Allwetter-Service",
    7: {
      1: "Tipps",
      2: "1. Um die schnellste Antwort zu erhalten, empfehlen wir dringend, unser Personal auf der Kontoseite zu kontaktieren, nachdem ",
      3: "Anmelden/Registrieren.",
      4: "2. Besuchen Sie das ",
      5: "Oceansmile Help Center",
      6: "um häufig gestellte Fragen zu Bestellungen, Versand, Zahlungen und mehr schnell zu finden.",
    },
    message: {
      1: "Name ist erforderlich.",
      2: "E-Mail ist erforderlich.",
      3: "Telefonnummer ist erforderlich.",
      4: "Firma ist erforderlich.",
      5: "Captcha ist erforderlich.",
      6: "Nachricht ist erforderlich.",
    },
  },
  //why us页面
  why: {
    1: "Warum wählen",
    2: "Oceansmile ist ein hochwertiger PCB / PCBA-Hersteller in Shenzhen, China, mit 15 Jahren Erfahrung. Mit professionellen PCB-Fertigungskapazitäten werden unsere PCB/SMT-Ingenieure mit mehr als 10 Jahren Erfahrung Ihre Konstruktionsdateien doppelt überprüfen. Außerdem ist Oceansmile nach IATF16949, ISO9001, ISO14001, UL, CQC, RoHS und REACH zertifiziert; mehr noch, wir bearbeiten den gesamten Prozess einschließlich PCB / PCBA / gedruckte Schaltungen / PCB-Montage / INDUSTRY-LEADING IoT-LÖSUNGEN / Tests und benutzerdefinierte Testprogramme / Bauteilbeschaffung / Mechanische Anpassung (Metall, Silikon, Kunststoff, Holz) / Spritzguss / PCB-Designhilfe & Layout / Batterieanpassung / Produktdesign / OEM / ODM und endgültiger Versand. Wir sind in der Lage, BGA, Micro-BGA, QFN und andere paketlose Bauteile zu montieren. Wir schützen strikt die Sicherheit von Produktionsdokumentationen und geistigen Eigentumsrechten Ihrer Produkte. Wir bieten One-Stop-Prototyping- und agile Fertigungsservices an, fragen Sie nach allen Teilen, die Sie benötigen!",
    3: "One-Stop Multi-Service R&D Unterstützung Plattform (Nicht nur PCB & PCBA)",
    4: "Oceansmile ist ein professioneller Global One-Stop-Dienstleistungshersteller mit 15 Jahren PCB- und PCBA-Produktionserfahrung in China. Mit professionellen PCB / PCBA-Fertigungskapazitäten können wir alle fortschrittlichen Designs mit anspruchsvollen Anforderungen unterstützen, einschließlich FR4, HDI, lasergebohrten Mikro-Vias, Pads, Mikrowellen und RF-Boards, bis zu 32 Schichten. Ospcb ist ein professioneller Hersteller mit 15 Jahren PCB- und PCBA-Produktionserfahrung in China.",
    5: "Wir bieten One-Stop-Prototyping- und agile Fertigungsservices an. Nicht nur PCB/PCBA!",
    6: "Gedruckte Schaltungen / PCB-Montage / INDUSTRY-LEADING IoT-LÖSUNGEN / Tests und benutzerdefinierte Testprogramme / Bauteilbeschaffung / Mechanische Anpassungen (Metall, Silikon, Kunststoff, Holz) / Spritzguss / PCB-Designhilfe & Layout / Batterieanpassung / Produktdesign / OEM / ODM…",
    7: "Agilität: Geschwindigkeit der PCB- und Montagefertigung mit unserem Datensystem und MES-Produktionssystem, effiziente Zusammenarbeit",
    8: "Online-Bestell- und Fertigungsprozess-Management, schnelle Lieferung mit DHL, FedEx, UPS…",
    9: "Umfassende Lieferkettenkapazität, die reichhaltige internationale Lieferkette bietet nicht nur schnelle Bauteilbeschaffungsfähigkeiten, sondern kann auch mehr kostengünstige Optionen mit besserer Qualität bieten. Erfahrene Ingenieure bieten 24-Stunden-Schnellantwort.",
    10: "Geringere Kosten: Senken Sie die Kosten, machen Sie das Produkt wettbewerbsfähiger und erzielen Sie eine Win-Win-Situation",
    11: "Wir bieten Online-Angebote, keine transparenten Preise. Erfahrene Ingenieure helfen Ihnen, die Produktion (DFM) zu analysieren, um zusätzliche Kosten im Produktionsprozess zu vermeiden, Zeit zu sparen. Keine versteckten Gebühren und sekundären Kosten. Wir konzentrieren uns auf Prototypen und kleine sowie mittlere Serienproduktion. Einzigartige, flexible Produktionsmethoden.",
    12: "Qualitätssicherung: Hervorragende Ressourcen- und Lieferantenkontrollsysteme, Qualitätskontrollpläne werden ständig aktualisiert und verbessert",
    13: "Oceansmile's PCB-Materialien sind zertifiziert nach IATF 16949, ISO9001, ISO14001, UL, RoHS und REACH. Bei montierten PCBs bieten wir eine Vielzahl von sorgfältigen Prüfmethoden an, wie z. B. automatisierte optische Inspektion (AOI), In-Circuit-Tests (ICT), Röntgenuntersuchungen, Funktionsprüfungen und mehrere visuelle Inspektionsstufen.",
    14: "Wir sind für jedes Produkt verantwortlich, gemäß strengen Produktionsprozessen. Wir haben ein professionelles Ingenieurteam (alle mit mehr als 5 Jahren Erfahrung). Die Ausrüstung hat hervorragende Leistung, alle Geräte sind hochpräzise und leistungsstarke Top-Marken.",
    15: "Schützen Sie geistige Eigentumsrechte",
    16: "Wir schützen strikt die geistigen Eigentumsrechte der Produkte unserer Kunden und stellen sicher, dass alle Produktionsausrüstungen vom Sicherheitssystem überwacht werden. Wir unterzeichnen mit unseren Kunden eine NDA-Vereinbarung, um die geistigen Eigentumsrechte der Produkte dauerhaft zu schützen.",
    17: "Nichts ist unmöglich: Wir wachsen mit unseren Kunden und bringen unsere Ideen in die Realität",
    18: "Wir bieten One-Stop-Prototyping- und agile Fertigungsservices an. Wir haben unbegrenzte Serviceoptionen, basierend auf den besten Ressourcen in Shenzhen, China. Kundenorientierte Denkweise und Selbstanpassung. Erfahrene Teams, lebendige Gedanken, wir werden uns immer weiterentwickeln und niemals aufhören.",
  },
  //论坛详情页面
  articleDetail: {
    1: "Nachrichten & Blog",
    2: "Kommentare",
    3: "Bitte loggen Sie sich ein, um Kommentare zu posten.",
    4: "Kommentar posten",
    5: "Antworten",
    7: "Artikel veröffentlichen",
    8: "BELIEBTE TAGS",
    9: "Kategorien",
    message: {
      1: "Forum-Kategorie darf nicht leer sein.",
      2: "Titel darf nicht leer sein.",
      3: "Titelbild darf nicht leer sein.",
      4: "Inhalt darf nicht leer sein.",
      5: "Erfolgreich erstellt.",
      6: "Bitte loggen Sie sich ein, um einen Artikel zu posten.",
      7: "Wählen Sie maximal 10 Artikeltags!",
      8: "Bitte geben Sie einen Kommentar ein!",
      9: "Kommentar erfolgreich eingereicht.",
      10: "Möchten Sie den Kommentar wirklich löschen?",
      11: "Tipps",
      12: "Löschen",
      13: "Erfolgreich gelöscht!",
    },
  },
  //论坛页面
  article: {
    1: "Forum Center",
    2: "Artikel veröffentlichen",
    3: "Kategorien",
    4: "Kategorie auswählen",
    5: {
      1: "Kategorie",
      2: "Wählen Sie eine Kategorie",
      3: "Titel",
      4: "Bitte geben Sie den Titel des Artikels ein!",
      5: "Titelbild",
      6: "Inhaltsentwurf",
      7: "Bitte geben Sie eine kurze Beschreibung des Artikels ein!",
      8: "Tags",
      9: "Suchen/Hinzufügen",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Beiträge",
    2: "Sammlungen",
    3: "Gefällt mir",
    4: "Artikel veröffentlichen",
    5: {
      1: "Neuen Artikel erstellen",
      2: "Kategorie",
      3: "Titel",
      4: "Bitte geben Sie den Titel des Artikels ein!",
      5: "Titelbild",
      6: "Inhaltszusammenfassung",
      7: "Bitte geben Sie eine kurze Beschreibung des Artikels ein!",
      8: "Suchen/Hinzufügen",
    },
  },
  //帮助中心列表
  help: {
    1: "Wie können wir Ihnen helfen?",
    2: "Geben Sie verwandte Schlüsselwörter zur Suche ein",
  },
  //帮助中心详情
  helpDetail: {
    1: "Hilfezentrum",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "3D-Druck",
      2: "SLA, MJF, SLM, FDM, SLS Technologien",
      3: "• Harz, Nylon, Metall und Kunststoff",
      4: "• Toleranz bis 0,2mm",
      5: "• Lieferzeit ab 2 Werktagen",
    },
    2: {
      1: "CNC Bearbeitung",
      2: "Fräsen (3-, 4- & volle 5-Achse), Drehen und Nachbearbeitung",
      3: "• Aluminium, Kupfer, Kunststoff",
      4: "• Toleranz bis 0,05mm",
      5: "• Lieferzeit ab 3 Werktagen",
    },
    3: {
      1: "Spritzgussfertigung",
      2: "Vakuumguss",
      3: "• ABS, POM, Nylon, PC, PC/ABS, PVC, PE, PP, PS, TPU, Silikon, kundenspezifische Materialien",
      4: "• Lebensdauer >=50k/100k",
      5: "• Toleranzen nach ISO 2768-1 kontrolliert",
      6: "• Lieferzeit 15-25 Werktage",
    },
    4: {
      1: "Verschickte Länder",
      2: "Fabrikbereich",
      3: "Materialien und Oberflächenbehandlungen",
      4: "Angebotsanfrage",
    },
    5: "Jetzt Angebot einholen",
    6: "360°-Closed-Loop-Service",
    7: "Professionelles Ingenieur- und Serviceteam bietet Ihnen einen 360°-Echtzeit-Service mit regelmäßigen Produktionsfeedback-Berichten.",
    8: "Standard-Serviceprozess",
    9: "Oceansmile verfügt über ein leistungsstarkes IT-Produktionsmanagementsystem, das schnelle, umfassende und qualitativ hochwertige Produktbearbeitungsdienste bietet.",
    10: {
      1: "Schnelles Angebot",
      2: "Angebot und einzuordnende DFM-Dienste innerhalb von 24 Stunden nach Eingang der Dokumente durch Ingenieure.",
      3: "Bestätigung der Lösung",
      4: "Material- und Oberflächenanforderungen auswählen, Produktionsplan bestätigen.",
      5: "Zahlungsbestätigung",
      6: "Überprüfen Sie Ihre Bestellung und bezahlen Sie. Hinzufügen von Verkäufen, um VIP-Services zu erhalten.",
      7: "Bearbeitung / 3D-Druck",
      8: "Blanking - Programmierung - Bearbeitung - Nachbearbeitung, Inspektion - Lieferung",
      9: "After-Sales-Service",
      10: "Prüfen Sie Produkte mit Zeichnung. Wir reagieren sofort auf Qualitätsprobleme.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Produktdetails",
    2: "Bewertungen",
    3: {
      1: "Bewertungen",
      2: "Rezension",
      3: "Bilder",
      4: "Klicken Sie hier zum Hochladen",
      5: "Bewertung abschicken",
      6: "Geben Sie Ihre Bewertung ein...",
      7: "Noch keine Kommentare",
    },
    4: "Enthält Batterie",
    5: "Arbeitstage",
    6: "auf Lager",
    7: "wählen",
    8: {
      1: "CN Lager",
    },
    9: "IN DEN WARENKORB",
    10: "Tags",
    11: "NICHT AUF LAGER",
    12: "Startseite",
    13: "Produkte Shop",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Bitte geben Sie den Inhalt ein, der angegeben werden soll. Dieses Feld ist optional.",
      2: "Bemerkungen",
      3: "Keine",
      4: "Erfolgreich hinzugefügt.",
      5: "Bewertung erfolgreich!",
      6: "Es gibt bereits drei Bilder! Bitte löschen Sie eines, bevor Sie neue Bilder hochladen.",
    },
  },
  commodity: {
    1: {
      1: "Schlüsselwort",
      4: "pro Seite",
    },
    2: {
      1: "Mehr...",
      2: "In den Warenkorb",
      3: "NICHT AUF LAGER",
      4: "Keine Ergebnisse gefunden",
    },
    3: {
      1: "Bemerkungen und Menge",
      2: "Menge",
      3: "Beschreibung",
      4: "Bitte geben Sie den zu beachtenden Inhalt ein. Sie können dies ignorieren",
      5: "SKU",
      6: "Keine Daten",
    },
    message: {
      1: "Erfolgreich hinzugefügt.",
    },
  },
  //忘记密码
  forget: {
    1: "Passwort vergessen",
    2: {
      1: "E-Mail-Adresse",
      2: "E-Mail",
    },
    3: {
      1: "Auth-Code",
      2: "Code",
      3: "Erhalten",
    },
    4: {
      1: "Neues Passwort",
    },
    5: {
      1: "Passwort bestätigen",
    },
    message: {
      1: "Bitte geben Sie eine E-Mail-Adresse ein.",
      2: "Bitte geben Sie eine E-Mail-Adresse ein.",
      3: "Bitte geben Sie den Authentifizierungscode ein.",
      4: "Bitte geben Sie das Passwort ein.",
      5: "Bitte bestätigen Sie das Passwort.",
      6: "Passwort stimmt nicht mit Bestätigungspasswort überein.",
      7: "Passwort erfolgreich aktualisiert.",
    },
  },
  //主页
  home: {
    1: {
      1: "PCB Fertigungsrechner",
      2: "Länge  Breite",
      3: "Länge",
      4: "Breite",
      5: "Menge",
      6: "Bitte eingeben",
      7: "Schichten",
      8: "Dicke",
      9: "Neuling? Unregistriert?",
      10: "Holen Sie sich",
      11: "Gutscheine hier!",
      12: "Angebot jetzt einholen",
      13: "PCBA Montage Rechner",
    },
    2: {
      1: "Schnelle Bearbeitung",
      2: "Schon ab 24 Stunden",
      3: "Einzelbetreuung",
      4: "Reibungsloses Einkaufserlebnis",
      5: "Bester Preis",
      6: "Direktpreis vom Hersteller",
      7: "Nachrichten",
      8: "PCB-Prototypen auf einfache Weise",
      9: "Mit über einem Jahrzehnt Erfahrung im Bereich PCB...",
      10: "Mehr erfahren",
    },
    3: {
      1: "PCB Sofortangebot",
      2: "1-6 Schichten Polyimid flexible",
      3: "Bestellformular 1 Stück",
      4: "4-5 Werktage Lieferzeit",
      5: "PCB SOFORTANGEBOT",
      6: "PCB-Datei hochladen",
      7: "Bestellübersicht",
      8: "Zahlung",
      9: "Echtzeitverfolgung",
      10: "Lieferung",
      11: "Bestätigung empfangen",
      12: "Klicken Sie auf „Sofortangebot“ in der Menüleiste und wählen Sie „PCB/PCBA Sofortangebot“. Dann gelangen Sie zur Angebotsseite und laden die PCB-Datei hoch.",
      13: "Laden Sie Gerber-Dateien (zip, rar) hoch, andere Formate können aufgrund von Versionskompatibilitätsproblemen Fehler verursachen. Das System überprüft innerhalb von 30 Sekunden automatisch. Fehler beim Hochladen? Sie können überspringen und Ihre PCB-Datei an uns per E-Mail senden.",
      14: "Die erfahrenen Ingenieure von Oceansmile führen DFM an Ihrem Produktdesign durch und bieten Optimierungsvorschläge an.",
      15: "Online-Zahlung, sicher und nachverfolgbar. Wir unterstützen mehrere Zahlungsmethoden, wie Paypal, Kreditkartenzahlung, Offline-Banküberweisung usw. Bei Fragen zur Zahlung wenden Sie sich bitte an unseren Kundenservice.",
      16: "Visualisieren Sie den Fortschritt der Bestellung und prüfen Sie den Produktionsstatus jederzeit. Wir arrangieren spezialisiertes Personal zur Auftragsverfolgung, um sicherzustellen, dass die Produkte pünktlich und in guter Qualität fertiggestellt werden.",
      17: "Eine Vielzahl von Kurieroptionen und weltweit anerkannte Kurierservices garantieren eine schnelle und sichere Lieferung Ihrer Produkte. DHL, FedEx, UPS, EUPost",
      18: "Sie erhalten Ihr Produkt. Wir pflegen Win-Win-Kundenbeziehungen und bieten Ihnen weiterhin hochwertigen After-Sales-Service. Wenn es Probleme mit dem Produkt gibt, werden wir mit unseren Ressourcen schnell reagieren.",
    },
    4: {
      1: "Bestseller-Produkte",
      2: "PCB-Prototyp",
      3: "Ab",
      4: "Bauzeit",
      5: "PCB-Montage",
      6: "Komponentenlieferanten",
      7: "Flex, Rigid-Flex PCB",
      8: "HDI PCB",
      9: "3D-Druck",
      10: "Tage",
      11: "Stunden",
      12: "CNC-Bearbeitung",
      13: "Stücke",
    },
    5: {
      1: "Montagedienst-Promotion",
      2: "Nur",
      3: "IN GESAMT",
      4: "für 1-20 Stück Montage",
      5: "JETZT ANGEBOT EINHOLEN",
      6: "One-Stop-Lösung für PCB & Montage",
      7: "Einfach gemacht, Menge, Einmal",
      8: "SMT & Through-Hole-Montage",
      9: "Wellenlöten für PCBA",
      10: "PCBA Online-Angebot",
      11: "One-Stop-BOM-Kauf-Lösung",
      12: "Über 99,6% Zufriedenheitsrate",
      13: "Box Build, Kabelbaum",
      14: "Funktionstests",
      15: "und andere Tests",
      16: "IC-Programmierung",
      17: "BGA",
      18: "QFN",
      19: "Letzte 30 Tage",
      20: "Kunden",
      21: "Zahlend",
      22: "SMT",
    },
    6: {
      1: "Günstiger PCB-Prototyp-Hersteller in China",
      2: "Datum",
      3: "Käufername",
      4: "Letztes Mal",
      5: "Fortschritt",
    },
    7: {
      1: "SCHICHT",
      2: "4-Schicht PCB mit 2 Routing-Schichten, einer Erdungsebene und einer Stromversorgungsebene",
      3: "Mehrschicht PCB Stapelung",
      4: "6-Schicht PCB hat GTL, GBL und 4 innere Schichten. Jedes Signal, das von 1 bis 6 Schichten geht",
      5: "8-Schicht PCB hat GTL, GBL und 6 innere Schichten. Jedes Signal, das von 1 bis 8 Schichten geht",
    },
    8: "Von unserem Blog",
    9: "Was Kunden sagen",
    10: "mm",
  },
  //登录页面
  login: {
    1: "Anmelden / Einloggen",
    2: "Konto erstellen",
    3: {
      1: "E-Mail-Adresse",
      2: "Code",
      3: "Erhalten",
      4: "Passwort",
      5: "Passwort bestätigen",
      6: "Registrieren",
      7: "Durch die Erstellung dieses Kontos stimmen Sie unseren",
      8: "Allgemeinen Geschäftsbedingungen zu",
    },
    4: {
      1: "ANMELDEN",
      2: "Willkommen zurück!",
      3: "Passwort vergessen?",
      4: "Einloggen",
    },
    message: {
      1: "Bitte geben Sie eine E-Mail-Adresse ein.",
      2: "Bitte akzeptieren Sie die Nutzungsbedingungen, um fortzufahren.",
      3: "Bitte geben Sie den Authentifizierungscode ein.",
      4: "Bitte geben Sie das Passwort ein.",
      5: "Bitte bestätigen Sie das Passwort.",
      6: "Passwort stimmt nicht mit der Bestätigung überein.",
      7: "Registrierung erfolgreich! Bitte melden Sie sich auf der rechten Seite der Seite an.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Angemessener Preis garantiert",
      2: "$3,78 für 5 Stück 10*10cm PCB-Prototyp, $25 für 10 Stück PCB-Montage.",
      3: "PCB-Material garantiert",
      4: "Oceansmile verwendet nur hochwertige Rohmaterialien, die ROHS-konform für die Produktion sind.",
      5: "3. Qualität garantiert",
      6: "Oceansmile entspricht vollständig dem ISO 9001:2015-Qualitätsmanagementsystem.",
      7: "4. Pünktliche Lieferung garantiert",
      8: "Eil-PCB-Prototyp, One-to-One-Service online, 99 % pünktliche Lieferung per DHL.",
    },
    2: {
      1: "Produktanzeige",
      2: "Mit über 10 Jahren Erfahrung in der schnellen PCB-Prototypenerstellung und der Produktion von kleinen bis mittelgroßen PCB-Volumina.",
    },
    3: {
      1: "PCB-Montagefähigkeiten",
      2: "Wir sind stolz auf unseren Ruf als führender Spezialist für Oberflächenmontage, Durchgangsbohrung und gemischte Technologie in der PCB-Montage sowie elektronische Fertigungsdienstleistungen und schlüsselfertige elektronische PCB-Montage.",
      3: "PROTOTYP PCB MONTAGE",
      4: "Jetzt anfragen",
      5: "PCB-Typen für Montage",
      6: "FR-4-Platine",
      7: "Metallkern-Platine",
      8: "Flexible Platine",
      9: "Rigide-flexible Platine",
      10: "Usw.",
      11: "Lötarten",
      12: "Bleifreies Löten",
      13: "SMD- und TH-Komponentenmontage",
      14: "Einseitige und doppelseitige Montage",
      16: "Komponentenlieferanten",
      17: "Digi-key, Mouser, Arrow, Element 14 und",
      18: "mehr …",
      19: "Chinesischer Markt für universelle",
      20: "Komponenten nach Ihrer Genehmigung.",
      21: "Testarten",
      22: "Visuelle Inspektion",
      23: "AOI-Inspektion",
      24: "Röntgeninspektion",
      25: "In-Circuit-Test",
      26: "Funktionstest",
    },
    4: {
      1: "Fabrikshow",
      2: "Mit über 10 Jahren Erfahrung in der schnellen PCB-Prototypenerstellung und der Produktion von kleinen bis mittelgroßen PCB-Volumina.",
    },
    5: {
      1: "Wie man PCB-Montageservice bei OceanSmile bestellt?",
      2: "Klicken Sie hier, um Details zu erfahren, wie Sie PCBA bestellen können, oder kontaktieren Sie uns,",
      3: "wenn Sie Fragen zu unseren Montageservices haben.",
    },
    6: {
      1: "Erster Schritt",
      2: "Laden Sie Ihre PCB-Gerber-Dateien hoch und füllen Sie das Formular aus",
      3: "Zweiter Schritt",
      4: "Laden Sie eine formatierte BOM-Datei hoch",
      5: "Dritter Schritt",
      6: "Erhalten Sie ein sofortiges Online-Angebot",
    },
    7: "Laden ...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "PCB-Fertigung und Montagefähigkeiten",
      3: "Erfahren Sie mehr über Oceansmile's Fähigkeiten und bringen Sie Ihre Ideen schnell in die Realität.",
      4: "Grün",
      5: "Lila",
      6: "Rot",
      7: "Gelb",
      8: "Blau",
      9: "Weiß",
      10: "Schwarz",
    },
    2: {
      1: "Rigide PCB",
      2: "Flex PCB",
      3: "PCB Montage",
    },
    3: {
      1: "PCB-Spezifikationen",
      2: "Bohrungs-/Lochgröße",
      3: "Minimale Ringbreite",
      4: "Mindestabstand",
      5: "Minimale Linienbreite und -abstand",
      6: "BGA",
      7: "Lötmaske",
    },
    4: {
      1: "Eigenschaften",
      2: "Fähigkeit",
      3: "Hinweise",
      4: "Muster",
      5: "Schichtanzahl",
      6: "Schichten",
      7: "Die Anzahl der Kupferschichten der Platinen.",
      8: "Material",
      9: "FR-4",
      10: "Aluminium",
      11: "Kupferkern",
      12: "Rogers",
      13: "Keramik",
      14: "FR-4 PCB",
      15: "Aluminium PCB",
      16: "Plattenabmessung",
      17: "Min.",
      18: "Max.",
      19: "Die Größe der PCB-Platine",
      20: "Maßtoleranz",
      21: "(Regulär) für CNC-Routing und ±0,4mm für V-Schnitt",
      22: "Plattendicke",
      23: "Die fertige Dicke der Platine",
      24: "Dicktoleranz",
      25: "± 0,1mm für Platendicke ≤1,6mm, ± 10% für Platendicke ≥1,6mm",
      26: "Fertiges Kupfer",
      27: "Äußere Schicht",
      28: "Innere Schicht",
      29: "Oberflächenfinish",
      30: "HASL (mit Blei / bleifrei), ENIG, OSP, ENEPIG",
      31: "Der zusätzliche Prozess, um freiliegendes Kupfer für die Lötfähigkeit zu helfen.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Eigenschaften",
      2: "Fähigkeit",
      3: "Hinweise",
      5: "Bohrlochgröße (Rundes Loch)",
      6: "Mechanische Bohrung",
      7: "Laserbohrung",
      8: "Bohrlochgröße (Slot-Loch)",
      9: "Bohrlochgröße (Halbloch)",
      10: "Der Durchmesser der Halb-Löcher sollte mehr als 0,5 mm betragen und der Abstand zwischen den Halb-Löchern sollte mehr als 0,9 mm betragen",
      11: "Blind-/Begrabene Vias",
      12: "Oceansmile kann sowohl Blind-Vias als auch Begrabene Vias unterstützen.",
      13: "Bohrlochgrößentoleranz",
      14: "PTH",
      15: "NPTH",
      16: "Slot-Loch",
    },
    6: {
      1: "Kupfer",
      2: "VIA",
    },
    7: {
      1: "Loch-zu-Loch-Abstand (verschiedene Netze)",
      2: "Der Mindestabstand zwischen plattierten Löchern, den wir akzeptieren können.",
      3: "Via-zu-Via-Abstand (dieselben Netze)",
      4: "Der Mindestabstand zwischen Vias, den wir akzeptieren können.",
      5: "Pad-zu-Pad-Abstand (SMD-Pads, verschiedene Netze)",
      6: "Der Mindestabstand zwischen SMD-Pads, den wir akzeptieren können.",
      7: "Pad-zu-Pad-Abstand (TH-Pads, verschiedene Netze)",
      8: "Der Mindestabstand zwischen TH-Pads, den wir akzeptieren können.",
      9: "Via zu Spur",
      10: "Der Mindestabstand zwischen Via und Spur, den wir akzeptieren können.",
      11: "PTH zu Spur",
      12: "Der Mindestabstand zwischen PTH und Spur, den wir akzeptieren können.",
      13: "NPTH zu Spur",
      14: "Der Mindestabstand zwischen NPTH und Spur, den wir akzeptieren können.",
      15: "Pad zu Leiterbahn",
      16: "Der Mindestabstand zwischen Pad und Leiterbahn, den wir akzeptieren können",
    },
    8: {
      1: "Min. Leiterbahnbreite",
      2: "Min. Abstand",
      3: "empfohlene Leiterbahnbreite/-abstand für Serienproduktion",
    },
    9: {
      1: "Spezifikation",
      2: "Min. BGA-Pad-Abmessungen",
      3: "Min. Abstand zwischen BGA",
      4: "BGA-Durchmesser",
      5: "Min. für HASL mit Blei",
      6: "Min. für HASL bleifrei",
      7: "Min. für andere Oberflächenbehandlungen",
      8: "Der Mindestdurchmesser der BGA-Pads, den wir akzeptieren können.",
      9: "Abstand zwischen BGA-Pads",
      10: "Der Mindestabstand zwischen den BGA-Pads, den wir akzeptieren können.",
    },
    10: {
      1: "Lötmaskenfarbe",
      2: "Die Farbe der Leiterplatte",
    },
    11: "mm",
  },
  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Produktinformationen",
      2: "Beschreibung",
      3: "RoHs",
      4: "Verfügbarkeit",
      5: "Auf Lager",
      6: "Preisgestaltung",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Letzte Aktualisierung: 16. Mai 2024.",
    2: "Betriebsstatement der Oceansmile-Website",
    3: "Diese Website wird von Oceansmile betrieben, einschließlich cnospcb.com und Lanyue Electronics (Shenzhen) Co., Ltd. Auf dieser Website beziehen sich die Begriffe 'Oceansmile', 'OSPCB', 'Lanyue', 'wir' und 'uns' auf die oben genannten Marken und Tochtergesellschaften. Die in diesem Dokument beschriebenen Allgemeinen Geschäftsbedingungen gelten für diese Entitäten und alle von diesen Marken bereitgestellten Dienste.",
    4: "Datenschutzrichtlinie",
    5: "Dies ist die Datenschutzrichtlinie, die von Lanyue/Oceansmile/Lanyue Electronics (Shenzhen) Co., Ltd. veröffentlicht wurde, welche die Verarbeitung personenbezogener Daten und die Ausübung Ihrer Rechte, usw. umfasst. Die Datenschutzrichtlinie gilt für diese Seite bezüglich aller verwandten Produkte und/oder Dienstleistungen, die von Lanyue (Oceansmile) bereitgestellt werden. Als Verantwortlicher für Ihre personenbezogenen Daten stimmt Lanyue zu, dass der Schutz Ihrer personenbezogenen Daten für uns von großer Bedeutung ist und Lanyue alle angemessenen Maßnahmen ergreifen wird, um Ihre personenbezogenen Daten gemäß allen geltenden Gesetzen und Vorschriften zu sammeln und zu verwenden.",
    6: "Personenbezogene Daten",
    7: "Unter der spezifischen Definition gemäß der Verordnung Nr. 2016/679 zum Schutz von natürlichen Personen bei der Verarbeitung personenbezogener Daten ('DSGVO') können personenbezogene Daten von Lanyue verarbeitet werden, die hauptsächlich in die folgenden Kategorien fallen",
    8: "1. Einige personenbezogene Daten werden von Ihnen freiwillig bereitgestellt.",
    9: "2. Andere personenbezogene Daten werden automatisch verarbeitet, wenn Sie unsere Seiten besuchen und/oder Produkte und/oder Dienstleistungen von Lanyue nutzen.",
    10: "3. Weitere personenbezogene Daten werden über Websites und Dienste von Drittanbietern gesammelt.",
    11: "Einhaltung der Exportkontrolle",
    12: "Verarbeitung personenbezogener Daten",
    13: "Sie stellen Ihre personenbezogenen Daten freiwillig im Kaufprozess oder anderen Prozessen mit Ihrer vorherigen Zustimmung zur Verfügung, einschließlich, aber nicht beschränkt auf Ihren Namen und Kontaktdaten, Kontodaten, Zahlungsinformationen, Kommentardaten und andere Daten, die Sie uns zur Verfügung stellen. Lanyue wird Ihre bereitgestellten personenbezogenen Daten verwenden, um Ihre Bestellungen auszuführen, bestimmte Dienstleistungen für Sie bereitzustellen, ein Konto zu registrieren und alle Kommunikationen zwischen Ihnen und Lanyue.",
    14: "Lanyue wird Ihre personenbezogenen Daten nur für den oben genannten oder für andere gesetzlich vorgeschriebene Zwecke verwenden. Sie haben das Recht, Ihre bereitgestellten personenbezogenen Daten jederzeit zu widerrufen oder zu löschen, wenn Sie der Meinung sind, dass Ihre Rechte nicht geschützt sind.",
    15: "Lanyue kann automatisch personenbezogene Daten sammeln, wenn Sie Produkte und/oder Dienstleistungen von Lanyue nutzen, einschließlich, aber nicht beschränkt auf",
    16: "Geräteinformationen, wie nach dem Kauf von Lanyue-Produkten und deren Lieferung an Ihre Adresse zur Überwachung, die Webserver von Lanyue möglicherweise automatisch Ihre IP-Adresse oder andere verwandte Informationen von Ihrem Gerät protokollieren.",
    17: "Daten, die die Produkte umfassen, die Sie verwenden, und wie Sie sie verwenden, sowie Ihr Nutzungsverhalten, wenn Sie unsere Websites durchsuchen. Der Zweck der automatischen Sammlung Ihrer personenbezogenen Daten besteht darin, Ihre Nutzungserfahrung von Lanyue-Produkten und/oder -Dienstleistungen zu bewerten und zu verbessern. Wenn Sie nicht wünschen, dass Ihre Nutzungsgewohnheiten oder andere personenbezogene Daten offengelegt werden, wenden Sie sich bitte an die in dieser Datenschutzrichtlinie angegebene Kontakt-E-Mail.",
    18: "Darüber hinaus verwendet die Website von Lanyue Dienste von Drittanbietern, einschließlich, aber nicht beschränkt auf Zahlungsdienstleister, Versandanbieter und Werbetreibende. Einige Anbieter können Ihre personenbezogenen Daten für die oben genannten Zwecke sammeln und haben ihre eigene Datenschutzrichtlinie zum Schutz der Verarbeitung Ihrer personenbezogenen Daten.",
    19: "PREIS UND ZAHLUNG",
    20: "Lanyue unternimmt alle Anstrengungen, um aktuelle und genaue Informationen zu Produkten und Preisen bereitzustellen, garantiert jedoch nicht die Aktualität oder Genauigkeit solcher Informationen.",
    21: "Online-Preisanfragen garantieren keine endgültige Bestellmenge oder Bearbeitungszeiten. Alle Bestellmengen und Bearbeitungszeiten unterliegen einer Überprüfung der Produktionskapazität. Der auf der Website angezeigte Preis ist nur nach Bestätigung und nachdem die übermittelten Dokumente geprüft wurden, verbindlich. Lanyue behält sich das Recht vor, den Preis zu ändern oder die Bestellung zu stornieren, wenn die eingereichten Dokumente nicht mit den in der Bestellmaske eingegebenen Daten übereinstimmen.",
    22: "Lanyue übernimmt keine Verantwortung für Preis-, Schreibfehler oder andere Fehler in Angeboten von Lanyue und behält sich das Recht vor, Bestellungen zu stornieren, die aufgrund solcher Fehler entstanden sind.",
    23: "VERSAND UND LIEFERUNG",
    24: "Der Kunde ist dafür verantwortlich, sicherzustellen, dass die auf dem Webformular angegebene Lieferadresse korrekt und vollständig ist. Alle zusätzlichen Transportkosten aufgrund falscher Adressangaben werden dem Kunden in Rechnung gestellt. Internationale Kunden sind für alle Zollgebühren und Einfuhrabgaben auf alle Bestellungen verantwortlich.",
    25: "Lanyue wird Ihre Waren innerhalb eines wirtschaftlich angemessenen Zeitrahmens an die in Ihrer Bestellung angegebene Adresse versenden. Wir bemühen uns sehr, sicherzustellen, dass alle Bestellungen pünktlich versendet werden. Es gibt jedoch Fälle, in denen die Frachtführer Verzögerungen oder Versandfehler haben. Wir bedauern dies, können jedoch keine Verantwortung für Verzögerungen durch diese Frachtführer übernehmen.",
    26: "HAFTUNGSAUSSCHLUSS FÜR GEISTIGE EIGENTUMSRECHTE",
    27: "Die für die Herstellung verwendeten PCB/Produktdesign-Dokumente wurden von Kunden bereitgestellt, und die Verantwortung von Lanyue bestand darin, das PCB-Board/Produkt gemäß dem Design des Kunden herzustellen. Kunden müssen die Eigentümerschaft und die damit verbundenen geistigen Eigentumsrechte der bereitgestellten Design-Dokumente garantieren, die keine geistigen Eigentumsrechte oder andere gesetzliche Rechte Dritter, wie Patentrechte, Urheberrechte, Markenrechte usw. verletzen dürfen.",
    28: "Sollte es durch die von Kunden bereitgestellten Dokumente zu einer Verletzung oder einem illegalen Problem kommen, das uns beeinträchtigt oder schädigt, trägt der Kunde alle damit verbundenen Verantwortung, und Lanyue behält sich das Recht vor, eine Entschädigung vom Kunden zu fordern.",
    29: "Kontaktieren Sie uns",
    30: "Wenn Sie Fragen oder Kommentare zu diesen Bedingungen haben, kontaktieren Sie uns bitte unter",
  },
  //看图页面
  canvas: {
    1: {
      1: "Alles öffnen",
      2: "Alles schließen",
    },
    2: {
      1: "Größe",
      2: "Minimale Leitbreite",
      3: "Minimale Abstand der Leitungen",
      4: "Anzahl der Bohrlöcher",
      5: "Minimale Lochgröße",
      6: "Maximale Lochgröße",
      7: "ENIG-Bereich",
      8: "Testpunkt",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA&Stencil",
    2: {
      1: "Alle",
      2: "Abgebrochen",
      3: "Warten auf Zahlung",
      4: "Bestätigung durch Oceansmile",
      5: "Bezahlt",
      6: "In Bearbeitung",
      7: "Verpackt",
      8: "Versendet",
      9: "Bitte Bestellnummer eingeben",
    },
    3: {
      1: "Bestellung | Produktinformationen",
      2: "Alle kaufen",
      3: "Material",
      4: "Menge",
      5: "Preis",
      6: "Produktbeschreibung",
      7: "Dateiname",
      8: "Dateibeschreibung",
    },
    4: {
      1: "Telefon",
      2: "Firma",
      3: "Steuernummer",
      4: "Gewicht",
      5: "Lieferung",
      6: "Lieferadresse",
    },
    5: {
      1: "Bauzeit",
      2: "Zahlungszeit",
      3: "unbezahlt",
      4: "Hinweise",
      5: "Die Bauzeit wird ab dem Zeitpunkt berechnet, an dem die Zahlung erfolgt ist.",
      6: "Erstellungszeit",
    },
    6: {
      1: "Zahlungsmethode",
      2: "Produktpreis",
      3: "Provisionen",
      4: "Expressgebühr",
      5: "Sonstige Rabatte",
      6: "Gesamt",
    },
    7: "Bestellbeschreibung",
    8: "Detail ansehen",
    9: "Versandart",
    10: "Rahmen",
    11: {
      1: "Jetzt kaufen",
      2: "Rechnung",
    },
    12: "Produkte",
    13: {
      1: "Einzelpreis",
      2: "Gesamtpreis",
      3: "Benutzerbeschreibung",
    },
    14: {
      0: "Detail",
      1: "Gerber-Datei",
      2: "Gerber-Beschreibung",
      3: "Produktbeschreibung",
      4: "Platinen-Typ",
      5: "Material",
      6: "Länge",
      7: "Breite",
      8: "Schichten",
      9: "Dicke",
      10: "Fertiger Kupfer",
      11: "Minimale Lochgröße",
      12: "Lötmaske",
      13: "Seidenstrahldruck",
      14: "Bauzeit",
      15: "Halbloch",
      16: "Bestellstatus",
      17: "Produktgewicht",
      18: "Verschiedene Designs im Panel",
      19: "FR4-TG",
      20: "Menge",
      21: "Oberflächenbehandlung",
      22: "Minimale Lötmaskendämmung",
      23: "Minimale Leitbreite/Abstand",
      24: "Impedanzsteuerung",
      25: "Kostenaufstellung",
      26: "Engineering-Gebühr",
      27: "Panel",
      28: "Castellierte Löcher",
      29: "Platine",
      30: "Test",
      31: "Kupfer",
      32: "Oberflächenbehandlung",
      33: "Via-Prozess",
      34: "Blinde Vias",
      35: "Via im Pad",
      36: "V-Schnittgebühr",
      37: "Farbgeld",
      38: "Große Größe",
      39: "Impedanzsteuerung",
      40: "Rabatt",
      41: "Gesamt",
      42: "PCBA-Kosten",
      43: "Komponentenkosten",
      44: "Montagekosten",
      45: "Dienstleistungskosten",
      46: "Bom-Datei",
      47: "Bom-Beschreibung",
      48: "Komponentendetails",
      49: "Produktbestätigung",
      50: "Designator",
      51: "MPN",
      52: "Gesamtmenge",
      53: "Abfallmaterial",
      54: "Einzelpreis",
      55: "Beschreibung",
      56: "Versionen",
      57: "Rahmen",
      58: "Größe",
      59: "Stencil-Seite",
      60: "Kostenaufstellung",
      61: "SMD-Stencil",
    },
    15: {
      1: "Bestellung ergänzen",
      2: "Preisdifferenz nachbestellen",
      3: "Bestellnummer",
      4: "Preisdifferenz",
      5: "Beschreibung",
      6: "Bezahlen",
    },
    16: "PCB",
    17: "PCBA",
    18: "Stencil",
    message: {
      1: "Erfolgreich gelöscht.",
      2: "Möchten Sie die Nachzahlung für die Ergänzungsbestellung zusammen bezahlen?",
      3: "Hinweise",
      4: "Bitte wählen Sie eine Bestellung.",
      5: "Erfolgreich kopiert.",
    },
  },
  //预览价格
  preview: {
    1: "Zurück",
    2: "Lieferadresse",
    3: "Bearbeiten",
    4: "Löschen",
    5: "NEUE ADRESSE HINZUFÜGEN",
    6: {
      1: "Ob es die Standardadresse ist",
      2: "Wir können einige Probleme mit Ihren Bestellungen oder Dateien per E-Mail bestätigen. Bitte stellen Sie sicher, dass Ihre E-Mail gültig ist!",
    },
    7: "Versandmethode",
    8: "Nicht zutreffend",
    9: "Gutscheine",
    10: "Keiner",
    11: "Zahlungsmethode",
    12: "Kreditkarte",
    13: "Manuelle Banküberweisung (TT)",
    14: {
      1: "ÜBERSICHT",
      2: "Preis der Differenz",
      3: "Provision",
      4: "Produktgewicht (KG)",
      5: "Zwischensumme",
      6: "Versandkosten",
      7: "Originalpreis",
      8: "Rabattbetrag",
      9: "Zu zahlender Betrag",
      10: "Gutschein-Rabatt",
      11: "OceanSmile-Rabatt",
      12: "Jetzt kaufen",
    },
    message: {
      1: "Möchten Sie die Adresse wirklich löschen?",
      2: "Hinweis",
      3: "Erfolgreich gelöscht.",
      4: "Vorname darf nicht leer sein.",
      5: "Nachname darf nicht leer sein.",
      6: "Firma darf nicht leer sein.",
      7: "Straße darf nicht leer sein.",
      8: "Land darf nicht leer sein.",
      9: "Bundesland darf nicht leer sein.",
      10: "Stadt darf nicht leer sein.",
      11: "Postleitzahl darf nicht leer sein.",
      12: "Telefon darf nicht leer sein.",
      13: "E-Mail darf nicht leer sein.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Online-Angebot",
      2: "Gerber-Datei hochladen",
      3: "Bestellübersicht",
      4: "Zahlung",
      5: "Fertigung",
      6: "Versand",
      7: "Bestätigen und Überprüfen",
    },
    2: "PCB-Spezifikationsauswahl",
    3: {
      1: "Gerber-Dateien hinzufügen",
      2: "Erfolgreich hochgeladen",
      3: "Gerber Online-Viewer",
      4: "Die PCB-Datei wurde erfolgreich hochgeladen, aber die Parameter können nicht geparsed werden.",
      5: "Nur ZIP- oder RAR-Dateien werden akzeptiert. Maximale Größe: 50MB.",
      6: "Wenn die hochgeladene Zip-Datei nicht erfolgreich geparsed wird oder kein Bild angezeigt wird, machen Sie sich keine Sorgen, unsere Ingenieure werden es manuell überprüfen und sich später per E-Mail bei Ihnen melden.",
      7: "Für spezielle Anforderungen, die auf dieser Seite nicht verfügbar sind, oder wenn Sie etwas nicht finden können, senden Sie bitte Ihre Anforderungen und Gerber-Dateien an uns, ",
      8: "und wir werden uns innerhalb von 24 Stunden bei Ihnen melden.",
    },
    4: {
      1: "Bemerkung für Gerber",
      2: "Einzelstücke",
      3: "Panel vom Kunden",
      4: "Panel von OSpcb",
      5: "Kantenrahmen",
      6: "Nein",
      7: "Panelgröße",
      8: "Panelmenge",
      9: "Sonstiges",
      10: "Bemerkung für PCB",
    },
    5: {
      1: "PCB-Montage",
      2: "Einzelstück",
      3: "Stencil",
      4: "Teileversorgungsmodus",
      5: "Turnkey",
      6: "Kommission",
      7: "Kombination",
      8: "Bemerkung zur PCBA",
      9: "Bemerkung zur Stückliste (BOM)",
      10: "BOM-Datei hinzufügen",
      11: "BOM-Vorlage herunterladen",
      12: "Bitte laden Sie eine genaue BOM-Datei hoch, die dieser Vorlage entspricht, damit wir sie korrekt lesen können.",
      13: "MPN kann aus Digikey, Mouser, Arrow, Element oder jeder anderen Plattform gewählt werden, unser System wird dies automatisch erkennen.",
      14: "Für spezielle PCB-Montageanforderungen schreiben Sie bitte an",
      15: "Schreiben Sie diese nicht direkt in die BOM-Datei.",
      16: "Wir erkennen nur Kommas, um den Standort zu unterscheiden.",
      17: "Die Designator-Nummer sollte ≤ Menge sein.",
      18: "Bitte füllen Sie die Digikey- oder Mouser-Teilenummer nicht in der MPN (Herstellerteilenummer)-Zeile aus.",
      19: "Bitte schließen Sie keine unnötigen Komponenten in Ihre BOM-Datei ein.",
    },
    6: {
      1: "Bitte wählen Sie ein Land",
      2: "PCB-Spezifikation",
      3: "Menge",
      4: "Bauzeit",
      5: "PCB-Kosten",
      6: "Ingenieurgebühr",
      7: "Platine",
      8: "PCBA-Kosten",
      9: "Montagekosten",
      10: "Komponentenkosten",
      11: "Zwischensumme",
      12: "In den Warenkorb legen",
      13: "Arbeitstage",
    },
    str: {
      "Normal FR-4 Board": "Normales FR-4-Board",
      "Flexible Boards": "Flexible Platinen",
      "Board Type": "Platinen-Typ",
      "Single Quantity": "Einzelstück",
      "FR4-TG": "FR4-TG",
      "Single Size": "Einzelgröße",
      "Different Design in Panel": "Unterschiedliches Design im Panel",
      "Min Trace Width/Spacing": "Minimale Leitungsbreite/Abstand",
      "Min Hole Size": "Minimale Lochgröße",
      "Minimum Solder Mask Dam": "Minimale Lötmasken-Damm",
      "Half Hole": "Halbloch",
      "Surface Finish": "Oberflächenbehandlung",
      "Finished Copper": "Fertiges Kupfer",
      "Impedance Control": "Impedanzkontrolle",
      "Panel Quantity": "Panel-Menge",
      "Edge Qty": "Randmenge",
      "Solder Mask": "Lötmaske",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 Schicht",
      "2 Layer": "2 Schichten",
      "4 Layer": "4 Schichten",
      "6 Layer": "6 Schichten",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0,2mm",
      "0_25mm": "0,25mm",
      "0_3mm": "0,3mm",
      "0_4mm": "0,4mm",
      "HASL with lead": "HASL mit Blei",
      "HASL lead free": "HASL bleifrei",
      "Immersion gold": "Tauchgold",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1,0w/(m⋅K)",
      "2_0w/(m⋅K)": "2,0w/(m⋅K)",
      "0_08": "0,08",
      "0_13": "0,13",
      "0_22": "0,22",
      "0_6": "0,6",
      "0_8": "0,8",
      "1_0": "1,0",
      "1_2": "1,2",
      "1_6": "1,6",
      "2_0": "2,0",
      "0_6mm": "0,6mm",
      "0_8mm": "0,8mm",
      "1_0mm": "1,0mm",
      "1_2mm": "1,2mm",
      "1_6mm": "1,6mm",
      "2_0mm": "2,0mm",
      "0_10": "0,10",
      "0_12": "0,12",
      "0_15": "0,15",
      "0_18": "0,18",
      "0_10mm": "0,10mm",
      "0_12mm": "0,12mm",
      "0_15mm": "0,15mm",
      "0_18mm": "0,18mm",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0,5oz",
      mm: "mm",
      OSP: "OSP",
      None: "Keine",
      White: "Weiß",
      Black: "Schwarz",
      Green: "Grün",
      Red: "Rot",
      Yellow: "Gelb",
      Blue: "Blau",
      Silkscreen: "Siebdruck",
      Aluminum: "Aluminium",
      Layers: "Schichten",
      Material: "Material",
      Thickness: "Dicke",
      TCE: "TCE",
      No: "Nein",
      Yes: "Ja",
      "Single Pieces": "Einzelteile",
      "Panel By Customer": "Panel vom Kunden",
      "Panel By OSpcb": "Panel von OSpcb",
      true: "Ja",
      false: "Nein",
      Turnkey: "Turnkey",
      Consignment: "Kommission",
      Combo: "Kombination",
      Unit: "Einheit",
      Total: "Gesamt",
      Framework: "Rahmen",
      "Stencil Side": "Stencil-Seite",
      "Existing fiducials": "Bestehende Fiducials",
      Top: "Oben",
      Bottom: "Unten",
      "Top+Bottom(On Single Stencil)": "Oben+Unten (Auf Einzel-Stencil)",
      "Top & Bottom(On Separate Stencil)":
        "Oben & Unten (Auf Separatem Stencil)",
      "SIZE (mm)": "GRÖSSE (mm)",
      Quantity: "Menge",
      none: "Keine",
      "half lasered": "Halb Lasergraviert",
      "lasered through": "Durchgebohrt",
      undefined: "",
    },
    tips: {
      Material:
        "FR-4 PCB ist das am häufigsten verwendete Material. Aluminium-PCB hat eine höhere physikalische Härte und bessere Wärmeableitungsfähigkeit. Flexible PCBs sind viel dünner als andere Materialien und können in gewissem Maße gebogen werden.",
      "FR4-TG":
        "Hohe Temperaturbeständigkeit von FR-4-PCBs. Je höher der TG-Wert, desto besser die Temperaturbeständigkeit.",
      Layers:
        "Die Anzahl der Kupferschichten in Ihrer Datei, standardmäßig ist nur eine Seite der Platine mit einer Lötmaske versehen.",
      Thickness: "Die Dicke der fertigen Platine, die Sie benötigen.",
      "Solder Mask":
        "Die Farbe der Lötmaske, die auf das Basismaterial aufgetragen wird.",
      Silkscreen: "Die Farbe des Siebdrucktexts.",
      "Single Size": "Die Länge und Breite Ihrer Platine.",
      "Single Quantity": "Die Anzahl der benötigten Platinen.",
      "Panel Quantity": "Die Anzahl der benötigten Platinen.",
      "Board Type":
        "Die Platinen sind entweder nur eine einzelne Platine oder sind als Panel mit gleichen und unterschiedlichen PCBs angeordnet.",
      "Different Design in Panel":
        "Wie viele verschiedene Designs befinden sich in Ihrer Datei, getrennt durch V-Cuts, Stanzlöcher oder Fräsnuten.",
      "Min Trace Width/Spacing":
        "Die minimale Spurbreite und der minimale Abstand zwischen den Leitungen in Ihrer Datei.",
      "Min Hole Size": "Die minimale Bohrlochgröße in Ihrer Datei.",
      "Minimum Solder Mask Dam":
        "Der minimale Lötmaskendamm zwischen Kupferpads.",
      "Half Hole":
        "PTH-Löcher oder Durchkontaktierungen, die durchgeschnitten wurden, um ein teilweise Loch zu erzeugen, das eine Öffnung in die Seite des Lochs bildet. Sie werden normalerweise verwendet, um eine PCB mit einer anderen zu verbinden.",
      "Surface Finish":
        "HASL (Hot Air Solder Level) verarbeitet eine dünne Schicht Lötzinn auf die Pads. ENIG (Electroless Nickel/Immersion Gold) hat eine bessere Planarität und Haltbarkeit als HASL. Es ist auch bleifrei. Hartgold wird für Goldfinger oder andere Bereiche mit hohem Abrieb verwendet.",
      "Finished Copper":
        "Die Dicke des Kupfers auf der äußeren Schicht (oben und unten).",
      TCE: "Thermischer Ausdehnungskoeffizient, ein Materialmerkmal, das angibt, wie sehr sich ein Material bei Erwärmung ausdehnt.",
      Framework:
        "Stellen Sie sicher, dass der Rahmen richtig ausgerichtet und sicher positioniert ist.",
      "Stencil Side":
        "Überprüfen Sie die Stencil-Seite auf korrekte Ausrichtung vor der Verwendung.",
      "Existing fiducials":
        "Überprüfen Sie die Positionierung der bestehenden Fiducials auf Ausrichtungsgenauigkeit.",
    },
    7: {
      1: "SMD-Stencil",
      2: "Gültiger Bereich",
      3: "Stencil-Kosten",
      4: "Stencil-Typ",
      5: "Größe",
      6: "Seite",
      7: "Gewicht",
      8: "Kostenübersicht",
      9: "SMD-Stencil",
    },
    message: {
      1: "Wenn die Daten das Systemlimit überschreiten, senden Sie bitte die Designdatei direkt an die angegebene E-Mail-Adresse.",
      2: "Wir werden Ihnen innerhalb von 24 Stunden manuelles Feedback und ein Angebot zukommen lassen.",
      3: "Bitte geben Sie die Breite der Platine ein.",
      4: "Bitte geben Sie die Länge der Platine ein.",
      5: "Bitte geben Sie die Menge der Platinen ein.",
      6: "Bitte laden Sie die Gerber-Datei hoch.",
      7: "Bitte laden Sie die BOM-Datei hoch.",
    },
  },
  //人工转账页面
  pay: {
    1: "Ihre Zahlungsanforderung wurde akzeptiert.",
    2: "Bitte tätigen Sie die Überweisung per Bankkonto. Die Zahlung wird abgeschlossen, sobald die Überweisung abgeschlossen ist. Gesamtzahlung",
    3: "Unterstützt Geschäftskonto & Privatkonto Überweisung",
    4: "1. Wenn Ihr Zahlungskonto ein Geschäftskonto ist, zahlen Sie bitte auf dieses Konto",
    5: "2. Wenn Ihr Zahlungskonto ein Privatkonto ist, zahlen Sie bitte auf dieses Konto",
    6: "Begünstigtenbank",
    7: "Adresse der Begünstigtenbank",
    8: "SWIFT-Code",
    9: "Vollständiger Name des Begünstigten",
    10: "Kontonummer des Begünstigten",
    11: "Adresse und Telefonnummer des Begünstigten",
    12: "Zwischenbank",
    13: "SWIFT-Code (Zwischenbank)",
    14: "Adresse der Zwischenbank",
    15: {
      1: "Tipps zur Überweisung",
      2: "1. Bitte senden Sie nach der Überweisung die Ospcb-Bestellnummer und eine Kopie des Überweisungsbelegs an service@cnospcb.com, damit wir mit der Produktion rechtzeitig beginnen können.",
      3: "2. Bitte stellen Sie sicher, dass Sie die korrekten Bankkontoinformationen bei der Überweisung eingeben.",
      4: "3. Überweisen Sie den gesamten Betrag in einer Zahlung. Teilen Sie die Zahlung nicht in mehrere Überweisungen auf.",
      5: "4. Bewahren Sie Ihren Überweisungsbeleg für spätere Rückfragen auf.",
      6: "Tipps zur finanziellen Sicherheit",
      7: "1. Unsere Mitarbeiter werden nur mit der E-Mail-Adresse mit der Domain cnospcb.com mit Ihnen in Kontakt treten. Vertrauen Sie daher keiner E-Mail-Adresse mit einer anderen Domain.",
      8: "2. Wenn sich die Bankkontoinformationen ändern, werden wir dies im Voraus auf der Website bekannt geben. Überweisen Sie daher kein Geld auf ein anderes Bankkonto als das auf dieser Seite angegebene.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Hinweis: Es kann zu einer Verzögerung bei der Zahlung kommen. Wenn sie lange nicht angekommen ist, wenden Sie sich bitte an den Kundenservice",
    2: "Automatische Weiterleitung in",
    3: "Sekunden",
    4: "jetzt",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Bestellnummer",
      2: "Zahlung empfangen",
      3: "Zahlungsart",
    },
    2: {
      1: "Bestellnummer",
      2: "Zahlungsbetrag",
      3: "Währungen",
      4: "Zahlungsart",
      5: "Zahlungs-ID",
      6: "Zahlungs-E-Mail",
      7: "Zahlungszeit",
      8: "Zahlung empfangen",
      9: "Zahlungsnachweis",
      10: "Aktion",
    },
    3: {
      1: "PDF herunterladen",
      2: "Warten auf Upload",
      3: "Hochladen",
    },
    message: {
      1: "Überweisungsbeleg erfolgreich hochgeladen. Bitte warten Sie geduldig auf die Überprüfung durch das Personal!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA & Stencil",
      2: "ALLE",
      3: "PCB",
      4: "PCBA",
      5: "Stencil",
      6: "Bitte Dateinamen eingeben",
    },
    2: {
      1: "Produkte",
      2: "Alle kaufen",
      3: "Material",
      4: "Menge",
      5: "Preis",
      6: "Produktstatus",
      7: "Produktbeschreibung",
      8: "Dateiname",
      9: "Dateibeschreibung",
      10: "Erstellungszeit",
      11: "Gesamt",
    },
    3: "Bauzeit",
    4: "Details anzeigen",
    5: "Jetzt kaufen",
    6: "Erstellungszeit",
    7: "Gesamt",
    8: "Wartet auf Bestätigung",
    9: "Liefermodus",
    10: {
      1: "Schlüsselwort",
      2: "Produktname",
      3: "Batterie enthalten",
      4: "Gesamtgewicht",
      5: "Stückpreis",
      6: "Gesamtpreis",
      7: "Status",
      8: "Beschreibung",
      9: "Kaufen",
      10: "Löschen",
      11: "Aktion",
    },
    statusMap: {
      0: "Entfernen",
      1: "Wartet auf Angebot",
      2: "Wartet auf Bestätigung durch den Kunden",
      3: "Kaufbar",
      4: "Zur Bestellung eingereicht",
      undefined: "",
    },
    message: {
      1: "Bitte wählen Sie den Artikel aus, den Sie bezahlen möchten!",
      2: "Möchten Sie dieses Produkt wirklich löschen?",
      3: "Hinweis",
      4: "Löschen",
      5: "Erfolgreich gelöscht.",
      6: "Möchten Sie zusammen mit der Nachbestellung bezahlen?",
      7: "Änderungen der Komponenten bestätigt.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Nachbestellnummer",
      2: "Produktnummer",
      3: "Status",
      4: "Zahlungsart",
      5: "Produkttyp",
      6: "Alle bezahlen",
    },
    2: {
      1: "Bestellnummer",
      2: "Produkttyp",
      3: "Produktnummer",
      4: "Betrag",
      5: "Zahlungsart",
      6: "Provision",
      7: "Gesamt",
      8: "Erstellungszeit",
      9: "Nachbestellstatus",
      10: "Aktion",
    },
    3: {
      1: "Ansehen",
      2: "Bezahlt",
      3: "Rechnung",
    },
    4: {
      1: "Details",
      2: "Nachbestellnummer",
      3: "Nachbestellstatus",
      4: "Bestellnummer",
      5: "Produkttyp",
      6: "Produktnummer",
      7: "Zahlungsart",
      8: "Servicegebühr",
      9: "Gesamtbetrag",
      10: "Zahlungszeit",
      11: "Beschreibung",
      12: "Warten auf Berechnung",
      13: "Nicht vorhanden",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Stencil",
      4: "Expressgebühr",
    },
    message: {
      1: "Bitte wählen Sie eine Bestellung aus.",
    },
  },
  //地址
  address: {
    1: {
      1: "Bearbeiten",
      2: "Löschen",
    },
    2: "NEUE ADRESSE HINZUFÜGEN",
    3: "ADRESSE BEARBEITEN",
    4: {
      1: "Vorname",
      2: "Nachname",
      3: "Firma",
      4: "Straße",
      5: "Bitte wählen Sie ein Land",
      6: "Bundesländer",
      7: "Stadt",
      8: "Postleitzahl",
      9: "Telefon",
      10: "E-Mail",
      11: "Steuernummer",
      12: "Ist dies die Standardadresse?",
      13: "Wir können uns per E-Mail mit Ihnen in Verbindung setzen, um Probleme mit Ihren Bestellungen oder Dateien zu klären. Bitte stellen Sie sicher, dass die E-Mail korrekt ist.",
    },
  },
  //优惠券
  coupon: {
    1: "Meine Gutscheine",
    2: "Gutschein",
    useOfTypeMap: {
      1: "verwendbar",
      2: "in Nutzung",
      3: "genutzt",
      4: "abgelaufen",
    },
    consumptionTypeMap: {
      0: "Unbegrenzt",
      1: "Für PCB",
      2: "Für PCBA",
      3: "Für Schablone",
    },
    useTypeMap: {
      1: "Gesamtkosten",
      2: "Versandkosten",
    },
  },
  //信函
  letter: {
    1: {
      1: "ALLE",
      2: "Beschwerde",
      3: "Feedback",
      4: "Vorschlag",
      5: "Bestellung",
    },
    2: "Erstellen",
    3: {
      1: "Seriennummer",
      2: "Titel",
      3: "Typ",
      4: "Inhalt",
      5: "Ansehen",
      6: "Erstellungszeit",
      7: "Endzeit",
      8: "Nachrichtenzustand",
      9: "Geliefert",
      10: "Gesehen",
      11: "Fortschrittsrate",
      12: "Nachrichten",
      13: "Aktion",
      14: "Zurückschreiben",
      15: "Typ auswählen",
    },
    4: "Inhaltsdetails",
    5: "Nachrichtendetails",
    6: "Antworten",
    7: {
      1: "Empfänger",
    },
    8: {
      1: "Inhalt der Antwort",
    },
    9: {
      1: "Erstellen",
      2: "Kommunikation",
      3: "Abschließen",
    },
    10: {
      1: "Eigenständig",
      2: "Service",
    },
    message: {
      1: "Titel darf nicht leer sein.",
      2: "Typ darf nicht leer sein.",
      3: "Inhalt darf nicht leer sein.",
      4: "Erfolgreich erstellt!",
      5: "Erfolgreich beantwortet.",
    },
  },
  //公告
  notice: {
    1: "Titel",
    2: "Team",
    3: "Veröffentlichungszeit",
    4: "Aktion",
    5: "Details",
    detail: {
      1: "Zurück",
      2: "Systembenachrichtigung",
      3: "Aktivitätsbenachrichtigung",
    },
  },
  //个人信息
  profile: {
    1: "Profil",
    2: {
      1: "Benutzername",
      2: "Kontotyp",
      3: "Avatar",
      4: "Avatar aktualisieren",
      5: "Firmenname",
      6: "Steuernummer",
      7: "Telefonnummer",
      8: "Facebook",
      9: "Skype",
      10: "WhatsApp",
      11: "Aktualisieren",
    },
    3: {
      1: "Passwort aktualisieren",
      2: "Aktuelles Passwort",
      3: "Neues Passwort",
      4: "Passwort bestätigen",
    },
    4: {
      1: "Speichern",
      2: "Abbrechen",
    },
    5: {
      1: "persönlich",
      2: "Unternehmen",
    },
    message: {
      1: "Neues Passwort und Bestätigung stimmen nicht überein.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Kreditkarte",
    3: "Manuelle Banküberweisung (TT)",
    4: "Andere",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "Erfolg!",
    "-1": "Unbekannter Fehler!",
    401: "Login abgelaufen, bitte erneut anmelden!",
    403: "Autorisierungsprüfung fehlgeschlagen!",
    10000:
      "Systemfehler! Bitte senden Sie rechtzeitig Feedback an den Administrator! Vielen Dank!",
    10001: "Die Dateigröße überschreitet das obere Limit!",
    10002: "Bitte wählen Sie die Bestellung aus, die Sie bezahlen möchten.",
    10003: "Bestellungsfehler!",
    10004:
      "Die Dateigröße überschreitet das obere Limit! Maximale Dateigröße 50MB!",
    10005: "Eine Bestellung wurde für diesen Artikel erstellt!",
    10006:
      "Bitte laden Sie die Gerber-Datei des Stencils hoch! Nur ZIP- oder RAR-Dateien werden akzeptiert. Maximale Größe 50MB!",
    10007: "Der Preis für die Form konnte nicht gefunden werden!",
    10008:
      "Bei der Auswahl Top & Bottom (auf separatem Stencil) darf die Menge nicht weniger als 2 betragen!",
    10009:
      "Das derzeit ausgewählte Produkt existiert nicht oder wurde deaktiviert!",
    10010:
      "Der Verkaufspreis dieses Produkts wurde noch nicht bekannt gegeben!",
    10011: "Ausverkauft, kann nicht zum Warenkorb hinzugefügt werden!",
    10012: "Der aktuelle Warenkorb-Artikel existiert nicht!",
    10013:
      "Der Verkaufspreis dieses Produkts wurde noch nicht bekannt gegeben!",
    10014:
      "Der aktuelle Lagerbestand des Produkts ist geringer als die angepasste Menge, bitte passen Sie die Menge an und versuchen Sie es erneut!",
    10015: "Der Artikel existiert nicht oder wurde gelöscht!",
    10016: "Bitte wählen Sie den Kommentar aus, auf den Sie antworten möchten!",
    10017: "Dieser Kommentar existiert nicht oder wurde entfernt!",
    10018: "Die UUID existiert nicht!",
    10019: "Bitte wählen Sie den Kommentar aus, den Sie mögen möchten!",
    10020: "Dieser Kommentar existiert nicht oder wurde entfernt!",
    10021:
      "Bitte wählen Sie den Kommentar aus, den Sie nicht mehr mögen möchten!",
    10022:
      "Bitte wählen Sie die Forenartikel-Details aus, die Sie ansehen möchten!",
    10023: "Die ausgewählte Forenkategorie existiert nicht!",
    10024: "Bitte wählen Sie den Artikel aus, den Sie löschen möchten!",
    10025: "Bitte wählen Sie den Artikel aus, den Sie mögen möchten!",
    10026:
      "Bitte wählen Sie den Artikel aus, den Sie nicht mehr mögen möchten!",
    10027:
      "Bitte wählen Sie den Artikel aus, den Sie aus der Sammlung entfernen möchten!",
    10028: "Bitte wählen Sie das Produkt, um die Gerber-Version zu sehen!",
    10029: "Bitte wählen Sie das Produkt, um eine Bestellung zu generieren!",
    10030: "Derzeit sind keine Bestellungen zur Zahlung verfügbar!",
    10031: "Die Zahlung war nicht erfolgreich!",
    10032:
      "Bitte laden Sie die PCB-Gerber-Datei hoch. Nur ZIP- oder RAR-Dateien werden akzeptiert. Maximale Größe 50MB!",
    10033:
      "Bitte laden Sie die PCBA-BOM-Datei hoch. Nur .CSV, .XLS oder .XLSX-Dateien werden akzeptiert!",
    10034: "Das Panel By Customer x darf nicht leer sein!",
    10035: "Das Panel By Customer y darf nicht leer sein!",
    10036: "Das Panel By Customer x darf 500 mm nicht überschreiten!",
    10037: "Das Panel By Customer y darf 500 mm nicht überschreiten!",
    10038: "Die Kantenleisten dürfen nicht leer sein!",
    10039: "Die Panelgröße darf nicht leer sein!",
    10040: "Der FR4 Tg darf nicht leer sein!",
    10041: "Der FR4 Mindest-Lötmaskenrahmen darf nicht leer sein!",
    10042: "Die FR4 Impedanzkontrolle darf nicht leer sein!",
    10043: "Die Anzahl der FR4-Halbohrkanten ist falsch!",
    10044: "Der TCE des Aluminiums darf nicht leer sein!",
    10045: "Die PCBA-Menge darf nicht 0 sein!",
    10046: "Stencil ist für PCBA erforderlich!",
    10047: "Der Teileversorgungmodus darf nicht leer sein!",
    10048: "Die Adress-ID darf nicht leer sein!",
    10049: "Das alte Passwort ist falsch!",
    10050: "Das E-Mail-Konto existiert nicht!",
    10051: "Die CAPTCHA ist abgelaufen!",
    10052: "Die CAPTCHA ist falsch!",
    10053: "Das E-Mail-Format ist ungültig!",
    10054: "Diese E-Mail-Adresse wurde bereits registriert!",
    10055: "Benutzername oder Passwort ist falsch!",
    10056: "Bitte wählen Sie Nachrichten aus, die als gelesen markiert sind!",
    10057: "Die Zahlungsmethode darf nicht leer sein!",
    10058: "Der Zahlungseintragstyp darf nicht leer sein!",
    10059: "Die Bestell-ID darf nicht leer sein!",
    10060: "Bitte wählen Sie die Bestellung aus, die Sie bezahlen möchten!",
    10061: "Ihre Bestellung wurde bearbeitet!",
    10062: "Der Preis für die Form konnte nicht gefunden werden!",
    10063: "Der aktuelle Vorlagenpreis konnte nicht abgerufen werden!",
    10064:
      "Ihre Bestellung wartet auf Bestätigung oder wurde bereits bearbeitet!",
    10065: "Bitte wählen Sie das Produkt aus, für das Sie bezahlen möchten!",
    10066:
      "Die ausgewählten Waren sind in abnormalem Zustand oder wurden bereits bezahlt!",
    10067:
      "Das ausgewählte PCB-Produkt enthält ein Produkt, für das bereits eine Bestellung generiert wurde!",
    10068:
      "Ihre Bestellung wartet auf Bestätigung oder wurde bereits bearbeitet!",
    10069:
      "Das ausgewählte PCBA-Produkt enthält ein Produkt, für das bereits eine Bestellung generiert wurde!",
    10070:
      "Das ausgewählte Stencil-Produkt enthält ein Produkt, für das bereits eine Bestellung generiert wurde!",
    10071: "Bitte wählen Sie das, was Sie ansehen möchten!",
    10072:
      "Das E-Mail-Format ist ungültig. Bitte geben Sie eine gültige E-Mail ein!",
    10073: "Der Bestätigungscode existiert nicht!",
    10074: "Der Bestätigungscode ist falsch, bitte versuchen Sie es erneut!",
    10075: "Bitte wählen Sie das Produkt, um die BOM-Version zu sehen!",
    10076: "Bitte wählen Sie die Bestellung aus, die Sie bestätigen möchten!",
    10077:
      "Diese Aktion kann aufgrund des abnormalen Produktstatus nicht durchgeführt werden!",
    10078: "Bitte wählen Sie die Markendetails aus, die Sie ansehen möchten!",
    10079:
      "Bitte wählen Sie den Artikel aus, den Sie nicht mehr mögen möchten!",
    10080:
      "Nur Benutzer, die dieses Produkt gekauft haben, können eine Bewertung hinterlassen!",
    10081: "Sie haben dieses Produkt bereits bewertet!",
    10082: "Bitte wählen Sie die Produktdetails aus, die Sie ansehen möchten!",
    10083: "Die Bestellnummer darf nicht leer sein!",
    10084: "Bestellungen dürfen nicht leer sein!",
    10085: "Kreditkartenzahlung fehlgeschlagen!",
    10086:
      "Geben Sie die Bestellnummern an, um den Überweisungsbeleg zu erstellen!",
    10087:
      "Das ausgewählte Produkt enthält ein Produkt, für das bereits eine Bestellung generiert wurde!",
    10088:
      "Die folgenden Artikel können aufgrund von Bestandsmangel nicht gekauft werden!",
    10089:
      "Aufgrund unzureichender Bestände können keine Bestellungen generiert werden!",
    10090: "Netzwerkfehler, bitte versuchen Sie es später erneut!",
    10091: "Das aktuelle Feedback wartet auf Bearbeitung!",
    10092: "Das aktuelle Feedback wurde bearbeitet!",
    10093:
      "Bitte wählen Sie die Ankündigungsdetails aus, die Sie anzeigen möchten!",
    10094:
      "Bitte wählen Sie einen Standort aus, um das Karussell-Diagramm zu durchsuchen!",
    10095: "Die aufgerufene Ressource existiert nicht!",
  },
};
