<template>
  <div id="app" v-loading="$store.state.api_loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <router-view></router-view>
  </div>
</template>

<script>
  import { recordVisit } from "@/api/api";
  export default {
    name: 'App',

    // 全站统计 每天每用户统计一次
    mounted() {
      this.recordVisitIfFirst();
    },
    methods: {
      recordVisitIfFirst() {
        // 获取当前日期，格式为 yyyy-MM-dd
        const today = new Date().toISOString().slice(0, 10);
        // 从 LocalStorage 中检查当天是否已经有访问记录
        const hasVisitedToday = localStorage.getItem('visited_' + today);
        if (!hasVisitedToday) {
          // 如果没有访问过，调用统计 API
          this.recordVisit();
          // 记录当天已经访问过
          localStorage.setItem('visited_' + today, 'true');
        }
      },
      recordVisit() {
        recordVisit().then((res) => {
          if (res && res.code == 200) {
          }
        }).catch((err) => { })
      }
    }
  }
</script>

<style>
  #app {
    font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  body,
  html,
  p,
  h1,
  h2 {
    padding: 0;
    margin: 0;
  }

  .table .cell {
    text-align: center;
  }

  .green {
    color: #00a63f !important;
  }

  .table .cell {
    text-align: center;
    font-size: 12px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .table .table-pagination {
    margin-top: 20px;
    text-align: right;
  }

  table {
    border-collapse: collapse;
  }

  table th {
    /* background-color: #f5f2f0; */
    font-weight: 700;
    text-align: center;
    border: 1px solid #ccc;
    line-height: 1.5;
    min-width: 30px;
    padding: 3px 5px;
  }
  table td {
    border: 1px solid #ccc;
    line-height: 1.5;
    min-width: 30px;
    padding: 3px 5px;
    text-align: center;
  }

  .el-form-item__content .w-e-full-screen-container {
    z-index: 1;
    width: 1300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @import "assets/font/font.css";
  @import url(https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,600i,700,700i,800,800i|PT+Sans:400,400i,700,700i);
</style>
