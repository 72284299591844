import router from '../../router'


const state = {
    RouteName: '',
    isCollapse: false,
    router_true: true, //路由是否跳转
    data_no_router: false, //路由弹框
    to_path: '',
};

const actions = {
    RouterAuth: ({
        commit
    }) => {
        router.beforeEach((to, from, next) => {
            commit;

            if (state.router_true == false) {
                state.data_no_router = true;
                state.to_path = to.path;
                return;
            }

            let token = sessionStorage.getItem('token');

            state.RouteName = to.path;
            if (Object.is(to.name, 'login')) {
                if (token) {
                    next('/');
                } else {
                    next();
                }
                return
            }

            if (token) {
                next();
            } else {
                next({
                    path: '/login'
                });
            }

        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
}