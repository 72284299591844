import axios from 'axios'
import store from '@/store';
import router from '../router/index';
import ismessage from './message';

import message from './message'; //消息提示框

let loginTimeOut = null;

const service = axios.create({
    
    // baseURL: process.env.VUE_APP_API_ENDPOINT,
    //正式服
    baseURL: 'https://www.lyenable.com/api',
    //以下是测试环境
    // baseURL: 'http://8.140.252.40:8999',
    // baseURL: 'http://10.2.2.173:8999',
    timeout: 6000000
    
})

// 不需要token的接口
const noTokens = [
    '/login', 
    '/register',
    '/register/code',
    '/user/update/password/getCode',
    '/user/update/password/notLogin',
    '/payment/paypal/timely/notify',
    '/payment/stripe/timely/notify',
    '/shop/extra/price/paypal',
    '/shop/extra/price/stripe',
    '/shop/product/list',
    '/shop/file/upload',
    '/shop/file/upload/gerber',
    '/pcb/config/detail/pcb',
    '/pcb/config/detail/pcba',
    '/pcb/config/detail/stencil',
    '/express/delivery/company/list',
    '/express/delivery/country/list',
    '/help/list',
    '/news/list',
    '/forums/article/list',
    '/forums/category',
    '/forums/tags',
];

service.interceptors.request.use(function (config) {
    if(store.state.is_api_loading && config.url != '/shop/file/upload/gerber' && config.url != '/payment/paypal/timely/notify' && config.url != '/payment/stripe/timely/notify'){
        store.state.api_loading = true;
    }
    
    const u_id = sessionStorage.getItem("userid") || '';
    if(u_id){
        config.headers['userid'] = u_id;
    }
    
    const url = config.url;
    const token = sessionStorage.getItem("token") || '';
    if (token && url && (noTokens.indexOf(url) < 0 || url == '/forums/article/list')) {
      config.headers['Authorization'] = token;
    }

    return config;
}, function (error) {
    if(error){
        if(error.response&&(error.response.status!=='undefined')){
            if (error.response.status == 500 || error.response.status == 502 || error.response.status == 404) {
                setTimeout(() => {
                    store.state.api_loading = false;
                    // ismessage("error", error.response.status+" - "+error.response.statusText);
                }, 1000);
            }else{
                store.state.api_loading = false;
                // ismessage("error", 'Network timeout');
            }
        }else{
            setTimeout(() => {
                store.state.api_loading = false;
                // ismessage("error", 'Network timeout');
            }, 1000);
        }
    }else{
        setTimeout(() => {
            store.state.api_loading = false;
            // ismessage("error", 'Network timeout');
        }, 1000);
    }

    setTimeout(() => {
        store.state.api_loading = false;
    }, 1000)
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(function (response) {
    store.state.api_loading = false;
    if(router.currentRoute.path != "/login"&&router.currentRoute.path != "/sys/file/upload"){
        if(response.data.code == 401){
            // message("error", response.data.msg);
            // 当前登录已失效，请重新登录
            // localStorage.clear();
            sessionStorage.clear();
            // 在注销或401时
            store.commit('SET_LOGGED_IN', false);
            if (loginTimeOut) {
                clearTimeout(loginTimeOut);
            }
            loginTimeOut = setTimeout(() => {
                // location.reload();
                if (router.currentRoute.path != "/login") {
                    router.push({name: 'login'});
                }
            }, 1000);
            this.$forceUpdate();
        }
    }
    if (response.status === 200 && response.config.url != '/shop/order/invoice') {
        return response.data;
    } else {
        return response;
    }
}, function (error) {
    if(error){
        if(error.response&&(error.response.status!=='undefined')){
            if (error.response.status == 500 || error.response.status == 502 || error.response.status == 404) {
                setTimeout(() => {
                    store.state.api_loading = false;
                    // ismessage("error", error.response.status+" - "+error.response.statusText);
                }, 1000);
            }else{
                store.state.api_loading = false;
                // ismessage("error", 'Network timeout');
            }
        }else{
            setTimeout(() => {
                store.state.api_loading = false;
                // ismessage("error", 'Network timeout');
            }, 1000);
        }
    }else{
        setTimeout(() => {
            store.state.api_loading = false;
            // ismessage("error", 'Network timeout');
        }, 1000);
    }

    setTimeout(() => {
        store.state.api_loading = false;
    }, 1000)
    return Promise.reject(error);
});

export default service