/**
 * 俄语语言包
 */
export default {
  1: "Отмена",
  2: "Поиск",
  3: "Сбросить",
  4: "Отправить",
  5: "Успех.",
  6: "Содержание...",
  //每个页面的标签title
  title: {
    1: "Условия и положения | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "Прототип PCB | Сборка PCBA | Платформа для производства инновационных и гибких электронных продуктов | Oceansmile | Lanyue Electronics",
    3: "Регистрация | Вход | Oceansmile | Lanyue Electronics",
    4: "Сброс пароля | Восстановление пароля | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "Техническая поддержка и услуги PCB | Электронный дизайн | Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Новости отрасли и центр обучения | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Новости отрасли и центр обучения | Oceansmile | Lanyue Electronics",
    9: "CNC работы | Металлические детали | 3D печать | Литье под давлением | Индивидуальные детали | Lanyue Electronics",
    10: "SMT | Сервис сборки PCB | Быстрая онлайн-оценка PCB | Oceansmile | Lanyue Electronics",
    11: "Возможности производства PCB | Оживите свои идеи | Oceansmile | Lanyue Electronics",
    12: "О нас - Оживите свои идеи | Прототип PCB | Oceansmile | Lanyue Electronics",
    13: "Контакты - Оживите свои идеи | Прототип PCB | Oceansmile | Lanyue Electronics",
    14: "Почему выбрать нас - Оживите свои идеи | Индивидуальный прототип PCB | Oceansmile | Lanyue Electronics",
    15: "Быстрая онлайн-оценка PCB | Сервис сборки PCB | Oceansmile | Lanyue Electronics",
    16: "Модель SMT | Сервис сборки PCB | Oceansmile | Lanyue Electronics",
    17: "Детали счета | Oceansmile | Lanyue Electronics",
    18: "Банковская информация | Банковский перевод | Oceansmile | Lanyue Electronics",
    19: "Поздравляем! Платеж прошел успешно | Oceansmile | Lanyue Electronics",
    20: "Продукт | Магазин продуктов",
    21: "Детали продукта | Магазин продуктов",
    22: "Компоненты | IC Easy Store | Поставки компонентов",
    23: "Просмотр Gerber онлайн | Oceansmile | Lanyue Electronics",
    24: "Корзина | Oceansmile | Lanyue Electronics",
    25: "Список заказов | Oceansmile | Lanyue Electronics",
    26: "Список уведомлений по заказам | Oceansmile | Lanyue Electronics",
    27: "Управление адресом доставки | Oceansmile | Lanyue Electronics",
    28: "Личная информация | Oceansmile | Lanyue Electronics",
    29: "Список новых заказов",
    30: "Купоны",
    31: "Жалобы и комментарии",
    32: "Список объявлений | Oceansmile | Lanyue Electronics",
    33: " | Детали объявления",
    34: "Записи платежей | Проверка",
    35: "Новости отрасли и центр обучения | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Новости отрасли и центр обучения | Oceansmile | Lanyue Electronics",
    37: " | Форум личного кабинета | Новости отрасли и центр обучения | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Центр помощи",
    2: "Отправить сообщение",
    3: "WhatsApp/горячая линия",
    4: "Моя корзина",
    5: "Добро пожаловать в Oceansmile",
    6: "Войти",
    7: "Новый клиент?",
    8: "Зарегистрируйтесь бесплатно",
    9: "Электронная почта",
    10: "Выйти",
    11: "Корзина",
    12: "Мои заказы",
    13: "Уведомления о статусе заказа",
    14: "Мои купоны",
    15: "Адрес доставки",
    16: "Мой профиль",
    17: "Письмо",
    18: "Уведомления",
    19: "Главная",
    20: "Сборка PCB",
    21: "Быстрая оценка PCB/PCBA",
    22: "Быстрая оценка моделей",
    23: "Магазин продуктов",
    24: "Новости и блог",
    25: "CNC работа/3D печать",
    26: "Продукты и возможности",
    27: "О нас",
    28: "Контакты",
    29: "Почему выбрать нас",
    30: "Быстрая оценка PCB",
  },
  //脚部页面
  footer: {
    1: {
      1: "БЫСТРЫЕ ССЫЛКИ",
      2: "Оставьте ваше сообщение ниже, и один из наших представителей свяжется с вами в течение 48 часов.",
      3: "Введите ваш e-mail",
      4: "Подписаться",
    },
    2: {
      1: "Более 10 лет опыта в быстрой разработке прототипов PCB и производстве PCB и PCBA малых и средних серий. Удовлетворение клиентов за счет высокого качества и обслуживания...",
      2: "Адрес",
      3: "Сообщество Соньюаньша, улица Гуанху, район Лонгхуа, Шэньчжэнь, Гуандун, Китай",
      4: "Телефон",
      5: "Электронная почта",
      6: "Сертифицировано",
    },
    3: {
      1: "Компетенции",
      2: "Продукты/Услуги",
      3: "Поддержка клиентов",
      4: "О нас",
      5: "Компетенции PCB",
      6: "Быстрая оценка PCB",
      7: "Контакты",
      8: "О нас",
      9: "Компетенции сборки PCB",
      10: "Сборка PCB",
      11: "Центр помощи",
      12: "Почему покупать у нас?",
      13: "Модели SMD",
      14: "Наши оборудование",
      15: "Персонализированное проектное консультирование",
      16: "Гид по методам доставки",
      17: "Компетенции PCB из алюминия",
      18: "Промышленные решения",
      19: "Отзывы",
      20: "Политика конфиденциальности и соглашения",
      21: "Гибкие решения для PCB",
      22: "Политика возврата",
      23: "3D печать/CNC",
      24: "Условия использования",
    },
    4: "Copyright © 2024 OSpcb. Все права защищены. Lanyue Electronics (Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Личный кабинет",
    2: "Заказ",
    3: "Мой аккаунт",
    4: "Система",
    5: "Корзина",
    6: "Мои заказы",
    7: "Долговечность",
    8: "Уведомления о заказах",
    9: "Записи платежей",
    10: "Адрес",
    11: "Мои купоны",
    12: "Мой профиль",
    13: "Письмо",
    14: "Уведомления",
  },
  //aboutUs页面
  aboutUs: {
    1: "Oceansmile — это комплексная платформа для быстрой и инновационной производства электронных продуктов в Шэньчжэне. С более чем 15-летним опытом производства печатных плат (PCB) и сборки на их основе (PCBA) в Китае, мы предлагаем полный спектр услуг по прототипированию и быстрому производству. Мы помогаем нашим клиентам выводить их продукты на рынок вовремя, с полными ресурсами, конкурентоспособными ценами, надежными материалами и исключительным качеством. В Oceansmile работает более 200 человек, и наша ежемесячная мощность производства составляет более 3 000 образцов или малых партий. Мы обслуживаем более 150 000 клиентов в более чем 250 странах по всему миру. Нет минимальных заказов. Мы предлагаем индивидуальное обслуживание и комплексные решения для различных промышленных приложений. Как один из самых опытных производителей Китая, мы — ваш лучший бизнес-партнер и друг по всем вопросам проектных нужд. Ориентация на клиента и адаптивность. Мы растем вместе с вами и подстраиваемся под ваши потребности.",
    2: {
      1: "Гибкость",
      2: "Стоимость",
      3: "Гарантия качества",
      4: "Невозможное не существует",
    },
    3: {
      1: "Онлайн-управление заказами и производственными процессами",
      2: "Быстрая доставка",
      3: "Полные возможности цепочки поставок",
      4: "Быстрая реакция",
    },
    4: {
      1: "Онлайн-оценка стоимости, прозрачные цены",
      2: "Без скрытых сборов или дополнительных расходов",
      3: "Низкие затраты на малые партии",
    },
    5: {
      1: "Строгие производственные процессы",
      2: "Профессиональная команда инженеров (более 5 лет опыта)",
      3: "Высокопроизводительное оборудование",
      4: "Надежные поставщики",
      5: "Комплексный опыт по продуктам",
    },
    6: {
      1: "Без ограничений на услуги",
      2: "Ориентация на клиента и адаптивность",
    },
    7: {
      1: "Мы предоставляем полный спектр услуг по прототипированию и быстрому производству. Это не только PCB/PCBA!",
      2: "Печатные платы / Сборка PCB / РЕШЕНИЯ ДЛЯ ПРОМЫШЛЕННОГО IoT / Тестирование и индивидуальные программы тестирования / Поставка компонентов / Механическая обработка (металлы, силикон, пластик, дерево) / Литье под давлением / Помощь в проектировании PCB и компоновке / Корректировка батарей / Дизайн продуктов / OEM / ODM",
    },
    8: "Oceansmile имеет три крупных завода, специализирующихся на прототипировании PCB, массовом производстве печатных плат и сборке PCB, а также производстве механических компонентов. С общей площадью в 15 000 квадратных метров и 6 линиями SMT, мы фокусируемся на валидации продуктов на стадии разработки у наших клиентов (прототипы) и обеспечиваем достаточно мощностей для массового производства.",
    9: "Доверие крупнейших брендов мира",
    10: "Oceansmile стремится удовлетворять потребности наших клиентов (более 15 000) в разных отраслях и странах по вопросам качества, поставок, стоимости и других требований. Как один из самых опытных производителей PCB/PCBA в Китае, мы заключили партнерства с многими мировыми брендами.",
    11: {
      1: "Оплаченные клиенты",
      2: "Страны и регионы",
      3: "Бизнес-партнеры",
    },
    12: "Почему выбрать Oceansmile?",
  },
  //联系我们页面
  contact: {
    1: "Связаться с нами",
    2: {
      1: "Вопросы по продажам и продуктам",
      2: "Oceansmile — это комплексная платформа для быстрой и инновационной производства электронных продуктов в Шэньчжэне. Мы предлагаем быстрые услуги прототипирования PCB и сборки на их основе. Наша команда может ответить на ваши вопросы о возможностях PCB, ценах на PCB и PCBA, механических компонентах из силикона/пластика... и любых других аспектах ваших проектных нужд. Оформить заказ в Oceansmile легко, так как мы предлагаем онлайн-оценку стоимости для стандартных PCB, гибких / жестко-гибких PCB, SMT-ламелей и сборки PCB с бесплатной проверкой файлов Gerber, гарантируя доставку вовремя через DHL, FedEx, UPS и других.",
      3: "Пожалуйста, оставьте свое сообщение ниже, и наш представитель свяжется с вами в ",
      4: "течение 24 часов.",
    },
    3: {
      1: "Имя",
      2: "Электронная почта",
      3: "Номер телефона",
      4: "Ваш номер телефона",
      5: "Компания",
      6: "Название вашей компании",
      7: "Captcha",
      8: "Ваш captcha",
      9: "Сообщение",
      10: "Сообщите нам, как мы можем помочь вам с продуктами, доставкой, оплатой и другими вопросами.",
      11: "Отправить сообщение",
    },
    4: "Мы ориентированы на клиента и гибки!",
    5: "Мы помогаем вам выводить ваши продукты на рынок вовремя, с полными ресурсами, конкурентоспособными ценами, надежными материалами и исключительным качеством.",
    6: "Сервис при любых условиях",
    7: {
      1: "Советы",
      2: "1. Для более быстрого ответа рекомендуется связаться с нашей командой через страницу вашего аккаунта после входа в систему.",
      3: "Войти/Зарегистрироваться.",
      4: "2.Посетите",
      5: "центр поддержки Oceansmile",
      6: " чтобы быстро найти часто задаваемые вопросы по заказам, доставкам, платежам и другим вопросам.",
    },
    message: {
      1: "Имя обязательно.",
      2: "Электронная почта обязательна.",
      3: "Номер телефона обязателен.",
      4: "Компания обязательна.",
      5: "Captcha обязательна.",
      6: "Сообщение обязательно.",
    },
  },
  //why us页面
  why: {
    1: "Почему выбирают ",
    2: "Oceansmile — это высококачественный производитель печатных плат и сборки PCB из Шэньчжэня, Китай, с более чем 15-летним опытом работы. Мы обладаем профессиональными возможностями для производства печатных плат и сборки PCB, а наши инженеры с более чем 10-летним опытом дважды проверяют ваши проектные файлы. Кроме того, Oceansmile сертифицирована по стандартам IATF16949, ISO9001, ISO14001, UL, CQC, RoHS и REACH. Мы управляем всем процессом, включая производство печатных плат, сборку PCB, IoT-решения для промышленности, тестирование и создание пользовательских тестовых программ, поставку компонентов, механическую обработку (металлы, силикон, пластик, дерево), литье под давлением, помощь в проектировании и компоновке печатных плат, настройку батарей, разработку продуктов, OEM/ODM и финальную доставку. Мы можем собирать компоненты BGA, Micro-BGA, QFN и другие без пайки. Мы строго защищаем конфиденциальность производственных документов и интеллектуальные права на продукты. Мы предлагаем полный спектр услуг от прототипирования до быстрой сборки, соответствующих всем вашим требованиям!",
    3: "Универсальная платформа поддержки для исследований и разработок (это не только PCB и сборка PCB!)",
    4: "Oceansmile — это профессиональный производитель мирового уровня с 15-летним опытом в производстве печатных плат и сборке PCB в Китае. Благодаря профессиональным возможностям в области производства печатных плат и PCB мы поддерживаем все передовые конструкции с жесткими требованиями, включая FR4, HDI, лазерное сверление, микровыводы, площадки, микроволновые платы и платы с радиочастотами, до 32 слоев. Oceansmile — это профессиональный производитель с 15-летним опытом производства печатных плат и сборки PCB в Китае.",
    5: "Мы предоставляем полный спектр услуг от прототипирования до быстрой сборки. Это не только PCB/PCBA!",
    6: "Печатные платы / Сборка печатных плат / IoT-решения для промышленности / Тестирование и разработка пользовательских тестовых программ / Поставка компонентов / Механическая обработка (металлы, силикон, пластик, дерево) / Литье под давлением / Помощь в проектировании и компоновке печатных плат / Настройка батарей / Разработка продуктов / OEM / ODM...",
    7: "Гибкость: Быстрое производство печатных плат и сборка PCB. Мы ускоряем прототипирование для вас.",
    8: "Управление заказами и производственными процессами онлайн, быстрая доставка через DHL, FedEx, UPS...",
    9: "Обширные возможности цепочки поставок, богатая международная цепочка поставок в Китае не только предоставляет быстрые возможности поиска компонентов, но и благодаря сильным ресурсам может предложить более дешевые и качественные варианты.",
    10: "Снижение затрат: Снижаем затраты, повышаем конкурентоспособность продукта и достигаем взаимовыгодного результата.",
    11: "Онлайн-расчет стоимости, прозрачная цена. Опытные инженеры помогают вам анализировать производственные файлы (DFM), помогая избежать лишних затрат в процессе производства и сэкономить время.",
    12: "Контроль качества: Отличная система контроля качества и управление поставщиками. Планы контроля качества постоянно обновляются и совершенствуются.",
    13: "Материалы для печатных плат сертифицированы по стандартам IATF 16949, ISO9001, ISO14001, UL, RoHS и REACH. Что касается сборки PCB, мы предлагаем различные методы проверки качества, такие как автоматическая оптическая инспекция (AOI), ин-circuit тестирование (ICT), рентгеновское тестирование, функциональное тестирование и многоступенчатая визуальная инспекция, что приводит к отличному качеству PCB и сборки PCB.",
    14: "Мы несем ответственность за каждый продукт в соответствии с строгими производственными процессами. У нас есть профессиональная инженерная команда (каждый инженер с опытом более 5 лет). Все оборудование с отличной производительностью, точность и мощные бренды. Мы имеем надежную систему AVL, а система управления поставщиками оценивает качество, сроки и стоимость поставок, регулярно исключая ненадежных поставщиков и добавляя новых. Опыт с продуктами значительно повышает вероятность успешной разработки новых продуктов, а также предоставляет готовые решения и рыночные примеры для ваших проектов.",
    15: "Защита интеллектуальной собственности",
    16: "Мы строго защищаем интеллектуальные права на продукцию наших клиентов, используя строгие системы управления заказами и производственными процессами для обеспечения безопасности проектных документов. Все производственные оборудования оснащены системой безопасности. Мы подписываем соглашение NDA с клиентами для постоянной защиты их интеллектуальной собственности. Доверяйте Oceansmile, и мы обеспечим вам лучшую безопасность.",
    17: "Невозможно? Нет, это возможно: мы растем вместе с нашими клиентами и превращаем их идеи в реальность.",
    18: "Мы предлагаем полный спектр услуг по прототипированию и быстрой сборке. У нас есть неограниченные варианты обслуживания, опираясь на лучшие ресурсы в Шэньчжэне, Китай. Мы ориентированы на клиента и адаптируемся под ваши требования. Опытные команды, живые идеи — мы будем двигаться вперед и не остановимся. Выбирайте Oceansmile, важный шаг на пути к успеху.",
  },
  //论坛详情页面
  articleDetail: {
    1: "Новости и блог",
    2: "Комментарии",
    3: "Войдите, чтобы оставить комментарий.",
    4: "Оставить комментарий",
    5: "Ответить",
    7: "Опубликовать статью",
    8: "ПОПУЛЯРНЫЕ ТЕГИ",
    9: "Категории",
    message: {
      1: "Категория форума не может быть пустой.",
      2: "Заголовок не может быть пустым.",
      3: "Изображение заголовка не может быть пустым.",
      4: "Содержание не может быть пустым.",
      5: "Успешно создано.",
      6: "Пожалуйста, войдите, чтобы опубликовать статью.",
      7: "Выберите не более 10 тегов для статьи!",
      8: "Пожалуйста, введите комментарий!",
      9: "Комментарий успешно отправлен.",
      10: "Вы уверены, что хотите удалить комментарий?",
      11: "Подсказки",
      12: "Удалить",
      13: "Успешно удалено!",
    },
  },
  //论坛页面
  article: {
    1: "Центр форума",
    2: "Опубликовать статью",
    3: "Категории",
    4: "Выберите категорию",
    5: {
      1: "Категория",
      2: "Выберите категорию",
      3: "Заголовок",
      4: "Пожалуйста, введите заголовок статьи!",
      5: "Изображение заголовка",
      6: "Эскиз содержания",
      7: "Пожалуйста, введите краткое описание статьи!",
      8: "Теги",
      9: "Поиск/Добавить",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Публикации",
    2: "Коллекции",
    3: "Лайки",
    4: "Опубликовать статью",
    5: {
      1: "Создать новую статью",
      2: "Категория",
      3: "Заголовок",
      4: "Пожалуйста, введите заголовок статьи!",
      5: "Изображение заголовка",
      6: "Эскиз содержания",
      7: "Пожалуйста, введите краткое описание статьи!",
      8: "Поиск/Добавить",
    },
  },
  //帮助中心列表
  help: {
    1: "Как мы можем помочь?",
    2: "Введите ключевые слова для поиска",
  },
  //帮助中心详情
  helpDetail: {
    1: "Центр помощи",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "3D печать",
      2: "Технологии SLA, MJF, SLM, FDM, SLS",
      3: "•Смола, нейлон, металл и пластик",
      4: "•Точность до 0,2 мм",
      5: "•Срок доставки от 2 рабочих дней",
    },
    2: {
      1: "CNC обработка",
      2: "Фрезерование (3-, 4- и 5-осевое), токарная обработка и постобработка",
      3: "•Алюминий, медь, пластик",
      4: "•Точность до 0,05 мм",
      5: "•Срок доставки от 3 рабочих дней",
    },
    3: {
      1: "Литье под давлением",
      2: "Вакуумное формование",
      3: "•ABS, POM, нейлон, PC, PC/ABS, PVC, PE, PP, PS, TPU, силикон, индивидуальные материалы",
      4: "•Жизненный цикл >=50k/100k",
      5: "•Контролируемая точность по ISO 2768-1",
      6: "•Срок доставки от 15 до 25 рабочих дней",
    },
    4: {
      1: "Страны доставки",
      2: "Площадь фабрики",
      3: "Материалы и отделки",
      4: "Запросить предложение",
    },
    5: "Запросить предложение сейчас",
    6: "Закрытая услуга 360°",
    7: "Инженерная команда и профессиональная служба поддержки предлагают онлайн-поддержку в реальном времени 360° с регулярными отчетами о ходе производства.",
    8: "Стандартный процесс обслуживания",
    9: "Oceansmile имеет мощную цифровую систему управления, чтобы предоставить быстрые, комплексные и высококачественные услуги.",
    10: {
      1: "Быстрое предложение",
      2: "Предложение и DFM в течение 24 часов после получения документов от инженеров.",
      3: "Подтверждение решения",
      4: "Выберите требования к материалам и отделке, подтвердите план производства.",
      5: "Подтверждение оплаты",
      6: "Проверьте ваш заказ и осуществите оплату. Добавьте продажи, чтобы получить VIP-услуги.",
      7: "Обработка / 3D печать",
      8: "Резка - Программирование - Обработка - Постобработка, Проверка - Отгрузка",
      9: "Послепродажное обслуживание",
      10: "Проверьте продукты по чертежу. Мы быстро отреагируем на проблемы с качеством.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Детали товара",
    2: "Отзывы",
    3: {
      1: "Отзывы",
      2: "Комментарии",
      3: "Изображения",
      4: "Нажмите, чтобы загрузить",
      5: "Оставить комментарий",
      6: "Введите ваш комментарий...",
      7: "Пока нет комментариев",
    },
    4: "Включает батарею",
    5: "рабочие дни",
    6: "в наличии",
    7: "выбрать",
    8: {
      1: "Склад в Китае",
    },
    9: "ДОБАВИТЬ В КОРЗИНУ",
    10: "Теги",
    11: "РАСПРОДАНО",
    12: "Главная страница",
    13: "Магазин товаров",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Пожалуйста, введите содержание отзыва. Это поле необязательно.",
      2: "Замечания",
      3: "Нет данных",
      4: "Успешно добавлено.",
      5: "Отзыв успешно отправлен!",
      6: "Уже есть три изображения! Пожалуйста, удалите одно перед загрузкой нового.",
    },
  },
  commodity: {
    1: {
      1: "Ключевое слово",
      4: "на странице",
    },
    2: {
      1: "Подробнее...",
      2: "Добавить в корзину",
      3: "РАСПРОДАНО",
      4: "Нет результатов",
    },
    3: {
      1: "Замечания и количество",
      2: "Количество",
      3: "Описание",
      4: "Пожалуйста, введите содержание отзыва. Вы можете пропустить",
      5: "SKU",
      6: "Нет данных",
    },
    message: {
      1: "Успешно добавлено.",
    },
  },
  //忘记密码
  forget: {
    1: "Забыл пароль",
    2: {
      1: "Адрес электронной почты",
      2: "E-mail",
    },
    3: {
      1: "Код авторизации",
      2: "Код",
      3: "Получить",
    },
    4: {
      1: "Новый пароль",
    },
    5: {
      1: "Подтвердить пароль",
    },
    message: {
      1: "Пожалуйста, введите адрес электронной почты.",
      2: "Пожалуйста, введите адрес электронной почты.",
      3: "Пожалуйста, введите код авторизации.",
      4: "Пожалуйста, введите пароль.",
      5: "Пожалуйста, подтвердите пароль.",
      6: "Пароль не совпадает с подтверждением пароля.",
      7: "Пароль успешно обновлен.",
    },
  },
  //主页
  home: {
    1: {
      1: "Калькулятор производства PCB",
      2: "Длина \\ Ширина",
      3: "Длина",
      4: "Ширина",
      5: "Количество",
      6: "Пожалуйста, введите",
      7: "Слои",
      8: "Толщина",
      9: "Новый? Не зарегистрирован?",
      10: "Получить",
      11: "Купоны здесь!",
      12: "Получить предложение",
      13: "Калькулятор монтажа PCBA",
    },
    2: {
      1: "Быстрые сроки",
      2: "Срок доставки до 24 часов",
      3: "Персонализированная помощь",
      4: "Плавный процесс покупки",
      5: "Лучшее предложение",
      6: "Цены с завода",
      7: "Новости",
      8: "Легкая прототипизация PCB",
      9: "С более чем десятилетним опытом в PCB...",
      10: "Узнать больше",
    },
    3: {
      1: "Мгновенное предложение PCB",
      2: "1-6 слоев гибкого полиамида",
      3: "Форма заказа на 1 штуку",
      4: "Срок доставки 4-5 дней",
      5: "МГНОВЕННОЕ ПРЕДЛОЖЕНИЕ PCB",
      6: "Отправить файл PCB",
      7: "Проверка заказа",
      8: "Оплата",
      9: "Онлайн сопровождение",
      10: "Доставка",
      11: "Подтвердить получение",
      12: "Нажмите на 'Мгновенное предложение' в меню, выберите 'Мгновенное предложение PCB/PCBA'. Затем перейдите на страницу предложения и отправьте файл PCB.",
      13: "Отправьте файлы Gerber (zip, rar), другие форматы могут вызвать ошибки из-за несовместимости версий. Система выполнит автоматическую проверку за 30 секунд. Неудачная загрузка? Вы можете пропустить и отправить файл по электронной почте на",
      14: "Опытные инженеры Oceansmile проведут DFM вашего дизайна и предложат оптимизацию для продукта.",
      15: "Онлайн оплата, безопасная и отслеживаемая. Мы поддерживаем различные способы оплаты, включая Paypal, кредитные карты, оффлайн банковские переводы и т. д. Если у вас есть вопросы по оплате, обратитесь в нашу службу поддержки.",
      16: "Вы сможете отслеживать статус вашего заказа в реальном времени, а специально назначенные сотрудники будут сопровождать ваш заказ, чтобы гарантировать своевременную доставку и качество.",
      17: "Мы используем разнообразные варианты доставки и известные мировые службы доставки для гарантированной быстрой и безопасной доставки.",
      18: "Вы получите ваш продукт. Мы поддерживаем взаимовыгодные отношения с нашими клиентами и продолжим предоставлять качественную послепродажную поддержку. Если у вас возникнут проблемы с продуктом, мы используем все наши ресурсы для вашей помощи.",
    },
    4: {
      1: "Лучшие продажи продуктов",
      2: "Прототип PCB",
      3: "От",
      4: "Время сборки",
      5: "Сборка PCB",
      6: "Поставщики компонентов",
      7: "Гибкие, Ригидные-гибкие PCB",
      8: "HDI PCB",
      9: "3D печать",
      10: "дни",
      11: "часы",
      12: "ЧПУ обработка",
      13: "шт.",
    },
    5: {
      1: "Акция на сборку сервиса",
      2: "Только",
      3: "ВСЕГО",
      4: "для сборки от 1 до 20 шт.",
      5: "ЗАПРОСИТЬ ЦЕНУ СЕЙЧАС",
      6: "Решение под ключ для PCB и сборки",
      7: "Легко, Количество, Один раз",
      8: "Сборка SMT и через отверстия",
      9: "Волновая пайка для PCBA",
      10: "Онлайн расчет PCBA",
      11: "Решение для покупки BOM под ключ",
      12: "Более 99,6% уровень удовлетворенности",
      13: "Сборка коробки, проводка",
      14: "Функциональные тесты",
      15: "и другие тесты",
      16: "Программирование IC",
      17: "BGA",
      18: "QFN",
      19: "Последние 30 дней",
      20: "Клиенты",
      21: "Оплачено",
      22: "SMT",
    },
    6: {
      1: "Доступный производитель прототипов PCB в Китае",
      2: "Дата",
      3: "Имя покупателя",
      4: "Последний раз",
      5: "Прогресс",
    },
    7: {
      1: "СЛОЙ",
      2: "4-слойный PCB с 2 слоями маршрутизации, слоем земли и слоем питания",
      3: "Многослойная сборка PCB",
      4: "6-слойный PCB имеет GTL, GBL и 4 внутренних слоя. Каждый сигнал, который проходит от 1 до 6 слоев",
      5: "8-слойный PCB имеет GTL, GBL и 6 внутренних слоя. Каждый сигнал, который проходит от 1 до 8 слоев",
    },
    8: "Из нашего блога",
    9: "Что говорят клиенты",
    10: "мм",
  },
  //登录页面
  login: {
    1: "Присоединиться / Войти",
    2: "Создать аккаунт",
    3: {
      1: "Электронная почта",
      2: "Код",
      3: "Получить",
      4: "Пароль",
      5: "Подтвердить пароль",
      6: "Зарегистрироваться",
      7: "Создавая этот аккаунт, вы соглашаетесь с нашими",
      8: "Условиями",
    },
    4: {
      1: "ВОЙТИ",
      2: "С возвращением!",
      3: "Забыли пароль?",
      4: "Войти",
    },
    message: {
      1: "Пожалуйста, введите адрес электронной почты.",
      2: "Пожалуйста, примите условия использования для продолжения.",
      3: "Пожалуйста, введите код аутентификации.",
      4: "Пожалуйста, введите пароль.",
      5: "Пожалуйста, подтвердите пароль.",
      6: "Пароль не совпадает с подтвержденным.",
      7: "Регистрация успешна! Пожалуйста, войдите с правой стороны страницы.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Гарантированная разумная цена",
      2: "$3.78 за 5 шт. прототипов PCB 10*10см, $25 за 10 шт. сборки PCB.",
      3: "Гарантированные материалы для PCB",
      4: "Oceansmile использует только высококачественные материалы, соответствующие стандартам ROHS.",
      5: "Гарантированное качество",
      6: "Oceansmile полностью соответствует системе управления качеством ISO 9001:2015.",
      7: "Гарантированная доставка в срок",
      8: "Экспресс-прототип PCB, онлайн-персонализированный сервис, доставка в 99% случаев вовремя через DHL.",
    },
    2: {
      1: "Показ продукции",
      2: "Более 10 лет опыта в быстром прототипировании PCB и производстве PCB малых и средних объемов.",
    },
    3: {
      1: "Возможности сборки PCB",
      2: "Мы гордимся нашей репутацией ведущего специалиста в области сборки PCB с поверхностным монтажом, сквозных отверстий и смешанных технологий, а также услуг по производству электроники и сборке PCB под ключ.",
      3: "СБОРКА ПРОТОТИПА PCB",
      4: "Получить предложение",
      5: "Типы PCB для сборки",
      6: "Плата FR-4",
      7: "Плата с металлическим сердечником",
      8: "Гибкая плата",
      9: "Жестко-гибкая плата",
      10: "И другие",
      11: "Типы пайки",
      12: "Бессвинцовая пайка",
      13: "Сборка компонентов SMD и TH",
      14: "Монтаж одной и двух сторон",
      16: "Поставщики компонентов",
      17: "Digi-key, Mouser, Arrow, Element 14 и",
      18: "и другие",
      19: "Китайский рынок для универсальных компонентов",
      20: "Компоненты после вашего утверждения.",
      21: "Типы тестов",
      22: "Визуальный осмотр",
      23: "AOI осмотр",
      24: "Рентгеновская проверка",
      25: "Тестирование на плате",
      26: "Функциональные испытания",
    },
    4: {
      1: "Показ завода",
      2: "Более 10 лет опыта в быстром прототипировании PCB и производстве PCB малых и средних объемов.",
    },
    5: {
      1: "Как заказать услугу сборки PCB в Oceansmile?",
      2: "Нажмите здесь для получения подробной информации о том, как заказать PCBA, или свяжитесь с нами",
      3: "если у вас есть вопросы по нашим услугам сборки.",
    },
    6: {
      1: "Первый шаг",
      2: "Загрузите файлы Gerber вашей PCB и заполните форму",
      3: "Второй шаг",
      4: "Загрузите отформатированный файл BOM",
      5: "Третий шаг",
      6: "Получите мгновенную онлайн-цену",
    },
    7: "Загрузка...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "Возможности производства и сборки PCB",
      3: "Узнайте больше о возможностях Oceansmile и быстром воплощении ваших идей в реальность.",
      4: "Зеленый",
      5: "Фиолетовый",
      6: "Красный",
      7: "Желтый",
      8: "Синий",
      9: "Белый",
      10: "Черный",
    },
    2: {
      1: "Жесткая PCB",
      2: "Гибкая PCB",
      3: "Сборка PCB",
    },
    3: {
      1: "Спецификации PCB",
      2: "Размер отверстия/перфорации",
      3: "Минимальное кольцо",
      4: "Минимальное расстояние",
      5: "Минимальная ширина и расстояние трасс",
      6: "BGA",
      7: "Маска для пайки",
    },
    4: {
      1: "Характеристики",
      2: "Возможности",
      3: "Примечания",
      4: "Шаблоны",
      5: "Количество слоев",
      6: "Слои",
      7: "Количество медных слоев на плате.",
      8: "Материал",
      9: "FR-4",
      10: "Алюминий",
      11: "Медяной сердечник",
      12: "Rogers",
      13: "Керамика",
      14: "FR-4 PCB",
      15: "Алюминиевая PCB",
      16: "Размер платы",
      17: "Минимум",
      18: "Максимум",
      19: "Размер платы PCB",
      20: "Точность размеров",
      21: "(Обычный) для CNC обработки и ±0.4мм для V-выемки",
      22: "Толщина платы",
      23: "Окончательная толщина платы",
      24: "Точность толщины",
      25: "± 0.1мм для толщины платы ≤1.6мм, ± 10% для толщины платы ≥1.6мм",
      26: "Окончательная медь",
      27: "Внешний слой",
      28: "Внутренний слой",
      29: "Тип покрытия поверхности",
      30: "HASL (свинцовый/без свинца), ENIG, OSP, ENEPIG",
      31: "Дополнительный процесс для защиты открытой меди для улучшения пайки.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Особенности",
      2: "Возможности",
      3: "Примечания",
      5: "Размер отверстия (округлое отверстие)",
      6: "Механическое сверление",
      7: "Лазерное сверление",
      8: "Размер отверстия (щелевое отверстие)",
      9: "Размер отверстия (полуотверстие)",
      10: "Диаметр полуотверстий должен быть более 0,5 мм, а шаг между полуотверстиями должен быть более 0,9 мм.",
      11: "Слепые/закопанные vias",
      12: "Oceansmile поддерживает как слепые, так и закопанные vias.",
      13: "Допуск на размер отверстия",
      14: "PTH",
      15: "NPTH",
      16: "Щелевое отверстие",
    },
    6: {
      1: "Медь",
      2: "VIA",
    },
    7: {
      1: "Зазор между отверстиями (разные сети)",
      2: "Минимальный зазор между позолоченными отверстиями, который мы можем принять.",
      3: "Зазор между vias (одинаковые сети)",
      4: "Минимальный зазор между vias, который мы можем принять.",
      5: "Зазор между контактами (SMD контакты, разные сети)",
      6: "Минимальный зазор между SMD контактами, который мы можем принять.",
      7: "Зазор между контактами (TH контакты, разные сети)",
      8: "Минимальный зазор между TH контактами, который мы можем принять.",
      9: "VIA на дорожку",
      10: "Минимальный зазор между VIA и дорожкой, который мы можем принять.",
      11: "PTH на дорожку",
      12: "Минимальный зазор между PTH и дорожкой, который мы можем принять.",
      13: "NPTH на дорожку",
      14: "Минимальный зазор между NPTH и дорожкой, который мы можем принять.",
      15: "Контакт на дорожку",
      16: "Минимальный зазор между контактом и дорожкой, который мы можем принять.",
    },
    8: {
      1: "Минимальная ширина трассы",
      2: "Минимальное расстояние",
      3: "Рекомендуемая ширина/расстояние трасс для массового производства",
    },
    9: {
      1: "Спецификация",
      2: "Минимальные размеры BGA контактов",
      3: "Минимальное расстояние между BGA",
      4: "Диаметр BGA",
      5: "Минимум для HASL с припоем",
      6: "Минимум для HASL без свинца",
      7: "Минимум для других видов покрытия",
      8: "Минимальный диаметр BGA контактов, который мы можем принять.",
      9: "Зазор между контактами BGA",
      10: "Минимальный зазор между контактами BGA, который мы можем принять.",
    },
    10: {
      1: "Цвет маски для пайки",
      2: "Цвет PCB платы",
    },
    11: "мм",
  },
  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Информация о продукте",
      2: "Описание",
      3: "RoHs",
      4: "Доступность",
      5: "В наличии",
      6: "Ценообразование",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Последнее обновление: 16 мая 2024 года.",
    2: "Заявление о работе веб-сайта Oceansmile",
    3: 'Этот веб-сайт управляется компанией Oceansmile, которая включает cnospcb.com и Lanyue Electronics (Shenzhen) Co., Ltd. На этом сайте термины "Oceansmile", "OSPCB", "Lanyue", "мы" и "наш" относятся к вышеупомянутым брендам и их дочерним компаниям. Условия, описанные в этом документе, применяются к этим организациям и ко всем предоставляемым ими услугам.',
    4: "Политика конфиденциальности",
    5: "Это Политика конфиденциальности, опубликованная Lanyue/Oceansmile/Lanyue Electronics (Shenzhen) Co., Ltd. Она включает обработку личных данных и способы их использования, а также как осуществлять ваши права. Политика конфиденциальности применяется к этому сайту в отношении любых продуктов и/или услуг, предоставляемых Lanyue(Oceansmile). В качестве контроллера ваших личных данных компания Lanyue согласна с тем, что защита ваших личных данных важна для нас, и мы предпримем все разумные меры для их сбора и использования в соответствии с применимыми законами и нормативными актами. Пожалуйста, внимательно прочитайте эту политику конфиденциальности, когда вы являетесь пользователем продуктов и/или услуг Oceansmile. Если у вас возникнут вопросы относительно ваших личных данных, свяжитесь с нами по адресу электронной почты: service@cnospcb.com. Обзор сервиса. Веб-сайты услуг, предоставляемые Oceansmile, содержат всю информацию, инструменты и услуги, предназначенные для поддержки пользователей при условии, что вы полностью принимаете и соблюдаете все условия, положения, политику и уведомления, указанные на этом сайте.",
    6: "Личные данные",
    7: 'Согласно конкретному определению, изложенному в Регламенте № 2016/679 о защите физических лиц в связи с обработкой персональных данных ("GDPR"), персональные данные, обрабатываемые компанией Lanyue, в основном относятся к следующим категориям',
    8: "1. Некоторые персональные данные предоставляются вами добровольно.",
    9: "2. Другие персональные данные обрабатываются автоматически, когда вы посещаете наши сайты и/или используете продукты и/или услуги Lanyue.",
    10: "3. Другие персональные данные собираются через сайты и услуги третьих сторон.",
    11: "Соблюдение экспортного контроля",
    12: "Обработка персональных данных",
    13: "Вы будете предоставлять свои персональные данные добровольно в процессе покупки или других процессах с вашим предварительным согласием, включая, но не ограничиваясь, вашим именем и контактной информацией, данными учетной записи, платежной информацией, данными о комментариях и другими данными, которые вы нам предоставляете. Lanyue использует ваши персональные данные для выполнения ваших заказов, предоставления определенных услуг вам, регистрации учетной записи и для любых коммуникаций между вами и Lanyue и т.д.",
    14: "Компания Lanyue использует ваши персональные данные для указанной цели, как указано выше, или для любых других юридических требований. Вы имеете право запросить удаление или отзыв предоставленных вами персональных данных в любой момент, если считаете, что ваши права не защищены.",
    15: "Компания Lanyue может автоматически собирать ваши персональные данные, когда вы используете продукты и/или услуги Lanyue, включая, но не ограничиваясь",
    16: "Информацию о вашем устройстве, например, после покупки продуктов Lanyue и их установки на ваш адрес для мониторинга, веб-серверы Lanyue могут автоматически регистрировать ваш IP-адрес или любую другую информацию о вашем устройстве.",
    17: "Данные, включая продукты, которые вы используете, как вы их используете, и ваши привычки при поиске на наших сайтах и т.д. Цель автоматического сбора ваших персональных данных — это оценка и улучшение вашего опыта использования продуктов и/или услуг Lanyue. Если вы не хотите раскрывать свои привычки использования или другие персональные данные, пожалуйста, свяжитесь с нами через указанный в этой Политике конфиденциальности контактный email.",
    18: "Кроме того, сайт Lanyue использует сторонние сервисы, которые могут включать платежные сервисы, поставщиков доставки и рекламодателей и т.д. Некоторые поставщики могут собирать ваши персональные данные для целей, изложенных выше, и также иметь свою собственную политику конфиденциальности для защиты обработки ваших персональных данных.",
    19: "ЦЕНА И ОПЛАТА",
    20: "Lanyue прилагает все усилия для предоставления актуальной и точной информации о продуктах и ценах, но не гарантирует актуальность или точность этой информации.",
    21: "Онлайн-ценовая котировка не гарантирует общий объем заказа и время выполнения. Все объемы заказа и сроки выполнения зависят от проверки производственных мощностей. Цена, указанная в веб-форме при оформлении заказа, будет обязательной только после подтверждения и проверки отправленных документационных файлов. Lanyue оставляет за собой право изменять цену или отменять заказ, если отправленные документы не соответствуют данным, введенным в форму заказа. В таких случаях мы уведомим вас об изменениях. Если ваш заказ был отменен и вы уже оплатили его, Lanyue вернет деньги на исходный способ оплаты.",
    22: "Компания Lanyue не несет ответственности за ошибки в ценообразовании, типографские или другие ошибки в предложениях Lanyue и оставляет за собой право отменить любые заказы, возникшие из таких ошибок.",
    23: "ДОСТАВКА И ДОСТАВКА",
    24: "Заказчик несет ответственность за правильность и полноту указанного в веб-форме адреса доставки. Все дополнительные транспортные расходы, связанные с неправильными данными адреса, будут взиматься с заказчика. Международные клиенты несут ответственность за свои таможенные пошлины и импортные сборы по всем заказам.",
    25: "Компания Lanyue отправит ваш товар в разумный срок на указанный вами адрес. Мы прилагаем все усилия для того, чтобы все ваши заказы были отправлены вовремя. Однако бывают случаи, когда транспортные компании испытывают задержки и/или совершают ошибки при доставке. Мы сожалеем, когда это происходит, но не несем ответственности за задержки со стороны этих компаний.",
    26: "ОТКАЗ ОТ ПРАВ ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ",
    27: "Документы проектирования для производства печатных плат/продуктов были предоставлены клиентами, и ответственность компании Lanyue заключалась в производстве печатных плат/продуктов в соответствии с проектом клиента. Клиенты должны гарантировать, что они обладают правами собственности и соответствующими интеллектуальными правами на предоставленные проектные документы, которые не должны нарушать права других лиц, такие как патентные права, авторские права, товарные знаки и т.д.",
    28: "Любые нарушения или правовые проблемы, возникшие из-за предоставленных клиентом документов, которые повлекли за собой ущерб или потери для нас, будут возмещаться клиентами, а Lanyue сохраняет право требовать компенсации.",
    29: "Связаться с нами",
    30: "Если у вас есть вопросы или комментарии по этим условиям, свяжитесь с нами по адресу",
  },
  //看图页面
  canvas: {
    1: {
      1: "Открыть все",
      2: "Закрыть все",
    },
    2: {
      1: "Размер",
      2: "Минимальная ширина трассы",
      3: "Минимальное расстояние между трассами",
      4: "Количество отверстий",
      5: "Минимальный размер отверстия",
      6: "Максимальный размер отверстия",
      7: "Область ENIG",
      8: "Контрольная точка",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA&Stencil",
    2: {
      1: "ВСЕ",
      2: "Отменено",
      3: "Ожидает оплаты",
      4: "Подтверждение от Oceansmile",
      5: "Оплачено",
      6: "В обработке",
      7: "Упаковано",
      8: "Отправлено",
      9: "Пожалуйста, введите номер заказа",
    },
    3: {
      1: "Заказ | Информация о продукте",
      2: "Все покупки",
      3: "Материалы",
      4: "Количество",
      5: "Цена",
      6: "Описание продукта",
      7: "Имя файла",
      8: "Описание файла",
    },
    4: {
      1: "Телефон",
      2: "Компания",
      3: "Налоговый номер",
      4: "Вес",
      5: "Доставка",
      6: "Адрес доставки",
    },
    5: {
      1: "Время сборки",
      2: "Время оплаты",
      3: "Не оплачено",
      4: "Советы",
      5: "Время сборки считается с момента оплаты",
      6: "Время создания",
    },
    6: {
      1: "Метод оплаты",
      2: "Цена продукта",
      3: "Комиссии",
      4: "Транспортные расходы",
      5: "Другие скидки",
      6: "Итого",
    },
    7: "Описание заказа",
    8: "Посмотреть детали",
    9: "Способ поставки",
    10: "Конструкция",
    11: {
      1: "Купить сейчас",
      2: "Счет",
    },
    12: "Продукты",
    13: {
      1: "Цена за единицу",
      2: "Общая цена",
      3: "Описание пользователя",
    },
    14: {
      0: "Детали",
      1: "Gerber файл",
      2: "Описание Gerber",
      3: "Описание продукта",
      4: "Тип платы",
      5: "Материал",
      6: "Длина",
      7: "Ширина",
      8: "Слои",
      9: "Толщина",
      10: "Завершающий медь",
      11: "Минимальный размер отверстия",
      12: "Маска для пайки",
      13: "Шелкография",
      14: "Время сборки",
      15: "Пол отверстия",
      16: "Статус заказа",
      17: "Вес продукта",
      18: "Разные проекты на панели",
      19: "FR4-TG",
      20: "Количество",
      21: "Поверхностная отделка",
      22: "Минимальная маска для пайки",
      23: "Минимальная ширина/расстояние трассы",
      24: "Контроль импеданса",
      25: "Детали начислений",
      26: "Инженерный сбор",
      27: "Панель",
      28: "Проходные отверстия",
      29: "Плата",
      30: "Тест",
      31: "Медь",
      32: "Поверхностная отделка",
      33: "Процесс отверстий",
      34: "Слепые отверстия",
      35: "Отверстия в подложке",
      36: "Взнос за V-рез",
      37: "Цветовой сбор",
      38: "Большой размер",
      39: "Контроль импеданса",
      40: "Скидка",
      41: "Итого",
      42: "Стоимость PCBA",
      43: "Стоимость компонентов",
      44: "Стоимость сборки",
      45: "Стоимость услуги",
      46: "Файл BOM",
      47: "Описание BOM",
      48: "Детали компонентов",
      49: "Подтверждение продукта",
      50: "Дизигнатор",
      51: "MPN",
      52: "Общее количество",
      53: "Отходы материалов",
      54: "Цена за единицу",
      55: "Описание",
      56: "Версия",
      57: "Конструкция",
      58: "Размер",
      59: "Сторона трафарета",
      60: "Детали начислений",
      61: "SMD трафарет",
    },
    15: {
      1: "Дополнительный заказ",
      2: "Доплатить номер заказа",
      3: "Номер заказа",
      4: "Разница в цене",
      5: "Описание",
      6: "Оплатить",
    },
    16: "PCB",
    17: "PCBA",
    18: "Stencil",
    message: {
      1: "Успешно удалено.",
      2: "Вы хотите оплатить дополнительный заказ?",
      3: "Совет",
      4: "Пожалуйста, выберите заказ.",
      5: "Успешно скопировано.",
    },
  },
  //预览价格
  preview: {
    1: "Назад",
    2: "Адрес доставки",
    3: "Редактировать",
    4: "Удалить",
    5: "ДОБАВИТЬ НОВЫЙ АДРЕС",
    6: {
      1: "Является ли это адресом по умолчанию",
      2: "Мы можем подтвердить некоторые проблемы с вашими заказами или файлами по электронной почте, пожалуйста, убедитесь, что ваш адрес электронной почты действителен!",
    },
    7: "Метод доставки",
    8: "Не применимо",
    9: "Купоны",
    10: "Нет",
    11: "Метод оплаты",
    12: "Кредитная карта",
    13: "Ручной банковский перевод (TT)",
    14: {
      1: "РЕЗЮМЕ",
      2: "Цена разницы",
      3: "Комиссия",
      4: "Вес продукта (КГ)",
      5: "Промежуточный итог",
      6: "Стоимость доставки",
      7: "Исходная цена",
      8: "Сумма скидки",
      9: "Сумма к оплате",
      10: "Скидка по купону",
      11: "Скидка OceanSmile",
      12: "Купить сейчас",
    },
    message: {
      1: "Вы уверены, что хотите удалить адрес?",
      2: "Совет",
      3: "Удалено успешно.",
      4: "Имя не может быть пустым.",
      5: "Фамилия не может быть пустой.",
      6: "Компания не может быть пустой.",
      7: "Улица не может быть пустой.",
      8: "Страна не может быть пустой.",
      9: "Провинция не может быть пустой.",
      10: "Город не может быть пустым.",
      11: "Почтовый индекс не может быть пустым.",
      12: "Телефон не может быть пустым.",
      13: "Электронная почта не может быть пустой.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Онлайн-цена",
      2: "Загрузить файл Gerber",
      3: "Обзор заказа",
      4: "Оплата",
      5: "Производство",
      6: "Отправка",
      7: "Подтвердить и проверить",
    },
    2: "Выбор спецификации PCB",
    3: {
      1: "Добавить файлы Gerber",
      2: "Загрузка успешна",
      3: "Онлайн-просмотрщик Gerber",
      4: "Файл PCB был загружен успешно, но параметры не могут быть разобраны.",
      5: "Приняты только файлы ZIP или RAR. Максимальный размер: 50 МБ.",
      6: "Если загруженный zip-файл не может быть правильно разобран или не отображается изображение, не беспокойтесь, наши инженеры проверят его вручную и свяжутся с вами по электронной почте позже.",
      7: "Для специальных требований, которые недоступны на этой странице, или если вы не можете найти то, что ищете, отправьте нам ваши требования и файлы Gerber,",
      8: " и мы свяжемся с вами в течение 24 часов.",
    },
    4: {
      1: "Примечание для Gerber",
      2: "Отдельные детали",
      3: "Панель от клиента",
      4: "Панель от OSpcb",
      5: "Рейлинги",
      6: "Нет",
      7: "Размер панели",
      8: "Количество панелей",
      9: "Другие",
      10: "Примечание для PCB",
    },
    5: {
      1: "Сборка PCB",
      2: "Единственное количество",
      3: "Трафарет",
      4: "Способ поставки компонентов",
      5: "Turnkey",
      6: "Консигнация",
      7: "Комбо",
      8: "Комментарий для PCBA",
      9: "Комментарий для BOM",
      10: "Добавить файл BOM",
      11: "Скачать шаблон BOM",
      12: "Пожалуйста, загрузите точный файл BOM, следуя этому шаблону, чтобы мы могли правильно его прочитать.",
      13: "MPN может быть выбран из Digikey, Mouser, Arrow, Element или любой другой платформы, наша система автоматически его распознает.",
      14: "Для специальных запросов по сборке PCB напишите на",
      15: "не пишите напрямую в файл BOM.",
      16: "Мы распознаем только запятые для различения местоположения.",
      17: "Номер обозначения должен быть ≤ Количеству.",
      18: "Пожалуйста, не заполняйте номер детали Digikey или Mouser в строке MPN (Номер детали производителя).",
      19: "Пожалуйста, не включайте ненужные компоненты в ваш файл BOM.",
    },
    6: {
      1: "Пожалуйста, выберите страну",
      2: "Спецификация PCB",
      3: "Количество",
      4: "Время производства",
      5: "Стоимость PCB",
      6: "Инженерные расходы",
      7: "Плата",
      8: "Стоимость PCBA",
      9: "Стоимость сборки",
      10: "Стоимость компонентов",
      11: "Промежуточный итог",
      12: "Добавить в корзину",
      13: "рабочих дней",
    },
    str: {
      "Normal FR-4 Board": "Обычная плата FR-4",
      "Flexible Boards": "Гибкие платы",
      "Board Type": "Тип платы",
      "Single Quantity": "Единичное количество",
      "FR4-TG": "FR4-TG",
      "Single Size": "Единичный размер",
      "Different Design in Panel": "Различный дизайн на панели",
      "Min Trace Width/Spacing": "Минимальная ширина трассы/Расстояние",
      "Min Hole Size": "Минимальный размер отверстия",
      "Minimum Solder Mask Dam": "Минимальный зазор маски для пайки",
      "Half Hole": "Пол отверстия",
      "Surface Finish": "Отделка поверхности",
      "Finished Copper": "Завершённый медный слой",
      "Impedance Control": "Контроль импеданса",
      "Panel Quantity": "Количество на панели",
      "Edge Qty": "Количество краёв",
      "Solder Mask": "Маска для пайки",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 слой",
      "2 Layer": "2 слоя",
      "4 Layer": "4 слоя",
      "6 Layer": "6 слоев",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0,2 мм",
      "0_25mm": "0,25 мм",
      "0_3mm": "0,3 мм",
      "0_4mm": "0,4 мм",
      "HASL with lead": "HASL с оловом",
      "HASL lead free": "HASL без свинца",
      "Immersion gold": "Золото погружением",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1,0w/(m⋅K)",
      "2_0w/(m⋅K)": "2,0w/(m⋅K)",
      "0_08": "0,08",
      "0_13": "0,13",
      "0_22": "0,22",
      "0_6": "0,6",
      "0_8": "0,8",
      "1_0": "1,0",
      "1_2": "1,2",
      "1_6": "1,6",
      "2_0": "2,0",
      "0_6mm": "0,6 мм",
      "0_8mm": "0,8 мм",
      "1_0mm": "1,0 мм",
      "1_2mm": "1,2 мм",
      "1_6mm": "1,6 мм",
      "2_0mm": "2,0 мм",
      "0_10": "0,10",
      "0_12": "0,12",
      "0_15": "0,15",
      "0_18": "0,18",
      "0_10mm": "0,10 мм",
      "0_12mm": "0,12 мм",
      "0_15mm": "0,15 мм",
      "0_18mm": "0,18 мм",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0,5oz",
      mm: "мм",
      OSP: "OSP",
      None: "Нет",
      White: "Белый",
      Black: "Чёрный",
      Green: "Зеленый",
      Red: "Красный",
      Yellow: "Желтый",
      Blue: "Синий",
      Silkscreen: "Трафарет",
      Aluminum: "Алюминий",
      Layers: "Слои",
      Material: "Материал",
      Thickness: "Толщина",
      TCE: "TCE",
      No: "Нет",
      Yes: "Да",
      "Single Pieces": "Единичные изделия",
      "Panel By Customer": "Панель от клиента",
      "Panel By OSpcb": "Панель от OSpcb",
      true: "Да",
      false: "Нет",
      Turnkey: "Turnkey",
      Consignment: "Консигнация",
      Combo: "Комбо",
      Unit: "Единица",
      Total: "Итого",
      Framework: "Рамка",
      "Stencil Side": "Сторона трафарета",
      "Existing fiducials": "Существующие фидуциалы",
      Top: "Верх",
      Bottom: "Низ",
      "Top+Bottom(On Single Stencil)": "Верх+Низ (на одном трафарете)",
      "Top & Bottom(On Separate Stencil)":
        "Верх и низ (на отдельных трафаретах)",
      "SIZE (mm)": "РАЗМЕР (мм)",
      Quantity: "Количество",
      none: "Нет",
      "half lasered": "Полу-лазерный",
      "lasered through": "Прорезано лазером полностью",
      undefined: "",
    },
    tips: {
      Material:
        "Материал FR-4 является самым популярным. Алюминиевый PCB имеет большую физическую прочность и лучшую теплопередачу. Гибкая плата намного тоньше других материалов и может быть в какой-то степени изгибаема.",
      "FR4-TG":
        "Температурная стойкость платы FR-4. Чем выше значение TG, тем выше термостойкость.",
      Layers:
        "Количество слоев меди в вашем файле, по умолчанию для одной платы маска для пайки применяется только с одной стороны.",
      Thickness: "Желаемая толщина готовой платы.",
      "Solder Mask": "Цвет маски для пайки на основе материала.",
      Silkscreen: "Цвет трафаретной маркировки.",
      "Single Size": "Длина и ширина вашей платы.",
      "Single Quantity": "Количество необходимых плат.",
      "Panel Quantity": "Количество плат на панели.",
      "Board Type":
        "Платы могут быть одиночными или собраны в панели с одинаковыми или разными платами.",
      "Different Design in Panel":
        "Сколько различных дизайнов в вашем файле, разделенных V-резами, отверстиями или пазами.",
      "Min Trace Width/Spacing":
        "Минимальная ширина трассы и расстояние между трассами в вашем файле.",
      "Min Hole Size": "Минимальный размер отверстий в вашем файле.",
      "Minimum Solder Mask Dam":
        "Минимальное расстояние маски для пайки между контактными площадками.",
      "Half Hole":
        "Отверстия PTH или vias, которые были разрезаны для создания частичного отверстия или полузакрытого отверстия.",
      "Surface Finish":
        "Обработка HASL наносит тонкий слой припоя на контактные площадки. ENIG имеет лучшую плоскостность и более долгий срок службы по сравнению с HASL.",
      "Finished Copper": "Толщина меди в наружных слоях (верхний и нижний).",
      TCE: "Коэффициент термической расширяемости, свойство материала, указывающее, на сколько материал расширяется при нагреве.",
      Framework:
        "Убедитесь, что рама правильно выровнена и правильно расположена.",
      "Stencil Side":
        "Проверьте выравнивание трафарета перед его использованием.",
      "Existing fiducials":
        "Проверьте расположение существующих фидуциалов для точности выравнивания.",
    },
    7: {
      1: "SMD трафарет",
      2: "Действительная зона",
      3: "Стоимость трафарета",
      4: "Тип трафарета",
      5: "Размер",
      6: "Сторона",
      7: "Вес",
      8: "Детали оплаты",
      9: "SMD трафарет",
    },
    message: {
      1: "Если данные превышают системный лимит, пожалуйста, отправьте файл дизайна напрямую на указанный электронный адрес.",
      2: "Мы предоставим ручные комментарии и котировку в течение 24 часов.",
      3: "Пожалуйста, заполните ширину платы.",
      4: "Пожалуйста, заполните длину платы.",
      5: "Пожалуйста, заполните количество плат.",
      6: "Пожалуйста, загрузите файл Gerber.",
      7: "Пожалуйста, загрузите файл BOM.",
    },
  },
  //人工转账页面
  pay: {
    1: "Ваш запрос на оплату принят.",
    2: "Пожалуйста, выполните перевод через банковский счет. Платеж будет завершен после завершения перевода. Общая сумма платежа",
    3: "Поддержка перевода с бизнес-счета и личного счета",
    4: "1. Если ваш платежный счет — это бизнес-банковский счет, пожалуйста, переведите на этот счет",
    5: "2. Если ваш платежный счет — это личный банковский счет, пожалуйста, переведите на этот счет",
    6: "Банк получателя",
    7: "Адрес банка получателя",
    8: "SWIFT код",
    9: "Полное имя получателя",
    10: "Номер счета получателя",
    11: "Адрес и номер телефона получателя",
    12: "Промежуточный банк",
    13: "SWIFT код (Промежуточный)",
    14: "Адрес промежуточного банка",
    15: {
      1: "Советы по переводу",
      2: "1. Пожалуйста, отправьте номер заказа Ospcb и копию банковского перевода на service@cnospcb.com после перевода, чтобы мы могли начать производство вовремя.",
      3: "2. Убедитесь, что вы ввели правильную информацию о банковском счете при переводе.",
      4: "3. Переведите полную сумму одним платежом. Не разделяйте платеж на несколько переводов.",
      5: "4. Сохраните копию банковского перевода для будущего использования.",
      6: "Советы по финансовой безопасности",
      7: "1. Наши сотрудники будут использовать только адреса электронной почты с доменом cnospcb.com для связи с вами, не доверяйте электронным письмам с другими доменами.",
      8: "2. Если информация о банковском счете изменится, мы заранее объявим об этом на сайте, пожалуйста, не доверяйте и не переводите деньги на банковский счет, отличный от указанного на этой странице.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Совет: может быть задержка в оплате. Если платеж не поступил долгое время, пожалуйста, свяжитесь с обслуживанием клиентов.",
    2: "Автоматическое перенаправление через",
    3: "секунд",
    4: "сейчас",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Номер заказа",
      2: "Получить платеж",
      3: "Тип оплаты",
    },
    2: {
      1: "Номер заказа",
      2: "Сумма оплаты",
      3: "Валюта",
      4: "Тип оплаты",
      5: "ID платежа",
      6: "Электронная почта для оплаты",
      7: "Время оплаты",
      8: "Оплата получена",
      9: "Доказательство оплаты",
      10: "Действие",
    },
    3: {
      1: "Скачать PDF",
      2: "Ожидает загрузки",
      3: "Загрузить",
    },
    message: {
      1: "Квитанция о переводе успешно загружена. Пожалуйста, ожидайте проверки сотрудниками!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA и трафарет",
      2: "ВСЕ",
      3: "PCB",
      4: "PCBA",
      5: "Трафарет",
      6: "Введите имя файла",
    },
    2: {
      1: "Продукты",
      2: "Купить все",
      3: "Материал",
      4: "Количество",
      5: "Цена",
      6: "Статус продукта",
      7: "Описание продукта",
      8: "Имя файла",
      9: "Описание файла",
      10: "Время создания",
      11: "Итого",
    },
    3: "Время производства",
    4: "Просмотр деталей",
    5: "Купить сейчас",
    6: "Время создания",
    7: "Итого",
    8: "Ожидает подтверждения",
    9: "Способ поставки",
    10: {
      1: "Ключевое слово",
      2: "Название продукта",
      3: "Включает батарею",
      4: "Общий вес",
      5: "Цена за единицу",
      6: "Итоговая цена",
      7: "Статус",
      8: "Описание",
      9: "Купить",
      10: "Удалить",
      11: "Действие",
    },
    statusMap: {
      0: "Удалить",
      1: "Ожидает запроса",
      2: "Ожидает подтверждения от клиента",
      3: "Доступно для покупки",
      4: "Заказ отправлен",
      undefined: "",
    },
    message: {
      1: "Выберите товар для оплаты!",
      2: "Вы уверены, что хотите удалить этот продукт?",
      3: "Совет",
      4: "Удалить",
      5: "Успешно удалено.",
      6: "Вы хотите оплатить вместе с заказом на пополнение?",
      7: "Изменения компонентов подтверждены.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Номер заказа на пополнение",
      2: "Номер продукта",
      3: "Статус",
      4: "Тип оплаты",
      5: "Тип продукта",
      6: "Оплатить все",
    },
    2: {
      1: "Номер заказа",
      2: "Тип продукта",
      3: "Номер продукта",
      4: "Сумма",
      5: "Тип оплаты",
      6: "Комиссия",
      7: "Итого",
      8: "Время создания",
      9: "Статус пополнения",
      10: "Действие",
    },
    3: {
      1: "Просмотр",
      2: "Оплатить",
      3: "Счет",
    },
    4: {
      1: "Детали",
      2: "Номер заказа на пополнение",
      3: "Статус пополнения",
      4: "Номер заказа",
      5: "Тип продукта",
      6: "Номер продукта",
      7: "Тип оплаты",
      8: "Сервисный сбор",
      9: "Общая сумма",
      10: "Время оплаты",
      11: "Описание",
      12: "Ожидает расчета",
      13: "Нет",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Трафарет",
      4: "Стоимость доставки",
    },
    message: {
      1: "Выберите заказ.",
    },
  },
  //地址
  address: {
    1: {
      1: "Редактировать",
      2: "Удалить",
    },
    2: "ДОБАВИТЬ НОВЫЙ АДРЕС",
    3: "РЕДАКТИРОВАТЬ АДРЕС",
    4: {
      1: "Имя",
      2: "Фамилия",
      3: "Компания",
      4: "Улица",
      5: "Пожалуйста, выберите страну",
      6: "Области",
      7: "Город",
      8: "Почтовый индекс",
      9: "Телефон",
      10: "Электронная почта",
      11: "Налоговый номер",
      12: "Это основной адрес?",
      13: "Мы можем подтвердить некоторые проблемы с вашими заказами или файлами по электронной почте, пожалуйста, убедитесь, что электронная почта правильная.",
    },
  },
  //优惠券
  coupon: {
    1: "Мои купоны",
    2: "Купон",
    useOfTypeMap: {
      1: "доступен",
      2: "в использовании",
      3: "использован",
      4: "истек",
    },
    consumptionTypeMap: {
      0: "Неограниченно",
      1: "Для PCB",
      2: "Для PCBA",
      3: "Для трафаретов",
    },
    useTypeMap: {
      1: "Общая стоимость",
      2: "Стоимость доставки",
    },
  },
  //信函
  letter: {
    1: {
      1: "ВСЕ",
      2: "Жалоба",
      3: "Обратная связь",
      4: "Предложение",
      5: "Заказ",
    },
    2: "Создать",
    3: {
      1: "Серийный номер",
      2: "Заголовок",
      3: "Тип",
      4: "Содержание",
      5: "Просмотр",
      6: "Время создания",
      7: "Время окончания",
      8: "Состояние сообщения",
      9: "Доставлено",
      10: "Просмотрено",
      11: "Степень выполнения",
      12: "Сообщения",
      13: "Действие",
      14: "Ответить",
      15: "Выбрать тип",
    },
    4: "Подробности содержания",
    5: "Подробности сообщения",
    6: "Ответить",
    7: {
      1: "Получатели",
    },
    8: {
      1: "Содержание ответа",
    },
    9: {
      1: "Создать",
      2: "Коммуникация",
      3: "Завершить",
    },
    10: {
      1: "Личное",
      2: "Сервис",
    },
    message: {
      1: "Заголовок не может быть пустым.",
      2: "Тип не может быть пустым.",
      3: "Содержание не может быть пустым.",
      4: "Успешно создано!",
      5: "Успешно ответили.",
    },
  },
  //公告
  notice: {
    1: "Заголовок",
    2: "Команда",
    3: "Время публикации",
    4: "Действие",
    5: "Подробности",
    detail: {
      1: "Назад",
      2: "Системное уведомление",
      3: "Уведомление о мероприятиях",
    },
  },
  //个人信息
  profile: {
    1: "Профиль",
    2: {
      1: "Имя пользователя",
      2: "Тип аккаунта",
      3: "Аватар",
      4: "Обновить аватар",
      5: "Название компании",
      6: "Налоговый номер",
      7: "Номер телефона",
      8: "Facebook",
      9: "Skype",
      10: "Whatsapp",
      11: "Обновить",
    },
    3: {
      1: "Обновить пароль",
      2: "Текущий пароль",
      3: "Новый пароль",
      4: "Подтвердить пароль",
    },
    4: {
      1: "Сохранить",
      2: "Отмена",
    },
    5: {
      1: "Личное",
      2: "Корпоративное",
    },
    message: {
      1: "Новый пароль и подтверждение пароля не совпадают.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Кредитная карта",
    3: "Ручной банковский перевод (TT)",
    4: "Другой",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "Успех!",
    "-1": "Неизвестная ошибка!",
    401: "Срок действия сессии истек, пожалуйста, войдите снова!",
    403: "Ошибка авторизации!",
    10000:
      "Системная ошибка! Пожалуйста, отправьте отзыв администратору! Спасибо!",
    10001: "Размер файла превышает верхний предел!",
    10002: "Пожалуйста, выберите заказ, который вы хотите оплатить.",
    10003: "Ошибка заказа!",
    10004:
      "Размер файла превышает верхний предел! Максимальный размер файла 50 МБ!",
    10005: "Для товара был создан заказ!",
    10006:
      "Пожалуйста, загрузите файл Gerber для трафарета! Разрешены только файлы ZIP или RAR. Максимальный размер 50 МБ!",
    10007: "Не удалось найти цену на форму!",
    10008:
      "При выборе Top & Bottom (на отдельном трафарете) количество не может быть меньше 2!",
    10009: "Выбранный товар не существует или был деактивирован!",
    10010: "Цена продажи этого товара еще не объявлена!",
    10011: "Нет в наличии, невозможно добавить в корзину!",
    10012: "Текущий товар в корзине не существует!",
    10013: "Цена продажи этого товара еще не объявлена!",
    10014:
      "Текущий запас товара меньше измененной количества, пожалуйста, скорректируйте и попробуйте снова!",
    10015: "Статья не существует или была удалена!",
    10016: "Пожалуйста, выберите комментарий, на который вы хотите ответить!",
    10017: "Этот комментарий не существует или был удален!",
    10018: "UUID не существует!",
    10019: "Пожалуйста, выберите комментарий, который вы хотите лайкнуть!",
    10020: "Этот комментарий не существует или был удален!",
    10021: "Пожалуйста, выберите комментарий, который вы хотите отменить лайк!",
    10022:
      "Пожалуйста, выберите детали статьи форума, которые вы хотите просмотреть!",
    10023: "Выбранная категория форума не существует!",
    10024: "Пожалуйста, выберите статью, которую вы хотите удалить!",
    10025: "Пожалуйста, выберите статью, которую вы хотите лайкнуть!",
    10026: "Пожалуйста, выберите статью, которую вы хотите отменить лайк!",
    10027:
      "Пожалуйста, выберите статью, которую вы хотите отменить из коллекции!",
    10028: "Пожалуйста, выберите товар для просмотра версии Gerber!",
    10029: "Пожалуйста, выберите товар для генерации заказа!",
    10030: "В настоящее время нет доступных заказов для оплаты!",
    10031: "Платеж не был успешным!",
    10032:
      "Пожалуйста, загрузите файл Gerber для PCB! Разрешены только файлы ZIP или RAR. Максимальный размер 50 МБ!",
    10033:
      "Пожалуйста, загрузите файл BOM для PCBA! Разрешены только файлы .CSV, .XLS или .XLSX!",
    10034: "Панель по заказу клиента x не может быть пустой!",
    10035: "Панель по заказу клиента y не может быть пустой!",
    10036: "Панель по заказу клиента x не может превышать 500 мм!",
    10037: "Панель по заказу клиента y не может превышать 500 мм!",
    10038: "Рельсы на краю не могут быть пустыми!",
    10039: "Размер панели не может быть пустым!",
    10040: "Тg FR4 не может быть пустым!",
    10041: "Минимальный дамп маски для FR4 не может быть пустым!",
    10042: "Контроль импеданса для FR4 не может быть пустым!",
    10043: "Количество половинных отверстий на краю для FR4 некорректно!",
    10044: "ТЦЕ для алюминия не может быть пустым!",
    10045: "Количество PCBA не может быть 0!",
    10046: "Трафарет необходим для PCBA!",
    10047: "Режим поставки деталей не может быть пустым!",
    10048: "ID адреса не может быть пустым!",
    10049: "Старый пароль неверен!",
    10050: "Электронная почта не существует!",
    10051: "CAPTCHA истек!",
    10052: "CAPTCHA неверна!",
    10053: "Неверный формат электронной почты!",
    10054: "Этот адрес электронной почты уже зарегистрирован!",
    10055: "Неверное имя пользователя или пароль!",
    10056: "Пожалуйста, выберите сообщения, помеченные как прочитанные!",
    10057: "Метод оплаты не может быть пустым!",
    10058: "Тип предмета оплаты не может быть пустым!",
    10059: "ID заказа не может быть пустым!",
    10060: "Пожалуйста, выберите заказ, который вы хотите оплатить!",
    10061: "Ваш заказ был обработан!",
    10062: "Не удалось найти цену на форму!",
    10063: "Невозможно получить текущую цену шаблона!",
    10064: "Ваш заказ ожидает подтверждения или уже был обработан!",
    10065: "Пожалуйста, выберите товар, который вы хотите оплатить!",
    10066:
      "Выбранные товары находятся в ненормальном состоянии или уже оплачены!",
    10067: "Выбранный продукт PCB уже имеет созданный заказ!",
    10068: "Ваш заказ ожидает подтверждения или уже был обработан!",
    10069: "Выбранный продукт PCBA уже имеет созданный заказ!",
    10070: "Выбранный продукт трафарет уже имеет созданный заказ!",
    10071: "Пожалуйста, выберите, что вы хотите посмотреть!",
    10072:
      "Неверный формат электронной почты. Пожалуйста, введите правильный адрес!",
    10073: "Код подтверждения не существует!",
    10074: "Код подтверждения неверен, попробуйте снова!",
    10075: "Пожалуйста, выберите товар для просмотра версии BOM!",
    10076: "Пожалуйста, выберите заказ, который вы хотите подтвердить!",
    10077:
      "Эту операцию невозможно выполнить из-за ненормального состояния продукта!",
    10078: "Пожалуйста, выберите детали бренда, которые вы хотите просмотреть!",
    10079: "Пожалуйста, выберите товар, чтобы убрать из списка избранного!",
    10080:
      "Только пользователи, которые приобрели этот товар, могут оставлять отзывы!",
    10081: "Вы уже оставили отзыв на этот товар!",
    10082: "Пожалуйста, выберите детали товара, которые вы хотите просмотреть!",
    10083: "Номер заказа не может быть пустым!",
    10084: "Заказы не могут быть пустыми!",
    10085: "Ошибка при оплате кредитной картой!",
    10086: "Предоставьте номера заказов для генерации счета для перевода!",
    10087: "Выбранный товар уже имеет созданный заказ!",
    10088: "Следующие товары не могут быть куплены из-за отсутствия на складе!",
    10089: "Невозможно сгенерировать заказ из-за недостатка товаров на складе!",
    10090: "Ошибка сети, пожалуйста, попробуйте позже!",
    10091: "Текущий отзыв ожидает обработки!",
    10092: "Текущий отзыв был обработан!",
    10093:
      "Пожалуйста, выберите детали объявления, которые вы хотите просмотреть!",
    10094:
      "Пожалуйста, выберите местоположение для запроса карусельной диаграммы!",
    10095: "Запрашиваемый ресурс не существует!",
  },
};
