/**
 * 葡萄牙语言包
 */
export default {
  1: "Cancelar",
  2: "Pesquisar",
  3: "Redefinir",
  4: "Enviar",
  5: "Sucesso.",
  6: "Conteúdo...",
  //每个页面的标签title
  title: {
    1: "Termos e Condições | PCB | PCBA | Oceansmile | Lanyue Electronics",
    2: "Protótipo PCB | Montagem PCBA | Uma plataforma para fabricação de produtos eletrônicos inovadores e ágeis | Oceansmile | Lanyue Electronics",
    3: "Inscrever-se | Entrar | Oceansmile | Lanyue Electronics",
    4: "Redefinir senha | Recuperar senha | PCB | PCBA | Oceansmile | Lanyue Electronics",
    5: "Centro de suporte técnico e serviços PCB | Design eletrônico | Oceansmile | Lanyue Electronics",
    6: " | Oceansmile | Lanyue Electronics",
    7: "Notícias da indústria e centro de aprendizado | PCB | PCBA | Oceansmile | Lanyue Electronics",
    8: " | Notícias da indústria e centro de aprendizado | Oceansmile | Lanyue Electronics",
    9: "Trabalho CNC | Peças metálicas | Impressão 3D | Moldagem por injeção | Peças personalizadas | Lanyue Electronics",
    10: "SMT | Serviço de montagem PCB | Cotação rápida de PCB online | Oceansmile | Lanyue Electronics",
    11: "Capacidades de produção PCB | Dê vida às suas ideias | Oceansmile | Lanyue Electronics",
    12: "Sobre nós - Dê vida às suas ideias | Protótipo PCB | Oceansmile | Lanyue Electronics",
    13: "Contato - Dê vida às suas ideias | Protótipo PCB | Oceansmile | Lanyue Electronics",
    14: "Por que nos escolher - Dê vida às suas ideias | Protótipo PCB personalizado | Oceansmile | Lanyue Electronics",
    15: "Cotação rápida de PCB online | Serviço de montagem PCB | Oceansmile | Lanyue Electronics",
    16: "Modelo SMT | Serviço de montagem PCB | Oceansmile | Lanyue Electronics",
    17: "Detalhes da fatura | Oceansmile | Lanyue Electronics",
    18: "Informações bancárias | Transferência bancária | Oceansmile | Lanyue Electronics",
    19: "Parabéns! Pagamento bem-sucedido | Oceansmile | Lanyue Electronics",
    20: "Produto | Loja de produtos",
    21: "Detalhes do produto | Loja de produtos",
    22: "Componentes | IC Easy Store | Fornecimento de componentes",
    23: "Visualização Gerber online | Oceansmile | Lanyue Electronics",
    24: "Carrinho | Oceansmile | Lanyue Electronics",
    25: "Lista de pedidos | Oceansmile | Lanyue Electronics",
    26: "Lista de notificações de pedidos | Oceansmile | Lanyue Electronics",
    27: "Gerenciar endereço de entrega | Oceansmile | Lanyue Electronics",
    28: "Informações pessoais | Oceansmile | Lanyue Electronics",
    29: "Lista de novos pedidos",
    30: "Cupons",
    31: "Reclamações e comentários",
    32: "Lista de anúncios | Oceansmile | Lanyue Electronics",
    33: " | Detalhes do anúncio",
    34: "Registros de pagamentos | Verificação",
    35: "Notícias da indústria e centro de aprendizado | PCB | PCBA | Oceansmile | Lanyue Electronics",
    36: " | Notícias da indústria e centro de aprendizado | Oceansmile | Lanyue Electronics",
    37: " | Fórum centro pessoal | Notícias da indústria e centro de aprendizado | Oceansmile | Lanyue Electronics",
  },
  // 头部页面
  layout: {
    1: "Centro de ajuda",
    2: "Enviar uma mensagem",
    3: "WhatsApp/Linhas de suporte",
    4: "Meu carrinho",
    5: "Bem-vindo ao Oceansmile",
    6: "Entrar",
    7: "Novo cliente?",
    8: "Inscreva-se gratuitamente",
    9: "E-mail",
    10: "Sair",
    11: "Carrinho",
    12: "Meus pedidos",
    13: "Notificações de progresso do pedido",
    14: "Meus cupons",
    15: "Endereço de entrega",
    16: "Meu perfil",
    17: "Carta",
    18: "Notificações",
    19: "Início",
    20: "Montagem PCB",
    21: "Cotação rápida PCB/PCBA",
    22: "Cotação rápida de modelos",
    23: "Loja de produtos",
    24: "Notícias e blog",
    25: "Trabalho CNC/Impressão 3D",
    26: "Produto e capacidade",
    27: "Sobre nós",
    28: "Contato",
    29: "Por que nos escolher",
    30: "Cotação rápida de PCB",
  },
  //脚部页面
  footer: {
    1: {
      1: "CONTEÚDOS RÁPIDOS",
      2: "Deixe sua mensagem abaixo e um de nossos representantes entrará em contato com você dentro de 48 horas.",
      3: "Digite seu e-mail",
      4: "Inscrever-se",
    },
    2: {
      1: "Mais de 10 anos de experiência na fabricação rápida de protótipos PCB e produção de PCB e PCBA de pequena a média escala. Fidelização de clientes com qualidade e serviço superiores...",
      2: "Endereço",
      3: "Comunidade Songyuansha, Rua Guanhu, Distrito Longhua, Shenzhen, Guangdong, China",
      4: "Telefone",
      5: "Email",
      6: "Certificado por",
    },
    3: {
      1: "Competências",
      2: "Produtos/Serviços",
      3: "Suporte ao cliente",
      4: "Sobre nós",
      5: "Competências PCB",
      6: "Cotação rápida PCB",
      7: "Contato",
      8: "Sobre nós",
      9: "Competências de montagem PCB",
      10: "Montagem PCB",
      11: "Centro de ajuda",
      12: "Por que comprar de nós?",
      13: "Modelos SMD",
      14: "Nossos equipamentos",
      15: "Consultoria personalizada para projetos",
      16: "Guia de métodos de envio",
      17: "Competências PCB em alumínio",
      18: "Soluções industriais",
      19: "Avaliações",
      20: "Política de privacidade e acordos",
      21: "Competências PCB flexíveis",
      22: "Política de devolução",
      23: "Impressão 3D/CNC",
      24: "Termos de uso",
    },
    4: "Copyright © 2024 OSpcb. Todos os direitos reservados. Lanyue Electronics (Shenzhen) Co., Ltd.",
  },
  //个人中心页面
  person: {
    1: "Centro de membro",
    2: "Pedido",
    3: "Minha conta",
    4: "Sistema",
    5: "Carrinho",
    6: "Meus pedidos",
    7: "Durabilidade",
    8: "Notificação de pedido",
    9: "Registros de pagamento",
    10: "Endereço",
    11: "Meus cupons",
    12: "Meu perfil",
    13: "Carta",
    14: "Notificações",
  },
  //aboutUs页面
  aboutUs: {
    1: "A Oceansmile é uma plataforma abrangente para a fabricação ágil e inovadora de produtos eletrônicos em Shenzhen. Com mais de 15 anos de experiência na fabricação de PCBs e PCBAs na China, oferecemos serviços completos de prototipagem e fabricação ágil. Ajudamos nossos clientes a levar seus produtos ao mercado a tempo, com recursos completos, custos competitivos, materiais confiáveis e qualidade excepcional. A Oceansmile emprega mais de 200 pessoas em nossa fábrica, com uma capacidade de produção mensal superior a 3.000 amostras ou pequenas séries. Servimos mais de 150.000 clientes em mais de 250 países ao redor do mundo. Não há pedido mínimo. Oferecemos um serviço personalizado 1 a 1 e soluções completas para diferentes cenários de aplicações industriais. Como um dos fabricantes mais experientes da China, somos seu melhor parceiro comercial e amigo em todos os aspectos de suas necessidades de projeto. Um foco no cliente e a adaptabilidade. Crescemos com você e nos ajustamos às suas necessidades.",
    2: {
      1: "Agilidade",
      2: "Custos",
      3: "Garantia de qualidade",
      4: "Nada é impossível",
    },
    3: {
      1: "Gestão de pedidos e processos de fabricação online",
      2: "Entrega rápida",
      3: "Capacidades completas de cadeia de suprimentos",
      4: "Respostas rápidas",
    },
    4: {
      1: "Orçamentos online, preços transparentes",
      2: "Sem taxas ocultas ou custos adicionais",
      3: "Baixos custos para produção de pequenas quantidades",
    },
    5: {
      1: "Processos de produção rigorosos",
      2: "Equipe de engenheiros profissionais (mais de 5 anos de experiência)",
      3: "Equipamentos de alto desempenho",
      4: "Base sólida de fornecedores",
      5: "Experiência completa em produtos",
    },
    6: {
      1: "Serviço sem limites",
      2: "Foco no cliente e adaptabilidade",
    },
    7: {
      1: "Oferecemos serviços completos de prototipagem e fabricação ágil. Não é apenas PCB/PCBA!",
      2: "Placas de circuito impresso / Montagem de PCB / SOLUÇÕES INDUSTRIAIS DE IoT DE PONTA / Testes e programas de testes personalizados / Fornecimento de componentes / Ajustes mecânicos (metais, silicone, plásticos, madeira) / Moldagem por injeção / Assistência no design de PCB e layout / Ajustes de baterias / Design de produtos / OEM / ODM",
    },
    8: "A Oceansmile possui três grandes fábricas especializadas em protótipos de PCB, produção em massa de PCBs, montagem de PCBs e produção mecânica. Com uma área de 15.000 metros quadrados e 6 linhas de SMT, focamos na validação de produtos em desenvolvimento dos clientes (protótipos), garantindo capacidade suficiente para produção em massa.",
    9: "Confiança das maiores marcas do mundo",
    10: "A Oceansmile se dedica a atender as necessidades dos nossos clientes (mais de 15.000) em várias indústrias e países no que diz respeito a qualidade, entregas, custos e outros requisitos. Como um dos fabricantes mais experientes de PCBs/PCBAs na China, estabelecemos parcerias com muitas empresas internacionais de renome.",
    11: {
      1: "Clientes pagos",
      2: "Países e regiões",
      3: "Parceiros comerciais",
    },
    12: "Por que escolher a Oceansmile?",
  },
  //联系我们页面
  contact: {
    1: "Entre em contato",
    2: {
      1: "Dúvidas sobre vendas e produtos",
      2: "A Oceansmile é uma plataforma abrangente para a fabricação ágil e inovadora de produtos eletrônicos em Shenzhen. Oferecemos serviços rápidos de prototipagem de PCB e montagem de PCB. Nossa equipe pode responder suas dúvidas sobre capacidades de PCB, preços de PCB e PCBA, peças mecânicas de silicone/plástico... e qualquer outro aspecto das suas necessidades de projeto. Realizar um pedido na Oceansmile é fácil, pois oferecemos orçamentos online para PCBs padrão, PCBs flexíveis / rígido-flexíveis, moldes SMD e montagem de PCBs, com verificações gratuitas de arquivos Gerber, garantindo entregas pontuais via DHL, FedEx, UPS e outros.",
      3: "Por favor, deixe sua mensagem abaixo e um de nossos representantes responderá ",
      4: "dentro de 24 horas.",
    },
    3: {
      1: "Nome",
      2: "E-mail",
      3: "Número de telefone",
      4: "Seu número de telefone",
      5: "Empresa",
      6: "Nome da sua empresa",
      7: "Captcha",
      8: "Seu captcha",
      9: "Mensagem",
      10: "Conte-nos como podemos ajudá-lo com produtos, entregas, pagamentos e mais.",
      11: "Enviar mensagem",
    },
    4: "Pensamento focado no cliente e adaptabilidade!",
    5: "Ajudamos você a levar seus produtos ao mercado a tempo, com recursos completos, custos competitivos, materiais confiáveis e qualidade excepcional.",
    6: "Serviço em qualquer condição",
    7: {
      1: "Dicas",
      2: "1. Para uma resposta mais rápida, recomendamos que entre em contato com nossa equipe através da página da sua conta após ",
      3: "Entrar/Registrar.",
      4: "2. Visite ",
      5: "o centro de suporte da Oceansmile ",
      6: "para encontrar rapidamente as perguntas frequentes sobre pedidos, envios, pagamentos e mais.",
    },
    message: {
      1: "O nome é obrigatório.",
      2: "O e-mail é obrigatório.",
      3: "O número de telefone é obrigatório.",
      4: "A empresa é obrigatória.",
      5: "O captcha é obrigatório.",
      6: "A mensagem é obrigatória.",
    },
  },
  //why us页面
  why: {
    1: "Por que escolher a ",
    2: "A Oceansmile é um fabricante de PCBs/PCBAs de alta qualidade em Shenzhen, China, com mais de 15 anos de experiência. Com capacidades profissionais na fabricação de PCBs/PCBAs, nossos engenheiros de PCB e SMT com mais de 10 anos de experiência revisarão seus arquivos de design duas vezes. Além disso, a Oceansmile é certificada pela IATF16949, ISO9001, ISO14001, UL, CQC, RoHS e REACH; gerenciamos todo o processo, incluindo PCB/PCBA / Placas de circuito impresso / Montagem de PCB / SOLUÇÕES INDUSTRIAIS DE IoT DE PONTA / Testes e programas de testes personalizados / Fornecimento de componentes / Ajustes mecânicos (metais, silicone, plásticos, madeira) / Moldagem por injeção / Assistência no design de PCB e layout / Ajustes de baterias / Design de produtos / OEM / ODM e envio final. Podemos montar componentes BGA, Micro-BGA, QFN e outros componentes sem montagem. Protegemos rigorosamente a segurança dos documentos de produção e os direitos de propriedade intelectual dos produtos. Oferecemos serviços completos de prototipagem e fabricação ágil, consulte todas as peças que você precisa!",
    3: "Plataforma integral de suporte multifuncional para pesquisa e desenvolvimento (não é só PCB e PCBA!)",
    4: "A Oceansmile é um fabricante profissional de serviços globais com 15 anos de experiência na produção de PCB e PCBA na China. Com capacidades profissionais de fabricação de PCB/PCBA, podemos apoiar todos os designs avançados com requisitos rigorosos, incluindo FR4, HDI, perfuração a laser, microvias, pads, placas de micro-ondas e RF, até 32 camadas. A Oceansmile é um fabricante profissional com 15 anos de experiência na produção de PCBs e PCBAs na China.",
    5: "Oferecemos serviços completos de prototipagem e fabricação ágil. Não é apenas PCB/PCBA!",
    6: "Placas de circuito impresso / Montagem de PCB / SOLUÇÕES INDUSTRIAIS DE IoT DE PONTA / Testes e programas de testes personalizados / Fornecimento de componentes / Ajustes mecânicos (metais, silicone, plásticos, madeira) / Moldagem por injeção / Assistência no design de PCB e layout / Ajustes de baterias / Design de produtos / OEM / ODM...",
    7: "Agilidade: Fabricação rápida de PCB e montagem de PCB. Aceleramos o tempo de prototipagem para você.",
    8: "Custos: Redução de custos, fabricação de produtos mais competitivos e benefícios mútuos.",
    9: "Garantia de qualidade: Excelente sistema de controle de recursos e fornecedores. Os planos de controle de qualidade são atualizados e melhorados continuamente.",
    10: "Proteção de propriedade intelectual: Protegemos rigorosamente os direitos de propriedade intelectual dos produtos de nossos clientes, garantindo que todo o processo de produção esteja sob supervisão de segurança. Assinamos acordos de confidencialidade (NDA) com nossos clientes para proteger os direitos de propriedade intelectual de seus produtos de forma permanente.",
    11: "Nada é impossível: Crescemos com nossos clientes e transformamos suas ideias em realidade.",
    12: "Oferta completa de serviços para a fabricação ágil com recursos confiáveis.",
    13: "Os materiais de PCB da Oceansmile são certificados pela IATF 16949, ISO9001, ISO14001, UL, RoHS e REACH. Quanto aos PCBs montados, oferecemos uma variedade de métodos de verificação meticulosos, como Inspeção Óptica Automatizada (AOI), Teste em Circuito (ICT), Teste de Raio X, Teste Funcional e vários estágios de Inspeção Visual, o que leva a um ótimo controle de qualidade de PCB e Montagem de PCB.",
    14: "Somos responsáveis ​​por cada produto de acordo com processos de produção rigorosos. Temos uma equipe de engenharia profissional (todos têm mais de 5 anos de experiência). Equipamentos com excelente desempenho, todos os equipamentos são de alta precisão e marcas de alto nível poderosas. Temos AVL robusto, e o sistema de gerenciamento de fornecedores pontuará de acordo com a qualidade, prazo de entrega e custo do fornecedor, e eliminará regularmente os fornecedores não qualificados e o fornecedor recém-adicionado. A experiência abundante em produtos pode aumentar muito a taxa de sucesso da pesquisa e desenvolvimento de produtos, e pode fornecer soluções existentes, produtos e casos de mercado para clientes para referência.",
    15: "Respeite e proteja os direitos de propriedade intelectual",
    16: "protege rigorosamente os direitos de propriedade intelectual dos produtos dos clientes, contando com ordens e sistemas de produção rigorosos para garantir a segurança dos documentos de design, e cada equipamento de produção é monitorado pelo sistema de segurança. Assinamos o acordo de NDA com nossos clientes para proteger permanentemente os direitos de propriedade intelectual dos produtos. Confie na Oceansmile, nós lhe damos o melhor serviço.",
    17: "Nada impossível: crescemos com nossos clientes e transformamos nossas ideias em realidade",
    18: "Oferecemos serviços de prototipagem e fabricação ágil completos. Temos opções de serviço ilimitadas, contando com os melhores recursos em Shenzhen, China. Mentalidade orientada para o cliente e autoadaptável. Equipes experientes, pensamentos vibrantes, continuaremos avançando e nunca pararemos. Escolha a Oceansmile, o passo importante mais perto do sucesso.",
  },
  //论坛详情页面
  articleDetail: {
    1: "Notícias e Blog",
    2: "Comentários",
    3: "Faça login para publicar comentários.",
    4: "Publicar comentário",
    5: "Responder",
    7: "Publicar artigo",
    8: "TAG POPULAR",
    9: "Categorias",
    message: {
      1: "A categoria do fórum não pode estar vazia.",
      2: "O título não pode estar vazio.",
      3: "A imagem do título não pode estar vazia.",
      4: "O conteúdo não pode estar vazio.",
      5: "Criado com sucesso.",
      6: "Por favor, faça login para publicar um artigo.",
      7: "Selecione no máximo 10 tags de artigo!",
      8: "Por favor, insira um comentário!",
      9: "Comentário enviado com sucesso.",
      10: "Tem certeza de que deseja excluir o comentário?",
      11: "Dicas",
      12: "Excluir",
      13: "Excluído com sucesso!",
    },
  },
  //论坛页面
  article: {
    1: "Centro do Fórum",
    2: "Publicar artigo",
    3: "Categorias",
    4: "Selecione uma categoria",
    5: {
      1: "Categoria",
      2: "Selecione uma categoria",
      3: "Título",
      4: "Por favor, insira o título do artigo!",
      5: "Imagem do título",
      6: "Esboço do conteúdo",
      7: "Por favor, insira uma breve descrição do artigo!",
      8: "Tags",
      9: "Procurar/Adicionar",
    },
  },
  //论坛个人详情页面
  forum: {
    1: "Publicações",
    2: "Coleções",
    3: "Curtidas",
    4: "Publicar artigo",
    5: {
      1: "Criar um novo artigo",
      2: "Categoria",
      3: "Título",
      4: "Por favor, insira o título do artigo!",
      5: "Imagem do título",
      6: "Esboço do conteúdo",
      7: "Por favor, insira uma breve descrição do artigo!",
      8: "Pesquisar/Adicionar",
    },
  },
  //帮助中心列表
  help: {
    1: "Como podemos ajudá-lo?",
    2: "Digite palavras-chave relacionadas para pesquisar",
  },
  //帮助中心详情
  helpDetail: {
    1: "Centro de ajuda",
  },
  //cnc/3D打印/模具页面
  cnc: {
    1: {
      1: "Impressão 3D",
      2: "Tecnologias SLA, MJF, SLM, FDM, SLS",
      3: "•Resina, Nylon, Metal e Plástico",
      4: "•Precisão até 0,2 mm",
      5: "•Prazo de entrega a partir de 2 dias úteis",
    },
    2: {
      1: "Usinagem CNC",
      2: "Fresagem (3-, 4- e 5-eixos), torneamento e pós-processamento",
      3: "•Alumínio, Cobre, Plástico",
      4: "•Precisão até 0,05 mm",
      5: "•Prazo de entrega a partir de 3 dias úteis",
    },
    3: {
      1: "Fabricação de moldes por injeção",
      2: "Moldagem por vácuo",
      3: "•ABS, POM, Nylon, PC, PC/ABS, PVC, PE, PP, PS, TPU, Silicone, Materiais personalizados",
      4: "•Ciclo de vida >=50k/100k",
      5: "•Precisões controladas com ISO 2768-1",
      6: "•Prazo de entrega de 15-25 dias úteis",
    },
    4: {
      1: "Países de envio",
      2: "Área da fábrica",
      3: "Materiais e acabamentos",
      4: "Solicitar orçamento",
    },
    5: "Solicitar orçamento agora",
    6: "Serviço 360° fechado",
    7: "A equipe de engenheiros e serviço profissional oferece suporte online 360° em tempo real, com relatórios regulares de acompanhamento da produção.",
    8: "Processo padrão de serviço",
    9: "A Oceansmile tem um sistema de gestão digital poderoso para fornecer serviços rápidos, completos e de alta qualidade.",
    10: {
      1: "Orçamento rápido",
      2: "Orçamento e DFM em 24 horas após o recebimento dos documentos pelos engenheiros.",
      3: "Confirmação da solução",
      4: "Selecione os requisitos de material e acabamento, confirme o plano de produção.",
      5: "Confirmação de pagamento",
      6: "Verifique seu pedido e faça o pagamento. Adicione vendas para obter serviços VIP.",
      7: "Usinagem / Impressão 3D",
      8: "Corte - Programação - Processamento - Pós-processamento, Inspeção - Envio",
      9: "Pós-venda",
      10: "Verifique os produtos com o desenho. Reagiremos rapidamente a problemas de qualidade.",
    },
  },
  //commodityDetail 商品详情页面
  commodityDetail: {
    1: "Detalhes do produto",
    2: "Avaliações",
    3: {
      1: "Avaliações",
      2: "Comentários",
      3: "Imagens",
      4: "Clique para carregar",
      5: "Publicar comentário",
      6: "Digite seu comentário...",
      7: "Nenhum comentário por enquanto",
    },
    4: "Inclui bateria",
    5: "dias úteis",
    6: "em estoque",
    7: "selecionar",
    8: {
      1: "Armazém CN",
    },
    9: "ADICIONAR AO CARRINHO",
    10: "Tags",
    11: "ESGOTADO",
    12: "Página inicial",
    13: "Loja de produtos",
    14: "SKU",
    15: "USD",
    16: "L/T",
    message: {
      1: "Por favor, insira o conteúdo da avaliação. Este campo é opcional.",
      2: "Observações",
      3: "Nenhum",
      4: "Adicionado com sucesso.",
      5: "Avaliação bem-sucedida!",
      6: "Já existem três imagens! Por favor, remova uma antes de carregar uma nova.",
    },
  },
  commodity: {
    1: {
      1: "palavra-chave",
      4: "por página",
    },
    2: {
      1: "Mais...",
      2: "Adicionar ao carrinho",
      3: "ESGOTADO",
      4: "Nenhum resultado encontrado",
    },
    3: {
      1: "Observações e quantidade",
      2: "Quantidade",
      3: "Descrição",
      4: "Por favor, insira o conteúdo da avaliação. Você pode ignorar",
      5: "SKU",
      6: "Sem dados",
    },
    message: {
      1: "Adicionado com sucesso.",
    },
  },
  //忘记密码
  forget: {
    1: "Esqueci a senha",
    2: {
      1: "Endereço de e-mail",
      2: "E-mail",
    },
    3: {
      1: "Código de autorização",
      2: "Código",
      3: "Obter",
    },
    4: {
      1: "Nova senha",
    },
    5: {
      1: "Confirmar senha",
    },
    message: {
      1: "Por favor, insira o endereço de e-mail.",
      2: "Por favor, insira o endereço de e-mail.",
      3: "Por favor, insira o código de autorização.",
      4: "Por favor, insira a senha.",
      5: "Por favor, confirme a senha.",
      6: "A senha não corresponde à senha de confirmação.",
      7: "Senha atualizada com sucesso.",
    },
  },
  //主页
  home: {
    1: {
      1: "Calculadora de fabricação de PCB",
      2: "Comprimento \\ Largura",
      3: "Comprimento",
      4: "Largura",
      5: "Quantidade",
      6: "Por favor, insira",
      7: "Camadas",
      8: "Espessura",
      9: "Novo? Não registrado?",
      10: "Obter",
      11: "Cupons aqui!",
      12: "Obter orçamento",
      13: "Calculadora de montagem PCBA",
    },
    2: {
      1: "Prazo rápido",
      2: "Prazo de entrega em até 24 horas",
      3: "Assistência personalizada",
      4: "Experiência de compra tranquila",
      5: "Melhor valor",
      6: "Preços diretos de fábrica",
      7: "Notícias",
      8: "Prototipagem fácil de PCB",
      9: "Com mais de uma década de experiência em PCB...",
      10: "Saiba mais",
    },
    3: {
      1: "Orçamento instantâneo de PCB",
      2: "1~6 camadas de Poliamida flexível",
      3: "Formulário de pedido de 1 peça",
      4: "Prazo de entrega de 4~5 dias",
      5: "ORÇAMENTO INSTANTÂNEO PCB",
      6: "Envie o arquivo PCB",
      7: "Revisão do pedido",
      8: "Pagamento",
      9: "Acompanhamento em tempo real",
      10: "Entrega",
      11: "Confirmar recebido",
      12: "Clique em 'Orçamento instantâneo' na barra de menu, selecione 'Orçamento instantâneo PCB/PCBA'. Então você acessa a página de orçamento e envia o arquivo PCB.",
      13: "Envie arquivos Gerber (zip, rar), outros formatos podem causar erros devido a problemas de compatibilidade de versão. O sistema fará a revisão automática em 30 segundos. O upload falhou? Você pode pular e enviar o arquivo por e-mail para",
      14: "Os engenheiros experientes da Oceansmile farão o DFM no design do produto e fornecerão sugestões de otimização para o produto.",
      15: "Pagamento online, seguro e rastreável. Suportamos vários métodos de pagamento, Paypal, cartão de crédito, transferência bancária offline, etc. Se você tiver alguma dúvida sobre o pagamento, entre em contato com nosso atendimento ao cliente.",
      16: "Visualize o estágio de progresso do pedido e consulte o status da produção do produto a qualquer momento. Um pessoal especializado será designado para acompanhar os pedidos e garantir que os produtos sejam entregues a tempo e com qualidade.",
      17: "Uma ampla variedade de opções de mensageria e empresas de entrega globalmente reconhecidas garantem uma entrega rápida e segura dos produtos.",
      18: "Você receberá seu produto. Mantemos relações de ganha-ganha com nossos clientes e continuaremos oferecendo serviço pós-venda de alta qualidade. Se você tiver problemas com o produto, utilizaremos nossos recursos para ajudá-lo.",
    },
    4: {
      1: "Produtos mais vendidos",
      2: "Prototipagem PCB",
      3: "A partir de",
      4: "Tempo de construção",
      5: "Montagem PCB",
      6: "Fornecedores de componentes",
      7: "PCB Flex, Rígido-Flex",
      8: "PCB HDI",
      9: "Impressão 3D",
      10: "dias",
      11: "horas",
      12: "Usinagem CNC",
      13: "pcs",
    },
    5: {
      1: "Promoção de serviço de montagem",
      2: "Somente",
      3: "NO TOTAL",
      4: "para montagem de 1-20 peças",
      5: "COTAR AGORA",
      6: "Solução completa para PCB & Montagem",
      7: "Fácil de fabricar, Quantidade, Uma única vez",
      8: "Montagem SMT e de furos passantes",
      9: "Soldagem por onda para PCBA",
      10: "Cotação online de PCBA",
      11: "Solução de compra BOM completa",
      12: "Taxa de satisfação superior a 99,6%",
      13: "Montagem de caixa, fiação",
      14: "Testes funcionais",
      15: "e outros testes",
      16: "Programação de IC",
      17: "BGA",
      18: "QFN",
      19: "Últimos 30 dias",
      20: "Clientes",
      21: "Pagando",
      22: "SMT",
    },
    6: {
      1: "Fabricante de PCB protótipo acessível na China",
      2: "Data",
      3: "Nome do comprador",
      4: "Última vez",
      5: "Progresso",
    },
    7: {
      1: "CAMADA",
      2: "PCB de 4 camadas com 2 camadas de roteamento, uma camada de terra e uma camada de alimentação",
      3: "Empilhamento de PCB multicamadas",
      4: "PCB de 6 camadas com GTL, GBL e 4 camadas internas. Cada sinal que viaja de 1 a 6 camadas",
      5: "PCB de 8 camadas com GTL, GBL e 6 camadas internas. Cada sinal que viaja de 1 a 8 camadas",
    },
    8: "Do nosso blog",
    9: "O que os clientes dizem",
    10: "mm",
  },
  //登录页面
  login: {
    1: "Junte-se / Entrar",
    2: "Criar Conta",
    3: {
      1: "Endereço de E-mail",
      2: "Código",
      3: "Obter",
      4: "Senha",
      5: "Confirmar Senha",
      6: "Cadastrar-se",
      7: "Ao criar uma conta, você concorda com nossos",
      8: "Termos e Condições",
    },
    4: {
      1: "ENTRAR",
      2: "Bem-vindo de volta!",
      3: "Esqueceu a senha?",
      4: "Entrar",
    },
    message: {
      1: "Por favor, insira um endereço de e-mail.",
      2: "Por favor, aceite os termos de uso para continuar.",
      3: "Por favor, insira o código de autenticação.",
      4: "Por favor, insira a senha.",
      5: "Por favor, confirme a senha.",
      6: "A senha não corresponde à confirmação.",
      7: "Registro bem-sucedido! Faça login no lado direito da página.",
    },
  },
  //pcbAssembly页面
  pcbAssembly: {
    1: {
      1: "Preço razoável garantido",
      2: "$3.78 para 5 pcs de protótipo PCB 10*10cm, $25 para 10pcs de montagem PCB.",
      3: "Garantia de material PCB",
      4: "Oceansmile utiliza apenas materiais de alta qualidade com conformidade ROHS para produção.",
      5: "Qualidade garantida",
      6: "Oceansmile é totalmente compatível com o sistema de gestão de qualidade ISO 9001:2015.",
      7: "Envio no prazo garantido",
      8: "Protótipo de PCB expresso, serviço um a um online, envio pontual 99% via DHL.",
    },
    2: {
      1: "Exibição de produtos",
      2: "Com mais de 10 anos de experiência em prototipagem rápida de PCB e produção de PCB em pequeno e médio volume.",
    },
    3: {
      1: "Capacidades de montagem de PCB",
      2: "Temos orgulho de nossa reputação como especialista líder em montagem de PCB de tecnologia de montagem em superfície, furos passantes e tecnologia mista, além de serviços de fabricação eletrônica e montagem de PCB turnkey.",
      3: "MONTAGEM DE PCB PROTÓTIPO",
      4: "Solicitar orçamento agora",
      5: "Tipos de PCB para montagem",
      6: "Placa FR-4",
      7: "Placa com núcleo metálico",
      8: "Placa flexível",
      9: "Placa rígida-flexível",
      10: "Etc.",
      11: "Tipos de soldagem",
      12: "Soldagem sem chumbo",
      13: "Montagem de componentes SMD e TH",
      14: "Montagem de um lado e dois lados",
      16: "Fornecedores de componentes",
      17: "Digi-key, Mouser, Arrow, Element 14 e",
      18: "mais…",
      19: "Mercado chinês para componentes universais",
      20: "Componente após sua aprovação.",
      21: "Tipo de teste",
      22: "Inspeção visual",
      23: "Inspeção AOI",
      24: "Inspeção por raios-X",
      25: "Teste em circuito",
      26: "Teste funcional",
    },
    4: {
      1: "Showroom da fábrica",
      2: "Com mais de 10 anos de experiência em prototipagem rápida de PCB e produção de PCB em pequeno e médio volume.",
    },
    5: {
      1: "Como pedir o serviço de montagem de PCB na OceanSmile?",
      2: "Clique aqui para obter mais detalhes sobre como pedir PCBA ou entre em contato conosco",
      3: "se tiver dúvidas sobre nossos serviços de montagem.",
    },
    6: {
      1: "Primeiro passo",
      2: "Faça upload dos arquivos Gerber do seu PCB e preencha o formulário",
      3: "Segundo passo",
      4: "Faça upload de um arquivo BOM formatado",
      5: "Terceiro passo",
      6: "Obtenha um orçamento online instantâneo",
    },
    7: "Carregando...",
  },
  //Product&Capacity页面
  product: {
    1: {
      1: "Capacidades de fabricação e montagem de PCB",
      3: "Saiba mais sobre as capacidades da Oceansmile e traga suas ideias para a realidade rapidamente.",
      4: "Verde",
      5: "Roxo",
      6: "Vermelho",
      7: "Amarelo",
      8: "Azul",
      9: "Branco",
      10: "Preto",
    },
    2: {
      1: "PCB rígido",
      2: "PCB flexível",
      3: "Montagem de PCB",
    },
    3: {
      1: "Especificações de PCB",
      2: "Tamanho do furo/perfuração",
      3: "Anel mínimo",
      4: "Deslocamento mínimo",
      5: "Largura e espaçamento mínimo das trilhas",
      6: "BGA",
      7: "Máscara de solda",
    },
    4: {
      1: "Características",
      2: "Capacidades",
      3: "Observações",
      4: "Modelos",
      5: "Número de camadas",
      6: "Camadas",
      7: "O número de camadas de cobre da placa.",
      8: "Material",
      9: "FR-4",
      10: "Alumínio",
      11: "Núcleo de cobre",
      12: "Rogers",
      13: "Cerâmica",
      14: "PCB FR-4",
      15: "PCB de alumínio",
      16: "Dimensões da placa",
      17: "Mín",
      18: "Máx",
      19: "O tamanho da placa PCB",
      20: "Tolerância dimensional",
      21: "(Regular) para usinagem CNC, e ±0,4mm para escoramento em V",
      22: "Espessura da placa",
      23: "A espessura final da placa",
      24: "Tolerância de espessura",
      25: "± 0,1mm para espessura de placa ≤1,6mm, ± 10% para espessura de placa ≥1,6mm",
      26: "Cobre final",
      27: "Camada externa",
      28: "Camada interna",
      29: "Acabamento de superfície",
      30: "HASL (com chumbo/sem chumbo), ENIG, OSP, ENEPIG",
      31: "O processo extra para expor o cobre e ajudar na soldabilidade.",
      32: "Tg 130 / Tg 150 / Tg 170",
      33: "1- 3 W/(m*k)",
      34: "380 W/(m*k)",
      35: "RO4350B / RO4003C",
      36: "Al2O3 / AIN",
    },
    5: {
      1: "Características",
      2: "Capacidade",
      3: "Notas",
      5: "Tamanho do buraco (buraco arredondado)",
      6: "Furação mecânica",
      7: "Furação a laser",
      8: "Tamanho do buraco (buraco em fenda)",
      9: "Tamanho do buraco (buraco meio redondo)",
      10: "O diâmetro dos buracos meio redondos deve ser maior que 0,5 mm e o espaçamento entre os buracos deve ser maior que 0,9 mm.",
      11: "Vias cegas/enterra",
      12: "Oceansmile suporta vias cegas e vias enterradas.",
      13: "Tolerância do tamanho do buraco",
      14: "PTH",
      15: "NPTH",
      16: "Buraco em fenda",
    },
    6: {
      1: "Cobre",
      2: "VIA",
    },
    7: {
      1: "Espaçamento entre furos (redes diferentes)",
      2: "O espaçamento mínimo entre furos que podemos aceitar.",
      3: "Espaçamento entre vias (mesmas redes)",
      4: "O espaçamento mínimo entre vias que podemos aceitar.",
      5: "Espaçamento entre pads (pads SMD, redes diferentes)",
      6: "O espaçamento mínimo entre pads SMD que podemos aceitar.",
      7: "Espaçamento entre pads (pads TH, redes diferentes)",
      8: "O espaçamento mínimo entre pads TH que podemos aceitar.",
      9: "Via para trilha",
      10: "O espaçamento mínimo entre via e trilha que podemos aceitar.",
      11: "PTH para trilha",
      12: "O espaçamento mínimo entre PTH e trilha que podemos aceitar.",
      13: "NPTH para trilha",
      14: "O espaçamento mínimo entre NPTH e trilha que podemos aceitar.",
      15: "Pad para trilha",
      16: "O espaçamento mínimo entre pad e trilha que podemos aceitar.",
    },
    8: {
      1: "Largura mínima da trilha",
      2: "Espaçamento mínimo",
      3: "Largura/espalhamento recomendados para produção em lote",
    },
    9: {
      1: "Especificação",
      2: "Dimensões mínimas do pad BGA",
      3: "Distância mínima entre BGAs",
      4: "Diâmetro do BGA",
      5: "Mínimo para HASL com chumbo",
      6: "Mínimo para HASL sem chumbo",
      7: "Mínimo para outras finalizações de superfície",
      8: "O diâmetro mínimo dos pads BGA que podemos aceitar.",
      9: "Espaçamento entre pads BGA",
      10: "O espaçamento mínimo entre os pads BGA que podemos aceitar.",
    },
    10: {
      1: "Cor da máscara de solda",
      2: "A cor da placa PCB",
    },
    11: "mm",
  },
  //元器件商品页面
  component: {
    1: "partNo",
    2: {
      1: "Informações do produto",
      2: "Descrição",
      3: "RoHs",
      4: "Disponibilidade",
      5: "Em estoque",
      6: "Preço",
      7: "MOP",
      8: "MPQ",
    },
  },
  //合同条款和条件
  terms: {
    1: "Última atualização: 16 de maio de 2024.",
    2: "Declaração de operação do site Oceansmile",
    3: "Este site é operado pela Oceansmile, que inclui cnospcb.com e Lanyue Electronics (Shenzhen) Co., Ltd. Neste site, os termos 'Oceansmile', 'OSPCB', 'Lanyue', 'nós' e 'nosso' se referem às marcas e subsidiárias acima mencionadas. Os termos e condições descritos neste documento se aplicam a essas entidades e a todos os serviços fornecidos pelas marcas acima.",
    4: "Política de Privacidade",
    5: "Esta é a Política de Privacidade publicada pela Lanyue/Oceansmile/Lanyue Electronics (Shenzhen) Co., Ltd. Que inclui o processamento de dados pessoais e como exercer seus direitos, etc. A política de privacidade se aplica a este site em relação a qualquer produto e/ou serviço fornecido pela Lanyue (Oceansmile). Como controlador de seus dados pessoais, a Lanyue concorda que a proteção de seus dados pessoais é importante para nós, e a Lanyue tomará todas as medidas razoáveis para coletar e usar seus dados pessoais de acordo com as leis e regulamentos aplicáveis. Por favor, leia cuidadosamente esta política de privacidade quando você for usuário dos produtos e/ou serviços da Oceansmile. Se você tiver alguma dúvida sobre suas informações pessoais, entre em contato conosco pelo e-mail designado: service@cnospcb.com. Visão geral do serviço. Os sites de serviços fornecidos pela Oceansmile contêm todas as informações, ferramentas e serviços que visam apoiar os usuários, desde que você aceite completamente e cumpra todos os termos, condições, políticas e avisos declarados neste site.",
    6: "Dados pessoais",
    7: "De acordo com a definição específica estabelecida no Regulamento nº 2016/679 sobre a proteção de pessoas naturais no que diz respeito ao processamento de dados pessoais (o 'GDPR'), os dados pessoais podem ser processados pela Lanyue principalmente nas seguintes categorias",
    8: "1. Alguns dados pessoais são fornecidos por você voluntariamente.",
    9: "2. Outros dados pessoais são processados automaticamente quando você visita nossos sites e/ou usa os produtos e/ou serviços da Lanyue.",
    10: "3. Outros dados pessoais são coletados através de sites e serviços de terceiros.",
    11: "Conformidade com Controle de Exportação",
    12: "Processamento de Dados Pessoais",
    13: "Você fornecerá seus dados pessoais voluntariamente para a Lanyue no processo de compra ou em outros processos com seu consentimento prévio, incluindo, mas não se limitando a, seu nome pessoal e suas informações de contato, dados da conta, informações de pagamento, dados de comentário e outros dados que você nos fornecer. A Lanyue usará seus dados pessoais fornecidos para o desempenho de seus pedidos, fornecimento de certos serviços para você, registro de conta e qualquer comunicação entre você e a Lanyue, etc.",
    14: "A Lanyue usará seus dados pessoais para o propósito especificado acima ou quaisquer outros requisitos legais. Você tem o direito de solicitar a retirada ou exclusão de seus dados pessoais fornecidos a qualquer momento se achar que seus direitos não estão protegidos.",
    15: "A Lanyue pode coletar automaticamente seus dados pessoais quando você usar os produtos e/ou serviços da Lanyue, incluindo, mas não se limitando a",
    16: "Informações do dispositivo, como após você comprar os produtos da Lanyue e implantar esses produtos no seu endereço para monitoramento, os servidores da web da Lanyue podem registrar automaticamente seu endereço IP ou qualquer outra informação relacionada ao seu dispositivo.",
    17: "Dados que incluem os produtos que você usa e como você os usa, e seus hábitos de uso ao pesquisar nossos sites, etc. O objetivo de coletar automaticamente seus dados pessoais é avaliar e melhorar sua experiência com os produtos e/ou serviços da Lanyue. Se você não deseja divulgar seus hábitos de uso ou outros dados pessoais, entre em contato com nosso e-mail de contato designado, conforme listado nesta Política de Privacidade.",
    18: "Além disso, o site da Lanyue utiliza serviços de terceiros, que podem incluir qualquer provedor de serviço de pagamento, fornecedores de transporte e anunciantes, etc. Alguns fornecedores podem coletar seus dados pessoais para os fins descritos acima e também têm suas próprias políticas de privacidade para proteger o processamento de seus dados pessoais.",
    19: "PREÇO E PAGAMENTO",
    20: "A Lanyue faz todo o possível para fornecer informações atuais e precisas sobre os produtos e preços, mas não garante a atualidade ou precisão de tais informações.",
    21: "A cotação de preço online não garante a quantidade total do pedido e os prazos de entrega. Todas as quantidades de pedidos e prazos de entrega estão sujeitas a revisão da capacidade de produção. O preço exibido na forma da web ao inserir o pedido será vinculativo apenas após confirmação e após a verificação dos arquivos de documentação enviados. A Lanyue reserva-se o direito de alterar o preço ou cancelar o pedido se a documentação enviada não corresponder aos dados inseridos na tela de entrada do pedido. Nesses casos, notificaremos você sobre as alterações. Se seu pedido for cancelado e você já tiver pago o pedido, a Lanyue fará o reembolso para o método de pagamento original.",
    22: "A Lanyue não se responsabiliza por erros de preços, erros tipográficos ou outros erros em qualquer oferta da Lanyue e a Lanyue se reserva o direito de cancelar qualquer pedido originado de tais erros.",
    23: "ENVIO E ENTREGA",
    24: "O cliente é responsável por garantir que o endereço de entrega fornecido no formulário da web esteja correto e completo. Quaisquer custos adicionais de transporte resultantes de dados incorretos do endereço serão cobrados ao cliente. Clientes internacionais são responsáveis pelos encargos de importação e taxas sobre todos os pedidos.",
    25: "A Lanyue enviará seus produtos dentro de um período comercial razoável para o endereço especificado no seu pedido. Trabalhamos muito para garantir que todos os seus pedidos sejam enviados a tempo. No entanto, há ocasiões em que os transportadores podem ter atrasos e/ou cometer erros de envio. Lamentamos quando isso acontece, mas não podemos ser responsabilizados pelos atrasos causados por esses transportadores.",
    26: "ISENÇÃO DE DIREITOS DE PROPRIEDADE INTELECTUAL",
    27: "Os documentos de design de PCB/produto usados para a fabricação foram fornecidos pelos clientes e a responsabilidade da Lanyue era fabricar a placa/produto de acordo com o design do cliente. Os clientes devem garantir que possuem os direitos de propriedade intelectual sobre os documentos de design fornecidos, que não infrinjam os direitos de propriedade intelectual ou outros direitos legais de terceiros, como direitos de patente, direitos autorais, marcas registradas, etc.",
    28: "Qualquer infração ou problema legal causado pelos documentos fornecidos pelos clientes, que cause impacto ou perda para nós, será de responsabilidade exclusiva dos clientes, que terão que nos indenizar.",
    29: "Contate-nos",
    30: "Se você tiver alguma dúvida ou comentário sobre estes Termos, entre em contato conosco em",
  },
  //看图页面
  canvas: {
    1: {
      1: "Abrir tudo",
      2: "Fechar tudo",
    },
    2: {
      1: "Tamanho",
      2: "Largura mínima da pista",
      3: "Espaçamento mínimo da pista",
      4: "Quantidade de furos de perfuração",
      5: "Tamanho mínimo do furo",
      6: "Tamanho máximo do furo",
      7: "Área ENIG",
      8: "Ponto de teste",
    },
  },
  //订单列表
  orderList: {
    1: "PCB/PCBA&Stencil",
    2: {
      1: "TODOS",
      2: "Cancelado",
      3: "Aguardando pagamento",
      4: "Confirmação pela Oceansmile",
      5: "Pago",
      6: "Em processamento",
      7: "Em embalado",
      8: "Enviado",
      9: "Digite o número do pedido",
    },
    3: {
      1: "Pedido | Informações do produto",
      2: "Todo comprar",
      3: "Material",
      4: "Quantidade",
      5: "Preço",
      6: "Descrição do produto",
      7: "Nome do arquivo",
      8: "Descrição do arquivo",
    },
    4: {
      1: "Telefone",
      2: "Empresa",
      3: "CNPJ",
      4: "Peso",
      5: "Entrega",
      6: "Envio para",
    },
    5: {
      1: "Tempo de construção",
      2: "Hora de pagamento",
      3: "não pago",
      4: "Dicas",
      5: "O tempo de construção é calculado a partir do momento após o pagamento",
      6: "Hora de criação",
    },
    6: {
      1: "Método de pagamento",
      2: "Preço do produto",
      3: "Comissões",
      4: "Taxa de expedição",
      5: "Outro desconto",
      6: "Total",
    },
    7: "Descrição do pedido",
    8: "Ver detalhes",
    9: "Modo de fornecimento",
    10: "Estrutura",
    11: {
      1: "Comprar agora",
      2: "Fatura",
    },
    12: "Produtos",
    13: {
      1: "Preço unitário",
      2: "Preço total",
      3: "Descrição do usuário",
    },
    14: {
      0: "Detalhes",
      1: "Arquivo Gerber",
      2: "Descrição Gerber",
      3: "Descrição do produto",
      4: "Tipo de placa",
      5: "Material",
      6: "Comprimento",
      7: "Largura",
      8: "Camadas",
      9: "Espessura",
      10: "Cobre acabado",
      11: "Tamanho mínimo do furo",
      12: "Máscara de solda",
      13: "Serigrafia",
      14: "Tempo de construção",
      15: "Furo metade",
      16: "Status do pedido",
      17: "Peso do produto",
      18: "Design diferente no painel",
      19: "FR4-TG",
      20: "Quantidade",
      21: "Acabamento de superfície",
      22: "Máscara de solda mínima",
      23: "Largura/espessura mínima da pista",
      24: "Controle de impedância",
      25: "Detalhes da cobrança",
      26: "Taxa de engenharia",
      27: "Painel",
      28: "Furos Castelhados",
      29: "Placa",
      30: "Teste",
      31: "Cobre",
      32: "Acabamento de superfície",
      33: "Processo de via",
      34: "Via cega",
      35: "Via no pad",
      36: "Taxa de V-cut",
      37: "Taxa de cor",
      38: "Tamanho grande",
      39: "Controle de impedância",
      40: "Desconto",
      41: "Total",
      42: "Custo PCBA",
      43: "Custo dos componentes",
      44: "Custo de montagem",
      45: "Custo de serviço",
      46: "Arquivo BOM",
      47: "Descrição do BOM",
      48: "Detalhes dos componentes",
      49: "Confirmação do produto",
      50: "Designador",
      51: "MPN",
      52: "Quantidade total",
      53: "Material desperdiçado",
      54: "Preço unitário",
      55: "Descrição",
      56: "Versões",
      57: "Estrutura",
      58: "Tamanho",
      59: "Lado da estêncil",
      60: "Detalhes da cobrança",
      61: "Stencil SMD",
    },
    15: {
      1: "Pedido suplementar",
      2: "Complementar número do pedido",
      3: "Número do pedido",
      4: "Diferença de preço",
      5: "Descrição",
      6: "Pagar",
    },
    16: "PCB",
    17: "PCBA",
    18: "Stencil",
    message: {
      1: "Excluído com sucesso.",
      2: "Você deseja pagar o pedido de pagamento suplementar junto?",
      3: "Dicas",
      4: "Por favor, selecione um pedido.",
      5: "Copiado com sucesso.",
    },
  },
  //预览价格
  preview: {
    1: "Voltar",
    2: "Endereço de envio",
    3: "Editar",
    4: "Excluir",
    5: "ADICIONAR NOVO ENDEREÇO",
    6: {
      1: "Se é o endereço padrão",
      2: "Podemos confirmar alguns problemas com seus pedidos ou arquivos por e-mail, por favor, verifique se o e-mail é válido!",
    },
    7: "Método de envio",
    8: "Não aplicável",
    9: "Cupons",
    10: "Nenhum",
    11: "Método de pagamento",
    12: "Cartão de crédito",
    13: "Transferência bancária manual (TT)",
    14: {
      1: "RESUMO",
      2: "Preço da diferença",
      3: "Comissão",
      4: "Peso do produto (KG)",
      5: "Subtotal",
      6: "Custo de envio",
      7: "Preço original",
      8: "Montante do desconto",
      9: "Montante a pagar",
      10: "Desconto do cupom",
      11: "Desconto OceanSmile",
      12: "Comprar agora",
    },
    message: {
      1: "Tem certeza de que deseja excluir o endereço?",
      2: "Dica",
      3: "Excluído com sucesso.",
      4: "O primeiro nome não pode estar vazio.",
      5: "O sobrenome não pode estar vazio.",
      6: "A empresa não pode estar vazia.",
      7: "A rua não pode estar vazia.",
      8: "O país não pode estar vazio.",
      9: "A província não pode estar vazia.",
      10: "A cidade não pode estar vazia.",
      11: "O código postal não pode estar vazio.",
      12: "O telefone não pode estar vazio.",
      13: "O e-mail não pode estar vazio.",
    },
  },
  //下单页面(PCB和钢网)
  orderView: {
    1: {
      1: "Orçamento online",
      2: "Carregar arquivo Gerber",
      3: "Revisão do pedido",
      4: "Pagamento",
      5: "Fabricação",
      6: "Envio",
      7: "Confirmar e revisar",
    },
    2: "Seleção de especificação PCB",
    3: {
      1: "Adicionar arquivos Gerber",
      2: "Carregamento bem-sucedido",
      3: "Visualizador online Gerber",
      4: "O arquivo PCB foi carregado com sucesso, mas os parâmetros não podem ser analisados.",
      5: "Somente arquivos ZIP ou RAR são aceitos. Tamanho máximo: 50 MB.",
      6: "Se o arquivo zip carregado não for analisado corretamente ou se nenhuma imagem for exibida, não se preocupe, nossos engenheiros revisarão manualmente e entrarão em contato por e-mail mais tarde.",
      7: "Para requisitos especiais não disponíveis nesta página ou se você não encontrar o que está procurando, envie seus requisitos e arquivos Gerber e ",
      8: "responderemos dentro de 24 horas.",
    },
    4: {
      1: "Observação para Gerber",
      2: "Peças individuais",
      3: "Painel pelo cliente",
      4: "Painel pela OSpcb",
      5: "Trilhos de borda",
      6: "Não",
      7: "Tamanho do painel",
      8: "Quantidade de painéis",
      9: "Outros",
      10: "Observação para PCB",
    },
    5: {
      1: "Montagem de PCB",
      2: "Quantidade única",
      3: "Estêncil",
      4: "Modo de fornecimento de peças",
      5: "Turnkey",
      6: "Consignação",
      7: "Combo",
      8: "Observação para PCBA",
      9: "Observação para BOM",
      10: "Adicionar arquivo BOM",
      11: "Baixar modelo de BOM",
      12: "Por favor, faça upload de um arquivo BOM preciso, seguindo este modelo para que possamos lê-lo corretamente.",
      13: "MPN pode ser escolhido entre Digikey, Mouser, Arrow, Element ou qualquer outra plataforma, nosso sistema o reconhecerá automaticamente.",
      14: "Para quaisquer solicitações especiais de montagem de PCB, escreva para",
      15: "não escreva diretamente no arquivo BOM.",
      16: "Só reconhecemos vírgulas para distinguir a localização.",
      17: "O número do designador deve ser ≤ Quantidade.",
      18: "Por favor, não preencha o número de peça Digikey ou Mouser na linha MPN (Número de peça do fabricante).",
      19: "Por favor, não inclua componentes desnecessários no seu arquivo BOM.",
    },
    6: {
      1: "Por favor, selecione o país",
      2: "Especificação do PCB",
      3: "Quantidade",
      4: "Tempo de construção",
      5: "Custo do PCB",
      6: "Taxa de engenharia",
      7: "Placa",
      8: "Custo do PCBA",
      9: "Custo de montagem",
      10: "Custo dos componentes",
      11: "Sub-total",
      12: "Adicionar ao carrinho",
      13: "dias úteis",
    },
    str: {
      "Normal FR-4 Board": "Placa FR-4 Normal",
      "Flexible Boards": "Placas flexíveis",
      "Board Type": "Tipo de placa",
      "Single Quantity": "Quantidade única",
      "FR4-TG": "FR4-TG",
      "Single Size": "Tamanho único",
      "Different Design in Panel": "Design diferente no painel",
      "Min Trace Width/Spacing": "Largura mínima da trilha/Espaçamento",
      "Min Hole Size": "Tamanho mínimo do furo",
      "Minimum Solder Mask Dam": "Dique mínimo de máscara de solda",
      "Half Hole": "Furo parcial",
      "Surface Finish": "Acabamento de superfície",
      "Finished Copper": "Cobre acabado",
      "Impedance Control": "Controle de impedância",
      "Panel Quantity": "Quantidade de painel",
      "Edge Qty": "Quantidade de bordas",
      "Solder Mask": "Máscara de solda",
      "TG 130-140": "TG 130-140",
      "1 Layer": "1 camada",
      "2 Layer": "2 camadas",
      "4 Layer": "4 camadas",
      "6 Layer": "6 camadas",
      "4/4mil": "4/4mil",
      "5/5mil": "5/5mil",
      "6/6mil": "6/6mil",
      "0_2mm": "0,2mm",
      "0_25mm": "0,25mm",
      "0_3mm": "0,3mm",
      "0_4mm": "0,4mm",
      "HASL with lead": "HASL com chumbo",
      "HASL lead free": "HASL sem chumbo",
      "Immersion gold": "Ouro por imersão",
      "1oz": "1oz",
      "2oz": "2oz",
      "50Ω": "50Ω",
      "90Ω": "90Ω",
      "100Ω": "100Ω",
      "1_0w/(m⋅K)": "1,0w/(m⋅K)",
      "2_0w/(m⋅K)": "2,0w/(m⋅K)",
      "0_08": "0,08",
      "0_13": "0,13",
      "0_22": "0,22",
      "0_6": "0,6",
      "0_8": "0,8",
      "1_0": "1,0",
      "1_2": "1,2",
      "1_6": "1,6",
      "2_0": "2,0",
      "0_6mm": "0,6mm",
      "0_8mm": "0,8mm",
      "1_0mm": "1,0mm",
      "1_2mm": "1,2mm",
      "1_6mm": "1,6mm",
      "2_0mm": "2,0mm",
      "0_10": "0,10",
      "0_12": "0,12",
      "0_15": "0,15",
      "0_18": "0,18",
      "0_10mm": "0,10mm",
      "0_12mm": "0,12mm",
      "0_15mm": "0,15mm",
      "0_18mm": "0,18mm",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      "1/3oz": "1/3oz",
      "0_5oz": "0,5oz",
      mm: "mm",
      OSP: "OSP",
      None: "Nenhum",
      White: "Branco",
      Black: "Preto",
      Green: "Verde",
      Red: "Vermelho",
      Yellow: "Amarelo",
      Blue: "Azul",
      Silkscreen: "Serigrafia",
      Aluminum: "Alumínio",
      Layers: "Camadas",
      Material: "Material",
      Thickness: "Espessura",
      TCE: "TCE",
      No: "Não",
      Yes: "Sim",
      "Single Pieces": "Peças únicas",
      "Panel By Customer": "Painel pelo cliente",
      "Panel By OSpcb": "Painel por OSpcb",
      true: "Sim",
      false: "Não",
      Turnkey: "Turnkey",
      Consignment: "Consignação",
      Combo: "Combo",
      Unit: "Unidade",
      Total: "Total",
      Framework: "Estrutura",
      "Stencil Side": "Lado do estêncil",
      "Existing fiducials": "Fiduciais existentes",
      Top: "Topo",
      Bottom: "Fundo",
      "Top+Bottom(On Single Stencil)": "Topo+Fundo (Em um único estêncil)",
      "Top & Bottom(On Separate Stencil)":
        "Topo e Fundo (Em estêncil separado)",
      "SIZE (mm)": "TAMANHO (mm)",
      Quantity: "Quantidade",
      none: "Nenhum",
      "half lasered": "Metade a laser",
      "lasered through": "Laserizado completamente",
      undefined: "",
    },
    tips: {
      Material:
        "O PCB FR-4 é o material mais utilizado. O PCB de alumínio tem maior dureza física e melhor dissipação térmica. O PCB flexível é muito mais fino que outros materiais e pode ser dobrado em certa medida.",
      "FR4-TG":
        "Resistência à alta temperatura do PCB FR-4. Quanto maior o valor do TG, melhor a resistência à temperatura.",
      Layers:
        "Número de camadas de cobre no seu arquivo, por padrão a única placa tem máscara de solda apenas em um lado.",
      Thickness: "A espessura da placa acabada que você deseja.",
      "Solder Mask": "A cor da máscara de solda aplicada no material base.",
      Silkscreen: "A cor das marcações de serigrafia.",
      "Single Size": "O comprimento e largura da sua placa.",
      "Single Quantity": "A quantidade das placas necessárias.",
      "Panel Quantity": "A quantidade de placas necessárias.",
      "Board Type":
        "Placas podem ser únicas ou agrupadas com PCBs iguais ou diferentes.",
      "Different Design in Panel":
        "Quantos designs diferentes no seu arquivo separados por v-cuts, furos ou fendas.",
      "Min Trace Width/Spacing":
        "Largura mínima de trilha e espaçamento entre as trilhas no seu arquivo.",
      "Min Hole Size": "Tamanho mínimo dos furos no seu arquivo.",
      "Minimum Solder Mask Dam":
        "Dique mínimo de máscara de solda entre os pads de cobre.",
      "Half Hole":
        "Furos PTH ou vias cortadas para formar furos parciais ou meia abertura.",
      "Surface Finish":
        "O tratamento HASL aplica uma camada fina de solda nos pads. O ENIG tem melhor planicidade e vida útil mais longa que o HASL.",
      "Finished Copper":
        "Espessura do cobre na camada externa (superior e inferior).",
      TCE: "Coeficiente de expansão térmica, uma propriedade do material que indica até onde o material se expande quando aquecido.",
      Framework:
        "Garanta que a estrutura esteja corretamente alinhada e posicionada.",
      "Stencil Side":
        "Verifique o alinhamento do lado do estêncil antes de usá-lo.",
      "Existing fiducials":
        "Verifique a posição dos fiduciais existentes para garantir a precisão do alinhamento.",
    },
    7: {
      1: "Estêncil SMD",
      2: "Área válida",
      3: "Custo do estêncil",
      4: "Tipo de estêncil",
      5: "Tamanho",
      6: "Lado",
      7: "Peso",
      8: "Detalhes de taxas",
      9: "Estêncil SMD",
    },
    message: {
      1: "Se os dados excederem o limite do sistema, envie diretamente o arquivo de design para o e-mail fornecido.",
      2: "Forneceremos feedback manual e orçamento em até 24 horas.",
      3: "Preencha a largura da placa.",
      4: "Preencha o comprimento da placa.",
      5: "Preencha a quantidade de placas.",
      6: "Faça o upload do arquivo Gerber.",
      7: "Faça o upload do arquivo BOM.",
    },
  },
  //人工转账页面
  pay: {
    1: "Sua solicitação de pagamento foi aceita.",
    2: "Por favor, faça a transferência via conta bancária. O pagamento será concluído assim que a transferência for realizada. Total do pagamento",
    3: "Suporta transferência de conta empresarial e pessoal",
    4: "1. Se sua conta de pagamento for uma conta bancária empresarial, por favor pague para esta conta",
    5: "2. Se sua conta de pagamento for uma conta bancária pessoal, por favor pague para esta conta",
    6: "Banco beneficiário",
    7: "Endereço do Banco Beneficiário",
    8: "Código SWIFT",
    9: "Nome completo do beneficiário",
    10: "Número da conta do beneficiário",
    11: "Endereço e número de telefone do beneficiário",
    12: "Banco intermediário",
    13: "Código SWIFT (intermediário)",
    14: "Endereço do Banco Intermediário",
    15: {
      1: "Dicas para a remessa",
      2: "1. Após realizar o pagamento, envie o número do pedido Ospcb e uma cópia do comprovante bancário para service@cnospcb.com para que possamos iniciar a produção no tempo certo.",
      3: "2. Certifique-se de inserir as informações corretas da conta bancária ao fazer a remessa.",
      4: "3. Transfira o valor total em um único pagamento. Não divida o pagamento em mais de uma transferência.",
      5: "4. Guarde o comprovante de transferência bancária para referência futura.",
      6: "Dicas para segurança financeira",
      7: "1. Nossa equipe usará apenas o e-mail com o domínio cnospcb.com para entrar em contato com você, portanto, não confie em nenhum e-mail com outro domínio.",
      8: "2. Se houver alguma alteração nas informações bancárias, iremos anunciar no site com antecedência, por favor, não confie nem transfira o dinheiro para qualquer conta bancária diferente da exibida nesta página.",
    },
  },
  //支付跳转页面
  payment: {
    1: "Dica: Pode haver um atraso no pagamento. Se não chegar por um longo tempo, entre em contato com o suporte ao cliente",
    2: "Redirecionando automaticamente em",
    3: "segundos",
    4: "agora",
  },
  //支付记录
  payRecord: {
    1: {
      1: "Número do pedido",
      2: "Receber pagamento",
      3: "Tipo de pagamento",
    },
    2: {
      1: "Número do pedido",
      2: "Valor pago",
      3: "Moedas",
      4: "Tipo de pagamento",
      5: "ID do pagamento",
      6: "E-mail do pagamento",
      7: "Hora do pagamento",
      8: "Pagamento recebido",
      9: "Comprovante de pagamento",
      10: "Ação",
    },
    3: {
      1: "Baixar PDF",
      2: "Aguardando envio",
      3: "Carregar",
    },
    message: {
      1: "Comprovante de transferência enviado com sucesso. Por favor, aguarde pacientemente a revisão pela equipe!",
    },
  },
  //购物车
  cart: {
    1: {
      1: "PCB/PCBA e Stencil",
      2: "TODOS",
      3: "PCB",
      4: "PCBA",
      5: "Stencil",
      6: "Digite o nome do arquivo",
    },
    2: {
      1: "Produtos",
      2: "Comprar tudo",
      3: "Material",
      4: "Quantidade",
      5: "Preço",
      6: "Status do produto",
      7: "Descrição do produto",
      8: "Nome do arquivo",
      9: "Descrição do arquivo",
      10: "Hora de criação",
      11: "Total",
    },
    3: "Tempo de construção",
    4: "Ver detalhes",
    5: "Comprar agora",
    6: "Hora de criação",
    7: "Total",
    8: "Aguardando confirmação",
    9: "Modo de fornecimento",
    10: {
      1: "Palavra-chave",
      2: "Nome do produto",
      3: "Inclui bateria",
      4: "Peso total",
      5: "Preço unitário",
      6: "Preço Ext.",
      7: "Status",
      8: "Descrição",
      9: "Comprar",
      10: "Excluir",
      11: "Ação",
    },
    statusMap: {
      0: "Excluir",
      1: "Aguardando cotação",
      2: "Aguardando confirmação do cliente",
      3: "Disponível para compra",
      4: "Pedido enviado",
      undefined: "",
    },
    message: {
      1: "Selecione o item que deseja pagar!",
      2: "Tem certeza de que deseja excluir este produto?",
      3: "Dica",
      4: "Excluir",
      5: "Excluído com sucesso.",
      6: "Deseja pagar junto com o pedido de reposição?",
      7: "Alterações nos componentes confirmadas.",
    },
  },
  //补款订单
  supplement: {
    1: {
      1: "Número do pedido de reposição",
      2: "Número do produto",
      3: "Status",
      4: "Tipo de pagamento",
      5: "Tipo de produto",
      6: "Pagar tudo",
    },
    2: {
      1: "Número do pedido",
      2: "Tipo de produto",
      3: "Número do produto",
      4: "Valor",
      5: "Tipo de pagamento",
      6: "Taxa de serviço",
      7: "Total",
      8: "Hora de criação",
      9: "Status do suplemento",
      10: "Ação",
    },
    3: {
      1: "Ver",
      2: "Pagar",
      3: "Fatura",
    },
    4: {
      1: "Detalhes",
      2: "Número do suplemento",
      3: "Status do suplemento",
      4: "Número do pedido",
      5: "Tipo de produto",
      6: "Número do produto",
      7: "Tipo de pagamento",
      8: "Taxa de serviço",
      9: "Valor total",
      10: "Hora de pagamento",
      11: "Descrição",
      12: "Aguardando cálculo",
      13: "Indisponível",
    },
    productType: {
      1: "PCB",
      2: "PCBA",
      3: "Stencil",
      4: "Taxa de envio",
    },
    message: {
      1: "Selecione um pedido.",
    },
  },
  //地址
  address: {
    1: {
      1: "Editar",
      2: "Excluir",
    },
    2: "ADICIONAR NOVO ENDEREÇO",
    3: "EDITAR ENDEREÇO",
    4: {
      1: "Primeiro nome",
      2: "Sobrenome",
      3: "Empresa",
      4: "Rua",
      5: "Por favor, selecione um país",
      6: "Províncias",
      7: "Cidade",
      8: "CEP",
      9: "Telefone",
      10: "E-mail",
      11: "Número fiscal",
      12: "É este o endereço padrão?",
      13: "Podemos confirmar alguns problemas com seus pedidos ou arquivos por e-mail, por favor, certifique-se de que o e-mail está correto.",
    },
  },
  //优惠券
  coupon: {
    1: "Meus cupons",
    2: "Cupom",
    useOfTypeMap: {
      1: "utilizável",
      2: "em uso",
      3: "usado",
      4: "expirado",
    },
    consumptionTypeMap: {
      0: "Ilimitado",
      1: "Para PCB",
      2: "Para PCBA",
      3: "Para estêncil",
    },
    useTypeMap: {
      1: "Custo total",
      2: "Custo do frete",
    },
  },
  //信函
  letter: {
    1: {
      1: "TODOS",
      2: "Reclamação",
      3: "Feedback",
      4: "Proposta",
      5: "Pedido",
    },
    2: "Criar",
    3: {
      1: "Número de série",
      2: "Título",
      3: "Tipo",
      4: "Conteúdo",
      5: "Ver",
      6: "Hora de criação",
      7: "Hora de término",
      8: "Estado da mensagem",
      9: "Entregue",
      10: "Visto",
      11: "Taxa de progresso",
      12: "Mensagens",
      13: "Ação",
      14: "Responder",
      15: "Selecionar tipo",
    },
    4: "Detalhes do conteúdo",
    5: "Detalhes da mensagem",
    6: "Responder",
    7: {
      1: "Destinatários",
    },
    8: {
      1: "Conteúdo da resposta",
    },
    9: {
      1: "Criar",
      2: "Comunicação",
      3: "Finalizar",
    },
    10: {
      1: "Pessoal",
      2: "Serviço",
    },
    message: {
      1: "O título não pode estar vazio.",
      2: "O tipo não pode estar vazio.",
      3: "O conteúdo não pode estar vazio.",
      4: "Criado com sucesso!",
      5: "Respondido com sucesso.",
    },
  },
  //公告
  notice: {
    1: "Título",
    2: "Equipe",
    3: "Hora de publicação",
    4: "Ação",
    5: "Detalhes",
    detail: {
      1: "Voltar",
      2: "Notificação do sistema",
      3: "Notificação de atividades",
    },
  },
  //个人信息
  profile: {
    1: "Perfil",
    2: {
      1: "Nome de usuário",
      2: "Tipo de conta",
      3: "Avatar",
      4: "Atualizar avatar",
      5: "Nome da empresa",
      6: "Número fiscal",
      7: "Número de telefone",
      8: "Facebook",
      9: "Skype",
      10: "Whatsapp",
      11: "Atualizar",
    },
    3: {
      1: "Atualizar senha",
      2: "Senha atual",
      3: "Nova senha",
      4: "Confirmar senha",
    },
    4: {
      1: "Salvar",
      2: "Cancelar",
    },
    5: {
      1: "pessoal",
      2: "corporativo",
    },
    message: {
      1: "A nova senha e a confirmação não coincidem.",
    },
  },
  //支付方式
  paymentType: {
    1: "PayPal",
    2: "Cartão de crédito",
    3: "Transferência bancária manual (TT)",
    4: "Outro",
    5: "USD",
  },
  //后端返回的信息
  message: {
    200: "Sucesso!",
    "-1": "Erro desconhecido!",
    401: "Sessão expirada, por favor, faça login novamente!",
    403: "Falha na verificação de autorização!",
    10000:
      "Falha no sistema! Por favor, envie feedback ao administrador a tempo! Obrigado!",
    10001: "O tamanho do arquivo excede o limite superior!",
    10002: "Por favor, selecione o pedido que deseja pagar.",
    10003: "Erro no pedido!",
    10004:
      "O tamanho do arquivo excede o limite superior! Tamanho máximo do arquivo é 50MB!",
    10005: "Um pedido foi gerado para este item!",
    10006:
      "Por favor, faça o upload do arquivo Gerber da máscara de solda! Apenas arquivos ZIP ou RAR são aceitos. Tamanho máximo de 50MB!",
    10007: "O preço do molde não pôde ser encontrado!",
    10008:
      "Na seleção Top & Bottom (em stencil separado), a quantidade não pode ser menor que 2!",
    10009: "O produto selecionado atualmente não existe ou foi desativado!",
    10010: "O preço de venda deste produto ainda não foi anunciado!",
    10011: "Sem estoque, não pode ser adicionado ao carrinho!",
    10012: "O item atual no carrinho não existe!",
    10013: "O preço de venda deste produto ainda não foi anunciado!",
    10014:
      "O estoque atual do produto é menor que a quantidade modificada, por favor, ajuste e tente novamente!",
    10015: "O artigo não existe ou foi excluído!",
    10016: "Por favor, selecione o comentário que deseja responder!",
    10017: "Este comentário não existe ou foi removido!",
    10018: "O UUID não existe!",
    10019: "Por favor, selecione o comentário que deseja curtir!",
    10020: "Este comentário não existe ou foi removido!",
    10021: "Por favor, selecione o comentário que deseja descurtir!",
    10022:
      "Por favor, selecione os detalhes do artigo do fórum que deseja visualizar!",
    10023: "A categoria do fórum selecionada não existe!",
    10024: "Por favor, selecione o artigo que deseja excluir!",
    10025: "Por favor, selecione o artigo que deseja curtir!",
    10026: "Por favor, selecione o artigo que deseja descurtir!",
    10027: "Por favor, selecione o artigo que deseja cancelar a coleta!",
    10028: "Por favor, selecione o produto para visualizar a versão Gerber!",
    10029: "Por favor, selecione o produto para gerar um pedido!",
    10030: "Atualmente não há pedidos disponíveis para pagamento!",
    10031: "O pagamento não foi bem-sucedido!",
    10032:
      "Por favor, faça o upload do arquivo Gerber da PCB. Apenas arquivos ZIP ou RAR são aceitos. Tamanho máximo de 50MB!",
    10033:
      "Por favor, faça o upload do arquivo BOM da PCBA. Apenas arquivos .CSV, .XLS ou .XLSX são aceitos!",
    10034: "O painel pelo cliente x não pode ser nulo!",
    10035: "O painel pelo cliente y não pode ser nulo!",
    10036: "O painel pelo cliente x não pode exceder 500 mm!",
    10037: "O painel pelo cliente y não pode exceder 500 mm!",
    10038: "Os trilhos de borda não podem ser nulos!",
    10039: "O tamanho do painel não pode ser nulo!",
    10040: "O Tg do FR4 não pode ser nulo!",
    10041: "O dam mínimo de máscara de solda do FR4 não pode ser nulo!",
    10042: "O controle de impedância do FR4 não pode ser nulo!",
    10043: "A quantidade de bordas de furos do FR4 está incorreta!",
    10044: "O TCE do alumínio não pode ser nulo!",
    10045: "A quantidade da PCBA não pode ser 0!",
    10046: "Stencil é necessário para PCBA!",
    10047: "O modo de fornecimento de peças não pode ser vazio!",
    10048: "O ID de endereço não pode ser nulo!",
    10049: "A senha antiga está incorreta!",
    10050: "A conta de e-mail não existe!",
    10051: "O CAPTCHA expirou!",
    10052: "O CAPTCHA está incorreto!",
    10053: "O formato do e-mail está incorreto!",
    10054: "Este e-mail já foi registrado!",
    10055: "O nome de usuário ou senha está incorreto!",
    10056: "Por favor, selecione as mensagens marcadas como lidas!",
    10057: "O método de pagamento não pode ser nulo!",
    10058: "O tipo de item de pagamento não pode ser vazio!",
    10059: "O ID do pedido não pode ser nulo!",
    10060: "Por favor, selecione o pedido que deseja pagar!",
    10061: "Seu pedido foi processado!",
    10062: "O preço do molde não pôde ser encontrado!",
    10063: "Não é possível obter o preço do modelo atual!",
    10064: "Seu pedido está pendente de confirmação ou já foi processado!",
    10065: "Por favor, selecione o produto que deseja pagar!",
    10066:
      "Os produtos selecionados estão em condição anormal ou já foram pagos!",
    10067: "O produto PCB selecionado já tem um pedido gerado!",
    10068: "Seu pedido está pendente de confirmação ou já foi processado!",
    10069: "O produto PCBA selecionado já tem um pedido gerado!",
    10070: "O produto stencil selecionado já tem um pedido gerado!",
    10071: "Por favor, selecione o que deseja visualizar!",
    10072:
      "O formato do e-mail está incorreto. Por favor, insira um e-mail correto!",
    10073: "O código de verificação não existe!",
    10074: "O código de verificação está incorreto, tente novamente!",
    10075: "Por favor, selecione o produto para visualizar a versão BOM!",
    10076: "Por favor, selecione o pedido que deseja confirmar!",
    10077:
      "Esta operação não pode ser realizada devido ao status anormal do produto!",
    10078: "Por favor, selecione os detalhes da marca que deseja visualizar!",
    10079: "Por favor, selecione o item a ser desmarcado!",
    10080: "Apenas usuários que compraram este produto podem avaliá-lo!",
    10081: "Você já avaliou este produto!",
    10082: "Por favor, selecione os detalhes do produto que deseja visualizar!",
    10083: "O número do pedido não pode ser nulo!",
    10084: "Os pedidos não podem ser nulos!",
    10085: "Falha no pagamento com cartão de crédito!",
    10086:
      "Forneça os números de pedidos para gerar o recibo de transferência!",
    10087: "O produto selecionado já tem um pedido gerado!",
    10088:
      "Os seguintes itens não podem ser comprados devido à falta de estoque!",
    10089: "Não é possível gerar pedidos devido à falta de estoque!",
    10090: "Erro na rede, tente novamente mais tarde!",
    10091: "O feedback atual está aguardando processamento!",
    10092: "O feedback atual foi processado!",
    10093: "Por favor, selecione os detalhes do anúncio que deseja visualizar!",
    10094:
      "Por favor, selecione uma localização para consultar o gráfico do carrossel!",
    10095: "O recurso acessado não existe!",
  },
};
